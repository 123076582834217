import { Magic } from 'magic-sdk';

import API from '@api/API';

import { useAppContext } from '../contexts/AppContext';

const { REACT_APP_MAGIC_LINK_API_KEY = '' } = process.env;

export const magic = new Magic(REACT_APP_MAGIC_LINK_API_KEY, { deferPreload: true });

const authWithMagicToken = (token: string) => {
  return API.post<{ user: any; token: string }, { token: string }>('/v1/user/auth/magic', {
    token,
  });
};

export const useMagic = () => {
  const { updateToken } = useAppContext();

  const loginWithEmailOTP = async (email: string) => {
    if (await magic.user.isLoggedIn()) {
      await magic.user.logout();
    }

    const magicToken = await magic.auth.loginWithEmailOTP({ email });

    if (magicToken) {
      const { user, token } = await authWithMagicToken(magicToken);

      if (!token) {
        throw new Error('Failed to authenticate with token');
      }

      updateToken(token);
      return { userId: user._id as string };
    } else {
      throw new Error('No login token');
    }
  };

  const loginWithSms = async (phoneNumber: string) => {
    if (await magic.user.isLoggedIn()) {
      await magic.user.logout();
    }

    const magicToken = await magic.auth.loginWithSMS({ phoneNumber });

    if (magicToken) {
      const { user, token } = await authWithMagicToken(magicToken);

      if (!token) {
        throw new Error('Failed to authenticate with token');
      }

      updateToken(token);
      return { userId: user._id as string };
    } else {
      throw new Error('No login token');
    }
  };

  return { magic, loginWithEmailOTP, loginWithSms };
};
