import { useState } from 'react';

export const useShareLink = () => {
  const [didCopy, setDidCopy] = useState(false);

  const shareLink = async ({
    title,
    text,
    url,
  }: {
    title?: string;
    text?: string;
    url: string;
  }) => {
    try {
      if (navigator.share) {
        return navigator.share({ title, text, url });
      }
    } catch (e) {}

    await navigator.clipboard.writeText(url);
    setDidCopy(true);

    setTimeout(() => {
      setDidCopy(false);
    }, 3000);
  };

  return { didCopy, shareLink };
};

const base64ToBlob = (base64Image: string) => {
  const byteCharacters = atob(base64Image.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/jpeg' });

  return blob;
};

export const convertBase64ToUrl = (base64Image: string): string => {
  const blob = base64ToBlob(base64Image);
  return window.URL.createObjectURL(blob);
};

// Function to convert base64 to Blob and trigger download
const downloadImage = (base64Image: string, filename: string) => {
  const blob = base64ToBlob(base64Image);
  const url = window.URL.createObjectURL(blob);
  window.open(url, '_blank');
};

export const useShareImage = () => {
  const shareImage = async ({ base64Image }: { base64Image: string }) => {
    const fileName = 'image.jpg';

    try {
      if (navigator.share) {
        const blob = base64ToBlob(base64Image);
        const file = new File([blob], fileName, {
          type: 'image/jpeg',
          lastModified: new Date().getTime(),
        });

        await navigator.share({ files: [file] });

        return;
      }
    } catch (e) {}

    downloadImage(base64Image, 'image.jpg');
  };

  return { shareImage };
};
