import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { isEmpty, keyBy, sortBy } from 'lodash';

import API from '@api/API';

import {
  Entertainer,
  EntertainerType,
  TYPES_RECORD,
  useCurrentEntertainerQuery,
} from '@queries/EntertainerQueries';

import { useAnalytics } from '@hooks/useAnalytics';
import { CurrentCity, useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useNavigate, useRouter } from '@router/index';

import Button from '@components/buttons/Button';
import { FormLabel } from '@components/form/FormLabel';
import { ImageUploadField } from '@components/form/ImageUploadField';
import { Input } from '@components/form/Input';
import { Select } from '@components/form/Select';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { List, ListItemProps } from '@components/layout/List/List';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { Link } from '../../router/index';
import { useOnboardEntertainer } from './OnboardEntertainerScreen/OnboardEntertainerScreen';
import { CitiesField, MultiSelectField } from './components/CitiesField';
import { ContactFields } from './components/ContactFields';
import { FeaturedPhotoManagementSection } from './components/FeaturedPhotoManagementSection';

const DRAG_QUEEN_SERVICES = [
  'Brunches',
  'Philanthropy Functions',
  'School functions',
  'Key Note Speaking',
  'All Ages Events',
  'Makeup Classes',
  'Birthdays',
  'Company Parties',
  'Bingos',
  'Weddings (Performer, Officiate, guest speaker, party host)',
  'Live Singing',
  'Panels',
  'Drag Queen Story Hour',
  'Model/TV Appearances',
  'Bachelor/Bachelorette Parties',
  'Produce Endorsement',
  'Charity Functions',
  'Party Host',
  'Drag Performances',
  'Impersonations/illusions',
  'Makeup for Special Events',
  'Private Events',
  'Holiday and Special Events',
  'Guest Judge',
];

export const DRAG_PERFORMER_CATEGORIES = [
  'Acrobatics',
  'Alternative',
  'Androgynous',
  'Bearded',
  'Bingo Host',
  'BIPOC Artist',
  'Body Positive',
  'Broadway',
  'Brunch Host',
  'Burlesque',
  'Cabaret',
  'Camp',
  'Celeb Impersonations',
  'Choreographer',
  'Club Kid',
  'Comedy King',
  'Comedy Queen',
  'Cosplay',
  'Costume Designer',
  'Dancing Diva',
  'Disabled',
  'DJ',
  'Drag King',
  'Drag Quing',
  'Drag Thing',
  'Dyke',
  'Fashion',
  'Genderfuck',
  'Glamour',
  'Goth',
  'Hair & Wigs',
  'Host/MC',
  'Improv',
  'Jewish',
  'Lip Sync Assassin',
  'Live Vocals',
  'Look Queen',
  'Makeup Artist',
  'Monster',
  'Musician',
  'Non-Binary',
  'Pageant',
  'Party Host',
  'Performance Artist',
  'Pop Artist',
  'Producer',
  'Punk',
  'Recording Artist',
  'Showgirl',
  'Spooky',
  'Stand-Up Comedy',
  'Stripper',
  'Stunts & Tricks',
  'Thick & Juicy',
  'TikToker',
  'Trans',
  'YouTuber',
];

const ENABLE_MEDIA = false;

interface CreateEntertainerPayload {
  avatar: any;
  type: EntertainerType;
  title: string;
  tagline: string;
  description: string;
  cityIds: string[];
  contact?: Entertainer['contact'];
  categories?: string[];
}

type UpdateEntertainerPayload = Partial<CreateEntertainerPayload> & {
  bookingHandle?: string;
  smsKeyword?: string;
};

const useCreateEntertainerMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync: createEntertainerAsync } = useMutation({
    mutationFn: async ({
      avatar,
      title,
      tagline,
      description,
      type,
      contact,
      cityIds,
      categories,
    }: CreateEntertainerPayload) => {
      try {
        await API.post('/v1/entertainer/me', {
          type,
          title,
          tagline,
          description,
          contact,
          cityIds,
          categories,
        });

        await API.put('/v1/entertainer/me', {
          avatar,
        });
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentEntertainerQuery.queryKey() });
    },
  });

  return {
    isSubmitting,
    createEntertainerAsync,
  };
};

export const useUpdateEntertainerMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync: updateEntertainerAsync } = useMutation({
    mutationFn: async ({
      avatar,
      title,
      type,
      tagline,
      description,
      contact,
      cityIds,
      bookingHandle,
      categories,
      smsKeyword,
    }: UpdateEntertainerPayload) => {
      const promises: any[] = [];

      try {
        const promise = API.put('/v1/entertainer/me', {
          title,
          type,
          tagline,
          description,
          contact,
          cityIds,
          bookingHandle,
          categories,
          smsKeyword,
        });

        promises.push(promise);
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }

      if (avatar) {
        try {
          const promise = API.put('/v1/entertainer/me', {
            avatar,
          });

          promises.push(promise);
        } catch (e) {
          console.error(e);
          Sentry.captureException(e);
        }
      }

      return Promise.all(promises);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'entertainer'] });
    },
  });

  return {
    isSubmitting,
    updateEntertainerAsync,
  };
};

interface CreateEditEntertainerProfileFormProps {
  entertainer?: Entertainer;
  currentCity?: CurrentCity;
  cities: CurrentCity[];
  hideContactFields?: boolean;
  onSave?: () => void;
}
export const CreateEditEntertainerProfileForm: FC<CreateEditEntertainerProfileFormProps> = ({
  entertainer,
  currentCity,
  cities,
  hideContactFields,
  onSave,
}) => {
  const { track } = useAnalytics();
  const [updatedEntertainerProperties, setUpdatedEntertainerProperties] =
    useState<UpdateEntertainerPayload>(
      entertainer
        ? { ...entertainer, avatar: undefined }
        : { cityIds: currentCity._id ? [currentCity._id] : [], type: 'dragPerformer' },
    );
  const [links, setLinks] = useState<Entertainer['contact']['links']>(
    entertainer?.contact?.links ?? [],
  );

  const isEditingProfile = !!entertainer;

  const { isSubmitting: isCreatingProfile, createEntertainerAsync } =
    useCreateEntertainerMutation();
  const { isSubmitting: isUpdatingProfile, updateEntertainerAsync } =
    useUpdateEntertainerMutation();

  const isSubmitting = isCreatingProfile || isUpdatingProfile;
  const canSubmit = !Boolean(
    (!entertainer && !updatedEntertainerProperties.avatar) ||
      !updatedEntertainerProperties.title ||
      !updatedEntertainerProperties.tagline ||
      !updatedEntertainerProperties.description ||
      updatedEntertainerProperties.cityIds.length === 0 ||
      isSubmitting,
  );

  const handleChangeImage = (updatedEventImage: string | ArrayBuffer) => {
    setUpdatedEntertainerProperties((prevValue) => ({
      ...prevValue,
      avatar: updatedEventImage,
    }));
  };

  const handleSubmitProfile = async () => {
    if (canSubmit) {
      const mergedUpdatedEntertainerProperties = {
        ...updatedEntertainerProperties,
        contact: {
          ...updatedEntertainerProperties.contact,
          links: links.filter((link) => link.text && link.url),
        },
      };
      track('Save Entertainer Profile', mergedUpdatedEntertainerProperties);
      if (isEditingProfile) {
        await updateEntertainerAsync(mergedUpdatedEntertainerProperties);
      } else {
        await createEntertainerAsync(
          mergedUpdatedEntertainerProperties as CreateEntertainerPayload,
        );
      }

      try {
        await onSave?.();
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <>
      <Card>
        <ImageUploadField
          placeholder="Add a profile image..."
          defaultValue={entertainer?.avatar}
          onChange={handleChangeImage}
        />
      </Card>

      <div className="promoter-form grid gap-2">
        <div />
        <div>
          <FormLabel text="Entertainer Type" />
          <Select
            options={sortBy(
              Object.entries(TYPES_RECORD).map(([value, label]) => ({
                value,
                label,
              })),
              'label',
            )}
            value={updatedEntertainerProperties.type}
            onChange={(e) =>
              setUpdatedEntertainerProperties((prevValue) => ({
                ...prevValue,
                type: e.target.value as EntertainerType,
              }))
            }
          />
        </div>
        <div>
          <FormLabel text="Stage Name *" />
          <Input
            defaultValue={entertainer?.title}
            name="stage-name"
            placeholder="eg. Glitter Disco, Willow Wisp, etc."
            onChange={(e) =>
              setUpdatedEntertainerProperties((prevValue) => ({
                ...prevValue,
                title: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <FormLabel text="Tagline *" />
          <Textarea
            name="tagline"
            defaultValue={entertainer?.tagline}
            placeholder="A short description to grab attention"
            onChange={(value) =>
              setUpdatedEntertainerProperties((prevValue) => ({ ...prevValue, tagline: value }))
            }
          />
        </div>

        {updatedEntertainerProperties.type === 'dragPerformer' && (
          <MultiSelectField
            label="Categories *"
            name="categories"
            defaultValue={entertainer?.details?.categories ?? []}
            options={DRAG_PERFORMER_CATEGORIES.map((value) => ({ value, label: value }))}
            onChange={(categories) =>
              setUpdatedEntertainerProperties((prevValue) => ({ ...prevValue, categories }))
            }
          />
        )}

        <div>
          <FormLabel text="Bio *" />
          <Textarea
            defaultValue={entertainer?.description}
            name="description"
            placeholder="Tell everyone about what you do"
            onChange={(value) =>
              setUpdatedEntertainerProperties((prevValue) => ({ ...prevValue, description: value }))
            }
          />
        </div>

        <MultiSelectField
          label="City *"
          name="city"
          defaultValue={updatedEntertainerProperties.cityIds}
          options={cities.map((city) => ({
            value: city._id,
            label: city.name,
          }))}
          onChange={(cityIds) =>
            setUpdatedEntertainerProperties((prevValue) => ({
              ...prevValue,
              cityIds,
            }))
          }
        />

        {entertainer._id && false && (
          <FeaturedPhotoManagementSection featuredMedia={entertainer.featuredMedia ?? []} />
        )}

        {!isEmpty(updatedEntertainerProperties.contact) && !hideContactFields && (
          <>
            <TitleToolbar text="Contact Information" size="sm" />

            <ContactFields.Instagram
              contact={updatedEntertainerProperties.contact}
              setContact={(updatedValue) =>
                setUpdatedEntertainerProperties((prevValue) => ({
                  ...prevValue,
                  contact: { ...prevValue.contact, ...updatedValue },
                }))
              }
            />
            <ContactFields.Facebook
              contact={updatedEntertainerProperties.contact}
              setContact={(updatedValue) =>
                setUpdatedEntertainerProperties((prevValue) => ({
                  ...prevValue,
                  contact: { ...prevValue.contact, ...updatedValue },
                }))
              }
            />
            <ContactFields.Email
              contact={updatedEntertainerProperties.contact}
              setContact={(updatedValue) =>
                setUpdatedEntertainerProperties((prevValue) => ({
                  ...prevValue,
                  contact: { ...prevValue.contact, ...updatedValue },
                }))
              }
            />
            <ContactFields.CashApp
              contact={updatedEntertainerProperties.contact}
              setContact={(updatedValue) =>
                setUpdatedEntertainerProperties((prevValue) => ({
                  ...prevValue,
                  contact: { ...prevValue.contact, ...updatedValue },
                }))
              }
            />

            {links.map((link, index) => (
              <div key={index} className="d-flex gap-2">
                <div>
                  <FormLabel text="Link Text" />
                  <Input
                    type="text"
                    placeholder="eg. Website, YouTube, etc."
                    defaultValue={link.text}
                    onChange={(e) => {
                      setLinks((prevLinks) =>
                        prevLinks.map((prevLink, prevIndex) =>
                          prevIndex === index ? { ...prevLink, text: e.target.value } : prevLink,
                        ),
                      );
                    }}
                  />
                </div>
                <div>
                  <FormLabel text="Link URL" />
                  <Input
                    type="text"
                    placeholder="eg. https://www.example.com"
                    defaultValue={link.url}
                    onChange={(e) => {
                      setLinks((prevLinks) =>
                        prevLinks.map((prevLink, prevIndex) =>
                          prevIndex === index ? { ...prevLink, url: e.target.value } : prevLink,
                        ),
                      );
                    }}
                  />
                </div>
              </div>
            ))}

            <Button
              variant="outlined"
              color="neutral"
              roundness="rounded"
              onClick={() => {
                setLinks((prevLinks) => [...prevLinks, { type: 'custom', text: '', url: '' }]);
              }}
            >
              Add Link
            </Button>
          </>
        )}

        <div className="mt-2">
          <Button roundness="rounded" disabled={!canSubmit} onClick={handleSubmitProfile}>
            {isEditingProfile ? 'Update Profile' : 'Create Profile'}
          </Button>
        </div>
      </div>

      {ENABLE_MEDIA && (
        <>
          <TitleToolbar text="Promo Video" />
          <Card>
            <AsyncImage src="https://picsum.photos/300/200" />
          </Card>
        </>
      )}
    </>
  );
};

export const CreateEntertainerProfileScreen: FC<{}> = () => {
  const [welcomeMessageIsVisible, setWelcomeMessageIsVisible] = useState(true);
  const { back } = useRouter();
  const { currentCity, cities } = useCurrentUserCity();

  const { entertainer, onboardingElement } = useOnboardEntertainer({
    onSubmitInstagram: () => setWelcomeMessageIsVisible(false),
    onSkipInstagram: () => setWelcomeMessageIsVisible(false),
  });

  return (
    <Screen
      name="Create Entertainer Profile Screen"
      hideToolbar
      headerProps={{
        left: (
          <Button
            startIcon={<FontAwesomeIcon name="chevron-left" />}
            variant="smooth"
            color="neutral"
            roundness="rounded"
            className="py-2"
            onClick={() => back('/profile')}
          >
            Events
          </Button>
        ),
        title: 'Create Profile',
      }}
    >
      <Screen.Content maxWidth={425}>
        {welcomeMessageIsVisible ? (
          <>
            <TitleToolbar text="Elevate Your Entertainment Career" size="md" />
            <p>
              As a Gaggl member, you already enjoy discovering amazing events. If you&apos;re an
              entertainer, take advantage of our exclusive features! Access gig opportunities,
              showcase your events, and manage bookings effortlessly.
            </p>
            <p>
              Whether you&apos;re a musician, DJ, comedian, or performer, Gaggl helps you connect,
              grow, and shine. Enhance your profile today and step into your next big break!
            </p>
            {onboardingElement}
          </>
        ) : (
          <>
            {currentCity && (
              <CreateEditEntertainerProfileForm
                currentCity={currentCity}
                entertainer={entertainer}
                cities={cities}
              />
            )}
          </>
        )}
      </Screen.Content>
    </Screen>
  );
};
