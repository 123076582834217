import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from '@api/API';

import {
  AnswerTriviaQuestionResponse,
  CurrentUserTriviaQuestionResponse,
  TriviaQuestion,
  useUserCurrentTriviaQuestion,
} from '@queries/TriviaQueries';

interface CreateTriviaChallengePayload {
  cityId: string;
}

export const useCreateTriviaChallengeMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync: createTriviaChallengeAsync } = useMutation({
    mutationFn: (payload: CreateTriviaChallengePayload) => {
      return API.post<{ triviaChallenge: { _id: string } }, CreateTriviaChallengePayload>(
        `/v1/trivia/`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['triviaChallenges'] });
    },
  });

  return {
    isSubmitting,
    createTriviaChallengeAsync,
  };
};

export interface UpdateTriviaChallengePayload {
  questionIds: string[];
}

export const useUpdateTriviaChallengeMutation = (
  { triviaChallengeId }: { triviaChallengeId: string },
) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync: updateTriviaChallengeAsync } = useMutation({
    mutationFn: (payload: UpdateTriviaChallengePayload) => {
      return API.put(`/v1/trivia/${triviaChallengeId}`, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['triviaChallenges', triviaChallengeId] });
    },
  });

  return {
    isSubmitting,
    updateTriviaChallengeAsync,
  };
};

interface PlayCityTriviaChallengePayload {
  lat: number;
  lng: number;
}
export const usePlayCityTriviaChallengeMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync: playCityTriviaChallengeAsync } = useMutation({
    mutationFn: (payload: PlayCityTriviaChallengePayload) => {
      return API.post<{ question: string; expiresAt: string }, PlayCityTriviaChallengePayload>(
        `/v1/trivia/play`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useUserCurrentTriviaQuestion.queryKey() });
    },
  });

  return {
    isSubmitting,
    playCityTriviaChallengeAsync,
  };
};

interface PlayAppTriviaChallengePayload {}
export const usePlayAppTriviaChallengeMutation = () => {
  const { isPending: isSubmitting, mutateAsync: playAppTriviaChallengeAsync } = useMutation({
    mutationFn: (payload: PlayAppTriviaChallengePayload | undefined) => {
      return API.post<CurrentUserTriviaQuestionResponse, PlayAppTriviaChallengePayload>(
        `/v1/trivia/play`,
        payload,
      );
    },
  });

  return {
    isSubmitting,
    playAppTriviaChallengeAsync,
  };
};

interface AnswerTriviaQuestionPayload {
  triviaQuestionId: string;
  answer: string;
}

export const useAnswerTriviaQuestionMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync: answerTriviaQuestionAsync } = useMutation({
    mutationFn: (payload: AnswerTriviaQuestionPayload) => {
      return API.post<AnswerTriviaQuestionResponse, AnswerTriviaQuestionPayload>(
        `/v1/trivia/play/submit`,
        payload,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useUserCurrentTriviaQuestion.queryKey() });
    },
  });

  return {
    isSubmitting,
    answerTriviaQuestionAsync,
  };
};
