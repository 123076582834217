import { ReactElement } from 'react';

import { BasicRating } from '@queries/CritiqueQueries';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';

export const BASIC_RATING_COLOR_RECORD: Record<BasicRating, string> = {
  like: '#4cd964',
  neutral: '#ffcc00',
  dislike: '#ff3b30',
};

export const BASIC_RATING_RECORD: Record<
  BasicRating,
  { label: string; pastTenseLabel: string; Icon: (props: { isSelected: boolean }) => ReactElement }
> = {
  like: {
    label: 'I like it',
    pastTenseLabel: 'I liked it',
    Icon: ({ isSelected }) => (
      <FontAwesomeIcon faStyle={isSelected ? 'fas' : 'far'} name="smile-beam" />
    ),
  },
  neutral: {
    label: "It's okay",
    pastTenseLabel: 'It was okay',
    Icon: ({ isSelected }) => <FontAwesomeIcon faStyle={isSelected ? 'fas' : 'far'} name="meh" />,
  },
  dislike: {
    label: "I don't like it",
    pastTenseLabel: "I didn't like it",
    Icon: ({ isSelected }) => <FontAwesomeIcon faStyle={isSelected ? 'fas' : 'far'} name="frown" />,
  },
} as const;
