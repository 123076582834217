import { List as F7List, ListItem as F7ListItem } from 'framework7-react';
import { FC, ReactElement, ReactNode } from 'react';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';

import './List.scss';

export interface ListItemProps {
  isVisible?: boolean;
  hasCheckbox?: boolean;
  defaultChecked?: boolean;
  title: string;
  afterTitle?: ReactNode;
  subTitle?: string;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  noChevron?: boolean;
  onClick?: () => void;
  onCheckboxChange?: (updatedCheckedValue: boolean) => void;
}

const DeprecatedListItem: FC<ListItemProps> = ({ title, subTitle, onClick = () => {} }) => {
  return (
    <li className="list-item" onClick={onClick}>
      <div className="list-item__text">
        <h5 className="list-item__title">{title}</h5>
        {subTitle && <div className="list-item__sub-title">{subTitle}</div>}
      </div>
      <div className="list-item__end-icon">
        <FontAwesomeIcon name="chevron-right" />
      </div>
    </li>
  );
};

interface ListProps {
  className?: string;
  listItems: ListItemProps[];
  ignoreScreenContentPadding?: boolean;
  showDividers?: boolean;
}

export const DeprecatedList = ({ listItems }: ListProps) => {
  return (
    <ul className="list">
      {listItems
        .filter(({ isVisible = true }) => isVisible)
        .map((listItemProps, i) => (
          <DeprecatedListItem key={i} {...listItemProps} />
        ))}
    </ul>
  );
};

export const List: FC<ListProps> = ({
  className = '',
  listItems,
  ignoreScreenContentPadding,
  showDividers = true,
}) => {
  return (
    <F7List
      className={`${
        ignoreScreenContentPadding ? 'ignore-screen-content-padding' : ''
      } ${className}`}
      dividersIos={showDividers}
      outlineIos={showDividers}
    >
      {listItems
        .filter(({ isVisible = true }) => isVisible)
        .map((listItemProps, i) => (
          <F7ListItem
            key={listItemProps.title}
            checkbox={listItemProps.hasCheckbox}
            defaultChecked={listItemProps.defaultChecked}
            link={listItemProps.onClick ? '#' : undefined}
            title={listItemProps.title}
            footer={listItemProps.subTitle}
            onClick={listItemProps.onClick}
            onChange={(e) => listItemProps.onCheckboxChange?.(e.target.checked)}
            noChevron={listItemProps.noChevron}
          >
            {listItemProps.startIcon && <div slot="media">{listItemProps.startIcon}</div>}
            {listItemProps.afterTitle && <div slot="after-title">{listItemProps.afterTitle}</div>}
            {listItemProps.endIcon}
          </F7ListItem>
        ))}
    </F7List>
  );
};
