import { HeadCell, RenderTable, TableRow } from '@components/table/RenderTable';
import {
  ParsedEventProperties,
  SourceLog,
  useSourceLogsQuery,
} from '@admin/queries/SourceLogsQueries';
import { truncate, isEmpty } from 'lodash';

import './SourceLogsPage.scss';
import { useMemo, useState } from 'react';
import * as dayjs from 'dayjs';
import Button from '@components/buttons/Button';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { Input } from '@components/form/Input';
import { useUpdateSourceLogMutation } from '@admin/mutations/SourceLogsMutations';
import { DatePicker } from '@components/form/DatePicker';
import IconButton from '@components/buttons/IconButton';

const days = ['Sundays', 'Mondays', 'Tuesdays', 'Wednesdays', 'Thursdays', 'Fridays', 'Saturdays'];

const DATE_FORMAT = 'MM/DD/YY hh:mma';
const headCells: HeadCell<TableRow<SourceLog>>[] = [
  {
    id: 'postedAt',
    label: 'Posted At',
    TableBodyCell: ({ row }) => (
      <td>{dayjs(row.postForProcessing.postedAt).format(DATE_FORMAT)}</td>
    ),
  },
  {
    id: 'processedAt',
    label: 'Processed At',
    TableBodyCell: ({ row }) => <td>{dayjs(row.createdAt).format(DATE_FORMAT)}</td>,
  },
  {
    id: 'hasEvent',
    className: 'has-event-col',
    label: 'Event/Tuning',
    TableBodyCell: ({ row }) => (
      <td className="has-event-col">
        {!isEmpty(row.processedEventProperties) ? 'yes' : 'no'}/
        {!isEmpty(row.fineTuningValue) ? 'yes' : 'no'}
      </td>
    ),
  },
  {
    id: 'text',
    className: 'text-col',
    label: 'Text',
    TableBodyCell: ({ row }) => (
      <td className="text-col">
        {truncate(row.postForProcessing.text, {
          length: 100,
          omission: '...',
        })}
      </td>
    ),
  },
  {
    id: 'startDate',
    label: 'Start Date',
    TableBodyCell: ({ row }) => <td>{row.processedEventProperties?.startDate || '--'}</td>,
  },
  {
    id: 'startTime',
    label: 'Start Time',
    TableBodyCell: ({ row }) => <td>{row.processedEventProperties?.startTime || '--'}</td>,
  },
  {
    id: 'endDate',
    label: 'End Date',
    TableBodyCell: ({ row }) => <td>{row.processedEventProperties?.endDate || '--'}</td>,
  },
  {
    id: 'endTime',
    label: 'End Time',
    TableBodyCell: ({ row }) => <td>{row.processedEventProperties?.endTime || '--'}</td>,
  },
];

const FineTuneEventField = ({
  event,
  handleUpdateEventValue,
  handleRemoveEvent,
}: {
  event: ParsedEventProperties;
  handleUpdateEventValue: (key: string, value: any) => void;
  handleRemoveEvent: () => void;
}) => {
  return (
    <div className="fine-tune-event" key={JSON.stringify(event)}>
      <Input
        placeholder="Title"
        defaultValue={event.title}
        onChange={(e) => handleUpdateEventValue('title', e.target.value)}
      />
      <DatePicker
        placeholder="Start Date"
        value={event.startDate}
        onChange={(updatedValue) => {
          const updatedValueFormatted = dayjs(updatedValue).format('YYYY-MM-DD');
          console.log(updatedValueFormatted);
          handleUpdateEventValue('startDate', updatedValueFormatted);
        }}
      />
      <Input
        placeholder="Start Time"
        defaultValue={event.startTime}
        onChange={(e) => handleUpdateEventValue('startTime', e.target.value)}
      />
      <DatePicker
        placeholder="End Date"
        value={event.endDate ? event.endDate : event.startDate}
        onChange={(updatedValue) => {
          const updatedValueFormatted = dayjs(updatedValue).format('YYYY-MM-DD');
          handleUpdateEventValue('endDate', updatedValueFormatted);
        }}
      />
      <Input
        placeholder="End Time"
        defaultValue={event.endTime}
        onChange={(e) => handleUpdateEventValue('endTime', e.target.value)}
      />
      <div className="d-flex align-items-center custom-checkbox">
        <input
          type="checkbox"
          defaultChecked={event.isRecurring ?? false}
          onChange={(e) => handleUpdateEventValue('isRecurring', e.target.checked)}
        />
        <label>Recurring</label>
      </div>
      {event.isRecurring && (
        <select
          defaultValue={event.days[0]}
          onChange={(e) => handleUpdateEventValue('days', [Number(e.target.value)])}
        >
          <option>---</option>
          {days.map((day, i) => (
            <option key={i} value={i}>
              {day}
            </option>
          ))}
        </select>
      )}
      <Button variant="outlined" onClick={handleRemoveEvent}>
        Remove
      </Button>
    </div>
  );
};

const Drawer = ({ currentRow, onClose }: { currentRow: SourceLog; onClose: () => void }) => {
  const [events, setEvents] = useState<ParsedEventProperties[]>(() => {
    if (currentRow.fineTuningValue?.events) {
      return currentRow.fineTuningValue?.events;
    }

    if (currentRow.processedEventProperties) {
      return [
        {
          title: currentRow.processedEventProperties?.title,
          description: currentRow.postForProcessing?.text,
          startDate: currentRow.processedEventProperties?.startDate,
          startTime: currentRow.processedEventProperties?.startTime,
          endDate: currentRow.processedEventProperties?.endDate,
          endTime: currentRow.processedEventProperties?.endTime,
          isRecurring: currentRow.processedEventProperties?.isRecurring,
          days: currentRow.processedEventProperties?.days,
        },
      ];
    }

    return [];
  });

  const handleUpdateEventValue = (index: number, key: string, value: any) => {
    setEvents((prevValue) => {
      const updatedValue = ['isRecurring', 'startDate', 'endDate'].includes(key)
        ? [...prevValue] // Clone current array so that it knows that there is a rerender
        : prevValue;

      updatedValue[index] = {
        ...updatedValue[index],
        [key]: value,
      };

      return updatedValue;
    });
  };

  const { isSubmitting, updateSourceLogAsync } = useUpdateSourceLogMutation(currentRow._id);
  const handleSave = async () => {
    const updatedEvents = events.map((event) => ({
      ...event,
      endDate: event.endDate ?? event.startDate,
      description: currentRow.postForProcessing.text,
      days: event.isRecurring ? event.days : [],
    }));

    await updateSourceLogAsync({
      events: updatedEvents,
    });
  };

  return (
    <div className="current-row-drawer">
      {mapStringToParagraphs(currentRow.postForProcessing.text)}
      <h3>Fine Tuned Properties</h3>
      {events.map((event, i) => (
        <FineTuneEventField
          key={i}
          event={event}
          handleUpdateEventValue={(key, value) => handleUpdateEventValue(i, key, value)}
          handleRemoveEvent={() => {
            setEvents((prevValue) => {
              prevValue[i] = undefined;
              return prevValue.filter(Boolean);
            });
          }}
        />
      ))}
      <Button
        variant="outlined"
        onClick={() =>
          setEvents((prevValue) => [
            ...prevValue,
            {
              title: '',
              description: currentRow.postForProcessing.text,
              startDate: '',
              startTime: '',
              endDate: '',
              endTime: '',
              isRecurring: false,
              days: [],
            },
          ])
        }
      >
        Add Event
      </Button>
      <pre>{JSON.stringify(currentRow, null, 2)}</pre>

      <div className="current-row-drawer_footer">
        <Button color="neutral" onClick={onClose}>
          Close
        </Button>
        <Button disabled={isSubmitting} className="save-btn" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export const SourceLogsPage = () => {
  const [showElPotrero, setShowElPotrero] = useState(false);
  const [currentRow, setCurrentRow] = useState<SourceLog>();
  const {
    sourceLogsAreReady,
    sourceLogs = [],
    refetchSourceLogs,
  } = useSourceLogsQuery({
    isEnabled: true,
  });

  const applyElPotreroFilter = <T extends { sourceUsername: string }>(sourceLogs: T[]): T[] => {
    return sourceLogs.filter(({ sourceUsername }) => sourceUsername !== 'elpotrerodenver');
  };

  const rows = useMemo(() => {
    const filters = [];

    if (!showElPotrero) {
      filters.push(applyElPotreroFilter);
    }

    return filters
      .reduce((filteredSourceLogs, filterFn) => filterFn(filteredSourceLogs), sourceLogs)
      .map((sourceLog) => ({
        key: sourceLog._id,
        ...sourceLog,
      }));
  }, [sourceLogs, showElPotrero]);

  return (
    <div className="source-logs-page">
      <Button color="primary" onClick={() => (document.location = '/discover')}>
        Back
      </Button>
      <div className="container">
        <button onClick={() => setShowElPotrero((prevValue) => !prevValue)}>
          {showElPotrero ? 'Hide El Potrero' : 'Show El Potrero'}
        </button>
        <RenderTable headCells={headCells} rows={rows} onRowClick={setCurrentRow} />
      </div>
      {currentRow && (
        <Drawer
          key={currentRow._id}
          currentRow={currentRow}
          onClose={() => setCurrentRow(undefined)}
        />
      )}
    </div>
  );
};
