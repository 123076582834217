import { ComponentProps, ReactNode } from 'react';

import Button from '@components/buttons/Button';

import { PopoverContentComponent, PopoverWrapper } from '../Popover/PopoverWrapper';
import './EmptyState.scss';

type EmptyStateButton =
  | {
      text: string;
      onClick: () => void;
      PopoverContent?: undefined;
    }
  | {
      text: string;
      onClick?: undefined;
      PopoverContent: PopoverContentComponent;
    };

export const EmptyState = ({
  className = '',
  title,
  text,
  content,
  button,
  secondaryButton,
}: {
  className?: string;
  title?: string;
  text?: string;
  content?: ReactNode;
  button?: EmptyStateButton;
  secondaryButton?: EmptyStateButton;
}) => {
  return (
    <div className={`empty-state-wrapper ${className}`}>
      <div className="empty-state">
        {title && <h4>{title}</h4>}
        {text && <p>{text}</p>}
        {content}
        {button?.onClick && <Button onClick={button.onClick}>{button.text}</Button>}
        {secondaryButton?.onClick && (
          <Button
            className="mt-2"
            variant="outlined"
            color="neutral"
            onClick={secondaryButton.onClick}
          >
            {secondaryButton.text}
          </Button>
        )}
        {button?.PopoverContent && (
          <PopoverWrapper className="w-100" PopoverContent={button?.PopoverContent}>
            <Button onClick={button.onClick}>{button.text}</Button>
          </PopoverWrapper>
        )}
      </div>
    </div>
  );
};
