import { FC, useEffect, useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import { useAppContext } from '@contexts/AppContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { sum } from 'lodash';
import ordinal from 'ordinal';

import API from '@api/API';

import { CurrentEntertainer, useTopEntertainersQuery } from '@queries/EntertainerQueries';
import { FeedPost, useFeedPostsQuery } from '@queries/PostQueries';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { emptyChartProps } from '@components/layout/DetailsChartSection/DetailsChartSection';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { BASIC_RATING_COLOR_RECORD } from '@components/overlays/CritiqueOverlay/CritiqueOverlayConstants';

import { RatingActivityCard } from '@screens/CommunityScreen/RatingActivityCard';

dayjs.extend(utc);

const boostEntertainerProfile = ({ entertainerId }: { entertainerId: string }) => {
  return API.post(`/v1/user/entertainer/${entertainerId}/boost`, {});
};

const BoostPopup: FC<{
  isVisible: boolean;
  onClose: () => void;
  canBoostProfile: boolean;
  posts: any[];
  onCritique: () => void;
  onBoostProfile: () => void;
}> = ({ isVisible, onClose, canBoostProfile, posts, onCritique, onBoostProfile }) => {
  return (
    <Popup height="full" isVisible={isVisible} onClose={onClose}>
      <div className="p-3">
        <TitleToolbar text="Unlock Your Boost" size="md" />
        <p className="mt-0">
          Rate one of the following profiles to unlock a boost for your own. Show some love to get
          some love!
        </p>

        {canBoostProfile ? (
          <Button
            startIcon={<FontAwesomeIcon name="chart-line" />}
            roundness="rounded"
            onClick={onBoostProfile}
          >
            Boost my profile
          </Button>
        ) : (
          <Button startIcon={<FontAwesomeIcon name="lock" />} disabled roundness="rounded">
            Boost my profile
          </Button>
        )}

        <TitleToolbar text="Trending Profiles" size="md" />
        {posts.map((post) => (
          <RatingActivityCard
            key={post.documentId}
            post={post}
            meta={{ unlockBoost: true }}
            onCritique={onCritique}
          />
        ))}
      </div>
    </Popup>
  );
};

export const BoostProfileSection: FC<{
  city: { _id: string; slug?: string; name: string };
  currentEntertainer: CurrentEntertainer;
  entertainerBasicRatings: Record<string, number>;
  type: CurrentEntertainer['type'];
  back: string;
}> = ({ city: currentCity, currentEntertainer, entertainerBasicRatings, type, back }) => {
  const [expiresAt, setExpiresAt] = useState<Date>(() => {
    if (currentEntertainer.boostExpiresAt) {
      const localizedExpiresAt = dayjs.utc(currentEntertainer.boostExpiresAt).local();

      if (localizedExpiresAt.isAfter(dayjs())) {
        return dayjs.utc(currentEntertainer.boostExpiresAt).local().toDate();
      }
    }

    return undefined;
  });
  const [remainingSeconds, setRemainingSeconds] = useState<number>();
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const { userIsLoggedIn } = useAppContext();
  const navigate = useNavigate();
  const { track } = useAnalytics();

  const [canBoostProfile, setCanBoostProfile] = useState(
    currentEntertainer?.boosts?.includes('basic'),
  );
  const { posts = [] } = useFeedPostsQuery();
  const { topEntertainers } = useTopEntertainersQuery(currentCity._id, type, userIsLoggedIn);

  const handleBoostProfile = async () => {
    track('Boost profile');

    if (!currentEntertainer) {
      return;
    }

    await boostEntertainerProfile({ entertainerId: currentEntertainer._id });
    setPopupIsVisible(false);
    setExpiresAt(dayjs().add(8, 'hours').toDate());
  };

  const handlePrimaryBoostProfile = () => {
    if (canBoostProfile) {
      handleBoostProfile();
    } else {
      track('Open Boost Profile Popup');
      setPopupIsVisible(true);
    }
  };

  const entertainerPostActivity = useMemo(() => {
    return posts.filter((post: any) => post.type === 'ratingActivity');
  }, [posts]);

  const pieChartData: { key: string; value: number; color: string }[] = useMemo(() => {
    const total = sum(Object.values(entertainerBasicRatings ?? {}));

    return Object.entries(entertainerBasicRatings)
      .filter(([key, value]) => value && key !== 'dislike')
      .map(([key, value]: [string, number]) => ({
        key,
        value: value ? Math.round((value / total) * 100) : 0,
        color: BASIC_RATING_COLOR_RECORD[key],
      }));
  }, [entertainerBasicRatings]);

  const currentEntertainerRank = useMemo(() => {
    if (!topEntertainers?.length || !currentEntertainer) {
      return undefined;
    }

    return topEntertainers.find((entertainer) => entertainer._id === currentEntertainer._id);
  }, [currentEntertainer, topEntertainers]);

  useEffect(() => {
    if (expiresAt) {
      const initialSeconds = dayjs(expiresAt).diff(dayjs(), 'seconds');
      setRemainingSeconds(initialSeconds > 0 ? initialSeconds : null);

      const interval = setInterval(() => {
        setRemainingSeconds((prevSeconds) => {
          if (prevSeconds === null || prevSeconds <= 0) {
            clearInterval(interval);
            setExpiresAt(undefined);
            return null;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [expiresAt]);

  const buttonText = useMemo(() => {
    if (remainingSeconds) {
      const remainingTimeFormatted = dayjs()
        .startOf('day')
        .add(remainingSeconds, 'seconds')
        .format('H[h] mm[m] ss[s]');
      return `Boost Active! Time remaining: ${remainingTimeFormatted}`;
    }

    return 'Boost my profile';
  }, [remainingSeconds]);

  if (!currentEntertainerRank) {
    return <></>;
  }

  const pieChartDataIsEmpty = pieChartData.every((data) => data.value === 0);

  return (
    <div
      className="d-grid gap-2 mt-4 p-3"
      style={{ border: '1px solid rgba(255, 255, 255, .7)', borderRadius: 16 }}
    >
      <TitleToolbar
        text={currentEntertainer.title}
        size="md"
        action={
          <Button
            variant="smooth"
            color="neutral"
            size="sm"
            className="px-3"
            roundness="rounded"
            onClick={() => {
              track('Manage Profile');
              navigate('/manage/entertainer', {
                state: {
                  back,
                },
              });
            }}
          >
            Manage Profile
          </Button>
        }
      />
      <div className="d-flex gap-3 align-items-center">
        <div style={{ width: '30%' }}>
          <PieChart
            rounded
            lineWidth={25}
            radius={50}
            data={pieChartData}
            paddingAngle={25}
            // label={({ dataEntry }) => `${dataEntry.value}%`}
            labelStyle={(index) => ({
              fill: pieChartData[index].color,
              fontSize: '12px',
              fontWeight: 'bold',
              fontFamily: 'Rubik',
              letterSpacing: 1,
            })}
            {...(pieChartDataIsEmpty
              ? {
                  rounded: false,
                  data: [{ key: 'empty', value: 100, color: 'rgba(241,241,241, .2)' }],
                  label: undefined,
                  labelStyle: undefined,
                  paddingAngle: 0,
                }
              : {})}
          />
        </div>
        <div style={{ width: '70%' }}>
          <p className="mt-0">
            Boosted profiles typically see between 34-52% increase in their rating score! Boost your
            profile for 8hrs to promote yourself and get more ratings!
          </p>
        </div>
      </div>
      {expiresAt ? (
        <Button roundness="rounded" variant="smooth">
          {buttonText}
        </Button>
      ) : (
        <Button
          startIcon={<FontAwesomeIcon name="chart-line" />}
          roundness="rounded"
          onClick={handlePrimaryBoostProfile}
        >
          {buttonText}
        </Button>
      )}
      <BoostPopup
        isVisible={popupIsVisible}
        posts={entertainerPostActivity}
        onClose={() => setPopupIsVisible(false)}
        canBoostProfile={canBoostProfile}
        onCritique={() => setCanBoostProfile(true)}
        onBoostProfile={handleBoostProfile}
      />
    </div>
  );
};
