import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useAppContext } from '@contexts/AppContext';

import API from '@api/API';

import { useAnalytics } from '@hooks/useAnalytics';

import Button from '@components/buttons/Button';
import Card from '@components/layout/Card/Card';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

const useUserEntertainerCampaignVoteQuery = (entertainerId: string, campaignId: string) => {
  const { userIsLoggedIn } = useAppContext();

  const { data, isFetched } = useQuery({
    queryKey: ['entertainer', entertainerId, 'campaign', campaignId, 'vote'],
    queryFn: () =>
      API.get<{ didVote: boolean }>(
        `/v1/entertainers/${entertainerId}/campaigns/${campaignId}/vote`,
      ),
    enabled: userIsLoggedIn,
  });

  return {
    userEntertainerCampaignVote: data,
    isUserEntertainerCampaignVoteReady: isFetched,
  };
};

const useUserEntertainerCampaignVoteMutation = (entertainerId: string, campaignId: string) => {
  const { userIsLoggedIn } = useAppContext();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      API.post(`/v1/entertainers/${entertainerId}/campaigns/${campaignId}/vote`, {}),
  });

  return {
    voteForEntertainerAsync: mutateAsync,
    isVotingForEntertainer: isPending,
  };
};

export const EntertainerCompetitionCard = ({
  competition,
  entertainer,
}: {
  competition: { _id: string; title: string };
  entertainer: { _id: string; title: string };
}) => {
  const { track } = useAnalytics();
  const { createAuthGateHandler } = useAuthGate();
  const [didVote, setDidVote] = useState(false);

  const { userEntertainerCampaignVote } = useUserEntertainerCampaignVoteQuery(
    entertainer._id,
    competition._id,
  );

  const { voteForEntertainerAsync, isVotingForEntertainer } =
    useUserEntertainerCampaignVoteMutation(entertainer._id, competition._id);

  const handleVoteForEntertainer = () => {
    track('Competition Vote', {
      entertainerId: entertainer._id,
      campaignId: competition._id,
    });

    createAuthGateHandler(async () => {
      setDidVote(true);
      await voteForEntertainerAsync();
    });
  };
  return (
    <Card>
      <Card.Header title={competition.title} />
      <Card.Footer>
        {userEntertainerCampaignVote?.didVote || didVote ? (
          <Button roundness="rounded" disabled>
            Voted
          </Button>
        ) : (
          <Button
            roundness="rounded"
            onClick={handleVoteForEntertainer}
            disabled={isVotingForEntertainer}
          >
            Vote for {entertainer.title}
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};
