import { Progressbar } from 'framework7-react';
import { FC, MouseEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import { sum } from 'lodash';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';

import Card from '../Card/Card';
import { EmptyState } from '../EmptyState/EmptyState';
import './DetailsChartSection.scss';

const getPercentage = (count: number, total: number) => {
  return Math.round((count / total) * 100);
};

interface DetailsChartData<ChoiceType extends string> {
  choices: Record<ChoiceType, number>;
  reasons: Record<ChoiceType, { count: number; label: string }[]>;
  reasonTotals: Record<ChoiceType, number>;
  colors: Record<ChoiceType, string>;
}

interface DetailsChartSectionProps<ChoiceType extends string> {
  detailsChartData: DetailsChartData<ChoiceType>;
  emptyState?: ReactNode;
  choiceType?: ChoiceType;
  updateChoiceType?: (choiceType: ChoiceType) => void;
}

export const emptyChartProps = {
  rounded: false,
  data: [{ key: 'empty', value: 100, color: 'rgba(241,241,241, .2)' }],
  label: undefined,
  labelStyle: undefined,
  paddingAngle: 0,
};

export const DetailsChartSection = <ChoiceType extends string>({
  detailsChartData,
  emptyState = <></>,
  choiceType,
  updateChoiceType,
}: DetailsChartSectionProps<ChoiceType>) => {
  const [currentChoiceType, setCurrentChoiceType] = useState<ChoiceType>(
    choiceType ?? (Object.keys(detailsChartData.choices)?.[0] as ChoiceType),
  );

  useEffect(() => {
    if (choiceType) {
      setCurrentChoiceType(choiceType);
    }
  }, [choiceType]);

  const pieChartData: { key: string; value: number; color: string }[] = useMemo(() => {
    const total = sum(Object.values(detailsChartData.choices));

    return Object.entries(detailsChartData.choices)
      .filter(([, value]) => value)
      .map(([key, value]: [string, number]) => ({
        key,
        value: Math.round((value / total) * 100),
        color: detailsChartData.colors[key],
      }));
  }, [detailsChartData.choices, detailsChartData.colors]);

  const pieChartDataIsEmpty = pieChartData.every((data) => data.value === 0);

  const handleClick = (event: MouseEvent, index: number) => {
    const { key } = pieChartData[index];
    setCurrentChoiceType(key as ChoiceType);
  };

  const currentChartReasons = detailsChartData.reasons[currentChoiceType];

  return (
    <div className="details-chart-section d-flex gap-2 align-items-stretch">
      <div className="align-self-center">
        <div className="details-chart-section_pie-chart-wrapper">
          <PieChart
            rounded
            lineWidth={25}
            radius={50}
            data={pieChartData}
            paddingAngle={25}
            // label={({ dataEntry }) => `${dataEntry.value}%`}
            labelStyle={(index) => ({
              fill: pieChartData[index].color,
              fontSize: '12px',
              fontWeight: 'bold',
              fontFamily: 'Rubik',
            })}
            onClick={handleClick}
            labelPosition={50}
            {...(pieChartDataIsEmpty ? emptyChartProps : {})}
          />
        </div>
      </div>
      <div className="details-chart-section_reason-column">
        <Card>
          {pieChartDataIsEmpty ? (
            <Card.Body>{emptyState}</Card.Body>
          ) : (
            <Card.Body>
              {currentChartReasons.length ? (
                currentChartReasons.slice(0, 3).map((reason, i) => (
                  <div
                    key={`${currentChoiceType}-${i}-${reason.label}`}
                    className="details-chart-section_reason-row"
                  >
                    <div className="details-chart-section_reason-label-wrapper">
                      <div className="details-chart-section_reason-label">
                        <strong>{reason.label}</strong>
                      </div>
                      <div>
                        {getPercentage(
                          reason.count,
                          detailsChartData.reasonTotals[currentChoiceType],
                        )}
                        %
                      </div>
                    </div>
                    <div className="details-chart-section_reason-percentage">
                      <Progressbar
                        style={
                          {
                            '--f7-progressbar-progress-color':
                              detailsChartData.colors[currentChoiceType],
                          } as any
                        }
                        progress={getPercentage(
                          reason.count,
                          detailsChartData.reasonTotals[currentChoiceType],
                        )}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <EmptyState text="No one has shared reasons for their critiques yet. Add your own below" />
              )}
            </Card.Body>
          )}
        </Card>
      </div>
    </div>
  );
};
