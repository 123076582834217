import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { formatPhoneNumber } from 'react-phone-number-input';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { useCurrentPromoterQuery } from '@queries/PromoterQueries';
import { useCurrentUser, useManageCompetitionsQuery } from '@queries/UserQueries';

import { useUpdateProfileMutation } from '@mutations/UserMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useLocation, useNavigate } from '@router/index';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { isEnv } from '@utilities/isEnv';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { Chip } from '@components/chips/Chip';
import { Input } from '@components/form/Input';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { List, ListItemProps } from '@components/layout/List/List';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { ReportOverlay } from '@components/overlays/ReportOverlay/ReportOverlay';

import { useAppContext } from '../contexts/AppContext';
import { UserEventsSection, UserGagglRankingCard } from './HomeScreen/HomeScreen';
import './ProfileScreen.scss';

export const ProfileScreen: FC<{}> = () => {
  const navigate = useNavigate();
  const { currentUserIsReady, currentUser } = useCurrentUser();
  const { promoter } = useCurrentPromoterQuery();
  const { entertainer, entertainerIsReady } = useCurrentEntertainerQuery();
  const { updateToken } = useAppContext();
  const [supportOverlayIsVisible, setSupportOverlayIsVisible] = useState(false);
  const { previousPathName } = useLocation();
  const { competitions = {} } = useManageCompetitionsQuery();
  const { track } = useAnalytics();

  const handleLogout = () => {
    try {
      updateToken(undefined);
      navigate('/', { animate: false });
    } catch (e) {
      console.log(e);
    }
  };

  const listItems: ListItemProps[] = [
    {
      isVisible: false,
      title: 'Edit Profile',
      onClick: () => navigate('/profile/edit'),
    },
    {
      title: `${promoter ? 'Manage' : 'Create'} Producer Profile`,
      onClick: () =>
        navigate('/manage/promoter', {
          state: { back: '/profile' },
        }),
    },
    {
      isVisible: !entertainer,
      title: `${entertainer ? 'Manage' : 'Create'} Entertainer Profile`,
      onClick: () => navigate('/manage/entertainer'),
    },
    {
      isVisible: !!Object.values(competitions).some(
        (promoterCompetitions) => promoterCompetitions.length,
      ),
      title: `Manage Competitions`,
      onClick: () => navigate('/manage/competitions'),
    },
    {
      title: 'App Settings',
      onClick: () =>
        navigate('/profile/settings', {
          state: { back: '/profile' },
        }),
    },
    {
      title: (
        <div className="d-flex gap-2 align-items-center">
          <span>{'Feedback & Support'}</span>
          <Chip
            startIcon={
              <div style={{ fontSize: 12, marginRight: '-.5rem' }}>
                <FontAwesomeIcon name="tools" />
              </div>
            }
            label="Report Issue"
          />
        </div>
      ) as any,
      onClick: () => setSupportOverlayIsVisible(true),
    },
    {
      isVisible: currentUser?.isSuperAdmin,
      title: 'Manage Upcoming Events',
      onClick: () => navigate('/super-admin/manage/events'),
    },
    {
      isVisible: currentUser?.isSuperAdmin,
      title: 'Manage Source Logs',
      onClick: () => (document.location.href = '/admin/source-logs'),
    },
    {
      isVisible: currentUser?.isSuperAdmin,
      title: 'Manage Instagram Accounts',
      onClick: () => (document.location.href = '/admin/instagram'),
    },
    {
      isVisible: currentUser?.isSuperAdmin,
      title: 'Manage Weekly Trivia',
      onClick: () => navigate('/super-admin/manage/trivia'),
    },
    // {
    //   title: 'Log Out',
    //   onClick: handleLogout,
    // },
  ];

  const [updatedDisplayName, setUpdatedDisplayName] = useState<string>();
  const [displayNameEditorIsVisible, setDisplayNameEditorIsVisible] = useState(false);
  const displayName = currentUser?.displayName
    ? currentUser?.displayName
    : currentUser?.firstName ?? '';
  const handleEditDisplayName = () => {
    setDisplayNameEditorIsVisible(true);
  };
  const handleCloseDisplayNameEditor = () => {
    setUpdatedDisplayName(undefined);
    setDisplayNameEditorIsVisible(false);
  };
  const { isSubmitting, updateProfileAsync } = useUpdateProfileMutation();
  const handleSaveDisplayName = async () => {
    if (isSubmitting) {
      return;
    }

    if (updatedDisplayName) {
      await updateProfileAsync({
        displayName: updatedDisplayName,
      });
    }

    setUpdatedDisplayName(undefined);
    setDisplayNameEditorIsVisible(false);
  };

  const handleOnboardingNavigation = useMemo(() => {
    if (!currentUser.onboarding?.basicInfoIsComplete) {
      return () =>
        navigate('/onboarding', {
          animate: false,
        });
    } else if (!currentUser.onboarding?.interestsAreComplete) {
      return () =>
        navigate('/onboarding/interests', {
          animate: false,
        });
    }

    return undefined;
  }, []);
  const generalOnboardingIsVisible = !!handleOnboardingNavigation;

  if (generalOnboardingIsVisible) {
    return (
      <Screen name="General Onboarding Screen" hideToolbar>
        <Screen.Content>
          {entertainerIsReady && entertainer ? (
            <>
              <TitleToolbar text="Welcome to the Gaggl Community App" size="md" />
              <p>
                Hey there! You&apos;re about to enter a whole new side of Gaggl. This part of the
                app is different from your professional experience. To get started, please go
                through the community setup process.
              </p>
              <p>
                This will help you access all the awesome community features like discovering local
                queer events and critiquing them based on diversity and inclusion or just how fun
                they are! Let&apos;s dive in and make the most of it!
              </p>
              <Button className="mt-2" roundness="rounded" onClick={handleOnboardingNavigation}>
                Discover Local Events
              </Button>
            </>
          ) : (
            <>
              <TitleToolbar text="Complete Profile Setup" size="md" />
              <p>
                Hey there! Please complete your profile setup to continue. This will help you access
                advanced features like creating an entertainer profile and participating in local
                competitions.
              </p>
              <Button className="mt-2" roundness="rounded" onClick={handleOnboardingNavigation}>
                Finish Setup
              </Button>
            </>
          )}
          {previousPathName && (
            <Button
              className="mt-2"
              roundness="rounded"
              variant="outlined"
              color="neutral"
              onClick={() => navigate(previousPathName)}
            >
              Back
            </Button>
          )}
        </Screen.Content>
      </Screen>
    );
  }

  return (
    <Screen
      name="Profile Screen"
      headerProps={{
        left: <></>,
        right: (
          <IconButton variant="outlined" onClick={handleLogout}>
            <FontAwesomeIcon name="sign-out-alt" />
          </IconButton>
        ),
      }}
    >
      <Screen.Content>
        <TitleToolbar
          text={
            <div className="d-flex gap-2 align-items-center">
              <div>
                <LetterAvatar
                  letter={findFirstAlphaCharacter(displayName)}
                  style={{ height: 42, width: 42, fontSize: 28 }}
                />
              </div>
              <h1 className="d-flex gap-2 align-items-center m-0">
                <div>
                  {displayNameEditorIsVisible ? (
                    <Input
                      className="display-name-input"
                      defaultValue={displayName}
                      focusOnMount
                      onChange={(e) => {
                        setUpdatedDisplayName(e.target.value);
                      }}
                    />
                  ) : (
                    <div style={{ lineHeight: 1 }}>{displayName}</div>
                  )}
                </div>
                <div className="display-name-actions">
                  {displayNameEditorIsVisible ? (
                    <div className="d-flex">
                      <IconButton
                        color="neutral"
                        variant="smooth"
                        onClick={handleCloseDisplayNameEditor}
                      >
                        <LineAwesomeIcon name="times" />
                      </IconButton>
                      <IconButton
                        disabled={isSubmitting}
                        color="primary"
                        variant="smooth"
                        onClick={handleSaveDisplayName}
                      >
                        <LineAwesomeIcon name="check" />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton color="neutral" variant="smooth" onClick={handleEditDisplayName}>
                      <LineAwesomeIcon name="edit" />
                    </IconButton>
                  )}
                </div>
              </h1>
            </div>
          }
          size="md"
        />
        {entertainer && !entertainer?.title && (
          <Card className="mt-2">
            <Card.Body
              onClick={() => {
                navigate(`/manage/entertainer`);
              }}
            >
              <div className="d-flex gap-2 align-items-center w-100">
                <div>
                  <div>{entertainer.title ? entertainer.title : 'Incomplete Profile'}</div>
                  <strong>
                    {entertainer.title ? 'Manage Entertainer Profile' : 'Setup Entertainer Profile'}
                  </strong>
                </div>
                <div className="ml-auto ps-2">
                  <FontAwesomeIcon name="chevron-right" />
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
        {currentUser && (
          <>
            <UserEventsSection back="/profile" />

            {currentUserIsReady ? (
              <List ignoreScreenContentPadding listItems={listItems} />
            ) : (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            )}
          </>
        )}
      </Screen.Content>
      <ReportOverlay
        isVisible={supportOverlayIsVisible}
        title="Feedback & Support"
        content={
          <p>
            We're still in beta so we are VERY open to feedback. If you have a question or there's a
            bug that we missed. Please let us know so that we can fix it!
          </p>
        }
        submitButtonText="Send"
        data={{
          collectionName: 'support',
          objectId: currentUser?._id,
        }}
        onClose={() => setSupportOverlayIsVisible(false)}
      />
    </Screen>
  );
};
