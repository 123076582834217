import { useQuery } from '@tanstack/react-query';

import API from '@api/API';

import { useCurrentUserLocation } from '@hooks/useCurrentUserLocation';

import { useAppContext } from '../contexts/AppContext';
import { FeedEvent } from './EventQueries';

const defaultCoords = { lat: 39.7770919, lng: -105.0495542 };

export const useFeedQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const { lat, lng } = defaultCoords;
  const { isFetched: feedIsReady, data: feed = { events: [], venues: [] } } = useQuery({
    queryKey: ['feed', lat, lng],
    queryFn: () => API.get<{ events: any[]; venues: any[] }>(`/v1/user/feed?lat=${lat}&lng=${lng}`),
    enabled: userIsLoggedIn,
  });

  return {
    feedIsReady,
    feed,
  };

  // return {
  //   feedIsReady: true,
  //   feed: {
  //     events: [{}, {}, {}],
  //     nearby: [{}, {}, {}],
  //   },
  // };
};

export const useNearbyVenuesQuery = () => {
  const { currentLocation } = useCurrentUserLocation();
  const { lat, lng } = currentLocation ?? {};

  const {
    isFetched,
    data = { venues: [] },
    refetch,
  } = useQuery({
    queryKey: ['venues', lat, lng],
    queryFn: () =>
      API.get<{ venues: any[] }>(`/v1/user/venues?lat=${lat}&lng=${lng}&page=0&limit=50`),
    enabled: !!lat && !!lng,
  });

  return {
    venuesAreReady: isFetched,
    venues: data.venues,
    refetchVenues: refetch,
  };
};
