import { FC, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { isEmpty } from 'lodash';

import { useCurrentUser } from '@queries/UserQueries';

import { UpdateProfilePayload, useUpdateProfileMutation } from '@mutations/UserMutations';

import Button from '@components/buttons/Button';
import { Input } from '@components/form/Input';
import { PhoneInput } from '@components/form/PhoneInput';
import { Screen } from '@components/layout/Screen/Screen';

export const EditProfileScreen: FC<{}> = () => {
  const { currentUserIsReady, currentUser, refetchUser } = useCurrentUser();
  const [updatedUserProperties, setUpdatedUserProperties] = useState<UpdateProfilePayload>({});
  const { isSubmitting, updateProfileAsync } = useUpdateProfileMutation();

  const handleUpdateProperty =
    (property: keyof UpdateProfilePayload) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setUpdatedUserProperties((prevValue) => ({
        ...prevValue,
        [property]: e.target.value,
      }));
    };

  const handleUpdateProfile = async () => {
    if (isEmpty(updatedUserProperties)) {
      return;
    }

    await updateProfileAsync(updatedUserProperties);
    refetchUser();
  };

  return (
    <Screen
      name="Edit Profile Screen"
      headerProps={{
        back: { to: '/profile' },
        title: 'Edit Profile',
      }}
    >
      <Screen.Content>
        {currentUserIsReady ? (
          <div className="grid gap-2">
            <Input
              placeholder="First Name"
              defaultValue={currentUser.firstName}
              onChange={handleUpdateProperty('firstName')}
            />
            <Input
              placeholder="Last Name"
              defaultValue={currentUser.lastName}
              onChange={handleUpdateProperty('lastName')}
            />
            <Input
              placeholder="Email"
              defaultValue={currentUser.email}
              disabled={!!currentUser.email}
              onChange={handleUpdateProperty('email')}
            />
            <PhoneInput
              disabled={!!currentUser.phone}
              value={updatedUserProperties?.phone ?? currentUser.phone ?? ''}
              onChange={(value) => handleUpdateProperty('phone')({ target: { value } } as any)}
            />
            <Button
              disabled={isSubmitting || isEmpty(updatedUserProperties)}
              roundness="rounded"
              onClick={handleUpdateProfile}
            >
              Update Profile
            </Button>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        )}
      </Screen.Content>
    </Screen>
  );
};
