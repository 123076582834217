import { FC, MouseEventHandler, useMemo, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import * as dayjs from 'dayjs';
import { keyBy, truncate } from 'lodash';

import { useEvents } from '@queries/EventQueries';
import {
  useTriviaChallenge,
  useTriviaChallengeOptions,
  useTriviaChallenges,
} from '@queries/TriviaQueries';

import {
  useCreateTriviaChallengeMutation,
  useUpdateTriviaChallengeMutation,
} from '@mutations/TriviaMutations';

import { useNavigate, useParams } from '@router/index';

import IconButton from '@components/buttons/IconButton';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { RenderTable } from '@components/table/RenderTable';

import './ManageTriviaChallengeScreen.scss';

export const ManageTriviaChallengeScreen: FC<{}> = () => {
  const { triviaChallengeId } = useParams<{ triviaChallengeId: string }>();
  const { triviaChallengeIsReady, triviaChallenge } = useTriviaChallenge(triviaChallengeId);
  const cityId = '658874efbe4bf4fe70a3d972'; // Denver hardcoded
  const { triviaChallengeOptionsAreReady, triviaChallengeOptions } =
    useTriviaChallengeOptions(triviaChallengeId);
  const { isSubmitting, updateTriviaChallengeAsync } = useUpdateTriviaChallengeMutation({
    triviaChallengeId,
  });
  const navigate = useNavigate();

  const questionRecord = useMemo(() => {
    return keyBy(
      [...(triviaChallenge?.questions ?? []), ...(triviaChallengeOptions?.questionOptions ?? [])],
      '_id',
    );
  }, [triviaChallenge?.questions, triviaChallengeOptions?.questionOptions]);

  const [updatedQuestions, setUpdatedQuestions] = useState<Record<string, boolean>>();
  const questionRows = useMemo(() => {
    const questions = updatedQuestions
      ? Object.keys(updatedQuestions).map((questionId) => questionRecord[questionId])
      : triviaChallenge?.questions ?? [];

    return questions.map((question) => ({
      key: question._id,
      action: 'remove',
      ...question,
    }));
  }, [updatedQuestions, triviaChallenge?.questions, questionRecord]);

  const [updatedQuestionOptions, setUpdatedQuestionOptions] = useState<Record<string, boolean>>();
  const questionOptionRows = useMemo(() => {
    const questions = updatedQuestionOptions
      ? Object.keys(updatedQuestionOptions).map((questionId) => questionRecord[questionId])
      : triviaChallengeOptions?.questionOptions ?? [];

    return questions.map((question) => ({
      key: question._id,
      action: 'add',
      ...question,
    }));
  }, [updatedQuestionOptions, triviaChallengeOptions?.questionOptions, questionRecord]);

  const handleAddQuestion = async (questionId: string) => {
    const modifiedUpdatedQuestions = {
      ...(updatedQuestions ?? keyBy(questionRows, '_id')),
      [questionId]: true,
    };

    const modifiedUpdatedQuestionOptions = {
      ...(updatedQuestionOptions ?? keyBy(questionOptionRows, '_id')),
    };
    delete modifiedUpdatedQuestionOptions[questionId];

    setUpdatedQuestions(modifiedUpdatedQuestions);
    setUpdatedQuestionOptions(modifiedUpdatedQuestionOptions);
    await updateTriviaChallengeAsync({
      questionIds: Object.keys(modifiedUpdatedQuestions),
    });
  };
  const handleRemoveQuestion = async (questionId: string) => {
    const modifiedUpdatedQuestions = {
      ...(updatedQuestions ?? keyBy(questionRows, '_id')),
    };
    delete modifiedUpdatedQuestions[questionId];

    const modifiedUpdatedQuestionOptions = {
      [questionId]: true,
      ...(updatedQuestionOptions ?? keyBy(questionOptionRows, '_id')),
    };

    setUpdatedQuestions(modifiedUpdatedQuestions);
    setUpdatedQuestionOptions(modifiedUpdatedQuestionOptions);
    await updateTriviaChallengeAsync({
      questionIds: Object.keys(modifiedUpdatedQuestions),
    });
  };

  const headCells = [
    {
      id: 'status',
      label: '',
      TableBodyCell: ({ row }) => (
        <td>
          <FontAwesomeIcon
            name="circle"
            className={row.isApproved ? 'question-is-active' : 'question-is-inactive'}
          />
        </td>
      ),
    },
    { id: 'question', label: 'Question', TableBodyCell: ({ row }) => <td>{row.question}</td> },
    { id: 'answer', label: 'Answer', TableBodyCell: ({ row }) => <td>{row.answer}</td> },
    {
      id: 'action',
      label: 'Action',
      TableBodyCell: ({ row }) => (
        <td>
          <IconButton
            variant="outlined"
            color={row.action === 'add' ? 'success' : 'danger'}
            onClick={
              row.action === 'add'
                ? () => handleAddQuestion(row._id)
                : () => handleRemoveQuestion(row._id)
            }
          >
            <FontAwesomeIcon name={row.action === 'add' ? 'plus' : 'minus'} />
          </IconButton>
        </td>
      ),
    },
  ];

  return (
    <Screen
      name="Manage Trivia Screen"
      headerProps={{ back: { to: '/super-admin/manage/trivia' }, title: triviaChallenge?.title }}
    >
      <Screen.Content>
        <TitleToolbar text="Questions" />
        {triviaChallengeIsReady ? (
          <div>
            {questionRows.length ? (
              <RenderTable headCells={headCells} rows={questionRows} />
            ) : (
              <div>No Questions</div>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        )}
        <TitleToolbar text="Options" />
        {triviaChallengeOptionsAreReady ? (
          <div>
            {questionOptionRows.length ? (
              <RenderTable headCells={headCells} rows={questionOptionRows} />
            ) : (
              <div>No Existing Trivia Challenges</div>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        )}
      </Screen.Content>
    </Screen>
  );
};
