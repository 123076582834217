import { useMutation } from '@tanstack/react-query';

import API from '@api/API';

interface UpdateVenuePayload {
  isActive: boolean;
  skipEventProcessing: boolean;
}

export const useUpdateVenueMutation = () => {
  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: async ({
      _id: venueId,
      ...payload
    }: {
      _id: string;
    } & UpdateVenuePayload) => {
      const response = await API.put<any, UpdateVenuePayload>(
        `/v1/super-admin/venues/${venueId}`,
        payload,
      );

      return response;
    },
  });

  return {
    isSubmitting,
    updateVenueAsync: mutateAsync,
  };
};
