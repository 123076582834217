import { useMutation } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { Form } from 'react-bootstrap';

import API from '@api/API';

import { useCurrentUserLocation } from '@hooks/useCurrentUserLocation';

import Button from '@components/buttons/Button';
import { Input } from '@components/form/Input';
import Card from '@components/layout/Card/Card';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { Toast } from '@components/layout/Toast/Toast';

const useSubmitVenueMutation = () => {
  const { currentLocation: coordinates } = useCurrentUserLocation();

  const { isPending: isSubmittingVenue, mutateAsync: submitVenueAsync } = useMutation({
    mutationFn: ({ title }: { title: string }) => {
      return API.post('/v1/venues/submit', {
        title,
        coordinates,
      });
    },
  });

  return {
    isSubmittingVenue,
    submitVenueAsync,
  };
};

export const SubmitVenueScreen: FC<{}> = () => {
  const { isSubmittingVenue, submitVenueAsync } = useSubmitVenueMutation();
  const [venueName, setVenueName] = useState('');
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const handleSubmit = async () => {
    await submitVenueAsync({ title: venueName });
    setVenueName('');
    setShowSuccessToast(true);
    setTimeout(() => {
      setShowSuccessToast(false);
    }, 10000);
  };

  return (
    <>
      <Screen name="Submit Venue Screen" headerProps={{ back: { to: '/venues' } }}>
        <Screen.Content>
          {showSuccessToast && (
            <Card>
              <Card.Body>
                <p>
                  Thank you for helping us make improvements! If approved, your request will show up
                  in the list of bars on the previous screen.
                </p>
              </Card.Body>
            </Card>
          )}

          <TitleToolbar text="Request Bar" size="md" />
          <p>
            While we are in beta, we are only allowing bars to be added by request. Please provide
            the name of the bar you would like to add and we will review it as soon as possible.
          </p>
          <div className="grid gap-2">
            <Input
              placeholder="Name of bar"
              value={venueName}
              onChange={(e) => setVenueName(e.target.value)}
            />
            <Button disabled={isSubmittingVenue} roundness="rounded" onClick={handleSubmit}>
              Request
            </Button>
          </div>
        </Screen.Content>
      </Screen>
    </>
  );
};
