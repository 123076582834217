import { CSSProperties, FC } from 'react';

import './LetterAvatar.scss';

const colors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#ecf0f1',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d',
];

interface LetterAvatarProps {
  letter: string;
  onClick?: () => void;
  style?: CSSProperties;
}

export const LetterAvatar: FC<LetterAvatarProps> = ({ letter, onClick, style = {} }) => {
  const lowerCaseLetter = letter.toLowerCase();
  const charCode = lowerCaseLetter.charCodeAt(0) - 97;
  const colorIndex = charCode % colors.length;
  const color = colors[colorIndex];
  return (
    <div className="letter-avatar" style={{ background: color, ...style }} onClick={onClick}>
      {letter}
    </div>
  );
};
