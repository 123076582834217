import { useMemo, useState } from 'react';

import * as dayjs from 'dayjs';
import { capitalize, groupBy } from 'lodash';

import { EntertainerBookingOpportunity } from '@queries/EntertainerQueries';
import {
  BOOKING_OPPORTUNITY_ENTERTAINER_STATUSES,
  PublicBookingOpportunity,
} from '@queries/PromoterQueries';

import { useRespondToBookingOpportunityMutation } from '@mutations/EntertainerMutations';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { OptionButton } from '@components/buttons/OptionButton';
import { Chip } from '@components/chips/Chip';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';

const formatStatus = (status: string) => {
  if (status === 'new') {
    return 'New';
  }

  if (status === 'notInterested') {
    return 'Not Interested';
  }

  return capitalize(status);
};

const StatusActionButton = ({
  bookingOpportunity,
}: {
  bookingOpportunity: EntertainerBookingOpportunity;
}) => {
  const [status, setStatus] = useState(bookingOpportunity.status);

  const { isSubmitting, respondToBookingOpportunityAsync } =
    useRespondToBookingOpportunityMutation();

  if (['confirmed', 'rejected'].includes(status)) {
    return (
      <Button
        disabled
        className="py-2 px-3"
        roundness="rounded"
        variant="outlined"
        color="neutral"
        size="sm"
      >
        {formatStatus(status)}
      </Button>
    );
  }

  return (
    <OptionButton
      label={formatStatus(status)}
      accept={{
        label: 'Interested',
        onClick: async () => {
          setStatus('interested');
          await respondToBookingOpportunityAsync({
            bookingOpportunityId: bookingOpportunity._id,
            status: 'interested',
          });
        },
      }}
      deny={{
        label: 'Not Interested',
        onClick: async () => {
          setStatus('notInterested');
          await respondToBookingOpportunityAsync({
            bookingOpportunityId: bookingOpportunity._id,
            status: 'notInterested',
          });
        },
      }}
    />
  );
};

export const BookingOpportunityCard = ({
  bookingOpportunity,
}: {
  bookingOpportunity: EntertainerBookingOpportunity;
}) => {
  const navigate = useNavigate();

  return (
    <Card>
      <Card.Header
        tagline={bookingOpportunity?.promoter?.title}
        title={bookingOpportunity.title}
        subtitle={`${dayjs(bookingOpportunity.startDate).format('ddd, MMM D, YYYY')} | ${bookingOpportunity.city?.name}`}
        action={<StatusActionButton bookingOpportunity={bookingOpportunity} />}
      />
      <Card.Footer>
        <Button
          onClick={() => {
            navigate(`/manage/entertainer/booking-opportunity/${bookingOpportunity._id}`);
          }}
        >
          Details
        </Button>
      </Card.Footer>
    </Card>
  );
};
