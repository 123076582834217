import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { use } from 'chai';

import API from '@api/API';

import { useFetchCurrentEntertainer } from '@queries/EntertainerQueries';
import { useFeedPostsQuery } from '@queries/PostQueries';
import { useFetchCurrentPromoter } from '@queries/PromoterQueries';
import { useFetchCurrentUser, useFetchTokenIsValid } from '@queries/UserQueries';

import { useAnalytics } from '@hooks/useAnalytics';
import { CurrentCity } from '@hooks/useCurrentUserLocation';

import { DEFAULT_AUTH_ROUTE } from '@router/constants';
import { useNavigate } from '@router/index';

const useAppInitilization = ({ token }: { token: string }) => {
  const [appIsReady, setAppIsReady] = useState(false);
  const fetchTokenIsValid = useFetchTokenIsValid();
  const fetchCurrentUser = useFetchCurrentUser();
  const fetchCurrentPromoter = useFetchCurrentPromoter();
  const fetchCurrentEntertainer = useFetchCurrentEntertainer();

  useEffect(() => {
    if (token) {
      try {
        (async () => {
          const tokenIsValid = await fetchTokenIsValid();

          if (tokenIsValid) {
            await Promise.all([
              fetchCurrentUser(),
              fetchCurrentPromoter(),
              fetchCurrentEntertainer(),
            ]);
          }

          setAppIsReady(true);
        })();
      } catch (e) {
        console.error(e);
        setAppIsReady(true);
      }
    } else {
      setAppIsReady(true);
    }
  });

  return {
    appIsReady,
  };
};

interface AppContext {
  currentCityOverride?: CurrentCity;
  overrideCurrentCity: (
    city: CurrentCity,
    options?: Partial<{
      storeInLocalStorage: boolean;
    }>,
  ) => void;
  userIsLoggedIn: boolean;
  isLocationPermissionGateDisabled: boolean;
  updateIsLocationPermissionGateDisabled: (isDisabled: boolean) => void;
  updateToken: (updatedToken?: string) => void;
  navigateAfterLogin: (path?: string, options?: { animate?: boolean }) => Promise<void>;
}

const appContext = createContext<AppContext>(undefined as AppContext);

export const useAppContext = () => {
  const context = useContext(appContext);

  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return context;
};

export const useAppSettingsQuery = () => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useAppSettingsQuery.queryKey(),
    queryFn: useAppSettingsQuery.queryFn,
    enabled: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  return {
    appSettingsAreReady: isFetched,
    appSettings: data ?? {},
    refetchTokenIsValid: refetch,
  };
};
useAppSettingsQuery.queryKey = () => ['appSettings'];
useAppSettingsQuery.queryFn = () =>
  API.get<
    Partial<{
      badgeReasons: string[];
      entertainerMediaBadges: { _id: string; title: string; imageUrl: string }[];
    }>
  >(`/v1/settings`);

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  const [currentCityOverride, setCurrentCityOverride] = useState<CurrentCity>();
  const [token, setToken] = useState<string>(localStorage.getItem('token'));
  const [isLocationPermissionGateDisabled, setIsLocationPermissionGateDisabled] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const fetchCurrentUser = useFetchCurrentUser();
  const { track } = useAnalytics();
  // const { appIsReady } = useAppInitilization({ token });
  const queryClient = useQueryClient();

  const updateToken = useCallback((updatedToken: string) => {
    setToken(updatedToken);

    if (updatedToken) {
      localStorage.setItem('token', updatedToken);
    } else {
      localStorage.removeItem('token');
    }
  }, []);

  const navigateAfterLogin = useCallback(
    async (path?: string, options: { animate?: boolean } = {}) => {
      try {
        const { user: currentUser } = await fetchCurrentUser();

        track('Log In', {
          isNewUser:
            !currentUser.onboarding?.basicInfoIsComplete ||
            !currentUser.onboarding?.interestsAreComplete,
        });

        if (path) {
          navigate(path, options);
          return;
        }

        if (!currentUser.onboarding?.basicInfoIsComplete) {
          navigate('/onboarding', options);
        } else if (!currentUser.onboarding?.interestsAreComplete) {
          navigate('/onboarding/interests', options);
        } else {
          navigate(DEFAULT_AUTH_ROUTE, options);
        }
      } catch (e) {
        navigate('/', options);
      }
    },
    [],
  );

  const overrideCurrentCity = (city: CurrentCity, { storeInLocalStorage = false }) => {
    track('Override City', { city: city.name });
    setCurrentCityOverride(city);
    queryClient.removeQueries({ queryKey: useFeedPostsQuery.key() });

    if (storeInLocalStorage) {
      localStorage.setItem('currentCityOverride', JSON.stringify(city));
    }
  };

  const value = useMemo(
    () => ({
      // TODO: Fix !!token becuase token can exist but be invalid
      userIsLoggedIn: !!token,
      updateToken,
      navigateAfterLogin,
      isLocationPermissionGateDisabled,
      updateIsLocationPermissionGateDisabled: (isDisabled = false) => {
        setIsLocationPermissionGateDisabled(isDisabled);
      },
      currentCityOverride,
      overrideCurrentCity,
    }),
    [token, updateToken, navigateAfterLogin, isLocationPermissionGateDisabled, currentCityOverride],
  );

  return <appContext.Provider value={value}>{children}</appContext.Provider>;
};
