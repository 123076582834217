import { FC, useMemo, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';

import { useVenueEventsQuery, useVenuePostsQuery } from '@queries/PostQueries';
import { useCurrentUser } from '@queries/UserQueries';
import {
  useCurrentUserVenueCritiqueQuery,
  useVenueCritiqueDataQuery,
  useVenueQuery,
} from '@queries/VenueQueries';
import { useFeedQuery, useNearbyVenuesQuery } from '@queries/useFeedQuery';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';
import { useFollowMutation } from '@mutations/FollowMutations';
import { useCreateVenuePost } from '@mutations/PostMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useLocation, useNavigate, useParams, useStoredBackUrl } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import EventCard from '@components/cards/EventCard';
import { PostCard } from '@components/cards/PostCard/PostCard';
import { VenueCard, VenueFollowButton } from '@components/cards/VenueCard';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { CritiqueSection } from '@components/layout/DetailsChartSection/CritiqueSection';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';
import { ReportOverlay } from '@components/overlays/ReportOverlay/ReportOverlay';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { ClaimPopup } from '../ClaimPopup';
import './VenueScreen.scss';

const VenueReviewSection: FC<{
  venueId: string;
  commentFieldIsVisible: boolean;
  setCommentFieldIsVisible: (updatedValue: boolean) => void;
}> = ({ venueId, commentFieldIsVisible, setCommentFieldIsVisible }) => {
  const [commentText, setCommentText] = useState<string>('');

  const { venuePostsAreReady, venuePosts = [] } = useVenuePostsQuery(venueId);
  const { isSubmitting, createVenuePostAsync } = useCreateVenuePost(venueId);
  const { createAuthGateHandler } = useAuthGate();

  const handleSubmitComment = async () => {
    if (!commentText || isSubmitting) {
      return;
    }

    createAuthGateHandler(async () => {
      await createVenuePostAsync({ text: commentText });

      setCommentText('');
    });
  };

  return (
    <>
      <div id="venue-comments" />
      <TitleToolbar
        size="md"
        text="Comments"
        action={
          <Button variant="flat" onClick={() => setCommentFieldIsVisible(true)}>
            Add Comment
          </Button>
        }
      />
      {commentFieldIsVisible && (
        <Textarea
          onChange={setCommentText}
          submitButton={{
            text: 'Comment',
            disabled: isSubmitting,
            onClick: handleSubmitComment,
          }}
        />
      )}

      {venuePostsAreReady && (
        <>
          {venuePosts.length ? (
            <div className="comments">
              {venuePosts.map((comment) => (
                <PostCard key={comment._id} post={comment} showDetails={false} hideHeaderAction />
              ))}
            </div>
          ) : (
            <EmptyState
              className="comments-empty-state"
              title="No Review Yet"
              text="Be the first to review or comment about this venue."
              button={{
                text: 'Add Comment',
                onClick: () => setCommentFieldIsVisible(true),
              }}
            />
          )}
        </>
      )}
    </>
  );
};

interface VenueScreenProps {
  venueId: string;
  back?: string;
}

export const VenueScreen: FC<VenueScreenProps> = ({ venueId, back }) => {
  const { userIsLoggedIn } = useAppContext();
  const { venueIsReady, venue } = useVenueQuery(venueId);
  const { userVenueCritique, userVenueCritiqueIsReady } = useCurrentUserVenueCritiqueQuery(venueId);
  const { venueCritiqueDataIsReady, venueCritiqueData, refetchVenueCritiqueData } =
    useVenueCritiqueDataQuery(venueId);
  const { currentUser } = useCurrentUser();
  const [commentFieldIsVisible, setCommentFieldIsVisible] = useState<boolean>(false);
  const { venueEvents } = useVenueEventsQuery(venueId);
  const { followAsync } = useFollowMutation();
  const navigate = useNavigate();
  const [reportOverlayIsVisible, setReportOverlayIsVisible] = useState(false);
  const { openAlertDialog } = useDialog();
  const { track } = useAnalytics();
  const { storedBackUrl } = useStoredBackUrl();
  const { createAuthGateHandler } = useAuthGate();
  const [isClaimPopupVisible, setIsClaimPopupVisible] = useState(false);

  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();

  const handleCritique = async (payload: CritiqueDocumentMutationPayload) => {
    createAuthGateHandler(async () => {
      await critiqueDocumentAsync({
        ...payload,
        documentType: 'venues',
        documentId: venueId,
      });
      refetchVenueCritiqueData();
    });
  };

  const handleScrollToComments = () => {
    document.getElementById('venue-comments')?.scrollIntoView({ behavior: 'smooth' });
    setCommentFieldIsVisible(true);
  };

  const description = venue?.tagline ?? venue?.description;

  return (
    <Screen
      name="Venue Screen"
      className="venue-screen"
      headerProps={{
        back: { to: back ?? storedBackUrl },
        title: 'Venue',
        right: (
          <IconButton variant="flat" color="danger" onClick={() => setReportOverlayIsVisible(true)}>
            <FontAwesomeIcon faStyle="far" name="flag" />
          </IconButton>
        ),
      }}
      hideToolbar
      // bottomToolbar={
      //   <Screen.BottomToolbar>
      //     <Button noWrap roundness="rounded" onClick={() => {}}>
      //       Share Critique
      //     </Button>
      //   </Screen.BottomToolbar>
      // }
    >
      <Screen.Content>
        {venueIsReady && venue && (
          <>
            <TitleToolbar
              className="mb-0"
              text={venue.title}
              action={
                <Button
                  roundness="rounded"
                  onClick={() => {
                    setIsClaimPopupVisible(true);
                  }}
                >
                  Claim
                </Button>
              }
            />
            {description &&
              mapStringToParagraphs(description, {
                collapseTopMargin: true,
              })}

            <TitleToolbar
              size="md"
              text="Upcoming Events"
              action={<VenueFollowButton venue={venue} />}
            />
            {venueEvents?.upcoming?.length ? (
              <HorizontalScroll
                items={venueEvents?.upcoming?.map((event) => (
                  <EventCard
                    showPromoter={false}
                    key={event._id}
                    event={event}
                    onClick={() =>
                      navigate(`/events/${event._id}`, {
                        state: {
                          back: `/venues/${venueId}`,
                        },
                      })
                    }
                  />
                ))}
              />
            ) : (
              <EmptyState
                title="No Upcoming Events"
                text={`${venue.title} doesn't have any upcoming events. Follow them to get notified when new events are added.`}
                button={{
                  text: 'Follow Venue',
                  onClick: () =>
                    followAsync({
                      documentType: 'venue',
                      documentId: venueId,
                    }),
                }}
              />
            )}

            <TitleToolbar
              text="Critiques 💅🏽"
              size="md"
              action={
                <PrimaryCritiqueCountChips
                  hideCounts
                  disableOverlay
                  controlSelectedRating
                  defaultValue={userVenueCritique?.primaryCritique}
                  counts={{
                    like: 0,
                    dislike: 0,
                    neutral: 0,
                  }}
                  onSubmit={async ({ primaryCritique }) => {
                    track('Click rate venue', {
                      primaryCritique,
                      venueId,
                      venueName: venue.title,
                    });
                    await handleCritique({ primaryCritique });
                  }}
                />
              }
            />
            {venueCritiqueDataIsReady && (userVenueCritiqueIsReady || !userIsLoggedIn) ? (
              <CritiqueSection
                critiqueData={venueCritiqueData}
                userCritique={userVenueCritique}
                onSubmit={handleCritique}
              />
            ) : (
              <Skeleton height={190} />
            )}

            {!!venueEvents?.previous?.length && (
              <>
                <TitleToolbar text="Past Highlights 🪭" size="md" />
                <HorizontalScroll
                  items={venueEvents?.previous?.map((event) => (
                    <EventCard
                      showPromoter={false}
                      key={event._id}
                      event={event}
                      onClick={() => navigate(`/events/${event._id}`)}
                    />
                  ))}
                />
              </>
            )}

            <VenueReviewSection
              venueId={venueId}
              commentFieldIsVisible={commentFieldIsVisible}
              setCommentFieldIsVisible={setCommentFieldIsVisible}
            />
          </>
        )}
      </Screen.Content>
      <ClaimPopup
        isVisible={isClaimPopupVisible}
        onClose={() => setIsClaimPopupVisible(false)}
        trackProperties={{
          venueId,
          venueTitle: venue?.title,
          userId: currentUser?._id,
        }}
      />
      <ReportOverlay
        isVisible={reportOverlayIsVisible}
        title="Report Venue"
        content={<p>Are you sure you want to report this venue?</p>}
        data={{
          collectionName: 'venue',
          objectId: venueId,
        }}
        onClose={() => setReportOverlayIsVisible(false)}
      />
    </Screen>
  );
};
