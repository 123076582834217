import { FC } from 'react';

import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

interface ScoringPopupProps {
  isVisible: boolean;
  onClose: () => void;
}

export const ScoringPopup: FC<ScoringPopupProps> = ({ isVisible, onClose }) => {
  return (
    <Popup isVisible={isVisible} onClose={onClose}>
      <Popup.Content>
        <TitleToolbar text="Critic Score" />
        <p>
          The more active you are, the higher your score! Hot takes and critiques are the easiest
          way to earn points. But reviews and comments are worth the most points.
        </p>
      </Popup.Content>
    </Popup>
  );
};
