import { List, ListItem } from 'framework7-react';
import { FC, useMemo, useRef, useState } from 'react';

import { uniqBy } from 'lodash';
import { AREAS_OF_IMPROVEMENT_ENABLED } from 'src/constants/FeatureFlags';

import { UserCritique } from '@queries/CritiqueQueries';
import { EntertainerRank } from '@queries/EntertainerQueries';

import { CritiqueDocumentMutationPayload } from '@mutations/CritiqueMutations';

import Button from '@components/buttons/Button';
import { SendEntertainerBadgeButton } from '@components/buttons/SendEntertainerBadgeButton';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Input } from '@components/form/Input';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';

import { ClapIcon } from './ClapIcon';

export const CritiqueList = ({
  selectedTags: initialSelectedTags,
  tags: initialTags,
  agreeBtnText,
  inputPlaceholder,
  onSubmit,
}: {
  selectedTags: string[];
  tags: string[];
  agreeBtnText: string;
  inputPlaceholder: string;
  onSubmit: (updatedTags: string[]) => void;
}) => {
  const [tags, setTags] = useState<string[]>(initialTags);
  const [selectedTags, setSelectedTags] = useState<string[]>(initialSelectedTags);
  const [customTag, setCustomTag] = useState<string>();
  const { openAlertDialog } = useDialog();

  const handleAgreeClick = (tag: string) => {
    if (!selectedTags.includes(tag)) {
      const updatedTags = [...selectedTags, tag].filter(Boolean);
      setSelectedTags(updatedTags);
      onSubmit(updatedTags);
    }
  };

  const handleSubmitCustomStrength = () => {
    if (customTag) {
      const wordCount = customTag.trim().split(' ').length;
      if (wordCount > 4) {
        openAlertDialog({
          title: 'Uh oh!',
          text: 'Custom tags cannot be more than 4 words. If you want to add a longer message, it might be better as a description or review.',
        });
        return;
      }

      const updatedTags = [...tags, customTag].filter(Boolean);
      const updatedSelectedTags = [...selectedTags, customTag].filter(Boolean);

      setTags(updatedTags);
      setSelectedTags(updatedSelectedTags);
      onSubmit(updatedSelectedTags);
      setCustomTag('');
    }
  };

  return (
    <>
      <List
        strongIos
        outlineIos
        dividersIos
        className="mt-2 mb-0"
        style={{ marginLeft: '-1rem', marginRight: '-1rem' }}
      >
        {tags.map((tag) => (
          <ListItem title={tag}>
            {!selectedTags.includes(tag) && (
              <div slot="after">
                <Button
                  size="sm"
                  className="py-0 ps-2 pe-3"
                  color="neutral"
                  variant="outlined"
                  startIcon={
                    <div style={{ width: 20, height: 24 }}>
                      <ClapIcon />
                    </div>
                  }
                  onClick={() => handleAgreeClick(tag)}
                >
                  {agreeBtnText}
                </Button>
              </div>
            )}
          </ListItem>
        ))}
      </List>

      <div
        className="d-flex mt-2 align-items-center justify-content-between pe-2"
        style={{ background: 'rgb(28, 28, 29)', borderRadius: 10 }}
      >
        <Input
          placeholder={inputPlaceholder}
          wrapperClassName="flex-grow-1"
          color="neutral"
          style={{ background: 'transparent' }}
          value={customTag}
          onChange={(e) => setCustomTag(e.target.value)}
        />
        <div>
          {customTag && (
            <Button
              className="py-1"
              size="md"
              variant="outlined"
              color="neutral"
              roundness="rounded"
              onClick={handleSubmitCustomStrength}
            >
              Add
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const DEFAULT_STRENGTH_OPTIONS = [
  'Fearless Expression',
  'Gender Bending',
  'Political Provocateur',
  'Cultural Remix',
  'Erotic Artistry',
  'Vocal Virtuosity',
  'Costume Creativity',
  'Comedic Genius',
  'Dramatic Flair',
  'Dance Mastery',
];

const DEFAULT_IMPROVEMENT_OPTIONS = [
  'Limited Creativity',
  'Predictable Songs',
  'Static Performance',
  'Outdated References',
  'Monotone Delivery',
  'Subpar Lip Syncing',
  'Dull Costumes',
  'Poor Engagement',
  'Limited Awareness',
  'Uneven Makeup',
];

interface EntertainerCritiqueContentProps {
  selectedEntertainer: EntertainerRank;
  onClickRating: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onUpdateStrengths: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onUpdateImprovements: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onClickViewProfile: (entertainer: EntertainerRank) => void;
  onClose: () => void;
}

let debouncedStrengthUpdate: any;
let debouncedImprovementUpdate: any;

const EntertainerCritiqueContent: FC<EntertainerCritiqueContentProps> = ({
  selectedEntertainer,
  onClickRating,
  onUpdateStrengths,
  onUpdateImprovements,
  onClickViewProfile,
  onClose,
}) => {
  const initialUserStrentghs = selectedEntertainer.like.map(({ label }) => label);
  const strengthOptions = useMemo(
    () =>
      uniqBy(
        [...initialUserStrentghs, ...selectedEntertainer.tags.like, ...DEFAULT_STRENGTH_OPTIONS],
        (label) => label.toLowerCase(),
      ).map((option) => ({
        label: option,
        value: option,
      })),
    [],
  );
  const [strengths, setStrengths] = useState<string[]>(initialUserStrentghs);

  const initialUserImprovements = selectedEntertainer.neutral.map(({ label }) => label);
  const improvementOptions = useMemo(
    () =>
      uniqBy(
        [
          ...initialUserImprovements,
          ...selectedEntertainer.tags.neutral,
          ...DEFAULT_IMPROVEMENT_OPTIONS,
        ],
        (label) => label.toLowerCase(),
      ).map((option) => ({
        label: option,
        value: option,
      })),
    [],
  );
  const [improvements, setImprovements] = useState<string[]>(initialUserImprovements);

  const handleUpdateStrengths = (updatedStrengths: string[]) => {
    setStrengths(updatedStrengths);
    clearTimeout(debouncedStrengthUpdate);
    debouncedStrengthUpdate = setTimeout(() => {
      onUpdateStrengths(selectedEntertainer._id)({
        like: updatedStrengths,
      });
    }, 1000);
  };

  const handleUpdateImprovements = (updatedImprovements: string[]) => {
    setImprovements(updatedImprovements);
    clearTimeout(debouncedImprovementUpdate);
    debouncedImprovementUpdate = setTimeout(() => {
      onUpdateImprovements(selectedEntertainer._id)({
        neutral: updatedImprovements,
      });
    }, 1000);
  };

  return (
    <>
      <TitleToolbar
        className="mt-2"
        text={selectedEntertainer.title}
        size="md"
        action={
          <PrimaryCritiqueCountChips
            hideCounts
            disableOverlay
            controlSelectedRating
            defaultValue={selectedEntertainer.primaryRating}
            counts={{
              like: 0,
              dislike: 0,
              neutral: 0,
            }}
            onSubmit={onClickRating(selectedEntertainer._id)}
          />
        }
      />

      <SendEntertainerBadgeButton
        entertainerId={selectedEntertainer._id}
        buttonProps={{ className: 'py-2' }}
      />

      <Button
        className="mt-2"
        roundness="rounded"
        color="neutral"
        variant="smooth"
        onClick={() => {
          onClose();
          onClickViewProfile(selectedEntertainer);
        }}
      >
        View Profile
      </Button>

      <TitleToolbar text="Strengths" size="sm" className="mt-4 mb-0" />
      <div className="mb-2">
        <span>
          Choose strengths to cheer on this entertainer or add your own! Shared publicly, posted
          anonymously.
        </span>
      </div>

      <ChipRadioOptions
        multiSelect
        addCustomOptions
        value={strengths}
        onChange={handleUpdateStrengths}
        options={strengthOptions}
      />

      {AREAS_OF_IMPROVEMENT_ENABLED && (
        <>
          <TitleToolbar text="Areas for Improvement" size="sm" className="mt-4 mb-0" />
          <div className="mb-2">
            <span>
              Pick areas to anonymously recommend improvements or add your own! Visible to all, but
              your name stays hidden.
            </span>
          </div>
          {AREAS_OF_IMPROVEMENT_ENABLED ? (
            <ChipRadioOptions
              multiSelect
              addCustomOptions
              value={improvements}
              onChange={handleUpdateImprovements}
              options={improvementOptions}
            />
          ) : (
            <EmptyState
              title="Section Locked"
              text={
                'Gaggl is committed to being a positive community. After receiving feedback about misuse of this section, it has been locked while we work on ensuring it’s used for constructive feedback. We apologize to those affected by cyberbullying and are taking steps to make Gaggl safer for everyone.'
              }
            />
          )}
        </>
      )}
    </>
  );
};

interface EntertainerCritiquePopupProps
  extends Omit<EntertainerCritiqueContentProps, 'selectedEntertainer'> {
  selectedEntertainer?: EntertainerRank;
}
export const EntertainerCritiquePopup: FC<EntertainerCritiquePopupProps> = (props) => {
  const { selectedEntertainer, onClose } = props;
  return (
    <Popup isVisible={!!selectedEntertainer} onClose={onClose}>
      <Popup.Content>
        {selectedEntertainer && (
          <EntertainerCritiqueContent {...props} selectedEntertainer={selectedEntertainer} />
        )}
      </Popup.Content>
    </Popup>
  );
};
