import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';

import pluralize from 'pluralize';

import { useEventQuery, useUserEventsQuery } from '@queries/EventQueries';

import { useRsvpToEventMutation } from '@mutations/EventMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import { EventHeader, EventImage, PromoterHeader } from '@components/cards/EventCard';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import './SuggestedEventsScreen.scss';

const SuggestedEventCard: FC<{
  eventId: string;
  contentHeight: number;
  onNotInterestedClick: () => void;
  onInterestedClick: () => void;
}> = ({ eventId, contentHeight, onNotInterestedClick, onInterestedClick }) => {
  const { eventIsReady, event } = useEventQuery(eventId);

  if (!eventIsReady) {
    return <Skeleton height={480} />;
  }

  return (
    <Card key={event._id} className="suggested-event-card">
      <Card.Body style={{ height: contentHeight }}>
        <PromoterHeader event={event} />
        <EventImage event={event} />
        <EventHeader event={event} />
        <TitleToolbar size="md" text="About This Event" />
        <div className="event-description">{mapStringToParagraphs(event.description)}</div>
      </Card.Body>
      <Card.Footer className="gap-2">
        <div className="suggested-event-card_gradient" />
        <Button color="neutral" roundness="rounded" onClick={onNotInterestedClick}>
          Not Interested
        </Button>
        <Button roundness="rounded" onClick={onInterestedClick}>
          Interested
        </Button>
      </Card.Footer>
    </Card>
  );
};

export const useInterestedButtonMutations = () => {
  const { rsvpToEventAsync } = useRsvpToEventMutation();

  const handleInterested = (eventId: string) => {
    rsvpToEventAsync({ eventId, isStarred: true, eventInterest: 'interested' });
  };

  const handleNotInterested = (eventId: string) => {
    rsvpToEventAsync({ eventId, isStarred: false, eventInterest: 'notInterested' });
  };

  return { handleInterested, handleNotInterested };
};

export const SuggestedEventsScreen: FC<{ back?: string }> = ({ back = '/home' }) => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const [currentEventIndex, setCurrentEventIndex] = useState<number>(0);
  const { eventsAreReady, events } = useUserEventsQuery({
    refetchOnWindowFocus: 'always',
  });

  const currentEventId = useMemo(() => {
    return events?.suggested?.[currentEventIndex]?._id;
  }, [events, currentEventIndex]);

  const { handleInterested, handleNotInterested } = useInterestedButtonMutations();

  const handleInterestedClick = () => {
    track('Click Interested');
    handleInterested(currentEventId);
    setCurrentEventIndex((prevIndex) => prevIndex + 1);
  };

  const handleNotInterestedClick = () => {
    track('Click Not Interested');
    handleNotInterested(currentEventId);
    setCurrentEventIndex((prevIndex) => prevIndex + 1);
  };

  const contentRef = useRef<{ el: HTMLElement }>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  useLayoutEffect(() => {
    if (eventsAreReady) {
      const TOP_OFFSET = 100;
      const BOTTOM_OFFSET = 142;
      setContentHeight(contentRef.current?.el?.clientHeight - TOP_OFFSET - BOTTOM_OFFSET);
    }
  }, [eventsAreReady]);

  return (
    <Screen
      name="Suggested Events Screen"
      headerProps={{ back: { to: back }, title: 'Suggested Events' }}
    >
      <Screen.Content ref={contentRef}>
        {eventsAreReady && currentEventId && (
          <TitleToolbar
            size="md"
            text={`${currentEventIndex + 1} of ${events?.suggested?.length} ${pluralize('event', events?.suggested?.length)}`}
          />
        )}
        {eventsAreReady ? (
          <>
            {currentEventId ? (
              <SuggestedEventCard
                eventId={currentEventId}
                contentHeight={contentHeight}
                onInterestedClick={handleInterestedClick}
                onNotInterestedClick={handleNotInterestedClick}
              />
            ) : (
              <EmptyState
                title="No more suggested events"
                text="We don't have any suggested events for you right now. Add more interests or follow more venues and promoters to help us find you more suggestions."
                button={{
                  text: `Manage Interests`,
                  onClick: () =>
                    navigate('/interests', {
                      state: { back: '/home' },
                    }),
                }}
              />
            )}
          </>
        ) : (
          <>
            <Skeleton height={26} />
            <Skeleton height={480} />
          </>
        )}
      </Screen.Content>
    </Screen>
  );
};
