import { Popup } from 'framework7-react';
import { FC, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { FeedEvent, UserEventRsvp } from '@queries/EventQueries';

import { RsvpToEventPayload } from '@mutations/EventMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { List } from '@components/layout/List/List';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { BASIC_RATING_RECORD } from './CritiqueOverlayConstants';

type EventInterestOption = 'interested' | 'notInterested';

const BASIC_RATING_FOLLOW_UP_OPTIONS: Record<EventInterestOption, string[]> = {
  interested: [
    'Diverse',
    'Inclusive',
    'Social',
    'Friendly',
    'Great drinks',
    'Great food',
    'Great music',
    'Cozy atmosphere',
    'Unique cocktails',
    'Creative decor',
    'Welcoming staff',
    'Fun activities',
    'Comfortable seating',
    'Affordable prices',
    'Convenient location',
    'Clean environment',
    'Fun dance floor',
    'Genuine connections',
  ],
  notInterested: [
    'Unmemorable',
    'Stereotypical',
    'Mildly diverse',
    'Somewhat inclusive',
    'Average ambiance',
    'Standard menu',
    'Typical crowd',
    'Decent service',
    'Moderate pricing',
    'Average music selection',
    'Average food quality',
    'Ordinary atmosphere',
    'Common drinks',
    'Rude People',
    'Bad Music',
    'Bad Drinks',
    'Bad Food',
    'Bad Service',
    'Overpriced',
    'Boring',
    'Crowded space',
    'Limited seating',
    'Slow service',
    'Loud atmosphere',
    'Poor hygiene',
    'Uncomfortable temperature',
    'Unorganized events',
    'Unfriendly staff',
    'Overcrowded dance floor',
  ],
};

interface SharedProps {
  primaryRating?: EventInterestOption;
  reasons?: Record<EventInterestOption, string[]>;
  onSubmit: (properties: RsvpToEventPayload) => Promise<void>;
  onClose?: () => void;
  showCommentSection?: boolean;
}

interface EventInterestWrapperProps extends Partial<PropsWithChildren>, SharedProps {
  primaryRating: EventInterestOption;
}

const EventInterestWrapper: FC<EventInterestWrapperProps> = ({
  showCommentSection = true,
  primaryRating,
  reasons: initialReasons,
  children,
  onSubmit,
  onClose,
}) => {
  const { track } = useAnalytics();
  const [followUpReasons, setFollowUpReasons] = useState<Record<EventInterestOption, string[]>>({
    interested: initialReasons?.interested || [],
    notInterested: initialReasons?.notInterested || [],
  });
  const [addCommentIsVisible, setAddCommentIsVisible] = useState(false);
  const [comment, setComment] = useState('');

  useEffect(() => {
    setFollowUpReasons({
      interested: initialReasons?.interested || [],
      notInterested: initialReasons?.notInterested || [],
    });
  }, [initialReasons]);

  const handleChangeFollowUpReasons = (updatedValues: string[]) => {
    setFollowUpReasons((prevValues) => ({
      ...prevValues,
      [primaryRating]: updatedValues,
    }));
  };

  const handleClose = () => {
    track('Close event interest overlay');
    onClose?.();
  };

  return (
    <div>
      {children}

      <div className={`flex flex-column ${children ? 'mt-3' : ''}`}>
        {primaryRating ? (
          <>
            <Card>
              <Card.Body>
                <div>
                  <ChipRadioOptions
                    multiSelect
                    addCustomOptions
                    value={followUpReasons[primaryRating]}
                    options={BASIC_RATING_FOLLOW_UP_OPTIONS[primaryRating].map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    onChange={handleChangeFollowUpReasons}
                  />
                </div>
              </Card.Body>
            </Card>

            {showCommentSection && (
              <Card>
                {addCommentIsVisible ? (
                  <Textarea onChange={setComment} placeholder="Add comment" focusOnMount />
                ) : (
                  <List
                    listItems={[
                      { title: 'Add Comment', onClick: () => setAddCommentIsVisible(true) },
                    ]}
                  />
                )}
              </Card>
            )}

            <Card>
              <Button
                onClick={() =>
                  onSubmit({
                    eventInterest: primaryRating,
                    comment,
                    [primaryRating]: followUpReasons[primaryRating],
                  })
                }
              >
                Done
              </Button>
            </Card>
          </>
        ) : (
          <>
            {onClose && (
              <Card>
                <Button color="default" onClick={handleClose}>
                  Nevermind
                </Button>
              </Card>
            )}
          </>
        )}
      </div>
    </div>
  );
};

interface EventInterestOverlayProps extends SharedProps {
  isVisible: boolean;
}

export const EventInterestOverlay: FC<EventInterestOverlayProps> = ({
  isVisible: controlledIsVisible,
  showCommentSection = true,
  primaryRating: initialPrimaryRating,
  reasons: initialReasons,
  onSubmit,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [primaryRating, setPrimaryRating] = useState<EventInterestOption>(initialPrimaryRating);

  useEffect(() => {
    setPrimaryRating(initialPrimaryRating);
  }, [initialPrimaryRating]);

  useEffect(() => {
    setIsVisible(controlledIsVisible);
  }, [controlledIsVisible]);

  if (!isVisible) {
    return <></>;
  }

  return createPortal(
    <div className="generic-rating-overlay">
      <div className="generic-rating-overlay-content">
        <EventInterestWrapper
          showCommentSection={showCommentSection}
          primaryRating={primaryRating}
          reasons={initialReasons}
          onSubmit={onSubmit}
          onClose={onClose}
        >
          <Card>
            <Card.Body>
              <div className="d-flex gap-2">
                <Button
                  color="neutral"
                  roundness="rounded"
                  variant={primaryRating === 'notInterested' ? 'default' : 'outlined'}
                  onClick={() => setPrimaryRating('notInterested')}
                >
                  Not Interested
                </Button>
                <Button
                  roundness="rounded"
                  variant={primaryRating === 'interested' ? 'default' : 'outlined'}
                  onClick={() => setPrimaryRating('interested')}
                >
                  Interested
                </Button>
              </div>
            </Card.Body>
          </Card>
        </EventInterestWrapper>
      </div>
    </div>,
    document.getElementById('framework7-root'),
  );
};
