import { useMutation } from '@tanstack/react-query';

import API from '@api/API';

interface AuthWithMagicLinkPayload {
  phoneNumber: string;
}
interface AuthWithMagicLinkResponseBody {
  userId: string;
}

export const useAuthWithMagicLink = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (payload: AuthWithMagicLinkPayload) =>
      API.post<AuthWithMagicLinkResponseBody, AuthWithMagicLinkPayload>(
        '/v1/user/auth/magic-link',
        payload,
      ),
  });

  return {
    isSubmitting: isPending,
    authWithMagicLinkAsync: mutateAsync,
  };
};

interface ValidateAuthWithMagicLinkPayload {
  userId: string;
  code: string;
}
interface ValidateAuthWithMagicLinkResponseBody {
  user: any;
  token: string;
}

export const useValidateAuthWithMagicLink = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (payload: ValidateAuthWithMagicLinkPayload) =>
      API.post<ValidateAuthWithMagicLinkResponseBody, ValidateAuthWithMagicLinkPayload>(
        '/v1/user/auth/magic-link/validate',
        payload,
      ),
  });

  return {
    isSubmitting: isPending,
    validateAuthWithMagicLinkAsync: mutateAsync,
  };
};
