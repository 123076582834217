import { useQuery } from '@tanstack/react-query';
import API from '@api/API';

export interface ParsedEventProperties {
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  isRecurring: boolean;
  days: number[];
}

export interface SourceLog {
  _id: string;
  createdAt: string;
  sourceUsername: string;
  postForProcessing: {
    key: string;
    postedAt: string;
    text: string;
  };
  processedEventProperties?: ParsedEventProperties;
  fineTuningValue?: {
    events: ParsedEventProperties[];
    isInValid: boolean;
  };
}

export const useSourceLogsQuery = ({ isEnabled = true }: { isEnabled: boolean }) => {
  const limit = 1000;
  const { isFetched, data, refetch } = useQuery({
    queryKey: useSourceLogsQuery.queryKey(),
    queryFn: () =>
      API.get<{ sourceLogs: SourceLog[] }>(`/v1/super-admin/source-logs?limit=${limit}`),
    enabled: isEnabled,
  });

  return {
    sourceLogsAreReady: isFetched,
    sourceLogs: data?.sourceLogs,
    refetchSourceLogs: refetch,
  };
};
useSourceLogsQuery.queryKey = () => ['sourceLogs'];
