import { useQuery } from '@tanstack/react-query';

import API from '@api/API';

export const useOutgoingMessageByIncomingMessageId = (
  { incomingMessageId }: { incomingMessageId: string },
) => {
  const { isFetched: outgoingMessageIsReady, data } = useQuery({
    queryKey: ['outgoingMessages', 'incomingMessageId', incomingMessageId],
    queryFn: () =>
      API.get<{ outgoingMessage: { events: any[]; venues: any[] } }>(
        `/v1/outgoing-messages/?incomingMessageId=${incomingMessageId}`,
      ),
    enabled: true,
  });

  const { outgoingMessage } = data ?? { outgoingMessage: { events: [], venues: [] } };

  return {
    outgoingMessageIsReady,
    outgoingMessage,
  };
};
