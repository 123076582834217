import { useQuery } from '@tanstack/react-query';
import API from '@api/API';
import { useEffect, useState } from 'react';

export interface TriviaQuestion {
  _id: string;
  question: string;
  type: 'shortAnswer' | 'multipleChoice';
  options?: string[];
  progress?: {
    current: number;
    total: number;
  };
}

export interface AnswerTriviaQuestionResponse {
  message: string;
  isCorrect: boolean;
  answer?: string;
  correctAnswer?: string;
  expiresAt: string;
}

export const useTriviaChallenges = (queryParams: { cityId?: string }, isEnabled = true) => {
  const { cityId } = queryParams;
  const queryString = new URLSearchParams(queryParams).toString();

  const { isFetched, data, refetch } = useQuery({
    queryKey: ['triviaChallenges', 'city', cityId],
    queryFn: () => API.get<{ triviaChallenges: any[] }>(`/v1/trivia?${queryString}`),
    enabled: isEnabled,
  });

  return {
    triviaChallengesAreReady: isFetched,
    triviaChallenges: data?.triviaChallenges,
    refetchTriviaChallenges: refetch,
  };
};

export const useTriviaChallenge = (triviaChallengeId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: ['triviaChallenges', triviaChallengeId],
    queryFn: () => API.get<{ triviaChallenge: any }>(`/v1/trivia/${triviaChallengeId}`),
    enabled: isEnabled,
  });

  return {
    triviaChallengeIsReady: isFetched,
    triviaChallenge: data?.triviaChallenge,
    refetchTriviaChallenge: refetch,
  };
};

export const useTriviaChallengeOptions = (triviaChallengeId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: ['triviaChallenges', triviaChallengeId, 'options'],
    queryFn: () => API.get<{ questionOptions: any[] }>(`/v1/trivia/${triviaChallengeId}/options`),
    enabled: isEnabled,
  });

  return {
    triviaChallengeOptionsAreReady: isFetched,
    triviaChallengeOptions: data,
    refetchTriviaChallengeOptions: refetch,
  };
};

export interface CurrentUserTriviaQuestionResponse {
  canRequestNewQuestion: boolean;
  currentQuestion?: TriviaQuestion;
  currentResponse?: AnswerTriviaQuestionResponse;
}

export const useUserCurrentTriviaQuestion = () => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useUserCurrentTriviaQuestion.queryKey(),
    queryFn: useUserCurrentTriviaQuestion.queryFn,
  });

  return {
    currentTriviaQuestionIsReady: isFetched,
    currentTriviaQuestion: data,
    refetchTriviaChallengeOptions: refetch,
  };
};
useUserCurrentTriviaQuestion.queryKey = () => ['currentTriviaQuestion'];
useUserCurrentTriviaQuestion.queryFn = () =>
  API.get<CurrentUserTriviaQuestionResponse>(`/v1/trivia/play`);

export const useFetchUserCurrentTriviaQuestion = () => {
  const [currentTriviaQuestion, setCurrentTriviaQuestion] =
    useState<CurrentUserTriviaQuestionResponse>();

  useEffect(() => {
    (async () => {
      const data = await useUserCurrentTriviaQuestion.queryFn();
      setCurrentTriviaQuestion(data);
    })();
  }, []);

  return {
    currentTriviaQuestionIsReady: !!currentTriviaQuestion,
    currentTriviaQuestion,
  };
};
