import { FC, ReactElement } from 'react';

import './TitleToolbar.scss';

interface TitleToolbarProps {
  className?: string;
  text: string | ReactElement;
  size?: 'sm' | 'md' | 'lg';
  action?: ReactElement;
}
export const TitleToolbar: FC<TitleToolbarProps> = ({
  className = '',
  size = 'lg',
  text,
  action,
}) => {
  return (
    <div
      className={`title-toolbar title-toolbar-size-${size} d-flex justify-content-between align-items-center ${className}`}
    >
      <h1 className="title">{text}</h1>
      {action && <div className="title-action text-nowrap">{action}</div>}
    </div>
  );
};
