import { FC, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import parsePhoneNumber from 'libphonenumber-js';
import { keyBy } from 'lodash';

import { useOutgoingMessageByIncomingMessageId } from '@queries/index';

import { useOneSignal } from '@hooks/useOneSignal';

import { useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import './EventsScreen.scss';

const PHONE_NUMBER = `+17207342336`;
const MESSAGE = "What's happening in Denver this weekend?";
const GET_STARTED_LINK = `sms:${PHONE_NUMBER}?&body=${MESSAGE}`;

export const SmsScreen: FC<{}> = () => {
  useEffect(() => {
    window.open(GET_STARTED_LINK);
  }, []);

  const phoneNumber = parsePhoneNumber(PHONE_NUMBER);

  return (
    <Screen name="Sms Screen">
      <Screen.Content>
        <Card>
          <Card.Header title="Discover Local Events" />
          <Card.Body>
            <p>
              We're an AI-powered texting app here to let you know what's happening in Denver. To
              get started, just send "{MESSAGE}" to {phoneNumber.formatNational()}.
            </p>
          </Card.Body>
          <Card.Footer>
            <a href={GET_STARTED_LINK} className="btn btn-primary">
              Get Started
            </a>
          </Card.Footer>
        </Card>
      </Screen.Content>
    </Screen>
  );
};
