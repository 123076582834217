import { useQueryClient } from '@tanstack/react-query';
import { FC, useMemo } from 'react';

import { useAppContext } from '@contexts/AppContext';
import { keyBy, orderBy } from 'lodash';

import {
  useCurrentEntertainerQuery,
  usePrioritizedEntertainersQuery,
  useTopEntertainersQuery,
} from '@queries/EntertainerQueries';
import { useFeedPostsQuery } from '@queries/PostQueries';

import { useSendEntertainerBadge } from '@mutations/EntertainerMutations';

import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import Button from '@components/buttons/Button';
import { SendEntertainerBadgeButton } from '@components/buttons/SendEntertainerBadgeButton';
import { List } from '@components/layout/List/List';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

const useShareBadgeList = () => {
  const { userIsLoggedIn } = useAppContext();
  const { currentCity } = useCurrentUserCity();
  const { entertainer } = useCurrentEntertainerQuery();

  const { posts = [] } = useFeedPostsQuery(userIsLoggedIn, {});

  const {
    topEntertainersAreReady,
    topEntertainers = [],
    totalEntertainers,
    refetchTopEntertainers,
  } = useTopEntertainersQuery(currentCity?._id, 'dragPerformer', userIsLoggedIn);

  const sortedEntertainers = useMemo(() => {
    const boostedEntertainers = posts.filter(
      ({ type, entertainer, isBoosted }: any) =>
        isBoosted && type === 'ratingActivity' && !!entertainer,
    );
    const boostedEntertainersById = keyBy(boostedEntertainers, 'documentId');
    const likedEntertainersById = keyBy(
      topEntertainers.filter(({ primaryRating }) => primaryRating === 'like'),
      '_id',
    );

    return orderBy(
      topEntertainers,
      [
        (entertainer) => !!boostedEntertainersById[entertainer._id],
        (entertainer) => !!likedEntertainersById[entertainer._id],
      ],
      ['desc', 'desc'],
    );
  }, [posts, topEntertainers]);

  return { entertainers: sortedEntertainers };
};

export const SendBadgesList: FC<{ lockedBadgeId: string }> = ({ lockedBadgeId }) => {
  const { currentCity } = useCurrentUserCity();
  const { prioritizedEntertainers = [] } = usePrioritizedEntertainersQuery(
    currentCity?._id,
    'dragPerformer',
  );

  return (
    <div style={{ marginLeft: '-1rem', marginRight: '-1rem' }}>
      <List
        className="mt-0"
        listItems={prioritizedEntertainers.map((entertainer) => ({
          title: entertainer.title,
          endIcon: (
            <SendEntertainerBadgeButton
              entertainerId={entertainer._id}
              lockedBadgeId={lockedBadgeId}
              skeletonMaxWidth={100}
              text="Send"
              buttonProps={{
                size: 'sm',
                className: 'px-3 py-2',
                roundness: 'rounded',
                variant: 'smooth',
                color: 'neutral',
              }}
            />
          ),
        }))}
      />
    </div>
  );
};

export const SendBadgePopup: FC<{
  isVisible: boolean;
  lockedBadgeId?: string;
  onClose: () => void;
}> = ({ isVisible, lockedBadgeId, onClose }) => {
  return (
    <Popup isVisible={isVisible} onClose={onClose}>
      <Popup.Content>
        <TitleToolbar className="mt-2 mb-0" text="Share the love!" size="md" />
        <p className="mt-1">
          Did someone stand out to you recently? Did they have a phenomenal performance? Wow you
          with a look? Badges are a nice way to send them some love.
        </p>
        {isVisible && <SendBadgesList lockedBadgeId={lockedBadgeId} />}
      </Popup.Content>
    </Popup>
  );
};

export const UnlockBadgePopup: FC<{
  isVisible: boolean;
  lockedBadgeId?: string;
  onClose: () => void;
}> = ({ isVisible, lockedBadgeId, onClose }) => {
  return (
    <Popup isVisible={isVisible} onClose={onClose}>
      <Popup.Content>
        <TitleToolbar className="mt-2 mb-0" text="Share a badge to unlock" size="md" />
        <p className="mt-1">
          To unlock a badge, you gotta share some love by sending one. Select a fellow entertainer
          from the list.
        </p>
        {isVisible && <SendBadgesList lockedBadgeId={lockedBadgeId} />}
      </Popup.Content>
    </Popup>
  );
};
