import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useAppContext } from '@contexts/AppContext';

import API from '@api/API';

import { useAnalytics } from '@hooks/useAnalytics';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { SendEntertainerBadgeButton } from '@components/buttons/SendEntertainerBadgeButton';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { ReportOverlay } from '@components/overlays/ReportOverlay/ReportOverlay';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

const useUserEntertainerCampaignVoteQuery = (entertainerId: string, campaignId: string) => {
  const { userIsLoggedIn } = useAppContext();

  const { data, isFetched } = useQuery({
    queryKey: ['entertainer', entertainerId, 'campaign', campaignId, 'vote'],
    queryFn: () =>
      API.get<{ didVote: boolean }>(
        `/v1/entertainers/${entertainerId}/campaigns/${campaignId}/vote`,
      ),
    enabled: userIsLoggedIn,
  });

  return {
    userEntertainerCampaignVote: data,
    isUserEntertainerCampaignVoteReady: isFetched,
  };
};

const useUserEntertainerCampaignVoteMutation = (entertainerId: string, campaignId: string) => {
  const { userIsLoggedIn } = useAppContext();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      API.post(`/v1/entertainers/${entertainerId}/campaigns/${campaignId}/vote`, {}),
  });

  return {
    voteForEntertainerAsync: mutateAsync,
    isVotingForEntertainer: isPending,
  };
};

export const EntertainerMediaCard = ({
  entertainerId,
  media,
  imageHeight,
}: {
  entertainerId: string;
  media: {
    _id: string;
    title: string;
    image?: string;
  };
  imageHeight?: number;
}) => {
  const { track } = useAnalytics();
  const { userIsLoggedIn } = useAppContext();
  const { createAuthGateHandler } = useAuthGate();
  const [reportOverlayIsVisible, setReportOverlayIsVisible] = useState(false);

  return (
    <>
      <Card>
        <Card.Header
          title={media.title}
          action={
            userIsLoggedIn ? (
              <IconButton
                variant="flat"
                color="neutral"
                onClick={() => setReportOverlayIsVisible(true)}
              >
                <FontAwesomeIcon faStyle="far" name="flag" />
              </IconButton>
            ) : undefined
          }
        />
        <Card.Body>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: imageHeight,
            }}
          >
            <AsyncImage
              src={media.image}
              style={{ borderRadius: '1rem', height: 'auto', maxHeight: '100%' }}
            />
          </div>
        </Card.Body>
        <Card.Footer>
          <SendEntertainerBadgeButton
            entertainerId={entertainerId}
            entertainerMediaId={media._id}
          />
        </Card.Footer>
      </Card>
      <ReportOverlay
        isVisible={reportOverlayIsVisible}
        title="Report Image"
        content={<p>Are you sure you want to report this image?</p>}
        data={{
          collectionName: 'entertainerMedia',
          objectId: media._id,
        }}
        onClose={() => setReportOverlayIsVisible(false)}
      />
    </>
  );
};
