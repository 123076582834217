import { MouseEventHandler, useEffect, useState } from 'react';

import { useFollowsQuery } from '@queries/UserQueries';

import { useFollowMutation, useUnfollowMutation } from '@mutations/FollowMutations';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { useAnalytics } from './useAnalytics';

export const useUserIsFollowing = (documentType: 'promoter' | 'venue', documentId: string) => {
  const { follows } = useFollowsQuery();
  const { followAsync } = useFollowMutation();
  const { unfollowAsync } = useUnfollowMutation();
  const [userIsFollowing, setUserIsFollowing] = useState<boolean>(false);
  const { track } = useAnalytics();
  const { createAuthGateHandler } = useAuthGate();

  useEffect(() => {
    setUserIsFollowing(follows?.[documentType]?.[documentId]);
  }, [follows, documentType, documentId]);

  const handleFollow: MouseEventHandler = async (event) => {
    event.stopPropagation();
    createAuthGateHandler(async () => {
      setUserIsFollowing(true);
      track(`Follow ${documentType}`, { documentId });
      await followAsync({ documentType, documentId });
    });
  };

  const handleUnfollow: MouseEventHandler = async (event) => {
    event.stopPropagation();
    createAuthGateHandler(async () => {
      setUserIsFollowing(false);
      track(`Unfollow ${documentType}`, { documentId });
      await unfollowAsync({ documentType, documentId });
    });
  };

  return {
    userIsFollowing,
    handleFollow,
    handleUnfollow,
  };
};
