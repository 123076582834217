import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from '@api/API';

import { useCurrentUserGagglRankQuery } from '@queries/CritiqueQueries';
import {
  EventInterestOption,
  EventRsvpOption,
  useCurrentUserEventRsvpsQuery,
} from '@queries/EventQueries';

interface MarkDuplicateEventPayload {
  duplicateEventId: string;
}

export const useMarkDuplicateEventMutation = ({ eventId: primaryEventId }: { eventId: string }) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync: markDuplicateEventAsync } = useMutation({
    mutationFn: (payload: MarkDuplicateEventPayload) => {
      return API.post(`/v1/events/${primaryEventId}/mark-duplicate`, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['events'] });
    },
  });

  return {
    isSubmitting,
    markDuplicateEventAsync,
  };
};

export interface UpdateEventPayload {
  isActive?: boolean;
  title?: string;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  updatedForAccuracy?: boolean;
  venue?: {
    _id?: string;
    title?: string;
  };
  entertainerIds?: string[];
}

export const useUpdateEventMutation = ({ eventId }: { eventId: string }) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync: updateEventAsync } = useMutation({
    mutationFn: (payload: UpdateEventPayload) => {
      return API.put(`/v1/events/${eventId}`, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['events'] });
    },
  });

  return {
    isSubmitting,
    updateEventAsync,
  };
};

export interface RsvpToEventPayload {
  rsvp?: EventRsvpOption;
  isLiked?: boolean;
  isStarred?: boolean;
  eventInterest?: EventInterestOption;
  interested?: string[];
  notInterested?: string[];
  comment?: string;
}
export const useRsvpToEventMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (params: RsvpToEventPayload & { eventId: string }) => {
      const { eventId, ...payload } = params;
      return API.post(`/v1/user/events/${eventId}/rsvp`, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentUserEventRsvpsQuery.queryKey() });
      queryClient.invalidateQueries({ queryKey: useCurrentUserGagglRankQuery.queryKey() });
    },
  });

  return {
    isSubmitting: isPending,
    rsvpToEventAsync: mutateAsync,
  };
};
