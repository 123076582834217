import { FC, useMemo, useState } from 'react';

import { maxBy } from 'lodash';

import { BasicRating, CritiqueData, UserCritique } from '@queries/CritiqueQueries';

import { CritiqueDocumentMutationPayload } from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { CritiqueOverlay } from '@components/overlays/CritiqueOverlay/CritiqueOverlay';
import { BASIC_RATING_COLOR_RECORD } from '@components/overlays/CritiqueOverlay/CritiqueOverlayConstants';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';
import { CritiqueList } from '@screens/entertainer/EntertainerRankingsScreen/EntertainerCritiquePopup';

import { ButtonFilter } from '../ButtonFilter/ButtonFilter';
import { EmptyState } from '../EmptyState/EmptyState';
import { DetailsChartSection } from './DetailsChartSection';

interface CritiqueSectionProps {
  showShareButton?: boolean;
  critiqueData: CritiqueData;
  userCritique?: UserCritique;
  onSubmit: (payload: CritiqueDocumentMutationPayload) => Promise<void>;
}

const TAB_OPTIONS = [
  {
    value: 'like',
    label: 'Positive',
  },
  { value: 'neutral', label: 'Indifferent' },
  {
    value: 'dislike',
    label: 'Negative',
  },
] as const;
type TabOption = (typeof TAB_OPTIONS)[number]['value'];

const PLACEHOLDER_TEXTS: Record<BasicRating, string> = {
  like: 'Add positives',
  neutral: 'Add indifferent reasons',
  dislike: 'Add negatives',
};

export const CritiqueSection: FC<CritiqueSectionProps> = ({
  showShareButton = true,
  critiqueData,
  userCritique,
  onSubmit,
}) => {
  const { track } = useAnalytics();
  const [critiqueOverlayIsVisible, setCritiqueOverlayIsVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>(() => {
    const maxEntry = maxBy(Object.entries(critiqueData.basicRatings ?? {}), ([, value]) => value);
    return maxEntry?.[0] ?? 'like';
  });
  const { createAuthGateHandler } = useAuthGate();

  const handleOpenOverlay = () => {
    createAuthGateHandler(() => setCritiqueOverlayIsVisible(true));
  };

  const handleSubmit = async (payload: CritiqueDocumentMutationPayload) => {
    setCritiqueOverlayIsVisible(false);
    onSubmit(payload);
  };

  return (
    <div className="d-flex gap-2 flex-column">
      <div className="w-100">
        <ButtonFilter
          className="my-0"
          options={TAB_OPTIONS.map(({ value, label }) => ({
            value,
            label,
          }))}
          selected={currentTab}
          onChange={(updatedSearchType: TabOption) => setCurrentTab(updatedSearchType)}
        />
      </div>

      <DetailsChartSection
        choiceType={currentTab}
        updateChoiceType={setCurrentTab}
        detailsChartData={{
          choices: critiqueData.basicRatings,
          reasons: critiqueData.reasons,
          reasonTotals: critiqueData.reasonTotals,
          colors: BASIC_RATING_COLOR_RECORD,
        }}
        emptyState={
          <EmptyState
            text="No one has critiqued this yet. Be the first!"
            // button={{
            //   text: 'Be the first',
            //   onClick: () => {
            //     track('Open Critique Overlay', {
            //       component: 'Empty State Button',
            //     });
            //     handleOpenOverlay();
            //   },
            // }}
          />
        }
      />

      <div key={`critique-section-${currentTab}`}>
        <CritiqueList
          selectedTags={userCritique?.[currentTab].map(({ label }) => label) ?? []}
          tags={critiqueData.reasons?.[currentTab].map(({ label }) => label)}
          agreeBtnText="Yas"
          inputPlaceholder={PLACEHOLDER_TEXTS[currentTab as BasicRating]}
          onSubmit={(updatedTags) => {
            onSubmit({
              [currentTab]: updatedTags,
            });
          }}
        />
      </div>

      {showShareButton && false && (
        <>
          <Button
            color="default"
            endIcon={<FontAwesomeIcon name="chevron-right" />}
            onClick={() => {
              track('Open Critique Overlay', {
                component: 'Primary Share Button',
              });
              handleOpenOverlay();
            }}
          >
            Share Critique
          </Button>
          <CritiqueOverlay
            isVisible={critiqueOverlayIsVisible}
            primaryRating={userCritique?.primaryCritique}
            reasons={
              userCritique
                ? {
                    like: userCritique?.like,
                    neutral: userCritique?.neutral,
                    dislike: userCritique?.dislike,
                  }
                : undefined
            }
            onSubmit={handleSubmit}
            onClose={() => setCritiqueOverlayIsVisible(false)}
          />
        </>
      )}
    </div>
  );
};
