import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import API from '@api/API';

import { useAttachments } from './useAttachments';

interface SharedMessageData {
  _id: string;
  text?: string;
  image?: string;
}

interface SentMessageData extends SharedMessageData {
  type: 'sent';
  name?: undefined;
  avatar?: undefined;
}

interface ReceivedMessageData extends SharedMessageData {
  type: 'received';
  name: string;
  avatar: string;
}

export type MessageData = SentMessageData | ReceivedMessageData;

export interface ChatParams {
  currentPromoterId?: string;
  entertainerId?: string;
  currentEntertainerId?: string;
  promoterId?: string;
  currentUserId?: string;
  userId?: string;
}

const getChatApiUrl = (params: ChatParams) => {
  const {
    currentPromoterId,
    entertainerId,
    currentEntertainerId,
    promoterId,
    currentUserId,
    userId,
  } = params;

  if (currentPromoterId && entertainerId) {
    return `/v1/chat/promoter/${currentPromoterId}/entertainer/${entertainerId}`;
  }

  if (currentEntertainerId) {
    if (userId) {
      return `/v1/chat/entertainer/${currentEntertainerId}/user/${userId}`;
    }

    if (promoterId) {
      return `/v1/chat/entertainer/${currentEntertainerId}/promoter/${promoterId}`;
    }
  }

  if (currentUserId) {
    if (entertainerId) {
      return `/v1/chat/user/${currentUserId}/entertainer/${entertainerId}`;
    }
  }

  return '';
};

const useChatQuery = (
  params: ChatParams,
): { messagesAreReady: boolean; messagesData: MessageData[] } => {
  const { isFetched, data } = useQuery({
    queryKey: useChatQuery.queryKey(params),
    queryFn: () => useChatQuery.queryFn(params),
    refetchInterval: 5000,
  });

  return {
    messagesAreReady: isFetched,
    messagesData: data?.messages || [],
  };
};
useChatQuery.queryKey = (params: ChatParams) => ['chat', params];
useChatQuery.queryFn = (params: ChatParams) => {
  const url = getChatApiUrl(params);

  if (url) {
    return API.get<{ messages: MessageData[] }>(url);
  }

  return Promise.resolve({ messages: [] });
};

interface SendMessageMutationPayload {
  messages: { text: string; image?: string }[];
}

const useSendMessageMutation = (params: ChatParams) => {
  const queryClient = useQueryClient();
  const { isPending, mutateAsync } = useMutation({
    mutationFn: async ({ messages }: SendMessageMutationPayload) => {
      const url = getChatApiUrl(params);
      if (!url) {
        return Promise.resolve();
      }

      return API.post<{}, SendMessageMutationPayload>(url, { messages });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useChatQuery.queryKey(params) });
    },
  });

  return { isSendingMessage: isPending, sendMessageAsync: mutateAsync };
};

export const useChatMessages = (params: ChatParams) => {
  const {
    images,
    attachments,
    setAttachments,
    deleteAttachment,
    handleAttachment,
    sheetVisible,
    setSheetVisible,
  } = useAttachments();

  const [messageText, setMessageText] = useState('');
  const { messagesAreReady, messagesData } = useChatQuery(params);
  const { isSendingMessage, sendMessageAsync } = useSendMessageMutation(params);

  const sendMessage = async () => {
    if (!isSendingMessage) {
      const messagesToSend = [];
      attachments.forEach((attachment) => {
        messagesToSend.push({
          image: attachment,
        });
      });
      if (messageText.length) {
        messagesToSend.push({
          text: messageText,
        });
      }
      if (messagesToSend.length === 0) {
        return;
      }
      setAttachments([]);
      setSheetVisible(false);
      await sendMessageAsync({ messages: messagesToSend });
      setMessageText('');
    }
  };

  return {
    messagesAreReady,
    messageText,
    setMessageText,
    messagesData,
    sendMessage,

    images,
    attachments,
    deleteAttachment,
    handleAttachment,
    sheetVisible,
    setSheetVisible,
  };
};
