import {
  Elements,
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { FC, useCallback, useState } from 'react';

import { loadStripe } from '@stripe/stripe-js';

import API from '@api/API';

import { useAnalytics } from '@hooks/useAnalytics';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const TipButton: FC<{ entertainer: TipEntertainer; amount: number; onComplete: () => void }> = ({
  entertainer,
  amount,
  onComplete,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      return;
    }

    setIsLoading(true);

    const { clientSecret } = await API.post<
      { clientSecret: string },
      { tip: number; entertainerId: string }
    >('/v1/stripe/create-payment-intent', { tip: amount, entertainerId: entertainer._id });

    if (!clientSecret) {
      console.log('Error creating payment intent');
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      redirect: 'if_required',
      confirmParams: {
        return_url: window.location.href,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      setMessage(error?.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);

    onComplete();
  };

  return (
    <Button
      disabled={isLoading}
      roundness="rounded"
      onClick={handleSubmit}
    >{`Tip $${(amount / 100).toFixed(2)}`}</Button>
  );
};

interface TipEntertainer {
  _id: string;
  title: string;
}
const TipPopup: FC<{
  isVisible: boolean;
  onClose: () => void;
  entertainer?: TipEntertainer;
  amount: number;
}> = ({ isVisible, onClose, entertainer, amount }) => {
  return (
    <Popup isVisible={isVisible} onClose={onClose} height="3quarters">
      <Elements
        stripe={stripePromise}
        options={{
          currency: 'usd',
          mode: 'payment',
          amount,
          appearance: {
            rules: {
              '.Label': {
                color: '#fff',
              },
              '.Input': {
                border: 'none',
                backgroundColor: 'rgb(14, 35, 45)',
                color: '#fff',
              },
            },
          },
        }}
      >
        <div className="p-4">
          <TitleToolbar text={`Tip ${entertainer?.title}`} size="md" />
          <PaymentElement />
          <div className="mt-4">
            <TipButton amount={amount} entertainer={entertainer} onComplete={onClose} />
          </div>
        </div>
      </Elements>
      {/* <div className="p-4">
        <h2>Tip Entertainer</h2>
        <p>Enter the amount you would like to tip the entertainer</p>
        <input type="number" placeholder="Enter amount" />
        <button>Tip</button>
      </div> */}
    </Popup>
  );
};
const useTipEntertainerPopup = () => {
  const [entertainer, setEntertainer] = useState<TipEntertainer | undefined>(undefined);
  const [amount, setAmount] = useState(1000);

  const openTipEntertainerPopup = (entertainer: TipEntertainer) => {
    setEntertainer(entertainer);
  };

  const updateAmount = useCallback((updatedAmount: number) => setAmount(updatedAmount), []);

  return {
    tipEntertainerPopup: (
      <TipPopup
        isVisible={!!entertainer}
        onClose={() => setEntertainer(undefined)}
        entertainer={entertainer}
        amount={amount}
      />
    ),
    openTipEntertainerPopup,
    updateAmount,
  };
};

export const useTipEntertainer = () => {
  const { track } = useAnalytics();
  const tipEntertainerPopupState = useTipEntertainerPopup();

  const handleVenmoEntertainer = ({
    entertainerId,
    venmo,
    message = 'You slay!',
    amount = 10, // In dollars
  }: {
    entertainerId: string;
    message?: string;
    venmo?: string;
    amount?: number;
  }) => {
    track('Tip Entertainer', { entertainerId, venmo, message, amount });
    if (venmo) {
      openExternalUrl(
        `venmo://paycharge?txn=pay&recipients=${venmo}&note=${encodeURIComponent(message)}!&amount=${amount}`,
      );
    }
  };

  return { ...tipEntertainerPopupState, handleVenmoEntertainer };
};
