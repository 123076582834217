import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from '@api/API';

import { useCurrentUser, useNotificationsQuery } from '@queries/UserQueries';

import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

export interface UpdateProfilePayload {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  displayName?: string;
  notificationsStatus?: 'enabled' | 'disabled';
  smsNotificationsStatus?: 'enabled' | 'disabled';
}

export const useUpdateProfileMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateProfileAsync, isPending: isSubmitting } = useMutation({
    mutationFn: (updatedUserProperties: UpdateProfilePayload) =>
      API.put('/v1/user/me', updatedUserProperties),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentUser.queryKey() });
    },
  });

  return { isSubmitting, updateProfileAsync };
};

export const useDeleteProfileMutation = () => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => API.delete('/v1/user/me'),
  });

  return { isSubmitting: isPending, deleteProfileAsync: mutateAsync };
};

export interface UserOnboardingPayload {
  displayName?: string;
  interests?: string[];
}

export const useUserOnboardingMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateUserOnboardingAsync, isPending: isSubmitting } = useMutation({
    mutationFn: (updatedUserProperties: UserOnboardingPayload) =>
      API.post('/v1/user/onboarding', updatedUserProperties),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentUser.queryKey() });
    },
  });

  return { isSubmitting, updateUserOnboardingAsync };
};

export const useMarkNotificationsAsSeenMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => API.post('/v1/user/notifications/seen', {}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useNotificationsQuery.queryKey() });
    },
  });

  return { isSubmitting: isPending, markNotificationsAsSeenAsync: mutateAsync };
};

export interface MarkNotificationAsReadPayload {
  notificationId: string;
}

export const useMarkNotificationAsReadMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ notificationId }: MarkNotificationAsReadPayload) =>
      API.post(`/v1/notifications/${notificationId}/read`, {}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useNotificationsQuery.queryKey() });
    },
  });

  return { isSubmitting: isPending, markNotificationAsReadAsync: mutateAsync };
};

// export interface ChangeUserCityPayload {
//   cityId: string;
// }

// export const useChangeUserCityMutation = () => {
//   const queryClient = useQueryClient();

//   const { mutateAsync, isPending } = useMutation({
//     mutationFn: ({ cityId }: ChangeUserCityPayload) =>
//       API.post(`/v1/notifications/${notificationId}/read`, {}),
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: useCurrentUserCity.queryKey() });
//     },
//   });

//   return { isSubmitting: isPending, changeUserCityAsync: mutateAsync };
// };
