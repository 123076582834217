import { List, ListItem } from 'framework7-react';
import { ComponentProps, FC, useMemo, useRef, useState } from 'react';

import { uniqBy } from 'lodash';

import { UserCritique } from '@queries/CritiqueQueries';
import { EntertainerRank } from '@queries/EntertainerQueries';
import {
  Promoter,
  useCurrentUserPromoterCritiqueQuery,
  usePromoterCritiqueDataQuery,
} from '@queries/PromoterQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Input } from '@components/form/Input';
import { CritiqueSection } from '@components/layout/DetailsChartSection/CritiqueSection';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';
import { DocumentCritiquePopup } from '@components/overlays/DocumentCritiquePopup';

interface PromoterCritiqueContentProps {
  selectedPromoter: Promoter;
  onClickRating: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onUpdateStrengths: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onUpdateImprovements: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onClose: () => void;
}

interface PromoterCritiquePopupProps {
  selectedPromoter?: Promoter;
  onClose: () => void;
}
export const PromoterCritiquePopup: FC<PromoterCritiquePopupProps> = ({
  selectedPromoter,
  onClose,
}) => {
  const { track } = useAnalytics();
  const navigate = useNavigate();

  const { promoterCritiqueDataIsReady, promoterCritiqueData } = usePromoterCritiqueDataQuery(
    selectedPromoter?._id,
  );
  const { userPromoterCritiqueIsReady, userPromoterCritique } = useCurrentUserPromoterCritiqueQuery(
    selectedPromoter?._id,
  );

  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();
  const handleClickRating = () => {
    return async (payload: CritiqueDocumentMutationPayload) => {
      track('Click rate promoter', {
        ...payload,
        promoterId: selectedPromoter?._id,
        promoterName: selectedPromoter?.title,
      });
      await critiqueDocumentAsync({
        ...payload,
        documentId: selectedPromoter?._id,
        documentType: 'promoters',
      });
    };
  };

  const handleViewPromoterProfile = () => {
    navigate(`/promoters/${selectedPromoter?._id}`);
  };

  return (
    <DocumentCritiquePopup
      isLoading={!promoterCritiqueDataIsReady || !userPromoterCritiqueIsReady}
      onClose={onClose}
      onClickRating={handleClickRating}
      onViewDocumentDetails={handleViewPromoterProfile}
      selectedDocument={selectedPromoter}
      critiqueData={promoterCritiqueData}
      userDocumentCritique={userPromoterCritique}
    />
  );
};
