import { useMemo } from 'react';

import { keyBy } from 'lodash';

import { useCurrentPromoterQuery, usePromoterManageQuery } from '@queries/PromoterQueries';

import { useCreatePromoterEventMutation } from '@mutations/PromoterMutations';

import { useCityOptionsByIds } from '@hooks/useCityOptionsByIds';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useParams, useRouter } from '@router/index';

import { Screen } from '@components/layout/Screen';

import { CreateUpdateEventForm } from './CreateEditEventForm';

export const CreateEditBookingOpportunityEventScreen = () => {
  const { back } = useRouter();
  const { bookingOpportunityId } = useParams<{ bookingOpportunityId?: string }>();
  const { promoterIsReady, promoter } = useCurrentPromoterQuery();
  const { promoterDetails, promoterDetailsAreReady } = usePromoterManageQuery(promoter?._id);

  const bookingOpportunity = useMemo(() => {
    return promoterDetails?.bookingOpportunities.find(({ _id }) => _id === bookingOpportunityId);
  }, [promoterDetails?.bookingOpportunities, bookingOpportunityId]);

  const { createPromoterEventAsync } = useCreatePromoterEventMutation(promoter?._id);
  const { cityOptionsAreReady, cityOptions } = useCityOptionsByIds(promoter?.cityIds ?? []);

  const handleSubmit = async (payload) => {
    await createPromoterEventAsync({ ...payload, bookingOpportunityId });
    back();
  };

  return (
    <Screen
      name="Create Promoter Event Screen"
      headerProps={{ back: { to: '/manage/promoter' }, title: 'Create Event' }}
      hideToolbar
    >
      <Screen.Content>
        {cityOptionsAreReady && bookingOpportunity && (
          <CreateUpdateEventForm
            defaultValues={{
              title: bookingOpportunity.title,
              startDate: bookingOpportunity.startDate,
              startTime: bookingOpportunity.startTime,
              cityId: bookingOpportunity.city._id,
              venue: {
                _id: bookingOpportunity.venue._id,
                title: bookingOpportunity.venue.title,
              },
            }}
            cityOptions={cityOptions}
            onSubmit={handleSubmit}
          />
        )}
      </Screen.Content>
    </Screen>
  );
};
