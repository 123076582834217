import { useMemo } from 'react';

import dayjs from 'dayjs';
import { keyBy } from 'lodash';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { useEventQuery } from '@queries/EventQueries';

import {
  useAddEntertainerToEventMutation,
  useCreateEntertainerEventMutation,
} from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCityOptionsByIds } from '@hooks/useCityOptionsByIds';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useRouter } from '@router/index';

import { Screen } from '@components/layout/Screen';

import { CreateUpdateEventContent, CreateUpdateEventForm } from './CreateEditEventForm';

export const CreateEditEntertainerEventScreen = ({ eventId }: { eventId: string }) => {
  const { back } = useRouter();
  const { entertainerIsReady, entertainer, refetchEntertainer } = useCurrentEntertainerQuery();
  const { createEntertainerEventAsync } = useCreateEntertainerEventMutation(entertainer?._id);
  const { addEntertainerToEventAsync } = useAddEntertainerToEventMutation(entertainer?._id);
  const { cityOptionsAreReady, cityOptions } = useCityOptionsByIds(entertainer?.cityIds ?? []);
  const { event } = useEventQuery(eventId);
  const { track } = useAnalytics();

  const isEdit = eventId && event && event?._id === eventId;
  const handleSubmit = async (payload) => {
    console.log({ isEdit, payload });
    if (isEdit) {
      // TODO: update event
    } else {
      await createEntertainerEventAsync(payload);
      await refetchEntertainer();
    }

    back();
  };

  const handleSelectedExistingEvent = async (existingEventId: string) => {
    track('Add entertainer to existing event', {
      entertainerId: entertainer?._id,
      eventId: existingEventId,
    });
    await addEntertainerToEventAsync({ eventId: existingEventId });
    back();
  };

  return (
    <Screen
      name={`${eventId ? 'Edit' : 'Add'} Entertainer Event Screen`}
      headerProps={{
        back: { to: '/manage/entertainer' },
        title: `${eventId ? 'Edit' : 'Add'} Event`,
      }}
      hideToolbar
    >
      <Screen.Content maxWidth={425}>
        {cityOptionsAreReady && entertainerIsReady && (eventId ? event?._id === eventId : true) && (
          <CreateUpdateEventContent
            cityOptions={cityOptions}
            defaultValues={{ entertainers: [entertainer] }}
            event={event}
            onSubmit={handleSubmit}
            onSelectExistingEvent={{
              text: 'Select event',
              action: handleSelectedExistingEvent,
            }}
          />
        )}
      </Screen.Content>
    </Screen>
  );
};
