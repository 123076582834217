import { createContext, useContext, useRef, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';

import { useFetchCurrentUserGagglRank } from '@queries/CritiqueQueries';

import { AuthPopup, useAuthState } from './LogInScreen';

const AuthGateContext = createContext<{
  createAuthGateHandler: (callback: () => void) => void;
}>({ createAuthGateHandler: () => {} });

export const useAuthGate = () => useContext(AuthGateContext);

export const AuthGateProvider = ({ children }) => {
  const [authPopupIsVisible, setAuthPopupIsVisible] = useState(false);
  const authState = useAuthState();
  const { userIsLoggedIn } = useAppContext();
  const fetchCurrentUserGagglRank = useFetchCurrentUserGagglRank();

  const authCallbackRef = useRef<() => void>();
  const createAuthGateHandler = (callback: () => void) => {
    if (userIsLoggedIn) {
      return callback();
    }

    setAuthPopupIsVisible(true);
    authCallbackRef.current = callback;
  };

  return (
    <AuthGateContext.Provider value={{ createAuthGateHandler }}>
      {children}
      <AuthPopup
        isVisible={authPopupIsVisible}
        onClose={() => {
          setAuthPopupIsVisible(false);
          authState.setUserId('');
        }}
        redirectOverride={async () => {
          await fetchCurrentUserGagglRank();
          requestAnimationFrame(() => {
            authCallbackRef.current?.();
          });
        }}
        {...authState}
      />
    </AuthGateContext.Provider>
  );
};
