import { useMemo } from 'react';

import { keyBy } from 'lodash';

import { useCurrentPromoterQuery } from '@queries/PromoterQueries';

import { useCreatePromoterEventMutation } from '@mutations/PromoterMutations';

import { useCityOptionsByIds } from '@hooks/useCityOptionsByIds';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useRouter } from '@router/index';

import { Screen } from '@components/layout/Screen';

import { CreateUpdateEventForm } from './CreateEditEventForm';

export const CreateEditPromoterEventScreen = () => {
  const { back } = useRouter();
  const { promoter } = useCurrentPromoterQuery();
  const { createPromoterEventAsync } = useCreatePromoterEventMutation(promoter?._id);
  const { cityOptionsAreReady, cityOptions } = useCityOptionsByIds(promoter?.cityIds ?? []);

  const handleSubmit = async (payload) => {
    await createPromoterEventAsync(payload);
    back();
  };

  return (
    <Screen
      name="Create Promoter Event Screen"
      headerProps={{ back: { to: '/manage/promoter' }, title: 'Create Event' }}
      hideToolbar
    >
      <Screen.Content maxWidth={425}>
        {cityOptionsAreReady && (
          <CreateUpdateEventForm cityOptions={cityOptions} onSubmit={handleSubmit} />
        )}
      </Screen.Content>
    </Screen>
  );
};
