import { FC, MouseEventHandler, ReactElement, ReactNode, useEffect, useState } from 'react';

import { FeedEvent, useCurrentUserEventRsvp } from '@queries/EventQueries';
import { useFollowsQuery } from '@queries/UserQueries';

import { useRsvpToEventMutation } from '@mutations/EventMutations';
import { useFollowMutation, useUnfollowMutation } from '@mutations/FollowMutations';

import { useUserIsFollowing } from '@hooks/useUserIsFollowing';

import { useNavigate } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';

import { EventCleanupPopup } from '@screens/EventCleanupScreen';

import './EventCard.scss';
import { PromoterFollowButton } from './PromoterCard';
import { VenueFollowButton } from './VenueCard';

const ENABLE_PROMOTER_FOLLOW = true;

export const PromoterHeader: FC<{ event: FeedEvent }> = ({ event }) => {
  const documentType = event.promoters?.length ? 'promoter' : 'venue';
  const promoters: { _id: string; title: string }[] = event.promoters?.length
    ? event.promoters
    : [event.venue];
  const [promoter] = promoters;

  if (!promoter) {
    return <></>;
  }

  return (
    <div className="event-promoters">
      <div className="d-flex justify-content-between gap-4 align-items-center">
        <div className="d-flex gap-2 align-items-center">
          <div className="promoter-img">
            {/* <AsyncImage src="" alt={event.venue.title} /> */}
            <LetterAvatar letter={promoter.title?.[0]} />
          </div>
          <h3>{promoter?.title}</h3>
        </div>
        <div className="event-promoters_following">
          {documentType === 'promoter' && <PromoterFollowButton promoter={promoter} />}
          {documentType === 'venue' && <VenueFollowButton venue={promoter} />}
        </div>
      </div>
    </div>
  );
};

export const EventHeader: FC<{
  event: FeedEvent;
  isStarred?: boolean;
  hideRsvp?: boolean;
  action?: ReactNode;
}> = ({ event, hideRsvp, action }) => {
  const { rsvpToEventAsync } = useRsvpToEventMutation();
  const { rsvp } = useCurrentUserEventRsvp(event._id);

  const [updatedRsvp, setUpdatedRsvp] = useState<{
    isStarred?: boolean;
  }>({});

  const isStarred = updatedRsvp?.isStarred ?? rsvp?.isStarred ?? false;

  return (
    <div className={`event-header d-flex flex-row justify-content-between gap-2`}>
      <div className={`d-flex flex-column`}>
        <h1>{event.title}</h1>
        <h2 className="d-flex gap-2">
          <div>
            <span className="me-1">
              <LineAwesomeIcon name="clock" />
            </span>
            {event.start}
          </div>
          <div>
            <span className="me-1">
              <LineAwesomeIcon name="map-marker" />
            </span>
            {event.venue.title}
          </div>
        </h2>
      </div>
      {hideRsvp ? (
        <>{action && <div>{action}</div>}</>
      ) : (
        <div>
          <IconButton
            color="neutral"
            variant="smooth"
            onClick={async (mouseEvent) => {
              mouseEvent.stopPropagation();
              const updatedValue = !isStarred;
              setUpdatedRsvp((prevValue) => ({ ...prevValue, isStarred: updatedValue }));
              await rsvpToEventAsync({ eventId: event._id, isStarred: updatedValue });
            }}
          >
            <FontAwesomeIcon faStyle={isStarred ? 'fas' : 'far'} name="bookmark" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export const EventImage: FC<{ event: FeedEvent }> = ({ event }) => {
  if (!event.img) {
    return <></>;
  }

  return (
    <div className="event-img-wrapper">
      <div className="event-img">
        <AsyncImage className="bg-img" src={event.img} alt={event.title} />
        <AsyncImage
          className="primary-img"
          src={event.img}
          alt={event.title}
          placeholder={<div className="event-img-placeholder" />}
        />
      </div>
    </div>
  );
};

export const EventActions: FC<{
  event: FeedEvent;
  showCleanup: boolean;
  onCommentClick?: () => void;
}> = ({ event, showCleanup, onCommentClick }) => {
  const navigate = useNavigate();
  const { rsvp } = useCurrentUserEventRsvp(event._id);
  const { rsvpToEventAsync } = useRsvpToEventMutation();

  const [updatedRsvp, setUpdatedRsvp] = useState<{
    isLiked?: boolean;
  }>({});

  const isLiked = updatedRsvp?.isLiked ?? rsvp?.isLiked ?? false;
  const [isEventCleanupPopupVisible, setEventCleanupPopupVisible] = useState(false);

  const handleNavigateToCleanup: MouseEventHandler = (mouseEvent) => {
    mouseEvent.stopPropagation();
    setEventCleanupPopupVisible(true);
  };

  const actionButtons: {
    isVisible: boolean;
    icon: ReactElement;
    onClick: any;
  }[] = [
    {
      isVisible: true,
      icon: (
        <FontAwesomeIcon
          name={isLiked ? 'heart' : 'heart-outline'}
          className={isLiked ? 'is-liked' : ''}
        />
      ),
      onClick: (mouseEvent: MouseEvent) => {
        mouseEvent.stopPropagation();
        const updatedValue = !isLiked;
        setUpdatedRsvp((prevValue) => ({ ...prevValue, isLiked: updatedValue }));
        rsvpToEventAsync({ eventId: event._id, isLiked: updatedValue });
      },
    },
    {
      isVisible: !!onCommentClick,
      icon: <FontAwesomeIcon name="comment-outline" />,
      onClick: (mouseEvent: MouseEvent) => {
        mouseEvent.stopPropagation();
        onCommentClick();
      },
    },
    {
      isVisible: showCleanup,
      icon: <LineAwesomeIcon name="edit" />,
      onClick: handleNavigateToCleanup,
    },
  ];

  return (
    <div className="event-actions border-0 pt-0">
      <div className="d-flex gap-2">
        {actionButtons
          .filter(({ isVisible }) => isVisible)
          .map(({ icon, onClick }, i) => (
            <IconButton key={`action-${i}`} color="neutral" variant="smooth" onClick={onClick}>
              {icon}
            </IconButton>
          ))}
      </div>
      <EventCleanupPopup
        isVisible={isEventCleanupPopupVisible}
        onClose={() => setEventCleanupPopupVisible(false)}
        eventId={event._id}
      />
    </div>
  );
};

interface EventCardProps {
  className?: string;
  event: FeedEvent;
  onClick: () => void;
  showPromoter?: boolean;
  showFooter?: boolean;
  hideActions?: boolean;
  hideRsvp?: boolean;
  onCommentClick?: () => void;
}

const EventCard: FC<EventCardProps> = ({
  className,
  event,
  onClick,
  showFooter,
  showPromoter = true,
  hideActions = false,
  hideRsvp,
  onCommentClick,
}) => {
  return (
    <Card key={event._id} className={`event-card ${className}`} onClick={onClick}>
      {showPromoter && <PromoterHeader event={event} />}
      <EventHeader event={event} hideRsvp={hideRsvp} />
      <EventImage event={event} />
      {!hideActions && showFooter && (
        <EventActions event={event} onCommentClick={onCommentClick} showCleanup={showFooter} />
      )}
    </Card>
  );
};

export default EventCard;
