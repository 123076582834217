import { useState } from 'react';

import { CurrentPromoter } from '@queries/PromoterQueries';

import { useCreatePromoterMutation, useUpdatePromoterMutation } from '@mutations/PromoterMutations';

import { CurrentCity } from '@hooks/useCurrentUserLocation';

import Button from '@components/buttons/Button';
import { FormLabel } from '@components/form/FormLabel';
import { Input } from '@components/form/Input';
import { Textarea } from '@components/form/Textarea';

import { CitiesField } from '@screens/entertainer/components/CitiesField';

import './PromoterForm.scss';

export const PromoterForm = ({
  currentCity,
  cities,
  promoter,
  onSave,
}: {
  currentCity: CurrentCity;
  cities: CurrentCity[];
  promoter?: CurrentPromoter;
  onSave?: () => void;
}) => {
  const [title, setTitle] = useState(promoter?.title ?? '');
  const [description, setDescription] = useState(promoter?.description ?? '');
  const [cityIds, setCityIds] = useState<string[]>(promoter ? promoter.cityIds : [currentCity._id]);
  const { isSubmitting, createPromoterAsync } = useCreatePromoterMutation();
  const { isSubmitting: isUpdatingProfile, updatePromoterAsync } = useUpdatePromoterMutation();

  const handleCreatePromoter = async () => {
    if (promoter) {
      await updatePromoterAsync({
        title,
        description,
        cityIds,
      });
    } else {
      await createPromoterAsync({
        title,
        description,
        cityIds,
      });
    }

    onSave?.();
  };

  return (
    <div className="promoter-form grid gap-2">
      <div />
      <div>
        <FormLabel text="Organization Name *" />
        <Input
          placeholder="eg. Glitter Disco, Beyond Binary, etc."
          defaultValue={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div>
        <FormLabel text="Description *" />
        <Textarea
          placeholder="Tell everyone about what you do"
          defaultValue={description}
          onChange={setDescription}
        />
      </div>

      <CitiesField cities={cities} cityIds={cityIds} onChange={setCityIds} />

      <div className="mt-2">
        <Button
          roundness="rounded"
          disabled={!title || !description || !cityIds.length || isSubmitting || isUpdatingProfile}
          onClick={handleCreatePromoter}
        >
          Save Profile
        </Button>
      </div>
    </div>
  );
};
