import { FC } from 'react';
import { Spinner } from 'react-bootstrap';

import { useNearbyVenuesQuery } from '@queries/index';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { VenueCard } from '@components/cards/VenueCard';
import { Screen } from '@components/layout/Screen/Screen';

const VENUE_REQUEST_ENABLED = true;

export const VenuesScreen: FC<{}> = () => {
  const { venuesAreReady, venues } = useNearbyVenuesQuery();
  const navigate = useNavigate();

  const onClickRequest = () => {
    navigate(`/venues/request`);
  };

  return (
    <Screen
      name="Venues Screen"
      headerProps={{
        back: { to: '/discover' },
        title: 'Nearby Bars',
        right: VENUE_REQUEST_ENABLED && (
          <Button disabled={false} variant="flat" onClick={onClickRequest}>
            Request
          </Button>
        ),
      }}
    >
      <Screen.Content>
        {venuesAreReady ? (
          <div>
            {venues.map((venue) => (
              <VenueCard
                key={venue.title}
                venue={venue}
                onClick={() => navigate(`/venues/${venue._id}`)}
              />
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        )}
      </Screen.Content>
    </Screen>
  );
};
