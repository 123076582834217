import { useMutation } from '@tanstack/react-query';
import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import * as dayjs from 'dayjs';
import { capitalize } from 'lodash';

import API from '@api/API';

import {
  BookingOpportunityEntertainer,
  CurrentPromoter,
  PublicBookingOpportunity,
  useCurrentPromoterQuery,
  usePromoterManageQuery,
} from '@queries/PromoterQueries';
import { useCurrentUser } from '@queries/UserQueries';

import {
  useAddPromoterBookingOpportunityUpdateMutation,
  useConfirmPromoterBookingOpportunityEntertainerMutation,
  useUpdatePromoterBookingOpportunityEntertainerMutation,
} from '@mutations/PromoterMutations';

import { useCurrentUserLocation } from '@hooks/useCurrentUserLocation';

import { useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import EventCard, { PromoterHeader } from '@components/cards/EventCard';
import { Form } from '@components/form/Form';
import { Input } from '@components/form/Input';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

export const BookingOpportunityUpdatesSection = ({
  canAddUpdate,
  promoter,
  bookingOpportunity,
}: {
  canAddUpdate: boolean;
  promoter: Pick<CurrentPromoter, '_id' | 'title'>;
  bookingOpportunity: Pick<PublicBookingOpportunity, '_id' | 'updates'>;
}) => {
  const [showTextarea, setShowTextarea] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const { isSubmitting, addPromoterBookingOpportunityUpdateAsync } =
    useAddPromoterBookingOpportunityUpdateMutation(promoter._id);

  const handleSubmit = async () => {
    await addPromoterBookingOpportunityUpdateAsync({
      bookingOpportunityId: bookingOpportunity._id,
      message: newMessage,
    });
  };

  const hasUpdates = !!bookingOpportunity.updates.length;

  return (
    <div>
      <TitleToolbar
        text="Updates 🚨"
        size="md"
        action={
          hasUpdates &&
          canAddUpdate && (
            <Button variant="flat" onClick={() => setShowTextarea((prevValue) => !prevValue)}>
              {!showTextarea ? 'Add Update' : 'Hide'}
            </Button>
          )
        }
      />

      {(!hasUpdates || showTextarea) && canAddUpdate && (
        <Card>
          <Textarea
            onChange={setNewMessage}
            submitButton={{ text: 'Share Update', onClick: handleSubmit, disabled: isSubmitting }}
          />
        </Card>
      )}

      {!canAddUpdate && !hasUpdates ? (
        <EmptyState
          title="No updates"
          text="There are currently no updates for this booking opportunity."
        />
      ) : (
        <>
          {bookingOpportunity.updates.map((update) => (
            <Card key={update.message}>
              <Card.Header
                title={promoter.title}
                subtitle={`Posted ${dayjs(update.createdAt).fromNow()}`}
              />
              <Card.Body>{mapStringToParagraphs(update.message)}</Card.Body>
            </Card>
          ))}
        </>
      )}
    </div>
  );
};

const EntertainerOption: FC<{
  isStatusUpdateDisabled?: boolean;
  promoterId: string;
  bookingOpportunityId: string;
  bookingOpportunityEntertainer: BookingOpportunityEntertainer;
}> = ({
  isStatusUpdateDisabled,
  promoterId,
  bookingOpportunityId,
  bookingOpportunityEntertainer,
}) => {
  const navigate = useNavigate();
  const { entertainer } = bookingOpportunityEntertainer;
  const [status, setStatus] = useState(bookingOpportunityEntertainer.status);
  const { isSubmitting, updatePromoterBookingOpportunityEntertainerAsync } =
    useUpdatePromoterBookingOpportunityEntertainerMutation(promoterId);

  const CONTACT_METHODS = {
    email: {
      startIcon: <FontAwesomeIcon name="envelope" />,
      label: 'Email',
      onClick: () => openExternalUrl(`mailto:${entertainer.contact?.email}`),
    },
    messenger: {
      startIcon: <FontAwesomeIcon faStyle="fab" name="facebook-messenger" />,
      label: 'Messenger',
      onClick: () => openExternalUrl(`https://m.me/${entertainer.contact?.facebook}`),
    },
    instagram: {
      startIcon: <FontAwesomeIcon faStyle="fab" name="instagram" />,
      label: 'Instagram',
      onClick: () => openExternalUrl(`https://instagram.com/${entertainer.contact?.instagram}`), // openExternalUrl(`instagram://user?username=`),
    },
  };

  const handleChangeStatus = async (status: 'confirmed' | 'rejected') => {
    await updatePromoterBookingOpportunityEntertainerAsync({
      bookingOpportunityId,
      entertainerId: bookingOpportunityEntertainer.entertainer._id,
      status,
    });
  };

  const statusButton = (
    <Button
      disabled={isStatusUpdateDisabled}
      className="py-2 px-3"
      roundness="rounded"
      variant="outlined"
      color="neutral"
      size="sm"
      endIcon={<FontAwesomeIcon name="chevron-down" />}
    >
      {status === 'notInterested' ? 'Not Interested' : capitalize(status)}
    </Button>
  );

  const handleNavigateToEntertainer = () => {
    if (entertainer.bookingHandle) {
      navigate(`/noauth/entertainer/${entertainer.bookingHandle}`, {
        state: {
          back: `/manage/promoter/open-bookings/${bookingOpportunityId}`,
        },
      });
    }
  };

  return (
    <Card>
      <Card.Header
        title={entertainer.title ?? 'Unknown Entertainer'}
        onTitleClick={handleNavigateToEntertainer}
        action={
          isStatusUpdateDisabled ? (
            statusButton
          ) : (
            <PopoverWrapper
              PopoverContent={({ onClose }) => (
                <div className="p-2 d-flex gap-2">
                  <Button
                    disabled={isSubmitting}
                    roundness="rounded"
                    onClick={() => {
                      setStatus('confirmed');
                      handleChangeStatus('confirmed');
                      onClose();
                    }}
                  >
                    Confirm
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    roundness="rounded"
                    color="neutral"
                    variant="outlined"
                    onClick={() => {
                      setStatus('rejected');
                      handleChangeStatus('rejected');
                      onClose();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            >
              {statusButton}
            </PopoverWrapper>
          )
        }
      />
      {entertainer.avatar && (
        <div onClick={handleNavigateToEntertainer}>
          <AsyncImage
            src={entertainer.avatar}
            alt={entertainer.title}
            style={{ aspectRatio: 1, objectFit: 'cover' }}
          />
        </div>
      )}
      {entertainer.tagline && <Card.Body>{mapStringToParagraphs(entertainer.tagline)}</Card.Body>}
      <Card.Footer className="gap-2">
        <Button
          startIcon={<FontAwesomeIcon name="comment" />}
          roundness="rounded"
          variant="smooth"
          color="neutral"
          className="px-2"
          onClick={() => navigate(`/chat/promoter/${promoterId}/entertainer/${entertainer._id}`)}
        >
          Message
        </Button>
        {Object.keys(entertainer.contact ?? {})?.length ? (
          Object.entries(CONTACT_METHODS)
            .filter(([key]) => entertainer?.contact?.[key])
            .map(([key, { startIcon, label, onClick }]) => (
              <Button
                key={key}
                startIcon={startIcon}
                roundness="rounded"
                variant="smooth"
                color="neutral"
                className="px-2"
                onClick={onClick}
              >
                {label}
              </Button>
            ))
        ) : (
          <Button className="opacity-75" roundness="rounded" variant="outlined" color="neutral">
            No Contact Info
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};

export const ManagePromoterBookingOpportunityScreen: FC<{ back?: string }> = ({ back }) => {
  const { bookingOpportunityId } = useParams<{ bookingOpportunityId?: string }>();
  const { promoterIsReady, promoter } = useCurrentPromoterQuery();
  const { promoterDetails, promoterDetailsAreReady } = usePromoterManageQuery(promoter?._id);
  const navigate = useNavigate();

  const bookingOpportunity = useMemo(() => {
    return promoterDetails?.bookingOpportunities.find(({ _id }) => _id === bookingOpportunityId);
  }, [promoterDetails?.bookingOpportunities, bookingOpportunityId]);

  const { confirmPromoterBookingOpportunityEntertainersAsync } =
    useConfirmPromoterBookingOpportunityEntertainerMutation(promoter._id);

  const handleConfirmEntertainers = async () => {
    await confirmPromoterBookingOpportunityEntertainersAsync({ bookingOpportunityId });
  };

  const handleCreateEvent = async () => {
    navigate(`/manage/promoter/booking-opportunities/${bookingOpportunityId}/event`);
  };

  const filteredEntertainers = useMemo(() => {
    if (bookingOpportunity?.status === 'open') {
      return bookingOpportunity?.entertainers ?? [];
    }

    return bookingOpportunity?.entertainers?.filter(({ status }) => status === 'confirmed') ?? [];
  }, [bookingOpportunity?.status, bookingOpportunity?.entertainers]);

  return (
    <Screen
      name="Manage Promoter Booking Opportunity Screen"
      headerProps={{ back: { to: '/manage/promoter' }, title: 'Manage Opportunity' }}
      bottomToolbar={
        <Screen.BottomToolbar maxWidth={425}>
          {bookingOpportunity?.status === 'open' ? (
            <Button roundness="rounded" onClick={handleConfirmEntertainers}>
              Confirm Entertainers
            </Button>
          ) : (
            <Button
              roundness="rounded"
              disabled={!!bookingOpportunity?.eventId}
              onClick={handleCreateEvent}
            >
              {bookingOpportunity?.eventId ? 'Event Created' : 'Create Event'}
            </Button>
          )}
        </Screen.BottomToolbar>
      }
    >
      <Screen.Content maxWidth={425}>
        {promoterDetailsAreReady ? (
          <>
            {promoter.permissions.status === 'approved' && bookingOpportunity && (
              <div className="grid">
                <div>
                  <TitleToolbar className="title-toolbar-welcome" text={bookingOpportunity.title} />
                  <div className="d-flex gap-2">
                    <div>
                      <span className="me-1">
                        <LineAwesomeIcon name="clock" />
                      </span>
                      {dayjs(bookingOpportunity.startDate).format('ddd, MMM D, YYYY')}
                    </div>
                    <div>
                      <span className="me-1">
                        <LineAwesomeIcon name="map-marker" />
                      </span>
                      {`${bookingOpportunity.venue.title}, ${bookingOpportunity.city.name}`}
                    </div>
                  </div>

                  {mapStringToParagraphs(bookingOpportunity.details)}
                </div>
                <BookingOpportunityUpdatesSection
                  canAddUpdate
                  promoter={promoter}
                  bookingOpportunity={bookingOpportunity}
                />

                <TitleToolbar text="Entertainers 🪭" size="md" />
                {filteredEntertainers.length ? (
                  <>
                    {filteredEntertainers.map((bookingOpportunityEntertainer) => (
                      <EntertainerOption
                        key={bookingOpportunityEntertainer.entertainer._id}
                        promoterId={promoter._id}
                        isStatusUpdateDisabled={bookingOpportunity.status !== 'open'}
                        bookingOpportunityId={bookingOpportunity._id}
                        bookingOpportunityEntertainer={bookingOpportunityEntertainer}
                      />
                    ))}
                  </>
                ) : (
                  <EmptyState
                    title="No interested entertainers"
                    text="There were currently no interested entertainers for this open booking."
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <Skeleton height={200} />
        )}
      </Screen.Content>
    </Screen>
  );
};
