import { CSSProperties, FC, ReactElement } from 'react';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';

import Card from '../Card/Card';
import './LinkBanner.scss';

interface LinkBannerProps {
  id?: string;
  showHighlight?: boolean;
  variant?: 'default' | 'error';
  icon: ReactElement;
  title: string;
  description: string;
  onClick: () => void;
  style?: CSSProperties;
}

export const LinkBanner: FC<LinkBannerProps> = ({
  id,
  showHighlight,
  variant = 'default',
  icon,
  title,
  description,
  onClick,
  style,
}) => {
  return (
    <Card
      id={id}
      className={`link-banner ${showHighlight ? 'show-highlight' : ''} link-banner-variant-${variant}`}
      onClick={onClick}
      style={style}
    >
      <Card.Body className="ps-0">
        <div className="d-flex align-items-center">
          <div className="w-25 fs-1 text-center">{icon}</div>
          <div className="w-75">
            <h4 className="m-0">{title}</h4>
            <p className="m-0">{description}</p>
          </div>
          <div className="ps-2">
            <FontAwesomeIcon name="chevron-right" />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
