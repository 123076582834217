import { useRef, useState } from 'react';

import { useFollowsQuery, useInterestsQuery, useUserInterestsQuery } from '@queries/UserQueries';

import { useFollowMutation, useUnfollowMutation } from '@mutations/FollowMutations';

import IconButton from '@components/buttons/IconButton';
import { Chip } from '@components/chips/Chip';
import { Input } from '@components/form/Input';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { List } from '@components/layout/List/List';
import { Screen } from '@components/layout/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import './InterestsScreen.scss';

const INTEREST_INFO_ENABLED = false;

export const InterestsScreen = ({ back = '/home' }: { back?: string }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const { interests = [] } = useInterestsQuery({ searchValue });
  const { userInterests } = useUserInterestsQuery();
  const { follows } = useFollowsQuery();
  const { followAsync } = useFollowMutation();
  const { unfollowAsync } = useUnfollowMutation();

  const handleViewDetails = () => {};

  const handleCheckboxChange = async (tagId: string, updatedCheckboxValue: boolean) => {
    if (updatedCheckboxValue) {
      await followAsync({ documentType: 'tag', documentId: tagId });
    } else {
      await unfollowAsync({ documentType: 'tag', documentId: tagId });
    }
  };

  return (
    <Screen
      name="Interests Screen"
      className="interests-screen"
      headerProps={{
        back: {
          to: back,
        },
      }}
    >
      <Screen.Content>
        <TitleToolbar
          text="Your Interests"
          size="md"
          action={
            INTEREST_INFO_ENABLED ? (
              <IconButton color="neutral" variant="smooth" onClick={handleViewDetails}>
                <LineAwesomeIcon name="info" />
              </IconButton>
            ) : undefined
          }
        />
        {userInterests?.length ? (
          <div>
            {userInterests.map((interest) => (
              <Chip
                key={interest._id}
                label={interest.title}
                className="me-1"
                variant="outlined"
                onRemove={() => {
                  unfollowAsync({ documentType: 'tag', documentId: interest._id });
                }}
              />
            ))}
          </div>
        ) : (
          <EmptyState
            title="You haven't selected any interests yet."
            text="Add your interests to get more personalized recommendations."
            button={{
              text: 'Search Interests',
              onClick: () => {
                inputRef.current?.focus();
              },
            }}
          />
        )}
        <Input
          ref={inputRef}
          className="search-field"
          placeholder="Search Interests"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {interests.length ? (
          <List
            ignoreScreenContentPadding
            className="interests-list"
            listItems={interests.map((interest) => ({
              hasCheckbox: true,
              defaultChecked: follows?.tags?.[interest._id],
              title: interest.title,
              onCheckboxChange: (updatedCheckboxValue) =>
                handleCheckboxChange(interest._id, updatedCheckboxValue),
            }))}
          />
        ) : (
          <></>
        )}
      </Screen.Content>
    </Screen>
  );
};
