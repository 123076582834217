import { List, ListItem, Toggle } from 'framework7-react';
import { FC, useMemo, useState } from 'react';

import * as dayjs from 'dayjs';

import { useTopEntertainersQuery } from '@queries/EntertainerQueries';
import { FeedEvent } from '@queries/EventQueries';
import { useSearchQuery } from '@queries/UserQueries';

import { formatMonthlyDay } from '@utilities/formatMonthlyDay';

import Button from '@components/buttons/Button';
import EventCard, { EventHeader, EventImage, PromoterHeader } from '@components/cards/EventCard';
import { DatePicker } from '@components/form/DatePicker';
import { FormLabel } from '@components/form/FormLabel';
import { ImageUploadField } from '@components/form/ImageUploadField';
import { Input } from '@components/form/Input';
import { Select } from '@components/form/Select';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';

import { MultiSelectField } from '@screens/entertainer/components/CitiesField';

interface SubmitEventProperties {
  eventImage: any;
  title: string;
  description: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  cityId: string;
  isRecurring: boolean;
  entertainerIds?: string[];
  recurringSchedule?: {
    type: 'weekly' | 'bi-weekly' | 'monthlyNthDay';
  };
  venue: {
    _id?: string;
    title: string;
  };
}

interface DefaultValues {
  img: string;
  title: string;
  description: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  cityId: string;
  venue: {
    _id?: string;
    title: string;
  };
  entertainers: { _id: string }[];
}

interface CreateUpdateEventFormProps {
  cityOptions: { label: string; value: string }[];
  defaultValues?: Partial<DefaultValues>;
  event?: DefaultValues;
  onSubmit: (payload: SubmitEventProperties) => Promise<void>;
}

export const CreateUpdateEventForm: FC<CreateUpdateEventFormProps> = ({
  event,
  defaultValues = {},
  cityOptions,
  onSubmit,
}) => {
  const isEdit = Boolean(event);
  const cityId = event?.cityId ?? cityOptions?.[0]?.value;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updatedEventProperties, setUpdatedEventProperties] = useState<SubmitEventProperties>({
    ...event,
    cityId,
    eventImage: undefined,
    isRecurring: false,
    ...defaultValues,
  });

  const { topEntertainersAreReady, topEntertainers = [] } = useTopEntertainersQuery(
    cityId,
    'dragPerformer',
    true,
  );

  const startDateTime = useMemo(() => {
    const startDate: string | undefined = event?.startDate ?? updatedEventProperties?.startDate;
    const startTime: string | undefined = event?.startTime ?? updatedEventProperties?.startTime;

    return `${startDate?.toString()} ${startTime?.toString()}`.trim();
  }, [event, updatedEventProperties]);

  const endDateTime = useMemo(() => {
    const endDate: string | undefined = event?.endDate ?? updatedEventProperties?.endDate;
    const endTime: string | undefined = event?.endTime ?? updatedEventProperties?.endTime;

    return `${endDate?.toString()} ${endTime?.toString()}`.trim();
  }, [event, updatedEventProperties]);

  const handleChangeImage = (updatedEventImage: string | ArrayBuffer) => {
    setUpdatedEventProperties((prevValue) => ({
      ...prevValue,
      eventImage: updatedEventImage,
    }));
  };

  const hasStart = Boolean(updatedEventProperties?.startDate && updatedEventProperties?.startTime);

  const canSubmit = Boolean(
    !isSubmitting &&
      updatedEventProperties?.title &&
      updatedEventProperties?.description &&
      hasStart &&
      updatedEventProperties?.cityId &&
      updatedEventProperties?.venue?.title,
  );

  const repeatingScheduleOptions = useMemo(() => {
    const { startDate, isRecurring = false } = updatedEventProperties;

    if (!startDate || !isRecurring) {
      return [];
    }

    const startDateDay = dayjs(startDate).format('dddd');

    return [
      { label: `Weekly, every ${startDateDay}`, value: 'weekly' },
      { label: `Bi-weekly, every other ${startDateDay}`, value: 'bi-weekly' },
      { label: `Monthly, every ${formatMonthlyDay(startDate)}`, value: 'monthlyNthDay' },
    ];
  }, [updatedEventProperties?.isRecurring, updatedEventProperties?.startDate]);

  const handleSubmit = async () => {
    if (canSubmit) {
      setIsSubmitting(true);
      await onSubmit(updatedEventProperties);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Card>
        <ImageUploadField
          placeholder="Add an event image..."
          defaultValue={event?.img}
          onChange={handleChangeImage}
        />
      </Card>
      <div className="grid gap-2 mt-2">
        <div>
          <FormLabel text="Title *" />
          <Input
            name="title"
            defaultValue={updatedEventProperties?.title}
            onChange={(e) =>
              setUpdatedEventProperties((prevValue) => ({
                ...prevValue,
                title: e.target.value,
              }))
            }
          />
        </div>

        <div>
          <FormLabel text="Description *" />
          <Textarea
            name="description"
            defaultValue={updatedEventProperties?.description}
            onChange={(updatedDescription) =>
              setUpdatedEventProperties((prevValue) => ({
                ...prevValue,
                description: updatedDescription,
              }))
            }
          />
        </div>

        <div>
          <FormLabel text="Start *" />
          <DatePicker
            showTimeSelect
            name="startDateTime"
            value={startDateTime}
            onChange={(updatedDate) =>
              setUpdatedEventProperties((prevValue) => ({
                ...prevValue,
                startDate: dayjs(updatedDate).format('YYYY-MM-DD'),
                startTime: dayjs(updatedDate).format('HH:mm'),
              }))
            }
          />
        </div>

        {hasStart && (
          <>
            <div style={{ margin: '0 -.5rem' }}>
              <List className="m-0">
                <ListItem title="Repeating Event">
                  <div slot="media">
                    <div style={{ width: 18 }}>
                      <FontAwesomeIcon name="redo" />
                    </div>
                  </div>
                  <Toggle
                    slot="after"
                    onToggleChange={(isRecurring: boolean) =>
                      setUpdatedEventProperties((prevValue) => ({
                        ...prevValue,
                        isRecurring,
                        recurringSchedule: prevValue?.recurringSchedule ?? {
                          type: 'weekly',
                          days: [],
                        },
                      }))
                    }
                  />
                </ListItem>
              </List>
            </div>
            {updatedEventProperties.isRecurring && (
              <Select
                name="recurringScheduleType"
                options={repeatingScheduleOptions}
                value={updatedEventProperties?.recurringSchedule?.type}
                onChange={(e) =>
                  setUpdatedEventProperties((prevValue) => ({
                    ...prevValue,
                    recurringSchedule: {
                      type: e.target.value as 'weekly' | 'bi-weekly' | 'monthlyNthDay',
                    },
                  }))
                }
              />
            )}
          </>
        )}

        <div>
          <FormLabel text="End *" />
          <DatePicker
            name="endDateTime"
            showTimeSelect
            value={endDateTime}
            minDate={startDateTime}
            onChange={(updatedDate) =>
              setUpdatedEventProperties((prevValue) => ({
                ...prevValue,
                endDate: dayjs(updatedDate).format('YYYY-MM-DD'),
                endTime: dayjs(updatedDate).format('HH:mm'),
              }))
            }
          />
        </div>

        <div>
          <FormLabel text="City *" />
          <Select
            name="cityId"
            options={cityOptions}
            value={updatedEventProperties.cityId}
            onChange={(e) =>
              setUpdatedEventProperties((prevValue) => ({
                ...prevValue,
                cityId: e.target.value,
              }))
            }
          />
        </div>

        <div>
          <FormLabel text="Venue *" />
          <Input
            name="venueTitle"
            defaultValue={updatedEventProperties?.venue?.title}
            onChange={(e) =>
              setUpdatedEventProperties((prevValue) => ({
                ...prevValue,
                venue: {
                  title: e.target.value,
                },
              }))
            }
          />
        </div>

        {topEntertainersAreReady ? (
          <MultiSelectField
            label="Drag Performers"
            name="entertainerIds"
            allowSearch
            defaultValue={
              (event?.entertainers ?? defaultValues?.entertainers)?.map(({ _id }) => _id) ?? []
            }
            options={topEntertainers
              .map(({ _id, title }) => ({
                value: _id,
                label: title,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))}
            onChange={(updatedEntertainerIds) => {
              setUpdatedEventProperties((prevValue) => ({
                ...prevValue,
                entertainerIds: updatedEntertainerIds,
              }));
            }}
          />
        ) : (
          <Skeleton height={64} />
        )}

        {/* <div>
          <FormLabel text="Drag Performers" />
          Request from local queens
          Multiselect
          <Input
            defaultValue={event?.venue?.title}
            onChange={(e) =>
              setUpdatedEventProperties((prevValue) => ({
                ...prevValue,
                venue: {
                  title: e.target.value,
                },
              }))
            }
          />
        </div> */}
      </div>
      <div className="mt-4">
        <Button roundness="rounded" disabled={!canSubmit} onClick={handleSubmit}>
          {isEdit ? 'Update Event' : 'Create Event'}
        </Button>
      </div>
    </>
  );
};

interface CreateUpdateEventContentProps {
  cityOptions: { label: string; value: string }[];
  defaultValues?: Partial<DefaultValues>;
  event?: DefaultValues;
  onSubmit: (payload: SubmitEventProperties) => Promise<void>;
  onSelectExistingEvent?: { text: string; action: (existingEventId: string) => void };
}

export const CreateUpdateEventContent: FC<CreateUpdateEventContentProps> = ({
  cityOptions,
  defaultValues,
  event,
  onSubmit,
  onSelectExistingEvent,
}) => {
  const [eventDate, setEventDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [eventCityId, setEventCityId] = useState<string>(cityOptions?.[0]?.value);
  const [shouldShowSearchResults, setShouldShowSearchResults] = useState(false);
  const [shouldCreateNewEvent, setShouldCreateNewEvent] = useState(false);
  const { searchResultsAreReady, searchResults } = useSearchQuery({
    startDate: eventDate,
    endDate: eventDate,
  });

  const { openConfirmDialog } = useDialog();

  if (event || !onSelectExistingEvent) {
    return (
      <CreateUpdateEventForm
        cityOptions={cityOptions}
        defaultValues={defaultValues}
        event={event}
        onSubmit={onSubmit}
      />
    );
  }

  if (shouldCreateNewEvent) {
    return (
      <CreateUpdateEventForm
        cityOptions={cityOptions}
        defaultValues={{ startDate: eventDate, ...defaultValues }}
        onSubmit={onSubmit}
      />
    );
  }

  const handleSelectExistingEvent = (event: FeedEvent) => {
    openConfirmDialog({
      title: 'Select Event',
      text: `Are you sure you want to add yourself to ${event.title}?`,
      onConfirm: () => onSelectExistingEvent.action(event._id),
    });
  };

  if (shouldShowSearchResults) {
    return (
      <>
        {searchResultsAreReady ? (
          <>
            {searchResults.events.length ? (
              <>
                {searchResults.events.map((event) => (
                  <Card
                    key={event._id}
                    className="event-card"
                    onClick={() => handleSelectExistingEvent(event)}
                  >
                    <PromoterHeader event={event} />
                    <EventHeader event={event} />
                    <EventImage event={event} />
                    <Card.Footer>
                      <Button roundness="rounded">{onSelectExistingEvent.text}</Button>
                    </Card.Footer>
                  </Card>
                ))}
              </>
            ) : (
              <EmptyState
                title="No existing events"
                text="No one has shared any events for this day. Go ahead and create your own!"
              />
            )}
          </>
        ) : (
          <div className="d-grid gap-2">
            <Skeleton height={120} />
            <Skeleton height={120} />
            <Skeleton height={120} />
            <Skeleton height={120} />
          </div>
        )}
        <div className="mt-4">
          <Button roundness="rounded" onClick={() => setShouldCreateNewEvent(true)}>
            Create New Event
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="d-grid gap-2">
        <div>
          <FormLabel text="Start *" />
          <DatePicker
            name="eventDate"
            value={eventDate}
            onChange={(updatedDate) => setEventDate(dayjs(updatedDate).format('YYYY-MM-DD'))}
          />
        </div>
        <div>
          <FormLabel text="City *" />
          <Select
            name="cityId"
            options={cityOptions}
            value={eventCityId}
            onChange={(e) => setEventCityId(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4">
        <Button roundness="rounded" onClick={() => setShouldShowSearchResults(true)}>
          Search Existing Events
        </Button>
      </div>
    </>
  );
};
