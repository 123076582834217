import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { Screen } from '@components/layout/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

export const NoAuthDownloadScreen = () => {
  return (
    <Screen name="No Auth Download Screen" hideToolbar>
      <Screen.Content>
        <TitleToolbar text="Thanks for submitting your response" />
        <p>Gaggl is a local LGBTQ events app designed to keep you in the know.</p>
        <Button
          onClick={() =>
            openExternalUrl('https://apps.apple.com/us/app/gaggl-local-lgbtq-events/id1593891795')
          }
        >
          Download on iPhone
        </Button>
      </Screen.Content>
    </Screen>
  );
};
