import { FC, useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import { useSwipeable } from 'react-swipeable';

import { motion } from 'framer-motion';

import { UserGagglRankResponseBody, useCurrentUserGagglRankQuery } from '@queries/CritiqueQueries';
import { CurrentUser, useCurrentUser } from '@queries/UserQueries';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { RenderTable } from '@components/table/RenderTable';

import './UserScoreNotification.scss';

const UnwrappedUserScoreNotification: FC<{
  currentUserGagglRank: UserGagglRankResponseBody;
  currentUser: CurrentUser;
}> = ({ currentUserGagglRank, currentUser }) => {
  const [scoreNotificationIsVisible, setScoreNotificationIsVisible] = useState(false);
  const [prevStanding, setPrevStanding] = useState(currentUserGagglRank);

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => {
      setScoreNotificationIsVisible(false);
    },
  });

  useEffect(() => {
    if (currentUserGagglRank && currentUserGagglRank?.score > prevStanding.score) {
      setScoreNotificationIsVisible(true);
      setPrevStanding(currentUserGagglRank);
      setTimeout(() => {
        setScoreNotificationIsVisible(false);
      }, 4000);
    }
  }, [currentUserGagglRank]);

  return (
    <motion.div
      id="user-score-notifcation"
      initial={{ top: -200 }}
      animate={scoreNotificationIsVisible ? { top: 0 } : undefined}
      exit={{ top: -200 }}
    >
      <div {...swipeHandlers}>
        <Card>
          <Card.Body>
            <div className="d-flex gap-3">
              <div className="user-score-notifcation_icon">
                <motion.div
                  initial={{ scale: 1 }}
                  animate={{ scale: 1.2 }}
                  transition={{ repeat: Infinity, repeatType: 'reverse', duration: 0.5 }}
                >
                  <FontAwesomeIcon name="award" />
                </motion.div>
              </div>
              <div className="user-score-notifcation_content">
                <h3>
                  <strong>Your ranking improved!</strong>
                </h3>
                <p>Thank you for taking the time to help the Gaggl community!</p>
              </div>
            </div>
            <div className="user-score-notifcation_score">
              <RenderTable
                headCells={[
                  {
                    id: 'rank',
                    label: '',
                    className: 'text-start',
                    TableBodyCell: () => (
                      <td className="text-start">
                        <CountUp
                          start={prevStanding.rank}
                          end={currentUserGagglRank.rank}
                          delay={1}
                          duration={1}
                        >
                          {({ countUpRef }) => (
                            <>
                              {'#'}
                              <span ref={countUpRef} />
                            </>
                          )}
                        </CountUp>
                      </td>
                    ),
                  },
                  {
                    id: 'displayName',
                    label: '',
                    className: 'text-start',
                    TableBodyCell: () => <td>{currentUser.displayName}</td>,
                  },
                  {
                    id: 'score',
                    label: '',
                    TableBodyCell: () => (
                      <td className="text-center">
                        <CountUp
                          start={prevStanding.score}
                          end={currentUserGagglRank.score}
                          delay={1}
                          duration={1}
                        >
                          {({ countUpRef }) => <span ref={countUpRef} />}
                        </CountUp>
                      </td>
                    ),
                  },
                ]}
                rows={[{ key: currentUserGagglRank?.score?.toString() }]}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    </motion.div>
  );
};

export const UserScoreNotification = () => {
  const { currentUserGagglRank } = useCurrentUserGagglRankQuery();
  const { currentUser } = useCurrentUser();

  if (!currentUserGagglRank || !currentUser) {
    return <></>;
  }

  return (
    <UnwrappedUserScoreNotification
      currentUserGagglRank={currentUserGagglRank}
      currentUser={currentUser}
    />
  );
};
