import { FC, useState } from 'react';

import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { EntertainerBookingRequest } from '@queries/EntertainerQueries';

import { useRespondToBookingRequestMutation } from '@mutations/EntertainerMutations';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { OptionButton } from '@components/buttons/OptionButton';
import Card from '@components/layout/Card/Card';

interface BookingRequestCardProps {
  bookingRequest: EntertainerBookingRequest;
}

export const BookingRequestCard: FC<BookingRequestCardProps> = ({ bookingRequest }) => {
  const [status, setStatus] = useState(bookingRequest.status);
  const { respondToBookingRequestAsync } = useRespondToBookingRequestMutation();

  const navigate = useNavigate();

  return (
    <Card>
      <Card.Header
        title={bookingRequest?.contact?.name}
        subtitle={`Received ${dayjs(bookingRequest.createdAt).format('MMM D, YYYY')}`}
        action={
          <OptionButton
            label={capitalize(status)}
            accept={{
              label: 'Interested',
              onClick: async () => {
                setStatus('interested');
                await respondToBookingRequestAsync({
                  bookingRequestId: bookingRequest._id,
                  status: 'interested',
                });
              },
            }}
            deny={{
              label: 'Not Interested',
              onClick: async () => {
                setStatus('notInterested');
                await respondToBookingRequestAsync({
                  bookingRequestId: bookingRequest._id,
                  status: 'notInterested',
                });
              },
            }}
          />
        }
      />
      <Card.Footer>
        <Button
          onClick={() => {
            navigate(`/manage/entertainer/booking-request/${bookingRequest._id}`);
          }}
        >
          Details
        </Button>
      </Card.Footer>
    </Card>
  );
};
