import { FC, useEffect, useState } from 'react';

import API from '@api/API';

import { CurrentPromoter, Promoter, useFetchCurrentPromoter } from '@queries/PromoterQueries';

import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { FormLabel } from '@components/form/FormLabel';
import { Input } from '@components/form/Input';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { CreateEditPromoterEventScreen } from '@screens/CreateEditEventScreen/CreateEditPromoterEventScreen';

import { PromoterForm } from '../ManagePromoterProfileScreen/PromoterForm';

export const OnboardPromoterScreen: FC<{}> = () => {
  const [welcomeMessageIsVisible, setWelcomeMessageIsVisible] = useState(true);
  const [countdown, setCountdown] = useState(10);
  const [instagramUsername, setInstagramUsername] = useState('');
  const [promoter, setPromoter] = useState<CurrentPromoter>();
  const fetchCurrentPromoter = useFetchCurrentPromoter();
  const { currentCity, cities } = useCurrentUserCity();
  const navigate = useNavigate();

  useEffect(() => {
    let interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 0) {
          clearInterval(interval);
        }

        return Math.max(0, prev - 1);
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleCheckInstagram = async () => {
    if (instagramUsername) {
      const { promoter: fetchedPromoter } = await API.get<{ promoter: CurrentPromoter }>(
        `/v1/promoter/instagram/${instagramUsername}`,
      );

      if (fetchedPromoter) {
        setPromoter(fetchedPromoter);
      }
    }

    setWelcomeMessageIsVisible(false);
  };

  const handleSkip = () => {
    setWelcomeMessageIsVisible(false);
  };

  const handleSave = async () => {
    await fetchCurrentPromoter();
    navigate('/manage/promoter');
  };

  const skipText = countdown === 0 ? 'Skip' : `Skip (${countdown})`;

  return (
    <Screen
      name="Onboard Promoter Screen"
      headerProps={{
        title: 'Promoter Sign Up',
      }}
      hideToolbar
    >
      <Screen.Content maxWidth={425}>
        {welcomeMessageIsVisible ? (
          <>
            <TitleToolbar text="Elevate Your LGBTQ+ Event Planning" size="md" />
            <p>
              Welcome to Gaggl, the ultimate platform designed exclusively for event producers like
              you. By signing up, you&apos;ll gain access to a world of talented drag performers,
              DJs, comedians, and more who are ready to make your events unforgettable. Not only can
              you share gig opportunities, but you can also promote your events in a space dedicated
              to queer folks.
            </p>
            <p>
              Whether you&apos;re organizing dance parties or corporate events, Gaggl is here to
              help you connect with performers, grow your network, and shine in the event production
              industry. Join our community today and take the first step towards creating
              extraordinary events with exceptional talent!
            </p>
            <p>To get started, please enter your Instagram username below.</p>
            <div>
              <FormLabel text="Instagram Username *" />
              <Input type="text" onChange={(e) => setInstagramUsername(e.target.value)} />
            </div>
            <Button
              className="mt-2"
              roundness="rounded"
              disabled={!instagramUsername}
              onClick={handleCheckInstagram}
            >
              Continue with Instagram
            </Button>
            <Button
              className="mt-2"
              roundness="rounded"
              color="neutral"
              variant="outlined"
              disabled={countdown !== 0}
              onClick={handleSkip}
            >
              {skipText}
            </Button>
          </>
        ) : (
          <>
            {currentCity && (
              <PromoterForm
                promoter={promoter}
                currentCity={currentCity}
                cities={cities}
                onSave={handleSave}
              />
            )}
          </>
        )}
      </Screen.Content>
    </Screen>
  );
};
