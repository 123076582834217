import { FC, MouseEventHandler } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import { useTriviaChallenges } from '@queries/TriviaQueries';

import { useCreateTriviaChallengeMutation } from '@mutations/TriviaMutations';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { RenderTable } from '@components/table/RenderTable';

export const SuperAdminManageWeeklyTriviaScreen: FC<{}> = () => {
  const cityId = '658874efbe4bf4fe70a3d972'; // Denver hardcoded
  const { triviaChallengesAreReady, triviaChallenges = [] } = useTriviaChallenges({
    cityId,
  });
  const { isSubmitting, createTriviaChallengeAsync } = useCreateTriviaChallengeMutation();
  const navigate = useNavigate();

  const createNavigateToUpdateHandler =
    (triviaChallengeId: string): MouseEventHandler =>
    (mouseEvent) => {
      mouseEvent.stopPropagation();
      navigate(`/super-admin/manage/trivia/${triviaChallengeId}`);
    };

  const handleCreateTriviaChallenge = async () => {
    const { triviaChallenge } = await createTriviaChallengeAsync({ cityId });
    navigate(`/super-admin/manage/trivia/${triviaChallenge._id}`);
  };

  const headCells = [
    {
      id: 'status',
      label: '',
      TableBodyCell: ({ row }) => (
        <td>
          <FontAwesomeIcon
            name="circle"
            className={row.isApproved ? 'event-is-active' : 'event-is-inactive'}
          />
        </td>
      ),
    },
    { id: 'title', label: 'Title', TableBodyCell: ({ row }) => <td>{row.title}</td> },
    {
      id: 'action',
      label: 'Action',
      TableBodyCell: ({ row }) => (
        <td>
          <IconButton onClick={createNavigateToUpdateHandler(row._id)}>
            <FontAwesomeIcon name="edit" />
          </IconButton>
        </td>
      ),
    },
  ];
  const rows = triviaChallenges.map((triviaChallenge) => ({
    key: triviaChallenge._id,
    ...triviaChallenge,
  }));

  return (
    <Screen
      name="SuperAdmin Manage Weekly Trivia Screen"
      headerProps={{ back: { to: '/profile' }, title: 'Manage Trivia' }}
    >
      <Screen.Content>
        <TitleToolbar
          text="Weekly Trivia"
          action={
            <Button disabled={isSubmitting} onClick={handleCreateTriviaChallenge}>
              Create
            </Button>
          }
        />
        {triviaChallengesAreReady ? (
          <div>
            {triviaChallenges.length ? (
              <RenderTable headCells={headCells} rows={rows} />
            ) : (
              <div>No Existing Trivia Challenges</div>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        )}
      </Screen.Content>
    </Screen>
  );
};
