import { Chip } from 'framework7-react';
import { useEffect, useRef, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import { motion } from 'framer-motion';
import { sortBy } from 'lodash';

import { useNearbyVenuesQuery } from '@queries/useFeedQuery';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';

import { useRouter } from '@router/index';

import { VenueCard } from '@components/cards/VenueCard';
import Card from '@components/layout/Card/Card';
import { List } from '@components/layout/List/List';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { CritiqueOverlay } from '@components/overlays/CritiqueOverlay/CritiqueOverlay';
import { BASIC_RATING_RECORD } from '@components/overlays/CritiqueOverlay/CritiqueOverlayConstants';

import { ONBOARDING_STEP_COUNT } from '../OnboardingConstants';
import './OnboardingCritiquesScreen.scss';

const COLOR_RECORD = {
  like: 'green',
  neutral: 'yellow',
  dislike: 'red',
};

const VENUE_OPTION_RECORD = Object.fromEntries(
  [
    {
      _id: '6588815fbe4bf4fe70b655ba',
      title: 'Buddies Denver',
      tagline: '"Let\'s be buddies"',
      distance: 0.4326189831382378,
      followerCount: 0,
      upcomingEventsCount: 0,
    },
    {
      _id: '6588815fbe4bf4fe70b65596',
      title: 'X BAR',
      tagline: 'putting the X in eXtra',
      distance: 0.5225971641762601,
      followerCount: 0,
      upcomingEventsCount: 0,
    },
    {
      _id: '6588815fbe4bf4fe70b655a5',
      title: "Charlie's Denver",
      tagline: 'Western saloon bar',
      distance: 0.6503776205563212,
      followerCount: 0,
      upcomingEventsCount: 1,
    },
    {
      _id: '659e0edc4bcc67654de8e065',
      title: 'Trade',
      tagline: 'Denver Queer Underground Fetish Bar',
      distance: 0.9988663384734746,
      followerCount: 0,
      upcomingEventsCount: 1,
    },
    {
      _id: '6588815fbe4bf4fe70b655e0',
      title: 'Blush & Blu',
      tagline: 'A distinct drinkery',
      distance: 0.9787562705191278,
      followerCount: 0,
      upcomingEventsCount: 1,
    },
  ].map((venue) => [venue._id, venue]),
);

export const OnboardingCritiquesScreen = () => {
  const { venuesAreReady, venues = [], refetchVenues } = useNearbyVenuesQuery();
  const [selectedVenue, setSelectedVenue] = useState<any>();
  const { navigate } = useRouter();

  const intervalRef = useRef<any>();
  useEffect(() => {
    if (!venues.length) {
      intervalRef.current = setInterval(() => {
        refetchVenues();
      }, 5000);

      return () => clearInterval(intervalRef.current);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    }
  }, [venues]);

  const venuesById = Object.fromEntries(venues.map((venue) => [venue._id, venue]));

  const handleCheckboxChange = (venueId: string) => (isChecked: boolean) => {
    setSelectedVenue(venuesById[venueId]);
  };

  const { isSubmitting, critiqueDocumentAsync } = useCritiqueDocumentMutation();

  const handleSubmitCritique = async (properties: CritiqueDocumentMutationPayload) => {
    await critiqueDocumentAsync({
      documentType: 'venues',
      documentId: selectedVenue._id,
      ...properties,
    });
    navigate('/onboarding/quick-picks');
  };

  return (
    <Screen name="Onboarding Critiques Screen" className="onboarding-critiques-screen" hideToolbar>
      <Screen.Content>
        <TitleToolbar
          className="title-toolbar-upper mb-0"
          text={`Step 3 of ${ONBOARDING_STEP_COUNT}`}
          size="sm"
        />
        <TitleToolbar className="my-0" text="Critiquing 💅🏽" />
        <p>
          The most useful part of Gaggl is knowing the communities opinion on all the local spots
          and events. And now that you're here, we want you to be a part of this too!
        </p>
        <p>To get started, which one of these bars have you been too?</p>
        {venuesAreReady ? (
          <List
            listItems={venues?.map(({ _id, title }) => ({
              title,
              hasCheckbox: true,
              onCheckboxChange: handleCheckboxChange(_id),
            }))}
          />
        ) : (
          <>
            <Skeleton height={300} />
          </>
        )}
        <CritiqueOverlay
          isVisible={!!selectedVenue}
          walkthroughEnabled
          upperContent={
            <Card>
              <Card.Body>
                <TitleToolbar text={`How do you feel about ${selectedVenue?.title}?`} size="md" />
                <p>
                  Don't worry. Unless you leave a comment, the rest of the community won't know it's
                  you. But your response will be super helpful to the rest of us! We just want your
                  honest opinion.
                </p>
              </Card.Body>
            </Card>
          }
          onSubmit={handleSubmitCritique}
        />

        {/* {selectedVenue && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="selected-venue-card-wrapper"
          >
            <VenueCard venue={selectedVenue} />
          </motion.div>
        )} */}
      </Screen.Content>
    </Screen>
  );
};
