import { FC, ReactNode } from 'react';

import pluralize from 'pluralize';

import { Promoter, usePromoterCritiqueDataQuery } from '@queries/PromoterQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';

import { useUserIsFollowing } from '@hooks/useUserIsFollowing';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import Card from '@components/layout/Card/Card';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';

interface PromoterCardProps {
  showUserIsFollowing?: boolean;
  promoter: Promoter;
  onClick?: () => void;
  footerOverride?: ReactNode;
}

export const PromoterFollowButton: FC<{ promoter: { _id: string; title: string } }> = ({
  promoter,
}) => {
  const { userIsFollowing, handleFollow, handleUnfollow } = useUserIsFollowing(
    'promoter',
    promoter._id,
  );

  return (
    <>
      {userIsFollowing ? (
        <Button color="neutral" variant="outlined" size="sm" onClick={handleUnfollow}>
          Unfollow
        </Button>
      ) : (
        <Button color="neutral" variant="outlined" size="sm" onClick={handleFollow}>
          Follow
        </Button>
      )}
    </>
  );
};

export const PromoterCard: FC<PromoterCardProps> = ({
  showUserIsFollowing = true,
  promoter,
  footerOverride,
  onClick,
}) => {
  const subtitle = `${promoter.upcomingEventsCount} upcoming ${pluralize('event', promoter.upcomingEventsCount)}`;

  const { promoterCritiqueData, refetchPromoterCritiqueData } = usePromoterCritiqueDataQuery(
    promoter._id,
    !footerOverride,
  );
  const { isSubmitting, critiqueDocumentAsync } = useCritiqueDocumentMutation();

  const handleSubmitCritique = async (properties: CritiqueDocumentMutationPayload) => {
    await critiqueDocumentAsync({
      documentType: 'promoters',
      documentId: promoter._id,
      ...properties,
    });
    refetchPromoterCritiqueData();
  };

  return (
    <Card onClick={onClick}>
      <Card.Header
        avatar={<LetterAvatar letter={findFirstAlphaCharacter(promoter.title)} />}
        title={promoter.title}
        subtitle={subtitle}
        action={showUserIsFollowing ? <PromoterFollowButton promoter={promoter} /> : undefined}
      />
      {promoter.description && <Card.Body>{mapStringToParagraphs(promoter.description)}</Card.Body>}
      {footerOverride ?? (
        <Card.Footer>
          <PrimaryCritiqueCountChips
            counts={promoterCritiqueData?.basicRatings}
            onSubmit={handleSubmitCritique}
          />
        </Card.Footer>
      )}
    </Card>
  );
};
