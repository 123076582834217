import { useQuery } from '@tanstack/react-query';

import API from '@api/API';

export interface InstagramAccount {
  _id: string;
  createdAt: string;
  updatedAt: string;
  instagramId: string;
  username: string;
  status: string;
  biography: string;
  businessCategoryName: string;
  profilePicUrl: string;
  fullName: string;
  type?: string;
  cityIds?: string[];
  promoterId?: string;
  entertainerId?: string;
  venueId?: string;
  isPossibleDragAccount?: boolean;
  locationNames?: string[];
}

export interface InstagramAccountsPageCityData {
  city: {
    _id: string;
    name: string;
    eventsAreEnabled: boolean;
  };
  events: any[];
  venues: any[];
  // accounts: InstagramAccount[];
}

interface InstagramAccountsPageData {
  accounts: InstagramAccount[];
  cities: InstagramAccountsPageCityData[];
}

export const useInstagramAccountsPageQuery = ({ isEnabled = true }: { isEnabled: boolean }) => {
  const limit = 1000;
  const { isFetched, data, refetch } = useQuery({
    queryKey: useInstagramAccountsPageQuery.queryKey(),
    queryFn: () => API.get<InstagramAccountsPageData>(`/v1/super-admin/instagram-accounts`),
    enabled: isEnabled,
  });

  return {
    instagramAccountsPageDataIsReady: isFetched,
    instagramAccountsPageData: data,
    refetchSourceLogs: refetch,
  };
};
useInstagramAccountsPageQuery.queryKey = () => ['instagramAccountsPage'];

interface ExtendedInstagramAccountsPageCityData {
  city: {
    city: {
      _id: string;
      name: string;
    };
    accounts: InstagramAccount[];
  };
}

export const useCityInstagramAccountsPageQuery = ({
  cityId,
  isEnabled = true,
}: {
  cityId: string;
  isEnabled: boolean;
}) => {
  const limit = 1000;
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCityInstagramAccountsPageQuery.queryKey(cityId),
    queryFn: () =>
      API.get<ExtendedInstagramAccountsPageCityData>(
        `/v1/super-admin/cities/${cityId}/instagram-accounts`,
      ),
    enabled: isEnabled,
  });

  return {
    cityInstagramAccountDataIsReady: isFetched,
    cityInstagramAccountData: data?.city,
    refetchSourceLogs: refetch,
  };
};
useCityInstagramAccountsPageQuery.queryKey = (cityId: string) => [
  'cities',
  cityId,
  'instagramAccounts',
];
