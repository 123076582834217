import { List, ListItem } from 'framework7-react';
import { ComponentProps, FC, useMemo, useRef, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import { uniqBy } from 'lodash';

import { UserCritique } from '@queries/CritiqueQueries';
import { EntertainerRank } from '@queries/EntertainerQueries';
import {
  Venue,
  useCurrentUserVenueCritiqueQuery,
  useVenueCritiqueDataQuery,
} from '@queries/VenueQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Input } from '@components/form/Input';
import { CritiqueSection } from '@components/layout/DetailsChartSection/CritiqueSection';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';
import { DocumentCritiquePopup } from '@components/overlays/DocumentCritiquePopup';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

interface VenueCritiqueContentProps {
  selectedVenue: Venue;
  onClickRating: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onUpdateStrengths: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onUpdateImprovements: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onClose: () => void;
}

interface VenueCritiquePopupProps {
  selectedVenue?: { _id: string; title: string };
  back?: string;
  onClose: () => void;
}
export const VenueCritiquePopup: FC<VenueCritiquePopupProps> = ({
  selectedVenue,
  back,
  onClose,
}) => {
  const { track } = useAnalytics();
  const navigate = useNavigate();

  const { userIsLoggedIn } = useAppContext();
  const { createAuthGateHandler } = useAuthGate();
  const { venueCritiqueDataIsReady, venueCritiqueData } = useVenueCritiqueDataQuery(
    selectedVenue?._id,
  );
  const { userVenueCritiqueIsReady, userVenueCritique } = useCurrentUserVenueCritiqueQuery(
    selectedVenue?._id,
  );

  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();
  const handleClickRating = () => {
    return async (payload: CritiqueDocumentMutationPayload) => {
      track('Click rate venue', {
        ...payload,
        venueId: selectedVenue?._id,
        venueName: selectedVenue?.title,
      });
      await critiqueDocumentAsync({
        ...payload,
        documentId: selectedVenue?._id,
        documentType: 'venues',
      });
    };
  };

  const handleViewVenueProfile = () => {
    navigate(`/venues/${selectedVenue?._id}`, back ? { state: { back } } : undefined);
  };

  return (
    <DocumentCritiquePopup
      isLoading={!venueCritiqueDataIsReady || (userIsLoggedIn && !userVenueCritiqueIsReady)}
      onClose={onClose}
      onClickRating={handleClickRating}
      onViewDocumentDetails={handleViewVenueProfile}
      selectedDocument={selectedVenue}
      critiqueData={venueCritiqueData}
      userDocumentCritique={userVenueCritique}
    />
  );
};
