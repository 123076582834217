import { SkeletonBlock } from 'framework7-react';
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import * as dayjs from 'dayjs';

import {
  BasicRating,
  SuggestedCritiqueResponseBody,
  useSuggestedCritiquesQuery,
} from '@queries/CritiqueQueries';
import { FeedEvent, useEventQuery } from '@queries/EventQueries';
import { Promoter } from '@queries/PromoterQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import EventCard, { EventHeader, EventImage } from '@components/cards/EventCard';
import { PromoterCard } from '@components/cards/PromoterCard';
import { VenueCard } from '@components/cards/VenueCard';
import { ButtonFilter } from '@components/layout/ButtonFilter/ButtonFilter';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import {
  CritiqueOverlay,
  CritiqueWrapper,
  PrimaryCritiqueButtons,
} from '@components/overlays/CritiqueOverlay/CritiqueOverlay';

import './CritiqueScreen.scss';

const EventCritiqueCard: FC<{ eventId: string; contentHeight: number; onSubmit: () => void }> = ({
  eventId,
  contentHeight,
  onSubmit,
}) => {
  const { eventIsReady, event } = useEventQuery(eventId);

  const [primaryRating, setPrimaryRating] = useState<BasicRating>();
  const { isSubmitting, critiqueDocumentAsync } = useCritiqueDocumentMutation();

  const handleSubmitCritique = async (properties: CritiqueDocumentMutationPayload) => {
    await critiqueDocumentAsync({
      documentType: 'events',
      documentId: event._id,
      ...properties,
    });
    onSubmit();
  };

  if (!eventIsReady) {
    return <></>;
  }

  return (
    <>
      <Card className="event-critique-card">
        <Card.Body style={{ height: contentHeight }}>
          <EventHeader event={event} />
          <EventImage event={event} />
          <TitleToolbar size="md" text="About This Event" />
          <div className="event-description">{mapStringToParagraphs(event.description)}</div>
        </Card.Body>
        <Card.Footer>
          <div className="critique-event-card_gradient" />
          <div className="w-100">
            <PrimaryCritiqueButtons value={primaryRating} onChange={setPrimaryRating} />
            <Button
              className="mt-3"
              color="neutral"
              variant="outlined"
              track={{
                event: 'Click Skip Event Critique',
                properties: {
                  'Event Name': event.title,
                },
              }}
              onClick={() => handleSubmitCritique({})}
            >
              I didn't go
            </Button>
          </div>
        </Card.Footer>
      </Card>

      <CritiqueOverlay
        isVisible={!!primaryRating}
        primaryRating={primaryRating}
        onSubmit={handleSubmitCritique}
      />
    </>
  );
};

const VenueCritiqueCard: FC<{ venue: any; onSubmit: () => void }> = ({ venue, onSubmit }) => {
  const [primaryRating, setPrimaryRating] = useState<BasicRating>();
  const { isSubmitting, critiqueDocumentAsync } = useCritiqueDocumentMutation();

  const handleSubmitCritique = async (properties: CritiqueDocumentMutationPayload) => {
    await critiqueDocumentAsync({
      documentType: 'venues',
      documentId: venue._id,
      ...properties,
    });
    onSubmit();
  };

  return (
    <>
      <VenueCard
        venue={venue}
        footerOverride={
          <Card.Footer>
            <div className="w-100">
              <PrimaryCritiqueButtons value={primaryRating} onChange={setPrimaryRating} />
              <Button
                className="mt-3"
                color="neutral"
                variant="outlined"
                track={{
                  event: 'Click Skip Venue Critique',
                  properties: {
                    'Venue Name': venue.title,
                  },
                }}
                onClick={() => handleSubmitCritique({})}
              >
                I've never been here
              </Button>
            </div>
          </Card.Footer>
        }
      />

      <CritiqueOverlay
        isVisible={!!primaryRating}
        primaryRating={primaryRating}
        onSubmit={handleSubmitCritique}
      />
    </>
  );
};

const PromoterCritiqueCard: FC<{ promoter: Promoter; onSubmit: () => void }> = ({
  promoter,
  onSubmit,
}) => {
  const [primaryRating, setPrimaryRating] = useState<BasicRating>();
  const { isSubmitting, critiqueDocumentAsync } = useCritiqueDocumentMutation();

  const handleSubmitCritique = async (properties: CritiqueDocumentMutationPayload) => {
    await critiqueDocumentAsync({
      documentType: 'promoters',
      documentId: promoter._id,
      ...properties,
    });
    onSubmit();
  };

  return (
    <>
      <PromoterCard
        promoter={promoter}
        footerOverride={
          <Card.Footer>
            <div className="w-100">
              <PrimaryCritiqueButtons value={primaryRating} onChange={setPrimaryRating} />
              <Button
                className="mt-3"
                color="neutral"
                variant="outlined"
                track={{
                  event: 'Click Skip Promoter Critique',
                  properties: {
                    'Promoter Name': promoter.title,
                  },
                }}
                onClick={() => handleSubmitCritique({})}
              >
                Skip promoter
              </Button>
            </div>
          </Card.Footer>
        }
      />

      <CritiqueOverlay
        isVisible={!!primaryRating}
        primaryRating={primaryRating}
        onSubmit={handleSubmitCritique}
      />
    </>
  );
};

const CRITIQUE_OPTIONS = ['events', 'venues', 'promoters'] as const;
type CritiqueOption = (typeof CRITIQUE_OPTIONS)[number];

const CRITIQUE_OPTION_RECORD: Record<CritiqueOption, { label: string }> = {
  events: { label: 'Events' },
  venues: { label: 'Venues' },
  promoters: { label: 'Promoters' },
};

export const CritiqueScreen = () => {
  const [currentCritiqueOption, setCurrentCritiqueOption] = useState<CritiqueOption>('events');
  const [currentCritiqueIndex, setCurrentCritiqueIndex] = useState<Record<CritiqueOption, number>>({
    events: 0,
    venues: 0,
    promoters: 0,
  });
  const [cachedSuggestedCritiques, setCachedSuggestedCritiques] =
    useState<SuggestedCritiqueResponseBody>();
  const {
    suggestedCritiques: initialSuggestedCritiques,
    suggestedCritiquesAreReady,
    refetchCritiques,
  } = useSuggestedCritiquesQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!cachedSuggestedCritiques && initialSuggestedCritiques) {
      setCachedSuggestedCritiques(initialSuggestedCritiques);
      const [firstNonEmptyGroupKey] = Object.entries(initialSuggestedCritiques).find(
        ([_, list]) => list.length > 0,
      );
      setCurrentCritiqueOption((firstNonEmptyGroupKey as CritiqueOption) ?? 'events');
    }
  }, [initialSuggestedCritiques]);

  const contentRef = useRef<{ el: HTMLElement }>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  useLayoutEffect(() => {
    if (suggestedCritiquesAreReady) {
      const TOP_OFFSET = 100;
      const BOTTOM_OFFSET = 360;
      setContentHeight(contentRef.current?.el?.clientHeight - TOP_OFFSET - BOTTOM_OFFSET);
    }
  }, [suggestedCritiquesAreReady]);

  const { currentEvent, currentVenue, currentPromoter } = useMemo(() => {
    return {
      currentEvent: cachedSuggestedCritiques?.events[currentCritiqueIndex.events],
      currentVenue: cachedSuggestedCritiques?.venues[currentCritiqueIndex.venues],
      currentPromoter: cachedSuggestedCritiques?.promoters[currentCritiqueIndex.promoters],
    };
  }, [currentCritiqueIndex, cachedSuggestedCritiques]);

  const handleIncrementCritique = useCallback(() => {
    setCurrentCritiqueIndex((prev) => ({
      ...prev,
      [currentCritiqueOption]: prev[currentCritiqueOption] + 1,
    }));
  }, [currentCritiqueOption, currentCritiqueIndex]);

  return (
    <Screen
      name="Critique Screen"
      headerProps={{
        back: { to: '/home' },
        title: 'Critique',
      }}
      onReenter={() => {
        refetchCritiques();
      }}
    >
      <Screen.Content ref={contentRef}>
        <ButtonFilter
          options={Object.entries(CRITIQUE_OPTION_RECORD).map(([value, { label }]) => ({
            value,
            label,
          }))}
          selected={currentCritiqueOption}
          onChange={setCurrentCritiqueOption as (v: string) => void}
        />
        {/* <TitleToolbar text={`Critique ${CRITIQUE_OPTION_RECORD[currentCritiqueOption].label}`} /> */}
        {suggestedCritiquesAreReady ? (
          <>
            {currentCritiqueOption === 'events' && (
              <>
                {currentEvent ? (
                  <EventCritiqueCard
                    key={currentEvent._id}
                    eventId={currentEvent._id}
                    contentHeight={contentHeight}
                    onSubmit={handleIncrementCritique}
                  />
                ) : (
                  <EmptyState
                    title="You don't have any events to critique right now."
                    text="Check out the upcoming events and see if you're interested in any of them and share your thoughts."
                    button={{
                      text: 'Explore events',
                      onClick: () =>
                        navigate('/discover', {
                          animate: false,
                        }),
                    }}
                  />
                )}
              </>
            )}
            {currentCritiqueOption === 'venues' && (
              <>
                {currentVenue ? (
                  <VenueCritiqueCard
                    key={currentVenue._id}
                    venue={currentVenue}
                    onSubmit={handleIncrementCritique}
                  />
                ) : (
                  <EmptyState
                    title="You've critiqued all the venues!"
                    text="Check out the upcoming events and see if you're interested in any of them and share your thoughts."
                    button={{
                      text: 'Explore events',
                      onClick: () =>
                        navigate('/discover', {
                          animate: false,
                        }),
                    }}
                  />
                )}
              </>
            )}
            {currentCritiqueOption === 'promoters' && (
              <>
                {currentPromoter ? (
                  <PromoterCritiqueCard
                    key={currentPromoter._id}
                    promoter={currentPromoter}
                    onSubmit={handleIncrementCritique}
                  />
                ) : (
                  <EmptyState
                    title="You've critiqued all the promoters!"
                    text="Check out the upcoming events and see if you're interested in any of them and share your thoughts."
                    button={{
                      text: 'Explore events',
                      onClick: () =>
                        navigate('/discover', {
                          animate: false,
                        }),
                    }}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <Skeleton height={240} />
        )}
      </Screen.Content>
    </Screen>
  );
};
