import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@contexts/AppContext';

import API from '@api/API';

import { CritiqueData, UserCritique } from './CritiqueQueries';

export interface Venue {
  _id: string;
  title: string;
  tagline: string;
  description: string;
}

export const useVenueQuery = (venueId: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useVenueQuery.queryKey(venueId),
    queryFn: () => useVenueQuery.queryFn(venueId),
    enabled: !!venueId,
  });

  return {
    venueIsReady: isFetched,
    venue: data?.venue,
    refetchVenue: refetch,
  };
};
useVenueQuery.queryKey = (venueId: string) => ['venue', venueId];
useVenueQuery.queryFn = (venueId: string) => API.get<{ venue: Venue }>(`/v1/venues/${venueId}`);

export const useVenueCritiqueDataQuery = (venueId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useVenueCritiqueDataQuery.queryKey(venueId),
    queryFn: () => useVenueCritiqueDataQuery.queryFn(venueId),
    enabled: isEnabled && !!venueId,
    refetchOnMount: 'always',
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: 'always',
  });

  return {
    venueCritiqueDataIsReady: isFetched,
    venueCritiqueData: data?.critiqueData,
    refetchVenueCritiqueData: refetch,
  };
};
useVenueCritiqueDataQuery.queryKey = (venueId) => ['venue-critiques', venueId];
useVenueCritiqueDataQuery.queryFn = (venueId) =>
  API.get<{ critiqueData: CritiqueData }>(`/v1/venues/${venueId}/critiques`);

export const useCurrentUserVenueCritiqueQuery = (venueId: string, isEnabled = true) => {
  const { userIsLoggedIn } = useAppContext();
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCurrentUserVenueCritiqueQuery.queryKey(venueId),
    queryFn: () => useCurrentUserVenueCritiqueQuery.queryFn(venueId),
    enabled: isEnabled && !!venueId && userIsLoggedIn,
  });

  return {
    userVenueCritiqueIsReady: isFetched,
    userVenueCritique: data?.userCritique,
    refetchUserVenueCritique: refetch,
  };
};
useCurrentUserVenueCritiqueQuery.queryKey = (venueId) => ['user-critiques', 'venues', venueId];
useCurrentUserVenueCritiqueQuery.queryFn = (venueId) =>
  API.get<{ userCritique: UserCritique }>(`/v1/user/critiques/venues/${venueId}`);

export const useCityVenuesQuery = (cityId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCityVenuesQuery.queryKey(cityId),
    queryFn: () => useCityVenuesQuery.queryFn(cityId),
    enabled: isEnabled && !!cityId,
  });

  return {
    cityVenuesIsReady: isFetched,
    cityVenues: data?.venues,
    refetchCityVenues: refetch,
  };
};
useCityVenuesQuery.queryKey = (cityId) => ['city-venues', cityId];
useCityVenuesQuery.queryFn = (cityId) =>
  API.get<{ venues: { _id: string; title: string; isActive: boolean }[] }>(
    `/v1/super-admin/cities/${cityId}/venues`,
  );
