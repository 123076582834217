import { FC, useState } from 'react';

import { map } from 'cypress/types/bluebird';
import pluralize from 'pluralize';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { FeedEvent } from '@queries/EventQueries';

import { useRespondToEntertainerEventRequestMutation } from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import EventCard from '@components/cards/EventCard';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { Screen } from '@components/layout/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

const EventRequestCard: FC<{ event: FeedEvent }> = ({ event }) => {
  const headerTitle = event.promoters?.[0]?.title ?? event.venue?.title;

  return (
    <Card>
      <div className="reviewed-header-wrapper">
        <div className="reviewed-header">
          <span>Tagged Event</span>
          <Card.Header
            avatar={<LetterAvatar letter={findFirstAlphaCharacter(headerTitle)} />}
            title={headerTitle}
          />
        </div>
      </div>
      <EventCard hideRsvp showPromoter={false} event={event} onClick={() => {}} hideActions />
      <Card.Body className="pt-0">{mapStringToParagraphs(event.excerpt)}</Card.Body>
    </Card>
  );
};

export const EventRequestsScreen = () => {
  const { entertainer } = useCurrentEntertainerQuery();
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const { eventRequests } = useCurrentEntertainerQuery();
  const { respondToEntertainerEventRequestAsync, isSubmitting } =
    useRespondToEntertainerEventRequestMutation(entertainer._id);
  const { track } = useAnalytics();

  const [cachedEventRequests, setCachedEventRequests] = useState(eventRequests);

  const currentEvent = cachedEventRequests?.[currentEventIndex];

  const handleSubmit = async (status: 'accepted' | 'rejected') => {
    if (currentEvent) {
      if (!isSubmitting) {
        track('Tagged Event Response', {
          entertainerId: entertainer._id,
          eventId: currentEvent._id,
          status,
        });
        await respondToEntertainerEventRequestAsync({
          eventId: currentEvent._id,
          status,
        });
        setCurrentEventIndex((prev) => prev + 1);
        setCachedEventRequests((prev) => prev?.filter((event) => event._id !== currentEvent._id));
      }
    }
  };

  return (
    <Screen
      name="Tagged Events Screen"
      headerProps={{
        title: 'Tagged Events',
      }}
      bottomToolbar={
        currentEvent && (
          <Screen.BottomToolbar>
            <div className="d-flex gap-2 w-100">
              <div className="w-50">
                <Button
                  variant="outlined"
                  color="neutral"
                  roundness="rounded"
                  disabled={isSubmitting}
                  onClick={() => handleSubmit('rejected')}
                >
                  Ignore
                </Button>
              </div>
              <div className="w-50">
                <Button
                  variant="outlined"
                  color="neutral"
                  roundness="rounded"
                  disabled={isSubmitting}
                  onClick={() => handleSubmit('accepted')}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </Screen.BottomToolbar>
        )
      }
    >
      <Screen.Content>
        <TitleToolbar
          size="md"
          text={`${Math.min(currentEventIndex + 1, cachedEventRequests?.length)} of ${cachedEventRequests?.length} tagged ${pluralize('events', cachedEventRequests?.length)}`}
        />
        {currentEvent ? (
          <EventRequestCard event={currentEvent} />
        ) : (
          <EmptyState
            title="No Tagged Events"
            text="You currently don't have any tagged events from producers or venues. Know you have something coming up? Keep your schedule up to date by adding your own events."
          />
        )}
      </Screen.Content>
    </Screen>
  );
};
