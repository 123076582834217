import { Block } from 'framework7-react';
import { FC, MouseEventHandler, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import { FeedEvent, useUserEventsQuery } from '@queries/EventQueries';
import { useCurrentUser } from '@queries/UserQueries';

import { useNavigate } from '@router/index';

import EventCard from '@components/cards/EventCard';
import Card from '@components/layout/Card/Card';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import './EventsScreen.scss';

export const EventsScreen: FC<{ f7router?: any }> = ({ f7router }) => {
  const { eventsAreReady, events } = useUserEventsQuery({
    refetchOnWindowFocus: 'always',
  });
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();

  const handleViewEvent = (eventId: string) => {
    navigate(`/events/${eventId}`);
  };

  return (
    <Screen name="Events Screen">
      <Screen.Content>
        <TitleToolbar text="Nearby Events" />
        {eventsAreReady ? (
          <div>
            {events?.events?.map((event) => (
              <EventCard
                key={event._id}
                event={event}
                onClick={() => handleViewEvent(event._id)}
                showFooter={currentUser?.isSuperAdmin}
              />
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        )}
      </Screen.Content>
    </Screen>
  );
};
