import { FC, useEffect } from 'react';

import { useRankingsQuery } from '@queries/CritiqueQueries';

import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { RenderTable } from '@components/table/RenderTable';

interface LeaderboardPopupProps {
  isVisible: boolean;
  onClose: () => void;
}

export const LeaderboardPopup: FC<LeaderboardPopupProps> = ({ isVisible, onClose }) => {
  const { rankings = [], refetchRankings } = useRankingsQuery();

  useEffect(() => {
    refetchRankings();
  }, [isVisible]);

  return (
    <Popup isVisible={isVisible} onClose={onClose}>
      <Popup.Content>
        <TitleToolbar text="Gaggl Leaderboard 🏆" size="md" />
        <p>
          Right now, this leaderboard is for kicks and giggles. But the leaders will soon be able to
          win 💰💰💰 for being the most active. Stay tuned!
        </p>
        <RenderTable
          headCells={[
            {
              id: 'rank',
              label: 'Rank',
              TableBodyCell: ({ row }) => <td className="text-center">#{row.rank}</td>,
            },
            {
              id: 'displayName',
              label: 'Name',
              className: 'text-start',
              TableBodyCell: ({ row }) => <td>{row.displayName}</td>,
            },
            {
              id: 'score',
              label: 'Score',
              TableBodyCell: ({ row }) => <td className="text-center">{row.score}</td>,
            },
          ]}
          rows={rankings.slice(0, 10).map((ranking) => ({ ...ranking, key: String(ranking.rank) }))}
        />
      </Popup.Content>
    </Popup>
  );
};
