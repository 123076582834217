import { List as F7List, ListItem as F7ListItem, Fab, f7ready } from 'framework7-react';
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { motion } from 'framer-motion';
import { orderBy } from 'lodash';

import { CritiqueQuestion, useCritiqueQuestionsQuery } from '@queries/CritiqueQueries';
import { useNearbyVenuesQuery } from '@queries/useFeedQuery';

import { useAnswerCritiqueQuestionMutation } from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import './QuickPicksScreen.scss';

export const QuickPickQuestionOptions: FC<{
  showSelected?: boolean;
  currentCritiqueQuestion: CritiqueQuestion;
  value?: string;
  handleSelectOption: (value: string) => void;
  onSubmit?: () => void;
  submitText?: string;
}> = ({
  showSelected,
  currentCritiqueQuestion,
  value,
  handleSelectOption,
  onSubmit,
  submitText = 'Next',
}) => {
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    setShowOptions(false);

    setTimeout(() => {
      setShowOptions(true);
    }, 50);
  }, [currentCritiqueQuestion?._id]);

  if (!showOptions) {
    return <></>;
  }

  return (
    <div>
      <F7List className="m-0">
        {currentCritiqueQuestion.options.map((option) => (
          <F7ListItem
            className={showSelected && value !== option.value ? 'opacity-25' : ''}
            key={option.value}
            checkbox
            checked={value === option.value}
            title={option.label}
            onClick={() => handleSelectOption(option.value)}
            noChevron
            after={
              value === option.value &&
              ((
                <Button roundness="rounded" size="md" onClick={onSubmit}>
                  {submitText}
                </Button>
              ) as any)
            }
          />
        ))}
      </F7List>
    </div>
  );
};

export const QuickPicksScreen = () => {
  const { critiqueQuestions, critiqueQuestionsAreReady } = useCritiqueQuestionsQuery();
  const [didSetCachedCritiqueQuestions, setDidSetCachedCritiqueQuestions] = useState(false);
  const [cachedCritiqueQuestions, setCachedCritiqueQuestions] = useState<CritiqueQuestion[]>([]);
  const [selectedVenueRecord, setSelectedVenueRecord] = useState<Record<number, string>>({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { answerCritiqueQuestionAsync } = useAnswerCritiqueQuestionMutation();
  const { track } = useAnalytics();

  useEffect(() => {
    if (critiqueQuestionsAreReady) {
      requestAnimationFrame(() => {
        setCachedCritiqueQuestions(critiqueQuestions);
        setDidSetCachedCritiqueQuestions(true);
      });
    }
  }, [critiqueQuestionsAreReady]);

  const currentCritiqueQuestion = useMemo(
    () => cachedCritiqueQuestions?.[currentQuestionIndex],
    [cachedCritiqueQuestions, currentQuestionIndex],
  );

  const [showSelected, setShowSelected] = useState(false);
  const handleNext = () => {
    setCurrentQuestionIndex((prev) => prev + 1);
    setShowSelected(false);
  };

  const timeoutRef = useRef<NodeJS.Timeout>();
  const handleSelectVenue = (venueId: string) => {
    setSelectedVenueRecord((prev) => ({ ...prev, [currentQuestionIndex]: venueId }));
    setShowSelected(true);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // timeoutRef.current = setTimeout(async () => {
    //   await answerCritiqueQuestionAsync({
    //     critiqueQuestionId: currentCritiqueQuestion?._id,
    //     answer: venueId,
    //   });
    //   handleNext();
    // }, 2000);
  };

  const handleSubmit = async () => {
    track('Answered Critique Question', {
      critiqueQuestionId: currentCritiqueQuestion?._id,
    });
    await answerCritiqueQuestionAsync({
      critiqueQuestionId: currentCritiqueQuestion?._id,
      answer: selectedVenueRecord[currentQuestionIndex],
    });
    handleNext();
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Screen
      name="Quick Picks Screen"
      className="quick-picks-screen"
      headerProps={{
        back: { to: '/home' },
        // subnavbar: (
        //   <div className="quick-picks-screen_subnavbar">
        //     <TitleToolbar text={currentCritiqueQuestion?.question} size="md" />
        //   </div>
        // ),
        // right: (
        //   <Button // startIcon={<FontAwesomeIcon name="comments" />}
        //     variant="flat"
        //   >
        //     Share Your Own Question
        //   </Button>
        // ),
      }}
      bottomToolbar={
        <Screen.BottomToolbar>
          <div className="d-flex gap-2 w-100">
            <div className="w-50">
              {currentQuestionIndex > 0 && (
                <Button
                  variant="outlined"
                  color="neutral"
                  roundness="rounded"
                  onClick={() => {
                    track('Previous Critique Question', {
                      previousCritiqueQuestionId:
                        cachedCritiqueQuestions?.[currentQuestionIndex - 1]?._id,
                    });
                    setCurrentQuestionIndex((prev) => prev - 1);
                  }}
                >
                  Back
                </Button>
              )}
            </div>
            <div className="w-50">
              <Button
                variant="outlined"
                color="neutral"
                roundness="rounded"
                onClick={() => {
                  track('Skip Critique Question', {
                    critiqueQuestionId: currentCritiqueQuestion?._id,
                  });
                  handleNext();
                }}
              >
                Skip
              </Button>
            </div>
          </div>
        </Screen.BottomToolbar>
      }
    >
      <Screen.Content>
        {critiqueQuestionsAreReady && didSetCachedCritiqueQuestions ? (
          <>
            {currentCritiqueQuestion ? (
              <>
                <Card>
                  <Card.Body>
                    <TitleToolbar
                      className="my-0"
                      text={currentCritiqueQuestion?.question}
                      size="md"
                    />
                  </Card.Body>
                </Card>
                <QuickPickQuestionOptions
                  showSelected={showSelected}
                  currentCritiqueQuestion={currentCritiqueQuestion}
                  value={selectedVenueRecord[currentQuestionIndex]}
                  handleSelectOption={handleSelectVenue}
                  onSubmit={handleSubmit}
                />
              </>
            ) : (
              <EmptyState
                title="No more questions"
                text="We don't have any questions for you right. Check back later!"
              />
            )}
          </>
        ) : (
          <Skeleton height={100} />
        )}
      </Screen.Content>
    </Screen>
  );
};
