import { FC, useMemo, useState } from 'react';

import { useAppSettingsQuery } from '@contexts/AppContext';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

import {
  useCurrentEntertainerQuery,
  useCurrentUserEntertainerCritiqueQuery,
} from '@queries/EntertainerQueries';

import { useSendEntertainerBadge } from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { List } from '@components/layout/List/List';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import Button from './Button';
import { SendBadgeIcon } from './SendBadgeIcon';

interface SendEntertainerBadgeButtonProps {
  initialResetTime?: string;
  entertainerId: string;
  text?: string;
  lockedBadgeId?: string;
  entertainerMediaId?: string;
  eventId?: string;
  buttonProps?: any;
  reasons?: string[];
}

const UnwrappedSendEntertainerBadgeButton: FC<SendEntertainerBadgeButtonProps> = ({
  initialResetTime,
  entertainerId,
  text = 'Send Badge',
  buttonProps = {},
  lockedBadgeId,
  eventId,
  reasons = [],
}) => {
  const { track } = useAnalytics();
  const { sendEntertainerBadgeAsync, isSubmitting } = useSendEntertainerBadge(entertainerId);
  const [didSend, setDidSend] = useState(false);
  const [resetTime, setResetTime] = useState(initialResetTime);
  const { createAuthGateHandler } = useAuthGate();

  const handleSubmit = async (reason: string) => {
    if (isSubmitting) {
      return;
    }

    track('Send Badge', {
      entertainerId,
      lockedBadgeId,
      eventId,
      reason,
      resetTime,
    });

    createAuthGateHandler(async () => {
      await sendEntertainerBadgeAsync({ lockedBadgeId, eventId, reason });
      setDidSend(true);

      const now = dayjs();
      setResetTime(
        (now.get('hour') < 9 ? now.set('hour', 9) : now.add(1, 'day').set('hour', 9)).toISOString(),
      );

      setTimeout(() => {
        setDidSend(false);
      }, 3000);
    });
  };

  const buttonText = useMemo(() => {
    if (didSend) {
      return 'Sent!';
    }

    if (resetTime && dayjs().isBefore(resetTime)) {
      // get the time until the next reset
      const hoursTilReset = dayjs(resetTime).diff(dayjs(), 'hour');
      const minutesTilReset = dayjs(resetTime).diff(dayjs(), 'minute') % 60;
      const secondsTilReset = dayjs(resetTime).diff(dayjs(), 'second') % 60;

      if (hoursTilReset > 0) {
        return `Resend in ${hoursTilReset}${pluralize('hr', hoursTilReset)}`;
      }

      if (minutesTilReset > 0) {
        return `Resend in ${minutesTilReset}${pluralize('min', minutesTilReset)}`;
      }

      if (secondsTilReset > 0) {
        return `Resend in ${secondsTilReset}${pluralize('sec', secondsTilReset)}`;
      }
    }

    return text;
  }, [didSend, resetTime]);

  return (
    <PopoverWrapper
      PopoverContent={({ onClose }) => (
        <div>
          <div className="px-3" style={{ borderBottom: '1px solid rgba(255,255,255,.5)' }}>
            <p>
              <strong>Pick a reason:</strong>
            </p>
          </div>
          <div style={{ height: 160, overflow: 'auto' }}>
            {reasons?.length && (
              <List
                listItems={
                  reasons?.map((reason) => ({
                    title: reason,
                    onClick: () => {
                      handleSubmit(reason);
                      onClose();
                    },
                  })) ?? []
                }
              />
            )}
          </div>
        </div>
      )}
    >
      <div>
        <Button
          startIcon={
            <div>
              <SendBadgeIcon height={16} width={16} />
            </div>
          }
          roundness="rounded"
          disabled={isSubmitting || didSend || dayjs().isBefore(resetTime)}
          {...buttonProps}
        >
          <span style={{ whiteSpace: 'nowrap' }}>{buttonText}</span>
        </Button>
      </div>
    </PopoverWrapper>
  );
};

const UnwrappedSendEntertainerMediaBadgeButton: FC<SendEntertainerBadgeButtonProps> = ({
  initialResetTime,
  entertainerId,
  text = 'Reward a Badge',
  entertainerMediaId,
  buttonProps = {},
  lockedBadgeId,
  eventId,
  reasons = [],
}) => {
  const { track } = useAnalytics();
  const { appSettings } = useAppSettingsQuery();
  const { sendEntertainerBadgeAsync, isSubmitting } = useSendEntertainerBadge(entertainerId);
  const [didSend, setDidSend] = useState(false);
  const [resetTime, setResetTime] = useState(initialResetTime);
  const [selectedBadgeId, setSelectedBadgeId] = useState<string>();
  const { createAuthGateHandler } = useAuthGate();

  const cannotSend = resetTime && dayjs().isBefore(resetTime);
  const handleSubmit = async (badgeId: string) => {
    if (isSubmitting || cannotSend) {
      return;
    }

    track('Send Media Badge', {
      entertainerId,
      entertainerMediaId,
      badgeId,
      resetTime,
    });

    setSelectedBadgeId(badgeId);

    createAuthGateHandler(async () => {
      await sendEntertainerBadgeAsync({
        lockedBadgeId,
        entertainerMediaId,
        badgeId,
        reason: 'Look',
      });
      setDidSend(true);

      const now = dayjs();
      setResetTime(
        (now.get('hour') < 9 ? now.set('hour', 9) : now.add(1, 'day').set('hour', 9)).toISOString(),
      );

      setTimeout(() => {
        setDidSend(false);
      }, 3000);
    });
  };

  const buttonText = useMemo(() => {
    if (didSend) {
      return 'Sent!';
    }

    if (resetTime && dayjs().isBefore(resetTime)) {
      // get the time until the next reset
      const hoursTilReset = dayjs(resetTime).diff(dayjs(), 'hour');
      const minutesTilReset = dayjs(resetTime).diff(dayjs(), 'minute') % 60;
      const secondsTilReset = dayjs(resetTime).diff(dayjs(), 'second') % 60;

      if (hoursTilReset > 0) {
        return `Resend in ${hoursTilReset}${pluralize('hr', hoursTilReset)}`;
      }

      if (minutesTilReset > 0) {
        return `Resend in ${minutesTilReset}${pluralize('min', minutesTilReset)}`;
      }

      if (secondsTilReset > 0) {
        return `Resend in ${secondsTilReset}${pluralize('sec', secondsTilReset)}`;
      }
    }

    return text;
  }, [didSend, resetTime]);

  return (
    <div
      className="d-grid gap-1 px-3 pt-1 pb-2"
      style={{ background: 'rgba(0,0,0,.2)', borderRadius: 8 }}
    >
      <div className="text-center">{buttonText}</div>
      <div className="d-flex justify-content-around">
        {appSettings.entertainerMediaBadges?.map((badge) => (
          <div
            key={badge._id}
            className="d-flex gap-1 flex-column align-items-center text-center"
            style={{
              width: `${100 / appSettings.entertainerMediaBadges.length}%`,
              opacity: cannotSend && selectedBadgeId !== badge._id ? 0.2 : 1,
            }}
            onClick={() => handleSubmit(badge._id)}
            role="button"
          >
            <div
              style={{
                width: 36,
                height: 36,
              }}
            >
              <img src={badge.imageUrl} style={{ margin: '2px auto', height: 32 }} />
            </div>
            <div style={{ fontSize: 10, lineHeight: 1 }}>{badge.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const SendEntertainerBadgeButton: FC<
  Omit<SendEntertainerBadgeButtonProps, 'initialResetTime'> & {
    skeletonMaxWidth?: number;
  }
> = (props) => {
  const { entertainer: currentUserEntertainer } = useCurrentEntertainerQuery();
  const { entertainerId, skeletonMaxWidth, entertainerMediaId } = props;
  const { userEntertainerCritiqueIsReady, userEntertainerCritique } =
    useCurrentUserEntertainerCritiqueQuery(entertainerId);
  const { appSettings, appSettingsAreReady } = useAppSettingsQuery();

  if (currentUserEntertainer?._id === entertainerId) {
    return <></>;
  }

  if (!userEntertainerCritiqueIsReady || !appSettingsAreReady) {
    return (
      <div className="mx-auto" style={{ maxWidth: skeletonMaxWidth }}>
        <Skeleton height={36} />
      </div>
    );
  }

  if (entertainerMediaId) {
    return (
      <UnwrappedSendEntertainerMediaBadgeButton
        {...props}
        reasons={appSettings?.badgeReasons ?? []}
        initialResetTime={userEntertainerCritique?.userSendBadgeResetTime}
      />
    );
  }

  return (
    <UnwrappedSendEntertainerBadgeButton
      {...props}
      reasons={appSettings?.badgeReasons ?? []}
      initialResetTime={userEntertainerCritique?.userSendBadgeResetTime}
    />
  );
};
