import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import * as dayjs from 'dayjs';

import { useSearchQuery } from '@queries/UserQueries';

const EVENT_FILTER_TYPES = ['today', 'tomorrow', 'thisWeekend', 'custom'] as const;
type EventDateFilter = (typeof EVENT_FILTER_TYPES)[number];

const EVENT_FILTERS: Record<
  EventDateFilter,
  {
    label: string;
    getRange: () => [string, string];
  }
> = {
  today: {
    label: 'Today',
    getRange: () => {
      const today = dayjs().format('YYYY-MM-DD');
      return [today, today];
    },
  },
  tomorrow: {
    label: 'Tomorrow',
    getRange: () => {
      const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
      return [tomorrow, tomorrow];
    },
  },
  thisWeekend: {
    label: 'This weekend',
    getRange: () => {
      const friday = dayjs().day(5).format('YYYY-MM-DD');
      const sunday = dayjs().day(5).add(2, 'days').format('YYYY-MM-DD');

      return [friday, sunday];
    },
  },
  custom: {
    label: 'Choose date',
    getRange: () => {
      const today = dayjs().format('YYYY-MM-DD');
      const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');

      return [today, tomorrow];
    },
  },
};

export interface EventDateFilterOption {
  value: EventDateFilter;
  label: string;
  getRange: () => [string, string];
}

interface EventFilters {
  dateRange?: [string, string];
}

export const useEventFilters = (initialFilters: Partial<EventFilters> = {}) => {
  const queryClient = useQueryClient();
  const [eventFilters, setEventFilters] = useState<EventFilters>(initialFilters);

  const eventDateFilterOptions: EventDateFilterOption[] = Object.entries(EVENT_FILTERS)
    .filter(([value]) => value !== 'custom')
    .map(([value, { label, getRange }]) => ({
      value: value as EventDateFilter,
      label,
      getRange,
    }));

  const dateFilter = useMemo(() => {
    const [currentFilterStartDate, currentFilterEndDate] = eventFilters?.dateRange ?? [];

    if (!currentFilterStartDate || !currentFilterEndDate) {
      return undefined;
    }

    const [value] = Object.entries(EVENT_FILTERS)
      .filter(([value]) => value !== 'custom')
      .find(([_, { getRange }]) => {
        const [startDate, endDate] = getRange();
        return startDate === currentFilterStartDate && endDate === currentFilterEndDate;
      });

    return value as EventDateFilter;
  }, [eventFilters?.dateRange]);

  const currentDateFilter = useMemo(() => EVENT_FILTERS?.[dateFilter], [dateFilter]);

  const updateDateFilter = (dateRange: [string, string]) => {
    setEventFilters((prev) => ({
      ...prev,
      dateRange,
    }));
  };

  const refetchEvents = async () => {
    const promises = Object.entries(EVENT_FILTERS)
      .filter(([value]) => value !== 'custom')
      .map(([_, { getRange }]) => {
        const [startDate, endDate] = getRange();
        queryClient.invalidateQueries({
          queryKey: useSearchQuery.queryKey({
            startDate,
            endDate,
          }),
        });
      });

    await Promise.all(promises);
  };

  return {
    refetchEvents,
    eventFilters: { ...eventFilters, dateFilter },
    currentDateFilter,
    updateDateFilter,
    eventDateFilterOptions,
  };
};
