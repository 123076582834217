import { FC, useEffect, useState } from 'react';

import { useAnalytics } from '@hooks/useAnalytics';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { FormLabel } from '@components/form/FormLabel';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { CopyText } from '@components/layout/CopyText/CopyText';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

export const ClaimPopup: FC<{
  isVisible: boolean;
  trackProperties: any;
  onClose: () => void;
}> = ({ isVisible, onClose, trackProperties }) => {
  const { track } = useAnalytics();
  const [code] = useState<number>(Math.floor(100000 + Math.random() * 900000));

  useEffect(() => {
    if (isVisible) {
      track('Claim', {
        ...trackProperties,
        code,
      });
    }
  }, [isVisible]);

  return (
    <Popup isVisible={isVisible} onClose={onClose}>
      <Popup.Content>
        <TitleToolbar size="md" text="Request Sent!" />
        <p className="mt-0">
          To verify your permission manage to this profile, send <strong>{code}</strong> in a
          message to @gaggl.app from this profile's Instagram account
        </p>

        <CopyText text={code.toString()} />

        <Button
          className="mt-3"
          roundness="rounded"
          startIcon={<FontAwesomeIcon faStyle="fab" name="instagram" />}
          onClick={() => {
            openExternalUrl('https://instagram.com/gaggl.app');
          }}
        >
          Message @gaggl.app
        </Button>
      </Popup.Content>
    </Popup>
  );
};
