import { FC, useState } from 'react';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';

import Button from './Button';

interface OptionButtonProps {
  label: string;
  accept: {
    label: string;
    onClick: () => Promise<void>;
  };
  deny: {
    label: string;
    onClick: () => Promise<void>;
  };
}

export const OptionButton: FC<OptionButtonProps> = ({ label, accept, deny }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAccept = (onClose: () => void) => async () => {
    setIsSubmitting(true);
    await accept.onClick();
    setIsSubmitting(false);
    onClose();
  };

  const handleDeny = (onClose: () => void) => async () => {
    setIsSubmitting(true);
    await deny.onClick();
    setIsSubmitting(false);
    onClose();
  };

  return (
    <PopoverWrapper
      PopoverContent={({ onClose }) => (
        <div className="p-2 d-flex gap-2">
          <Button disabled={isSubmitting} roundness="rounded" onClick={handleAccept(onClose)}>
            {accept.label}
          </Button>
          <Button
            disabled={isSubmitting}
            noWrap
            roundness="rounded"
            color="neutral"
            variant="outlined"
            onClick={handleDeny(onClose)}
          >
            {deny.label}
          </Button>
        </div>
      )}
    >
      <Button
        className="py-2 px-3"
        roundness="rounded"
        variant="outlined"
        color="neutral"
        size="sm"
        endIcon={<FontAwesomeIcon name="chevron-down" />}
      >
        {label}
      </Button>
    </PopoverWrapper>
  );
};
