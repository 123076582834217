export const mapStringToParagraphs = (
  text: string = '',
  { collapseTopMargin }: { collapseTopMargin?: boolean } = {},
) => {
  return text.split('\n').map((paragraph, index) => (
    <p key={index} className={`${collapseTopMargin && index === 0 ? 'mt-0' : ''}`}>
      {paragraph}
    </p>
  ));
};
