import { FC, useEffect, useMemo, useState } from 'react';

import * as dayjs from 'dayjs';
import pluralize from 'pluralize';

import { useEventQuery } from '@queries/EventQueries';
import { useCurrentUser, useInterestQuery, useSearchQuery } from '@queries/UserQueries';

import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';
import { useEventFilters } from '@hooks/useEventFilters';

import { useLocation, useNavigate, useParams, useQueryParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import EventCard, {
  EventActions,
  EventHeader,
  EventImage,
  PromoterHeader,
} from '@components/cards/EventCard';
import { PromoterCard } from '@components/cards/PromoterCard';
import { VenueCard } from '@components/cards/VenueCard';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Input } from '@components/form/Input';
import { SearchField } from '@components/form/SearchField/SearchField';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { ButtonFilter } from '@components/layout/ButtonFilter/ButtonFilter';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

interface SearchScreenProps {
  back?: string;
  searchValue?: string;
  startDate?: string;
  endDate?: string;
}

const SHOW_FILTER_BAR = false;

const SEARCH_TYPES = ['events', 'venues', 'promoters'] as const;
type SearchType = (typeof SEARCH_TYPES)[number];

const SEARCH_TYPE_RECORD: Record<SearchType, { label: string }> = {
  events: { label: 'Events' },
  venues: { label: 'Venues' },
  promoters: { label: 'Promoters' },
};

export const SearchScreen: FC<SearchScreenProps> = ({
  back = '/discover',
  searchValue,
  startDate,
  endDate,
}) => {
  const { currentCity } = useCurrentUserCity();
  const [filterDrawerIsOpen, setFilterDrawerIsOpen] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<SearchType>('events');
  const [updatedSearchValue, setSearchValue] = useState<string>(searchValue);
  // const { searchValue, startDate, endDate } = state;
  const { eventDateFilterOptions } = useEventFilters();
  const { searchResultsAreReady, searchResults } = useSearchQuery({
    searchValue,
    startDate,
    endDate,
  });
  const { events = [], venues = [], promoters = [] } = searchResults ?? {};
  const navigate = useNavigate();

  const handleSearch = () => {
    if (!searchValue) {
      return;
    }

    navigate(`/search`, {
      state: { searchValue },
    });
  };

  const handleViewEvent = (eventId: string) => {
    navigate(`/events/${eventId}`);
  };

  const handleViewVenue = (venueId: string) => {
    navigate(`/venues/${venueId}`);
  };

  const handleViewPromoter = (promoterId: string) => {
    navigate(`/promoters/${promoterId}`);
  };

  useEffect(() => {
    setSearchType(
      SEARCH_TYPES.find((searchTypeValue) => searchResults?.[searchTypeValue]?.length) ?? 'events',
    );
  }, [searchResults]);

  return (
    <Screen name="Search Screen" headerProps={{ back: { to: back }, title: 'Explore' }}>
      <Screen.Content>
        <div className="d-grid">
          <SearchField
            className="explore-events-field"
            name="searchValue"
            placeholder="Explore Gaggl"
            defaultValue={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSubmit={handleSearch}
          />

          {SHOW_FILTER_BAR && (
            <HorizontalScroll
              items={[
                <div className="d-flex gap-2">
                  <div className="d-inline-block">
                    <Button
                      roundness="rounded"
                      color="default"
                      onClick={() => setFilterDrawerIsOpen(true)}
                    >
                      <FontAwesomeIcon name="filter" />
                    </Button>
                  </div>
                  {eventDateFilterOptions.map((option) => (
                    <div className="d-inline-block">
                      <Button key={option.value} roundness="rounded" color="default">
                        {option.label}
                      </Button>
                    </div>
                  ))}
                </div>,
              ]}
            />
          )}
          <ButtonFilter
            options={Object.entries(SEARCH_TYPE_RECORD).map(([key, { label }]) => ({
              value: key as SearchType,
              label,
            }))}
            selected={searchType}
            onChange={(updatedSearchType: SearchType) => setSearchType(updatedSearchType)}
          />
        </div>

        {searchResultsAreReady ? (
          <>
            {searchType === 'events' && (
              <>
                <TitleToolbar
                  className="mt-0"
                  text={`${events.length} ${pluralize('Event', events.length)}`}
                  size="md"
                />
                {events.map((event) => (
                  <EventCard
                    key={event._id}
                    event={event}
                    onClick={() => handleViewEvent(event._id)}
                    showFooter={false}
                  />
                ))}
              </>
            )}

            {searchType === 'venues' && (
              <>
                <TitleToolbar
                  className="mt-0"
                  text={`${venues.length} ${pluralize('Venue', venues.length)}`}
                  size="md"
                />
                {venues.map((venue) => (
                  <VenueCard
                    key={venue._id}
                    venue={venue}
                    onClick={() => handleViewVenue(venue._id)}
                  />
                ))}
              </>
            )}

            {searchType === 'promoters' && (
              <>
                <TitleToolbar
                  className="mt-0"
                  text={`${promoters.length} ${pluralize('Promoter', promoters.length)}`}
                  size="md"
                />
                {promoters.map((promoter) => (
                  <PromoterCard
                    key={promoter._id}
                    promoter={promoter}
                    onClick={() => handleViewPromoter(promoter._id)}
                  />
                ))}
              </>
            )}
          </>
        ) : (
          <div className="grid gap-2">
            <Skeleton height={240} />
            <Skeleton height={240} />
            <Skeleton height={240} />
          </div>
        )}

        <Popup isVisible={filterDrawerIsOpen} onClose={() => setFilterDrawerIsOpen(false)}>
          <Popup.Content>Hi</Popup.Content>
        </Popup>
      </Screen.Content>
    </Screen>
  );
};
