// Import F7 Bundle
// Import F7-React Plugin
import * as Sentry from '@sentry/react';
import Framework7React, { f7ready } from 'framework7-react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { SplashScreen } from '@capacitor/splash-screen';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import 'framework7/css';
import 'framework7/css/bundle';
import Framework7 from 'framework7/lite/bundle';

import App from './App';

// MOVED TO NOTIFICATIONS
// CapacitorUpdater.addListener('updateAvailable', async (res) => {
//   try {
//     f7ready((f7) => {
//       f7.dialog.alert(
//         'Update Available',
//         "There's a new version of Gaggl availabe! The app will update now.",
//         async () => {
//           try {
//             await CapacitorUpdater.set(res.bundle);
//           } catch (error) {
//             Sentry.captureException(error);
//             CapacitorUpdater.reload();
//           }
//         },
//       );
//     });
//   } catch (error) {
//     Sentry.captureException(error);
//   }
// });

CapacitorUpdater.addListener('appReady', () => {
  // Hide splash
  SplashScreen.hide();
});

CapacitorUpdater.notifyAppReady();

// Init F7-React Plugin
Framework7.use(Framework7React);

window.addEventListener('error', (event) => {
  Sentry.captureException(event.error);
});

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  Sentry.captureException(event);
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter basename={process.env.PUBLIC_URL}>
//     <Provider store={store}>
//             <App />
//         </Provider>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
