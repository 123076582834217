import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';

import API from '@api/API';

import {
  fetchTokenIsValid,
  useFetchTokenIsValid,
  useIsValidTokenQuery,
} from '@queries/UserQueries';
import { useMagic } from '@queries/index';

import { useOneSignal } from '@hooks/useOneSignal';

import { useLocation, useNavigate, useQueryParams } from '@router/index';

import Button from '@components/buttons/Button';
import { Input } from '@components/form/Input';
import { Screen } from '@components/layout/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { useAppContext } from '../contexts/AppContext';

const authWithMagicLink = (payload: { userId: string; code: string }) => {
  return API.post<{ user: any; token: string }, { userId: string; code: string }>(
    '/v1/user/auth/magic-link/validate',
    payload,
  );
};

type RedirectType = 'entertainer' | 'promoter' | 'venue' | 'direct-message';

export const MagicLinkScreen = () => {
  const navigate = useNavigate();
  const fetchTokenIsValid = useFetchTokenIsValid();
  const { updateToken, navigateAfterLogin } = useAppContext();
  const { userId, code, redirect, entertainerId } = useQueryParams<{
    userId?: string;
    code?: string;
    redirect?: RedirectType;
    entertainerId?: string;
  }>();
  const redirectTimeoutRef = useRef<NodeJS.Timeout>();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  useEffect(() => {
    if (userId && code) {
      const authWithMagicLinkAsync = async () => {
        try {
          const { tokenIsValid } = await fetchTokenIsValid();

          if (!tokenIsValid) {
            const { token } = await authWithMagicLink({ userId, code });

            if (!token) {
              throw new Error('Failed to authenticate with token');
            }

            updateToken(token);

            await Promise.all([
              queryClient.invalidateQueries({ queryKey: ['user'] }),
              queryClient.invalidateQueries({ queryKey: ['tokenIsValid'] }),
            ]);
          }

          let queryParamsString = '';
          if (redirect === 'direct-message') {
            if (userId && entertainerId) {
              queryParamsString = `?currentUserId=${userId}&entertainerId=${entertainerId}`;
            }
          }

          await navigateAfterLogin(
            redirect ? `/magic-link/${redirect}${queryParamsString}` : '/home',
            {
              animate: false,
            },
          );
        } catch (e) {
          await navigateAfterLogin();
        }
      };

      authWithMagicLinkAsync();
    } else {
      if (pathname === '/magic-link') {
        clearTimeout(redirectTimeoutRef.current);
        redirectTimeoutRef.current = setTimeout(() => {
          navigate('/');
        }, 3000);
      }
    }

    return () => {
      clearTimeout(redirectTimeoutRef.current);
    };
  }, [userId, code]);

  return (
    <Screen name="MagicLink Screen">
      <Screen.Content></Screen.Content>
    </Screen>
  );
};
