import { FC, useMemo, useState } from 'react';

import { useCurrentPromoterQuery } from '@queries/PromoterQueries';

import {
  useCreateBookingOpportunityMutation,
  useNoAuthCreateBookingOpportunityMutation,
} from '@mutations/PromoterMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCityOptionsByIds } from '@hooks/useCityOptionsByIds';
import { useCitiesQuery, useCityById } from '@hooks/useCurrentUserLocation';

import { useManagedHistory, useRouter } from '@router/index';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { BookingContactInformationForm } from '@screens/entertainer/BookingRequestScreen';

import { CreateUpdateBookingOpportunityForm } from './CreateEditBookingOpportunityScreen';

export const NoAuthCreateEditBookingOpportunityScreen: FC<{ cityId: string }> = ({ cityId }) => {
  const { navigate, back } = useRouter();
  const { backlinkUrl } = useManagedHistory();
  const { cityIsReady, city } = useCityById(cityId);
  const [step, setStep] = useState<'contact' | 'eventDetails' | 'submitted'>('contact');
  const { track } = useAnalytics();

  const [contactInformation, setContactInformation] = useState({
    name: '',
    email: '',
    phone: '',
    organization: {
      type: '',
      title: '',
    },
  });

  const { createNoAuthBookingOpportunityAsync } = useNoAuthCreateBookingOpportunityMutation();

  const cityOptions = useMemo(() => {
    return city ? [{ value: city._id, label: city.name }] : [];
  }, [city]);

  const canAddEventDetails =
    contactInformation.name &&
    contactInformation.email &&
    contactInformation.phone &&
    contactInformation.organization.type &&
    contactInformation.organization.title;

  const handleSubmit = async (payload) => {
    track('Submit Event Details', {
      title: payload.title,
      details: payload.details,
      startDate: payload.startDate,
      startTime: payload.startTime,
      venue: payload.venue.title,
    });
    await createNoAuthBookingOpportunityAsync({ ...payload, contact: contactInformation });
    setStep('submitted');
  };

  return (
    <Screen
      name="Create Booking Opportunity Screen (No Auth)"
      headerProps={{
        left: !backlinkUrl && (
          <Button
            startIcon={<FontAwesomeIcon name="chevron-left" />}
            variant="smooth"
            color="neutral"
            roundness="rounded"
            className="py-2"
            onClick={() => back(`/noauth/entertainers/dragPerformer/${cityId}/rankings`)}
          >
            Performers
          </Button>
        ),
        back: backlinkUrl ? { to: '/community/drag' } : undefined,
        title: <Screen.GagglTitle />,
        right: (
          <Button
            variant="outlined"
            roundness="rounded"
            size="sm"
            color="neutral"
            startIcon={<FontAwesomeIcon className="fs-6" faStyle="fab" name="instagram" />}
            className="opacity-90"
            onClick={() => {
              track('Click follow on Instagram');
              openExternalUrl('https://www.instagram.com/gaggl.app/');
            }}
          >
            gaggl.app
          </Button>
        ),
      }}
      hideToolbar
    >
      <Screen.Content maxWidth={425}>
        {cityIsReady && city ? (
          <>
            <TitleToolbar text={`Book local ${city.name} drag performers`} size="md" />
            <p className="mt-0">
              Are you looking for drag entertainers for your brewery? brunch? private event? Fill
              out the form below to send your opportunity out to dozens of talented entertainers and
              pick the best fits.
            </p>
            {step === 'contact' && (
              <>
                <TitleToolbar text={`Contact Information`} size="sm" />
                <p className="mt-0">
                  Don't worry we'll never share your information. We just need to know how to notify
                  you when entertainers respond to your opportunity so that we can share their
                  profile with you and how to DM with them.
                </p>
                <BookingContactInformationForm
                  onChangeName={(updatedName) =>
                    setContactInformation((prevValue) => ({ ...prevValue, name: updatedName }))
                  }
                  onChangeEmail={(updatedEmail) =>
                    setContactInformation((prevValue) => ({ ...prevValue, email: updatedEmail }))
                  }
                  onChangePhone={(updatedPhone) =>
                    setContactInformation((prevValue) => ({ ...prevValue, phone: updatedPhone }))
                  }
                  organizationFields={{
                    onChangeType: (updatedOrganizationType) => {
                      setContactInformation((prevValue) => ({
                        ...prevValue,
                        organization: {
                          ...prevValue.organization,
                          type: updatedOrganizationType,
                        },
                      }));
                    },
                    onChangeTitle: (updatedOrganizationTitle) => {
                      setContactInformation((prevValue) => ({
                        ...prevValue,
                        organization: {
                          ...prevValue.organization,
                          title: updatedOrganizationTitle,
                        },
                      }));
                    },
                  }}
                />
                <div className="mt-4">
                  <Button
                    roundness="rounded"
                    disabled={!canAddEventDetails}
                    onClick={() => {
                      track('Submit Contact Info', {
                        name: contactInformation.name,
                        email: contactInformation.email,
                        phone: contactInformation.phone,
                        organizationType: contactInformation.organization.type,
                        organizationTitle: contactInformation.organization.title,
                      });
                      setStep('eventDetails');
                    }}
                  >
                    Add Event Details
                  </Button>
                </div>
              </>
            )}
            {step === 'eventDetails' && (
              <>
                <TitleToolbar text={`Event Details`} size="sm" />
                <p className="mt-0">
                  This is the only information that the entertainers will see so make sure to
                  include all the details they need to know to decide if they're a good fit for your
                  event.
                </p>
                {cityOptions.length && (
                  <CreateUpdateBookingOpportunityForm
                    cityOptions={cityOptions}
                    datePickerDirection="top"
                    onSubmit={handleSubmit}
                  />
                )}
              </>
            )}
            {step === 'submitted' && (
              <EmptyState
                title="Booking Opportunity Sent"
                text={`Your booking request has been shared with all the entertainers in ${city.name}.`}
                button={{
                  text: `View ${city.name} Entertainers`,
                  onClick: () => {
                    navigate(`/noauth/entertainers/dragPerformer/${cityId}/rankings`);
                  },
                }}
              />
            )}
          </>
        ) : (
          <>
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </>
        )}
      </Screen.Content>
    </Screen>
  );
};
