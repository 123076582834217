import { Input as F7Input } from 'framework7-react';
import {
  FC,
  FormEvent,
  FormEventHandler,
  KeyboardEvent,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Spinner from 'react-bootstrap/Spinner';

import * as dayjs from 'dayjs';
import { keyBy, sortBy, uniqBy } from 'lodash';

import { useSuggestedPromotersQuery } from '@queries/PromoterQueries';
import {
  useCurrentUser,
  useFollowsQuery,
  useSearchQuery,
  useUserInterestsQuery,
} from '@queries/UserQueries';
import { useNearbyVenuesQuery } from '@queries/index';

import { useAskInAppMessageQueryMutation } from '@mutations/PostMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserLocation } from '@hooks/useCurrentUserLocation';
import { EventDateFilterOption, useEventFilters } from '@hooks/useEventFilters';

import { useNavigate } from '@router/index';

import { isEnv } from '@utilities/isEnv';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import EventCard from '@components/cards/EventCard';
import { PromoterCard } from '@components/cards/PromoterCard';
import { VenueCard } from '@components/cards/VenueCard';
import { Chip } from '@components/chips/Chip';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { DateRange, DateRangePicker } from '@components/form/DateRangePicker';
import { Input } from '@components/form/Input';
import { SearchField } from '@components/form/SearchField/SearchField';
import { Textarea } from '@components/form/Textarea';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { UserInterestsSection } from '@screens/HomeScreen/HomeScreen';

import './DiscoverScreen.scss';

const DateRangePickerPopup: FC<{ isVisible: boolean }> = ({ isVisible }) => {
  const [dateRange, setDateRange] = useState<DateRange>();

  const handleDateRangeChange = (updatedDateRange: DateRange) => {
    console.log(updatedDateRange);
    setDateRange(updatedDateRange);
  };

  return (
    <Popup isVisible={isVisible} onClose={() => {}}>
      <Popup.Content>
        <DateRangePicker value={dateRange} onChange={setDateRange} />
      </Popup.Content>
    </Popup>
  );
};

const InAppMessageForm: FC<{}> = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>();
  const { isSubmitting: isSubmissingInAppMessage, askInAppMessageQueryAsync } =
    useAskInAppMessageQueryMutation();
  const handleAskInAppMessage = async () => {
    const { query } = await askInAppMessageQueryAsync({ message });
    if (query._id) {
      navigate(`/discover/${query._id}`);
    }
  };

  return (
    <Textarea
      onChange={setMessage}
      submitButton={{
        text: isSubmissingInAppMessage ? 'Sending...' : 'Send',
        disabled: isSubmissingInAppMessage,
        onClick: handleAskInAppMessage,
      }}
    />
  );
};

const EXPANDED_PROMOTERS_ENABLED = false;

export const DiscoverScreen: FC<{}> = () => {
  const today = dayjs().format('YYYY-MM-DD');

  const {
    eventDateFilterOptions,
    eventFilters,
    updateDateFilter,
    currentDateFilter,
    refetchEvents,
  } = useEventFilters({
    dateRange: [today, today],
  });
  const { refetchCurrentLocation } = useCurrentUserLocation();
  const { venues, venuesAreReady, refetchVenues } = useNearbyVenuesQuery();
  const { promoters = [] } = useSuggestedPromotersQuery();
  const { searchResultsAreReady, searchResults } = useSearchQuery({
    startDate: eventFilters.dateRange?.[0],
    endDate: eventFilters.dateRange?.[1],
  });
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [datePopupVisible, setDatePopupVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const { track } = useAnalytics();

  const handleSearch = () => {
    if (!searchValue) {
      return;
    }

    navigate(`/search`, {
      state: { searchValue },
    });
  };

  const handleDateFilterChange = (value: string) => {
    const selectedOption = eventDateFilterOptions.find((option) => option.value === value);

    if (selectedOption) {
      const [startDate, endDate] = selectedOption.getRange();
      track('Update Date Filter', {
        value,
        startDate,
        endDate,
      });
      updateDateFilter([startDate, endDate]);
    }
  };

  return (
    <>
      <Screen
        name="Discover Screen"
        onReenter={async () => {
          refetchCurrentLocation();
          refetchVenues();
          refetchEvents();
        }}
      >
        <Screen.Content>
          <TitleToolbar text="Search events 🔍" size="md" />
          <div className="d-flex align-items-center gap-2">
            <div className="explore-events_input-wrapper">
              <SearchField
                ref={searchInputRef}
                className="explore-events-field"
                name="searchValue"
                placeholder="Explore upcoming events"
                onChange={(e) => setSearchValue(e.target.value)}
                onSubmit={handleSearch}
              />
            </div>
          </div>
          <TitleToolbar text="Discover events by date 🗓️" size="md" />
          <HorizontalScroll
            items={[
              <ChipRadioOptions
                value={eventFilters.dateFilter}
                onChange={handleDateFilterChange}
                options={eventDateFilterOptions}
              />,
            ]}
          />
          {searchResultsAreReady ? (
            <>
              {searchResults?.events?.length > 0 ? (
                <HorizontalScroll
                  items={searchResults?.events?.map((event) => (
                    <EventCard
                      key={event._id}
                      event={event}
                      onClick={() =>
                        navigate(`/events/${event._id}`, {
                          state: { back: '/discover' },
                        })
                      }
                      showPromoter={false}
                      hideActions
                    />
                  ))}
                />
              ) : (
                <EmptyState
                  title="No events"
                  text={`No one has shared any events for ${currentDateFilter?.label.toLocaleLowerCase()}. Try searching for what you're looking for.`}
                  button={{
                    text: 'Explore events',
                    onClick: () => searchInputRef.current?.focus(),
                  }}
                />
              )}
            </>
          ) : (
            <Skeleton height={240} />
          )}

          <UserInterestsSection back={`/discover`} />

          <TitleToolbar
            text="Nearby Bars 🍻"
            size="md"
            action={
              <Button
                variant="flat"
                onClick={() => {
                  navigate('/venues');
                }}
              >
                View All
              </Button>
            }
          />
          {venuesAreReady && !!venues?.length ? (
            <HorizontalScroll
              items={[
                ...venues.slice(0, 5).map((venue) => (
                  <VenueCard
                    key={venue._id}
                    venue={venue}
                    onClick={() =>
                      navigate(`/venues/${venue._id}`, {
                        state: { back: '/discover' },
                      })
                    }
                    footerOverride={<></>}
                  />
                )),
                <EmptyState
                  button={{
                    text: 'See all venues',
                    onClick: () => navigate('/venues'),
                  }}
                />,
              ]}
            />
          ) : (
            <Skeleton height={120} />
          )}

          {!!promoters?.length && (
            <>
              <TitleToolbar
                text="Suggested Promoters 🪩"
                size="md"
                action={
                  EXPANDED_PROMOTERS_ENABLED ? (
                    <Button
                      variant="flat"
                      onClick={() => {
                        navigate('/promoters');
                      }}
                    >
                      View All
                    </Button>
                  ) : undefined
                }
              />
              <HorizontalScroll
                items={[
                  ...promoters.map((promoter) => (
                    <PromoterCard
                      key={promoter._id}
                      promoter={promoter}
                      onClick={() =>
                        navigate(`/promoters/${promoter._id}`, {
                          state: { back: '/discover' },
                        })
                      }
                      footerOverride={<></>}
                    />
                  )),
                  ...(EXPANDED_PROMOTERS_ENABLED
                    ? [
                        <EmptyState
                          button={{
                            text: 'Explore all promoters',
                            onClick: () => navigate('/promoters'),
                          }}
                        />,
                      ]
                    : []),
                ]}
              />
            </>
          )}
        </Screen.Content>
        <DateRangePickerPopup isVisible={datePopupVisible} />
      </Screen>
    </>
  );
};
