import { InputHTMLAttributes, forwardRef } from 'react';

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'checked' | 'onChange' | 'type'> {
  isChecked: boolean;
  onChange: (updatedValue: boolean) => void;
}

export const Toggle = forwardRef<HTMLInputElement, InputProps>(
  ({ isChecked, onChange, ...props }, ref) => {
    return (
      <label className="toggle">
        <input
          ref={ref}
          type="checkbox"
          checked={isChecked}
          onChange={(e) => onChange(e.target.checked)}
          {...props}
        />
        <span className="toggle-icon"></span>
      </label>
    );
  },
);
