import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import PhoneInput, { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input/input';

import API from '@api/API';

import { CritiqueQuestion } from '@queries/CritiqueQueries';

import { useAuthWithMagicLink } from '@mutations/MagicLinkMutations';

import { useParams, useQueryParams, useRouter } from '@router/index';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { Input } from '@components/form/Input';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { QuickPickQuestionOptions } from '@screens/QuickPicksScreen/QuickPicksScreen';

const useNoAuthQuickPickQuestion = ({
  critiqueQuestionId,
  cityId,
}: {
  critiqueQuestionId: string;
  cityId: string;
}) => {
  return useQuery({
    queryKey: ['critiqueQuestion', { critiqueQuestionId }],
    queryFn: () =>
      API.get<{ critiqueQuestion: CritiqueQuestion }>(
        `/v1/cities/${cityId}/quick-picks/${critiqueQuestionId}`,
      ),
    enabled: !!critiqueQuestionId,
  });
};

const useAnswerNoAuthQuickPickQuestionMutation = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({
      cityId,
      critiqueQuestionId,
      ...payload
    }: {
      cityId: string;
      critiqueQuestionId: string;
      code: string;
      phoneNumber: string;
      answer: string;
    }) => API.post(`/v1/cities/${cityId}/quick-picks/${critiqueQuestionId}`, payload),
  });

  return {
    isSubmitting: isPending,
    answerNoAuthQuickPickQuestionAsync: mutateAsync,
  };
};

export const NoAuthQuickPickScreen: FC = () => {
  const cityId = '655a5558b7b0405ad3468b89'; // '658874efbe4bf4fe70a3d972',
  const { critiqueQuestionId } = useParams<{ critiqueQuestionId: string }>();
  const { isFetched, data } = useNoAuthQuickPickQuestion({
    critiqueQuestionId,
    cityId,
  });

  const [selectedOption, setSelectedOption] = useState<string>();
  const currentCritiqueQuestion = data?.critiqueQuestion;
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [didSubmitPhone, setDidSubmitPhone] = useState(false);
  const [code, setCode] = useState('');
  const { navigate } = useRouter();

  const handleStartAuth = async () => {
    setPopupIsVisible(true);
  };
  const { authWithMagicLinkAsync } = useAuthWithMagicLink();
  const handleSubmitPhone = async () => {
    authWithMagicLinkAsync({ phoneNumber });
    setDidSubmitPhone(true);
  };
  const handleSendNewCode = async () => {
    authWithMagicLinkAsync({ phoneNumber });
  };

  const { isSubmitting, answerNoAuthQuickPickQuestionAsync } =
    useAnswerNoAuthQuickPickQuestionMutation();
  const handleSubmitFinal = async () => {
    navigate('/noauth/download');
    setPopupIsVisible(false);
    answerNoAuthQuickPickQuestionAsync({
      cityId,
      critiqueQuestionId,
      code,
      phoneNumber,
      answer: selectedOption,
    });
  };
  useEffect(() => {
    if (phoneNumber && code.length === 6 && !isSubmitting) {
      handleSubmitFinal();
    }
  }, [phoneNumber, code]);

  return (
    <Screen name="No Auth Quick Pick Screen" hideToolbar>
      <Screen.Content>
        {isFetched ? (
          <>
            <Card>
              <Card.Body>
                <TitleToolbar className="my-0" text={currentCritiqueQuestion?.question} size="md" />
              </Card.Body>
            </Card>
            <QuickPickQuestionOptions
              showSelected={!!selectedOption}
              currentCritiqueQuestion={currentCritiqueQuestion}
              value={selectedOption}
              handleSelectOption={setSelectedOption}
              onSubmit={handleStartAuth}
            />
          </>
        ) : (
          <Skeleton height={100} />
        )}
      </Screen.Content>
      <Popup isVisible={popupIsVisible} onClose={() => setPopupIsVisible(false)}>
        <Popup.Content>
          <div className="login-popup text-center">
            {didSubmitPhone && phoneNumber ? (
              <>
                <span className="login-popup_icon">
                  <FontAwesomeIcon faStyle="far" name="comment-dots" />
                </span>
                <p className="fs-3">
                  Please enter the code sent to{' '}
                  <strong className="d-inline-block">{formatPhoneNumber(phoneNumber)}</strong>
                </p>
                <Input
                  className="code-field"
                  focusOnMount
                  disabled={isSubmitting}
                  placeholder="------"
                  inputMode="numeric"
                  onChange={(e) => setCode(e.target.value)}
                  maxLength={6}
                />
                <div className="mt-2">
                  <Button variant="flat" color="neutral" onClick={handleSendNewCode}>
                    Send a new code
                  </Button>
                </div>
              </>
            ) : (
              <>
                <p className="fs-2 mb-0">
                  <strong>Are you a robot?</strong>
                </p>
                <p className="mt-0">
                  Please enter your phone number to validate that you are a human.
                </p>
                <PhoneInput
                  placeholder="Phone Number"
                  country="US"
                  onChange={setPhoneNumber}
                  inputComponent={Input}
                />
                <Button
                  className="mt-2"
                  roundness="rounded"
                  disabled={!isValidPhoneNumber(phoneNumber)}
                  onClick={handleSubmitPhone}
                >
                  I'm human
                </Button>
                <p className="text-center">
                  {'By verifying your phone number, you agree to our '}
                  <strong onClick={() => openExternalUrl('https://gaggl.app/terms')}>Terms</strong>
                  {' and '}
                  <strong onClick={() => openExternalUrl('https://gaggl.app/privacy-policy')}>
                    Privacy Policy
                  </strong>
                  .
                </p>
              </>
            )}
          </div>
        </Popup.Content>
      </Popup>
    </Screen>
  );
};
