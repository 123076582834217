import { EventHandler, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import ChipComponent from 'framework7/components/chip';
import { requestAnimationFrame } from 'framework7/shared/utils';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import { useDialog } from '@components/layout/Dialog/useDialog';

import { Chip } from './Chip';
import './ChipRadioOptions.scss';

interface CoreChipRadioOptionsProps {
  options: { disabled?: boolean; value: string; label: string; showBadge?: boolean }[];
  addCustomOptions?: boolean;
}

interface SingleSelectChipRadioOptionsProps extends CoreChipRadioOptionsProps {
  value: string;
  multiSelect?: false;
  onChange: (value: string) => void;
}

interface MultiSelectChipRadioOptionsProps extends CoreChipRadioOptionsProps {
  value: string[];
  multiSelect: true;
  onChange: (value: string[]) => void;
}

type ChipRadioOptionsProps = SingleSelectChipRadioOptionsProps | MultiSelectChipRadioOptionsProps;

export const ChipRadioOptions = ({
  value,
  multiSelect,
  addCustomOptions,
  options,
  onChange,
}: ChipRadioOptionsProps) => {
  const [customOptionInputIsVisible, setCustomOptionInputIsVisible] = useState(false);
  const [customOptionInputValue, setCustomOptionInputValue] = useState('');
  const [customOptions, setCustomOptions] = useState<string[]>([]);
  const customInputRef = useRef<HTMLInputElement>(null);
  const addClickRef = useRef(false);
  const { openAlertDialog } = useDialog();

  const valueRecord = useMemo(() => {
    if (multiSelect) {
      return Object.fromEntries(value.map((v) => [v, true]));
    }

    return { [value as string]: true };
  }, [multiSelect, value]);

  useEffect(() => {
    if (multiSelect) {
      const optionsRecord = Object.fromEntries(options.map((o) => [o.value, o.label]));
      const initialCustomOptions = value.filter((selectedValue) => !optionsRecord[selectedValue]);
      setCustomOptions(initialCustomOptions);
    }
  }, []);

  const handleChange = (newValue: string) => {
    if (multiSelect) {
      const newValueRecord = { ...valueRecord };
      newValueRecord[newValue] = !newValueRecord[newValue];
      onChange(Object.keys(newValueRecord).filter((v) => newValueRecord[v]));
    } else {
      (onChange as any)(newValue);
    }
  };

  const handleCustomInputBlur = () => {
    if (customOptionInputValue) {
      const wordCount = customOptionInputValue.trim().split(' ').length;
      if (wordCount > 4) {
        openAlertDialog({
          title: 'Uh oh!',
          text: 'Custom tags cannot be more than 4 words. If you want to add a longer message, it might be better as a description or review.',
        });
        return;
      }

      setCustomOptions((prev) => [...prev, customOptionInputValue]);
      handleChange(customOptionInputValue);
      setCustomOptionInputValue('');
    }

    setCustomOptionInputIsVisible(false);

    if (addClickRef.current) {
      addClickRef.current = false;
      requestAnimationFrame(() => {
        setCustomOptionInputIsVisible(true);
        customInputRef.current?.focus();
      });
    }
  };

  useLayoutEffect(() => {
    if (customOptionInputIsVisible) {
      customInputRef.current?.focus();
    }
  }, [customOptionInputIsVisible]);

  return (
    <div className="chip-radio-options">
      {options.map((option) => (
        <Chip
          className="chip-radio-option"
          key={option.value}
          color="neutral"
          disabled={option.disabled}
          variant={valueRecord[option.value] ? 'default' : 'outlined'}
          label={option.label}
          onClick={() => handleChange(option.value)}
          notificationBadge={option.showBadge}
        />
      ))}
      {customOptions.map((customOption, i) => (
        <Chip
          className="chip-radio-option"
          key={`${customOption}-${i}`}
          color="neutral"
          variant={valueRecord[customOption] ? 'default' : 'outlined'}
          label={customOption}
          onClick={() => handleChange(customOption)}
        />
      ))}
      {customOptionInputIsVisible && (
        <Chip
          className="chip-radio-option chip-radio-option_custom"
          color="neutral"
          variant="default"
          label={
            <input
              ref={customInputRef}
              type="text"
              onChange={(e) => setCustomOptionInputValue(e.target.value)}
              onBlur={handleCustomInputBlur}
            />
          }
        />
      )}
      {addCustomOptions && (
        <div
          onMouseDown={() => {
            addClickRef.current = true;
          }}
          onTouchStart={() => {
            addClickRef.current = true;
          }}
        >
          <Chip
            className="chip-radio-option"
            color="neutral"
            variant="outlined"
            startIcon={<LineAwesomeIcon name="plus" />}
            label="Add Custom"
            onClick={() => {
              setCustomOptionInputIsVisible(true);
            }}
          />
        </div>
      )}
    </div>
  );
};
