import { CSSProperties, ComponentProps, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Card as ReactBootstrapCard } from 'react-bootstrap';

import { motion } from 'framer-motion';

import './Card.scss';

interface CardProps extends ComponentProps<typeof motion.div> {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

const Card = ({ className, ...otherProps }: CardProps) => (
  <motion.div className={`card d-flex flex-column ${className ? className : ''}`} {...otherProps} />
);

Card.Img = ReactBootstrapCard.Img;

interface CardHeaderProps {
  className?: string;
  avatar?: ReactElement;
  title: string;
  subtitle?: string;
  tagline?: string;
  lineClamp?: boolean;
  action?: ReactNode;
  onTitleClick?: () => void;
  onClick?: () => void;
}
const CardHeader = ({
  className = '',
  avatar,
  title,
  subtitle,
  tagline,
  lineClamp = true,
  action,
  onTitleClick,
  onClick,
}: CardHeaderProps) => {
  const content = (
    <div className={`d-flex flex-column ${lineClamp ? 'line-clamp' : ''}`} onClick={onTitleClick}>
      {tagline && <h3>{tagline}</h3>}
      <h1>{title}</h1>
      {subtitle && <h2>{subtitle}</h2>}
    </div>
  );

  return (
    <div
      className={`card-header d-flex flex-row justify-content-start ${className}`}
      onClick={onClick}
    >
      {avatar && <div className="card-header-avatar">{avatar}</div>}
      {content}
      {action && <div className="card-header-action ml-auto">{action}</div>}
    </div>
  );
};
CardHeader.Title = (props) => (
  <div className={`header-title ${props.className ? props.className : ''}`}> {props.children} </div>
);
CardHeader.Action = (props) => (
  <div className={`header-action ${props.className ? props.className : ''}`}>{props.children}</div>
);

Card.Header = CardHeader;

interface CardBodyProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}
Card.Body = ({ className = '', children, style, onClick }: CardBodyProps) => (
  <div className={`card-body ${className}`} style={style} onClick={onClick}>
    {children}
  </div>
);
Card.Footer = (props) => (
  <div className={`card-footer mt-auto ${props.className ? props.className : ''}`}>
    {props.children}
  </div>
);

export default Card;
