import { Chip, Link, List, ListItem, Popover, f7ready } from 'framework7-react';
import { FC, MouseEventHandler, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Popover as PopoverType } from 'framework7/types';

import { BasicRating } from '@queries/CritiqueQueries';

import { CritiqueDocumentMutationPayload } from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';

import { CritiqueOverlay } from './CritiqueOverlay';
import { BASIC_RATING_RECORD } from './CritiqueOverlayConstants';
import './PrimaryCritiqueCountChips.scss';

const COLOR_RECORD = {
  like: 'green',
  neutral: 'yellow',
  dislike: 'red',
};

interface PrimaryCritiqueCountChipsProps {
  hideCounts?: boolean;
  disableOverlay?: boolean;
  controlSelectedRating?: boolean;
  defaultValue?: BasicRating;
  counts: {
    like: number;
    neutral: number;
    dislike: number;
  };
  onMoreClick?: () => void;
  onSubmit: (payload: CritiqueDocumentMutationPayload) => Promise<void>;
}

export const PrimaryCritiqueCountChips: FC<PrimaryCritiqueCountChipsProps> = ({
  hideCounts,
  disableOverlay,
  controlSelectedRating,
  defaultValue,
  counts,
  onMoreClick,
  onSubmit,
}) => {
  const divRef = useRef<any>();
  const popoverRef = useRef<PopoverType.Popover>();
  const { track } = useAnalytics();

  const [selectedRating, setSelectedRating] = useState<BasicRating>();
  const [critiqueOverlayIsVisible, setCritiqueOverlayIsVisible] = useState(false);

  useLayoutEffect(() => {
    f7ready((f7) => {
      popoverRef.current = f7.popover.create({
        el: divRef.current,
        on: {
          closed: () => {},
        },
      });
    });
  }, []);

  const handleClickChip =
    (rating: string): MouseEventHandler =>
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      track(`Click ${rating} chip`);
      setSelectedRating(rating as BasicRating);
      if (disableOverlay) {
        onSubmit({
          primaryCritique: rating as BasicRating,
        });
      } else {
        popoverRef.current?.open(e.target as HTMLElement);
      }
    };

  const handleCritiqueOverlayOpen: MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setCritiqueOverlayIsVisible(true);
    popoverRef.current?.close();
  };

  const handleSubmit = async (payload: CritiqueDocumentMutationPayload) => {
    setCritiqueOverlayIsVisible(false);
    onSubmit(payload);
  };

  const value = selectedRating ?? defaultValue;

  if (onMoreClick && value) {
    const { Icon } = BASIC_RATING_RECORD[value];

    return (
      <div className="primary-critique-count-chips">
        <Chip
          className="more-click-chip"
          text={
            (
              <div
                className={`d-flex align-items-center color-${COLOR_RECORD[value]}`}
                style={{ width: '100%' }}
              >
                <span>More</span>
                <span className="ps-2 ml-auto" style={{ fontSize: 10 }}>
                  <FontAwesomeIcon name="chevron-right" />
                </span>
              </div>
            ) as any
          }
          onClick={() => {
            track(`Click more chip`);
            onMoreClick();
          }}
          style={{ width: 100 }}
        >
          <div slot="media" className={`rating-${value}-color color-${COLOR_RECORD[value]}`}>
            <Icon isSelected />
          </div>
        </Chip>
      </div>
    );
  }

  return (
    <>
      <div
        className={`primary-critique-count-chips d-flex gap-2 ${hideCounts ? 'hide-counts' : ''}`}
      >
        {Object.entries(BASIC_RATING_RECORD).map(([rating, { Icon }]) => (
          <Chip
            key={rating}
            className={controlSelectedRating && value && rating !== value ? 'opacity-25' : ''}
            text={hideCounts ? undefined : String(counts?.[rating] ?? 0)}
            onClick={handleClickChip(rating)}
          >
            <div slot="media" className={`rating-${rating}-color color-${COLOR_RECORD[rating]}`}>
              <Icon isSelected={controlSelectedRating ? rating === value : true} />
            </div>
          </Chip>
        ))}
      </div>

      {!disableOverlay && (
        <>
          {createPortal(
            <div ref={divRef} className="popover">
              <div className="popover-arrow"></div>
              <div className="popover-inner">
                <List>
                  <ListItem link="#" onClick={handleCritiqueOverlayOpen}>
                    Critique
                  </ListItem>
                </List>
              </div>
            </div>,
            document.getElementById('framework7-root'),
          )}

          <CritiqueOverlay
            isVisible={critiqueOverlayIsVisible}
            primaryRating={selectedRating}
            onSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};
