import { FC, useMemo } from 'react';
import { DateRangePicker as ImportedDateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// theme css file
import * as dayjs from 'dayjs';

import './DateRangePicker.scss';

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface DateRangePickerProps {
  showTimeSelect?: boolean;
  placeholder?: string;
  value: DateRange;
  onChange: (updatedValue: DateRange) => void;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({ value, onChange }) => {
  const ranges = useMemo(() => {
    const { startDate: startDateString, endDate: endDateString } = value ?? {};

    const startDate = dayjs(startDateString);
    const endDate = dayjs(endDateString);

    return [
      {
        startDate: startDate.isValid() ? startDate.toDate() : new Date(),
        endDate: endDate.isValid() ? endDate.toDate() : new Date(),
        key: 'selection',
      },
    ];
  }, [value]);

  return (
    <ImportedDateRangePicker
      staticRanges={[]}
      inputRanges={[]}
      ranges={ranges}
      onChange={({ selection }) => onChange(selection)}
      minDate={new Date()}
    />
  );
};
