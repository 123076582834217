import { FC, useState } from 'react';

import { FeedPost } from '@queries/PostQueries';

import IconButton from '@components/buttons/IconButton';
import { PostCard } from '@components/cards/PostCard/PostCard';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { ReportOverlay } from '@components/overlays/ReportOverlay/ReportOverlay';

interface PostPopupProps {
  isVisible: boolean;
  post?: FeedPost;
  onClose: () => void;
}

export const PostPopup: FC<PostPopupProps> = ({ isVisible, post, onClose }) => {
  const [reportOverlayIsVisible, setReportOverlayIsVisible] = useState(false);

  if (!post) {
    return <></>;
  }

  return (
    <>
      <Popup height="full" isVisible={isVisible} onClose={onClose}>
        <Popup.Content>
          <TitleToolbar
            text="Post Details"
            size="md"
            action={
              <IconButton
                variant="flat"
                color="neutral"
                onClick={() => setReportOverlayIsVisible(true)}
              >
                <FontAwesomeIcon faStyle="far" name="flag" />
              </IconButton>
            }
          />
          {post && <PostCard post={post} showComments showCommentField />}
        </Popup.Content>
      </Popup>
      <ReportOverlay
        isVisible={reportOverlayIsVisible}
        title="Report Post"
        content={<p>Are you sure you want to report this post?</p>}
        data={{
          collectionName: 'post',
          objectId: post._id,
        }}
        onClose={() => setReportOverlayIsVisible(false)}
      />
    </>
  );
};
