import { Link as F7Link, Toolbar, f7ready } from 'framework7-react';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const useManagedHistory = () => {
  const [backlinkUrl, setBacklinkUrl] = useState<string>();

  useEffect(() => {
    f7ready((f7) => {
      try {
        const mainRouter = f7.views.main.router;
        const previousPath = mainRouter.history[mainRouter.history.length - 2];

        setBacklinkUrl(previousPath);
      } catch (e) {}
    });
  }, []);

  return {
    backlinkUrl,
  };
};

export let historyStack: string[] = [];

export const useNavigate = () => {
  const navigate = (
    to: string,
    options?: { state?: any; animate?: boolean; transition?: string },
  ) => {
    const { animate = true, state, transition } = options ?? {};
    f7ready((f7) => {
      const mainRouter = f7.views.main.router;
      console.log(to, state);

      mainRouter.navigate(to, {
        animate,
        props: state,
      });

      if (state?.back) {
        f7.views.main.router.history.pop();
        f7.views.main.router.history.push(state.back);
      }
    });
  };

  return navigate;
};
export type NavigateFunction = ReturnType<typeof useNavigate>;

export const useRouter = () => {
  const navigate = useNavigate();

  const back = (url?: string) => {
    f7ready((f7) => {
      const mainRouter = f7.views.main.router;
      mainRouter.back(url, url ? { force: true } : undefined);
      // historyStack.pop();
    });
  };

  return { navigate, back };
};

export const useStoredBackUrl = () => {
  return {
    storedBackUrl: historyStack.length > 1 ? historyStack[historyStack.length - 2] : undefined,
  };
};

interface LocationContextValue<LocationState = {}> {
  pathname: string;
  previousPathName?: string;
  state: LocationState;
}
export const LocationContext = createContext<LocationContextValue>({
  pathname: '',
  state: {},
});

export const useLocation = <LocationState extends {}>() => {
  const location = useContext<LocationContextValue<LocationState>>(LocationContext as any);
  // const [state, setState] = useState<LocationState>({} as LocationState);

  // useEffect(() => {
  //   f7ready((f7) => {
  //     const mainRouter = f7.views.main.router;
  //     setState(mainRouter.currentRoute.props as LocationState);
  //   });
  // }, []);

  return location;
};

export const useParams = <T extends unknown>(): T => {
  const [params, setParams] = useState<T>({} as T);

  useEffect(() => {
    f7ready((f7) => {
      const mainRouter = f7.views.main.router;
      const currentParams = mainRouter.currentRoute.params;
      setParams(currentParams as T);
    });
  }, []);

  return params;
};

interface LinkProps {
  className?: string;
  to: string;
}
export const Link: FC<PropsWithChildren<LinkProps>> = () => {
  return <></>;
};

export const withTabbar =
  <P extends {}>(Component: FC<P>) =>
  (props: P) => {
    return (
      <>
        <Component {...props} />
        <Toolbar tabbar bottom>
          <F7Link href="/events" text="Tab 1" animate={false} />
          <F7Link href="/venues" text="Tab 2" animate={false} />
          <F7Link href="/profile" text="Tab 3" animate={false} />
        </Toolbar>
      </>
    );
  };

export const useQueryParams = <T extends {}>() => {
  const [queryParams, setQueryParams] = useState<T>({} as T);

  useEffect(() => {
    f7ready((f7) => {
      const mainRouter = f7.views.main.router;
      const currentQueryParams = mainRouter.currentRoute.query;
      setQueryParams(currentQueryParams as T);
    });
  }, []);

  return queryParams;
};

export const useHasBack = () => {
  const [hasBack, setHasBack] = useState(false);

  useEffect(() => {
    f7ready((f7) => {
      const mainRouter = f7.views.main.router;
      console.log(mainRouter);
    });
  });
  return hasBack;
};
