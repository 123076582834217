import { ComponentType, FC, useState } from 'react';

import Button from '@components/buttons/Button';

import { PromoterCritiquePopup } from '@screens/promoter/PromoterCritiquePopup';
import { VenueCritiquePopup } from '@screens/venue/VenueCritiquePopup/VenueCritiquePopup';

import { EntertainerCritiquePopupButton } from './RatingActivityCard';

const POPUP_RECORD: Record<string, ComponentType<any>> = {
  venue: VenueCritiquePopup,
  promoter: PromoterCritiquePopup,
};

export const CritiquePopupButton: FC<{
  document: { _id: string; title: string };
  documentType: string;
}> = ({ document, documentType }) => {
  const [isVisible, setIsVisible] = useState(false);

  if (documentType === 'entertainer') {
    return <EntertainerCritiquePopupButton entertainerId={document._id} />;
  }

  const PopupComponent = POPUP_RECORD[documentType];

  if (PopupComponent) {
    return (
      <>
        <Button
          className="py-2 px-3"
          size="sm"
          roundness="rounded"
          variant="smooth"
          color="neutral"
          onClick={() => setIsVisible(true)}
        >
          Details
        </Button>
        <PopupComponent
          selectedVenue={isVisible ? document : undefined}
          selectedPromoter={isVisible ? document : undefined}
          onClose={() => setIsVisible(false)}
        />
      </>
    );
  }

  return <></>;
};
