import { FC, useState } from 'react';

import { useUserOnboardingMutation } from '@mutations/UserMutations';

import { useNavigate } from '@router/index';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { FormLabel } from '@components/form/FormLabel';
import { Input } from '@components/form/Input';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { ONBOARDING_STEP_COUNT } from '../OnboardingConstants';
import { DRAG_NAMES } from './DragNames';
import './OnboardingBasicInfoScreen.scss';

const getRandomDragName = () => {
  return DRAG_NAMES[Math.floor(Math.random() * DRAG_NAMES.length)];
};

const AUTH_IS_MAGIC_LINK = false;

export const OnboardingBasicInfoScreen: FC<{}> = () => {
  const defaultDisplayName = getRandomDragName();
  const [displayName, setDisplayName] = useState<string>(defaultDisplayName);
  const { updateUserOnboardingAsync, isSubmitting } = useUserOnboardingMutation();
  const navigate = useNavigate();

  const handleGoToNextStep = async () => {
    if (!displayName) {
      return;
    }

    await updateUserOnboardingAsync({
      displayName,
    });

    navigate('/onboarding/interests');
  };

  return (
    <Screen
      name="Onboarding Basic Info Screen"
      className="onboarding-basic-info-screen"
      hideToolbar
    >
      <Screen.Content>
        <TitleToolbar
          className="title-toolbar-upper"
          text={`Step 1 of ${ONBOARDING_STEP_COUNT}`}
          size="sm"
        />
        <TitleToolbar text="Basic Info" />
        <p>
          Welcome to Gaggl! We wanna get you into the app ASAP, but we also want you to have a good
          time. So we have a couple steps to help us get to know you better. To get started, please
          choose a display name. If you don't want to use your real name, that's totally cool! Just
          pick something fun. We chose a random drag name for you, but you can change it if you
          want.
        </p>
        <FormLabel text="Display Name" />
        <Input defaultValue={defaultDisplayName} onChange={(e) => setDisplayName(e.target.value)} />
        <div className="mt-2">
          <Button disabled={isSubmitting} roundness="rounded" onClick={handleGoToNextStep}>
            Next
          </Button>
        </div>
        {AUTH_IS_MAGIC_LINK && (
          <p className="text-center">
            {'By clicking "Next" and signing up, you agree to our '}
            <strong onClick={() => openExternalUrl('https://gaggl.app/terms')}>Terms</strong>
            {' and '}
            <strong onClick={() => openExternalUrl('https://gaggl.app/privacy-policy')}>
              Privacy Policy
            </strong>
            .
          </p>
        )}
      </Screen.Content>
    </Screen>
  );
};
