import { FC, useMemo, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';

import { useVenueAndPromoterRankings } from '@queries/CritiqueQueries';
import { useTopEntertainersQuery } from '@queries/EntertainerQueries';
import { Venue } from '@queries/VenueQueries';

import { useAnalytics } from '@hooks/useAnalytics';
import { CurrentCity, useCitiesQuery } from '@hooks/useCurrentUserLocation';

import { useRouter } from '@router/index';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { Screen } from '@components/layout/Screen';

import { VenueRankingsContent } from '@screens/CommunityScreen/VenueRankingsContent';

export const VenueRankingsScreen: FC<{ cityId: string }> = ({ cityId }) => {
  const { back } = useRouter();
  const { cities } = useCitiesQuery();
  const { track } = useAnalytics();
  const { userIsLoggedIn } = useAppContext();
  const { refetchVenueAndPromoterRankings } = useVenueAndPromoterRankings(cityId);

  const currentCity = useMemo<CurrentCity | undefined>(() => {
    if (!cities?.length || !cityId) {
      return null;
    }

    return cities.find((city) => city._id === cityId);
  }, [cities, cityId]);

  const metaTitle = `${currentCity?.name ?? 'Local'} Venue Leaderboard | Gaggl`;
  const introMessage = `Check out the current leaderboard for LGBTQ Venues in ${currentCity?.name ?? 'your city'}. View your the venue profiles and use the buttons to update your rating for each venue and support your favorites!`;

  return (
    <Screen
      name="Venue Rankings Screen"
      hideToolbar={!userIsLoggedIn}
      disableLocationPermissionGate
      headerProps={{
        left: !userIsLoggedIn ? (
          <Button
            startIcon={<FontAwesomeIcon name="chevron-left" />}
            variant="smooth"
            color="neutral"
            roundness="rounded"
            className="py-2"
            onClick={() => back('/')}
          >
            Events
          </Button>
        ) : undefined,
        title: <Screen.GagglTitle />,
        right: (
          <Button
            variant="outlined"
            roundness="rounded"
            size="sm"
            color="neutral"
            startIcon={<FontAwesomeIcon className="fs-6" faStyle="fab" name="instagram" />}
            className="opacity-90"
            onClick={() => {
              track('Click follow on Instagram');
              openExternalUrl('https://www.instagram.com/gaggl.app/');
            }}
          >
            gaggl.app
          </Button>
        ),
      }}
      onReenter={() => {
        refetchVenueAndPromoterRankings();
      }}
      metaData={{
        title: metaTitle,
        description: introMessage,
        image: 'https://my.gaggl.app/assets/images/drag-leaderboard.png',
      }}
    >
      <Screen.Content maxWidth={425}>
        {currentCity && (
          <VenueRankingsContent
            city={currentCity}
            back={`/noauth/cities/${currentCity._id}/venues/rankings`}
          />
        )}
      </Screen.Content>
    </Screen>
  );
};
