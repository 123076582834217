import { FC, useMemo } from 'react';

import * as dayjs from 'dayjs';

import { useEventQuery } from '@queries/EventQueries';
import { useCurrentUser, useInterestQuery } from '@queries/UserQueries';

import { useLocation, useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import EventCard, {
  EventActions,
  EventHeader,
  EventImage,
  PromoterHeader,
} from '@components/cards/EventCard';
import { VenueCard } from '@components/cards/VenueCard';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

interface InterestScreenProps {
  back?: string;
}

export const InterestScreen: FC<InterestScreenProps> = ({ back = '/discover' }) => {
  const { tagId } = useParams<{ tagId: string }>();
  const { interestIsReady, interest } = useInterestQuery(tagId);
  const navigate = useNavigate();

  const handleViewEvent = (eventId: string) => {
    navigate(`/events/${eventId}`);
  };

  return (
    <Screen
      name="Interest Screen"
      headerProps={{ back: { to: back }, title: interest?.tag?.title }}
    >
      <Screen.Content>
        <TitleToolbar text="Events" size="md" />
        {interestIsReady && !!interest?.events?.length && (
          <HorizontalScroll
            items={interest?.events.map((event) => (
              <EventCard
                key={event._id}
                event={event}
                onClick={() => handleViewEvent(event._id)}
                showFooter={false}
              />
            ))}
          />
        )}
        <TitleToolbar text="Venues" size="md" />
        {interestIsReady && !!interest?.venues?.length && (
          <HorizontalScroll
            items={interest?.venues?.map((venue) => <VenueCard key={venue._id} venue={venue} />)}
          />
        )}
      </Screen.Content>
    </Screen>
  );
};
