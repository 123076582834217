import { FC, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useAppContext } from '@contexts/AppContext';
import { motion } from 'framer-motion';

import { useCritiqueQuestionsQuery } from '@queries/CritiqueQueries';

import { useAnswerCritiqueQuestionMutation } from '@mutations/CritiqueMutations';

import Button from '@components/buttons/Button';
import Card from '@components/layout/Card/Card';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { QuickPicksLinkBanner } from '@screens/HomeScreen/HomeScreen';
import { QuickPickQuestionOptions } from '@screens/QuickPicksScreen/QuickPicksScreen';

import { ONBOARDING_STEP_COUNT } from '../OnboardingConstants';
import './OnboardingQuickPicksScreen.scss';

const QuickPicksLinkBannerOverlay: FC = () => {
  const bannerRef = useRef<HTMLDivElement>(null);
  const [startAnimation, setStartAnimation] = useState(false);
  const [animationIsComplete, setAnimationIsComplete] = useState(false);
  const [bannerStartingDimensions, setBannerStartingDimensions] = useState<DOMRect>();
  const [bannerEndingDimensions, setBannerEndingDimensions] = useState<DOMRect>();
  const { navigateAfterLogin } = useAppContext();

  const getBannerPosition = () => {
    setBannerStartingDimensions(bannerRef.current?.getBoundingClientRect());
    const interval = setInterval(() => {
      const homeScreenLinkBanner = document.getElementById('home-screen-quick-picks');
      if (homeScreenLinkBanner) {
        clearInterval(interval);
        setTimeout(() => {
          setBannerEndingDimensions(homeScreenLinkBanner.getBoundingClientRect());
        }, 500);
      }
    }, 500);
  };

  useLayoutEffect(() => {
    navigateAfterLogin();
    requestAnimationFrame(() => {
      getBannerPosition();
    });
  }, []);

  if (animationIsComplete) {
    return <></>;
  }

  return (
    <>
      <motion.div
        id="quick-picks-onboarding-transition"
        initial={{ opacity: 0 }}
        animate={{ opacity: startAnimation ? 0 : 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          ref={bannerRef}
          initial={{ opacity: 1 }}
          animate={
            startAnimation && bannerStartingDimensions && bannerEndingDimensions
              ? { opacity: 0 }
              : undefined
          }
        >
          <p>
            And now your score has gone up, and hopefully you got a fun laugh out of answering that
            question.
          </p>
          <p>
            You can find this button below on your Home screen to answer more of these questions and
            continue to help the Gaggl community
          </p>
          <div className="mt-2 mb-3">
            <QuickPicksLinkBanner disableLink />
          </div>
          <Button disabled={false} roundness="rounded" onClick={() => setStartAnimation(true)}>
            Finish walkthrough
          </Button>
        </motion.div>
      </motion.div>
      {startAnimation && bannerStartingDimensions && bannerEndingDimensions && (
        <motion.div
          initial={{
            top: bannerStartingDimensions.top,
            left: bannerStartingDimensions.left,
            width: bannerStartingDimensions.width,
            height: bannerStartingDimensions.height,
          }}
          animate={{
            top: bannerEndingDimensions.top,
            left: bannerEndingDimensions.left,
            width: bannerEndingDimensions.width,
            height: bannerEndingDimensions.height,
          }}
          onAnimationComplete={() => {
            setAnimationIsComplete(true);
          }}
          transition={{ duration: 1 }}
          style={{
            position: 'fixed',
            zIndex: 9001,
          }}
        >
          <QuickPicksLinkBanner disableLink />
        </motion.div>
      )}
    </>
  );
};

export const OnboardingQuickPicksScreen = () => {
  const [selectedVenueId, setSelectedVenueId] = useState<string>();
  const { critiqueQuestions = [], critiqueQuestionsAreReady } = useCritiqueQuestionsQuery();
  const { answerCritiqueQuestionAsync } = useAnswerCritiqueQuestionMutation();
  const [questionIsVisible, setQuestionIsVisible] = useState(false);
  const [overlayIsVisible, setOverlayIsVisible] = useState(false);

  const [currentCritiqueQuestion] = critiqueQuestions;

  const handleSubmit = async () => {
    if (!currentCritiqueQuestion || !selectedVenueId) {
      return;
    }

    setOverlayIsVisible(true);

    await answerCritiqueQuestionAsync({
      critiqueQuestionId: currentCritiqueQuestion._id,
      answer: selectedVenueId,
    });
  };

  return (
    <>
      <Screen name="Onboarding Quick Picks Screen" hideToolbar>
        <Screen.Content>
          {questionIsVisible ? (
            <>
              {currentCritiqueQuestion ? (
                <>
                  <Card>
                    <Card.Body>
                      <TitleToolbar
                        className="my-0"
                        text={currentCritiqueQuestion?.question}
                        size="md"
                      />
                    </Card.Body>
                  </Card>
                  <QuickPickQuestionOptions
                    showSelected={!!selectedVenueId}
                    currentCritiqueQuestion={currentCritiqueQuestion}
                    value={selectedVenueId}
                    handleSelectOption={setSelectedVenueId}
                    onSubmit={handleSubmit}
                    submitText="Confirm"
                  />
                </>
              ) : (
                <Skeleton height={300} />
              )}
            </>
          ) : (
            <>
              <TitleToolbar
                className="title-toolbar-upper mb-0"
                text={`Step 4 of ${ONBOARDING_STEP_COUNT}`}
                size="sm"
              />
              <TitleToolbar className="my-0" text="Quick Picks " />
              <p>
                Critiquing has a lot of options that might feel overwhelming when you're just
                getting started. To make it easier and more fun for you, we also have "Quick Picks"
                where we ask you questions and your response is like a mini-critique and you can
                still continue to help the community!
              </p>
              <Button
                disabled={false}
                roundness="rounded"
                onClick={() => setQuestionIsVisible(true)}
              >
                Give it a try
              </Button>
            </>
          )}
        </Screen.Content>
        {overlayIsVisible &&
          createPortal(<QuickPicksLinkBannerOverlay />, document.getElementById('framework7-root'))}
      </Screen>
    </>
  );
};
