import {
  ChangeEventHandler,
  FormEvent,
  KeyboardEvent,
  MutableRefObject,
  forwardRef,
  useRef,
} from 'react';

import { Input, InputProps } from '../Input';

interface SearchFieldProps extends InputProps {
  onSubmit: () => void;
}

export const SearchField = forwardRef<any, SearchFieldProps>(
  ({ onSubmit, ...props }, externalRef) => {
    const internalRef = useRef<HTMLInputElement>();
    const ref = externalRef ?? internalRef;

    const handleSubmit = (e?: FormEvent) => {
      e?.preventDefault();
      e?.stopPropagation();

      if ((ref as any).current) {
        (ref as any).current.blur();
      }

      onSubmit();
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    };

    return (
      <form role="search" onSubmit={handleSubmit}>
        <Input
          ref={ref}
          inputMode="search"
          {...props}
          onKeyDown={handleKeyDown}
          onSubmit={handleSubmit}
        />
      </form>
    );
  },
);
