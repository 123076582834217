import { FC, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import { keyBy, truncate } from 'lodash';

import { useOutgoingMessageByIncomingMessageId } from '@queries/index';

import { useOneSignal } from '@hooks/useOneSignal';

import { useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import './EventsScreen.scss';

const EventCard: FC<{ event: any; onClick: () => void }> = ({ event, onClick }) => {
  return (
    <Card key={event._id} onClick={onClick}>
      {event.img && (
        <div className="event-img">
          <AsyncImage className="bg-img" src={event.img} alt={event.title} />
          <AsyncImage className="primary-img" src={event.img} alt={event.title} />
        </div>
      )}
      <Card.Header title={event.title} subtitle={event.timeDetails} />
      <Card.Body>
        {mapStringToParagraphs(
          truncate(event.description, {
            length: 140,
            separator: '...',
          }),
        )}
      </Card.Body>
    </Card>
  );
};

export const SmsResponseScreen: FC<{}> = () => {
  const { incomingMessageId } = useParams<{ incomingMessageId?: string }>();
  const { outgoingMessageIsReady, outgoingMessage } = useOutgoingMessageByIncomingMessageId({
    incomingMessageId,
  });
  const navigate = useNavigate();
  const { initOneSignal } = useOneSignal();

  useEffect(() => {
    initOneSignal();
  }, []);

  const handleViewEvent = (eventId: string) => {
    navigate(`/events/${eventId}`, {
      state: { back: `/response-to/${incomingMessageId}` },
    });
  };

  const eventVenueNames = keyBy(outgoingMessage.events, 'venueName');
  const venuesWithoutEvents = outgoingMessage.venues.filter(
    (venue) => !eventVenueNames[venue.title],
  );

  return (
    <Screen name="Sms Response Screen">
      <Screen.Content>
        {outgoingMessageIsReady ? (
          <>
            {!!outgoingMessage.events.length && (
              <>
                <TitleToolbar text="Events" />
                <div>
                  {outgoingMessage.events.map((event) => (
                    <EventCard
                      key={event._id}
                      event={event}
                      onClick={() => handleViewEvent(event._id)}
                    />
                  ))}
                </div>
              </>
            )}
            {!!venuesWithoutEvents.length && (
              <>
                <TitleToolbar text="Venues" />
                <div>
                  {venuesWithoutEvents.map((venue) => (
                    <Card key={venue._id}>
                      <Card.Header title={venue.title} />
                      <Card.Body>
                        <p>{venue.description ?? venue.tagline}</p>
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <TitleToolbar text="Events" />
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          </>
        )}
      </Screen.Content>
    </Screen>
  );
};
