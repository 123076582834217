import { FC } from 'react';

import './FormLabel.scss';

interface FormLabelProps {
  text: string;
}

export const FormLabel: FC<FormLabelProps> = ({ text }) => {
  return (
    <div className="form-label">
      <label>{text}</label>
    </div>
  );
};
