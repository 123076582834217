import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

function getOrdinal(n: number): string {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function formatMonthlyDay(date: string): string {
  const inputDate = dayjs(date);

  // Get the day of the week as a full name
  const weekday = inputDate.format('dddd');

  // Calculate the week of the month
  const startOfMonth = inputDate.startOf('month');
  const endOfMonth = inputDate.endOf('month');
  let weekCount = 0;

  for (let day = startOfMonth; day.isSameOrBefore(endOfMonth); day = day.add(1, 'day')) {
    if (day.day() === inputDate.day()) {
      // Match the day of the week
      weekCount++;
    }
    if (day.isSame(inputDate, 'day')) {
      break;
    }
  }

  return `${getOrdinal(weekCount)} ${weekday}`;
}
