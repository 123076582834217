import { SkeletonBlock } from 'framework7-react';

import './Skeleton.scss';

export const Skeleton = ({ height }: { height: string | number }) => {
  return (
    <SkeletonBlock
      className="skeleton"
      height={typeof height === 'number' ? `${height}px` : height}
      width={'100%'}
      borderRadius="8px"
      tag="tag"
      effect="wave"
      style={{ opacity: 0.5 }}
    />
  );
};
