import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from '@api/API';

import { useCurrentUserGagglRankQuery } from '@queries/CritiqueQueries';
import { useFollowsQuery, useUserInterestsQuery } from '@queries/UserQueries';

interface FollowUnfollowPayload {
  documentType: 'promoter' | 'venue' | 'tag';
  documentId: string;
}

export const useFollowMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: FollowUnfollowPayload) => API.post(`/v1/user/follow`, payload),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: useFollowsQuery.queryKey(),
      });
      queryClient.invalidateQueries({
        queryKey: useUserInterestsQuery.queryKey(),
      });
    },
  });

  return {
    isSubmitting,
    followAsync: mutateAsync,
  };
};

export const useUnfollowMutation = () => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: (payload: FollowUnfollowPayload) => API.post(`/v1/user/unfollow`, payload),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: useFollowsQuery.queryKey(),
      });
      queryClient.invalidateQueries({
        queryKey: useUserInterestsQuery.queryKey(),
      });
    },
  });

  return {
    isSubmitting,
    unfollowAsync: mutateAsync,
  };
};
