import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { sortBy } from 'lodash';

import {
  InstagramAccount,
  InstagramAccountsPageCityData,
  useCityInstagramAccountsPageQuery,
  useInstagramAccountsPageQuery,
} from '@admin/queries/InstagramAccountsQueries';

import { useIsVisible } from '@hooks/useIsVisible';

import Button from '@components/buttons/Button';
import { LazyLoad } from '@components/layout/LazyLoad';
import { HeadCell, RenderTable, TableRow } from '@components/table/RenderTable';

import { mapStringToParagraphs } from '../../../utilities/mapStringToParagraphs';
import {
  useCreateInstagramAccountMutation,
  useUpdateInstagramAccountMutation,
} from '../../mutations/InstagramAccountsMutations';
import { useUpdateVenueMutation } from '../../mutations/VenuesMutations';
import './InstagramAccountsPage.scss';

const TYPES = ['entertainer', 'promoter', 'venue'];

const ManualInstagramAccountRow = ({ cityId }: { cityId: string }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [username, setUsername] = useState<string>('');
  const [type, setType] = useState<'venue' | 'promoter' | 'entertainer'>('entertainer');
  const { isSubmitting, createInstagramAccountAsync } = useCreateInstagramAccountMutation();

  const handleUpdateInstagramAccount = async () => {
    await createInstagramAccountAsync({
      username,
      type,
      cityId,
    });

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <div>
      <div className="create-instagram-account-row">
        <input ref={inputRef} type="text" onChange={(event) => setUsername(event.target.value)} />
        <select value={type} onChange={(event) => setType(event.target.value as any)}>
          {TYPES.map((typeOption) => (
            <option key={typeOption} value={typeOption}>
              {typeOption}
            </option>
          ))}
        </select>
        <button disabled={isSubmitting} onClick={handleUpdateInstagramAccount}>
          Create
        </button>
      </div>
    </div>
  );
};

const InstagramAccountRow = ({
  cityId,
  cities,
  account,
  setCurrentAccount,
}: {
  cityId: string;
  cities: InstagramAccountsPageCityData[];
  account: InstagramAccount;
  setCurrentAccount: (account: InstagramAccount | undefined) => void;
}) => {
  const [didAction, setDidAction] = useState(false);
  const { isSubmitting, updateInstagramAccountAsync } = useUpdateInstagramAccountMutation();
  const [assignedCityId, setAssignedCityId] = useState<string>(cityId);
  const [existingVenueListIsVisible, setExistingVenueListIsVisible] = useState(false);
  const [existingVenueId, setExistingVenueId] = useState<string>('');
  const [showMissingIdMessage, setShowMissingIdMessage] = useState(false);

  if (didAction) {
    return <></>;
  }

  const handleUpdateInstagramAccount = async (type: 'venue' | 'promoter' | 'entertainer') => {
    if (!account._id) {
      setShowMissingIdMessage(true);
      return;
    }

    await updateInstagramAccountAsync({
      _id: account._id,
      type,
      cityId: assignedCityId,
    });
    setCurrentAccount(undefined);
    setDidAction(true);
  };

  const handleApplyToVenue = async () => {
    await updateInstagramAccountAsync({
      _id: account._id,
      type: 'venue',
      cityId: assignedCityId,
      venueId: existingVenueId ? existingVenueId : undefined,
    });
    setCurrentAccount(undefined);
    setDidAction(true);
  };

  const assignedCity = cities.find(({ city }) => city._id === assignedCityId);

  return (
    <LazyLoad>
      <div key={account._id} style={{ marginBottom: 64 }}>
        <div className="instagram-account-row">
          <h3
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '.5rem',
            }}
          >
            <span style={{ color: account.isPossibleDragAccount ? '#ff0000' : undefined }}>
              {account.username}
            </span>
            <div>
              <select
                value={assignedCityId}
                onChange={(event) => setAssignedCityId(event.target.value)}
              >
                {cities.map(({ city }) => (
                  <option key={city._id} value={city._id}>
                    {city.name}
                  </option>
                ))}
              </select>
              <button onClick={() => setCurrentAccount(account)} style={{ width: 64 }}>
                View
              </button>
            </div>
          </h3>
          {account.isPossibleDragAccount && !!account.locationNames?.length && (
            <div>
              {account.locationNames.map((locationName) => (
                <span
                  key={locationName}
                  style={{
                    border: '1px solid rgba(0,0,0,.3)',
                    borderRadius: 4,
                    padding: '0.25rem 0.5rem',
                    margin: '0.1rem',
                    display: 'inline-block',
                    fontSize: 10,
                  }}
                >
                  {locationName}
                </span>
              ))}
            </div>
          )}
          <div className="overflow-hidden">{mapStringToParagraphs(account.biography)}</div>
        </div>
        <div style={{ display: 'flex', gap: '.5rem' }}>
          <button
            style={{ padding: '4px 0' }}
            onClick={() => handleUpdateInstagramAccount('entertainer')}
          >
            Entertainer
          </button>
          <button
            style={{ padding: '4px 0' }}
            onClick={() => handleUpdateInstagramAccount('promoter')}
          >
            Promoter
          </button>
          <button style={{ padding: '4px 0' }} onClick={() => setExistingVenueListIsVisible(true)}>
            Venue
          </button>
          <button style={{ padding: '4px 0' }}>Ignore</button>
        </div>
        {existingVenueListIsVisible && (
          <div className="mt-2">
            <select
              value={existingVenueId}
              onChange={(event) => setExistingVenueId(event.target.value)}
            >
              <option value="">-- New Venue --</option>
              {sortBy(assignedCity.venues, 'title').map(({ _id, title }) => (
                <option key={_id} value={_id}>
                  {title}
                </option>
              ))}
            </select>
            <button disabled={isSubmitting} onClick={handleApplyToVenue}>
              Submit
            </button>
          </div>
        )}
        {showMissingIdMessage && (
          <div>
            <div style={{ color: 'red' }}>Missing ID</div>
            <pre>{JSON.stringify(account, null, 2)}</pre>
          </div>
        )}
      </div>
    </LazyLoad>
  );
};

const VenueRow = ({ venue }: { venue: InstagramAccountsPageCityData['venues'][number] }) => {
  const [isHidden, setIsHidden] = useState(false);
  const [username, setUsername] = useState<string>('');
  const { isSubmitting, createInstagramAccountAsync } = useCreateInstagramAccountMutation();
  const handleUpdateInstagramAccount = async () => {
    await createInstagramAccountAsync({
      username,
      type: 'venue',
      cityId: venue.cityId,
      venueId: venue._id,
    });
  };

  const { updateVenueAsync } = useUpdateVenueMutation();
  const handleExclude = async () => {
    await updateVenueAsync({
      _id: venue._id,
      skipEventProcessing: true,
      isActive: true,
    });
    setIsHidden(true);
  };

  if (isHidden) {
    return <></>;
  }

  return (
    <div className="city-venue-row">
      <div className={`city-venue-row-title`}>
        <strong>{venue.title}</strong>
        <span onClick={handleExclude}>Exclude</span>
      </div>
      <div className="city-venue-row-instagram">
        <input
          type="text"
          placeholder={venue.contact?.instagram}
          onChange={(e) => setUsername(e.target.value)}
        />
        <button disabled={!username || isSubmitting} onClick={handleUpdateInstagramAccount}>
          Save
        </button>
      </div>
    </div>
  );
};

const CurrentCityOverlay = ({
  currentCity: initialCurrentCityData,
  cities,
  onClose,
}: {
  currentCity: InstagramAccountsPageCityData;
  cities: InstagramAccountsPageCityData[];
  onClose: () => void;
}) => {
  const { cityInstagramAccountData } = useCityInstagramAccountsPageQuery({
    cityId: initialCurrentCityData.city._id,
    isEnabled: true,
  });

  const [currentAccount, setCurrentAccount] = useState<InstagramAccount>();
  const [tab, setTab] = useState<'overview' | 'venues'>('overview');

  const bottomScrollElementRef = useRef<HTMLDivElement>(null);
  const { isVisible: isBottomScrollElementVisible } = useIsVisible(bottomScrollElementRef);
  const [page, setPage] = useState(1);
  const visibleAccountsList = useMemo(
    () => (cityInstagramAccountData?.accounts ?? []).slice(0, page * 10),
    [cityInstagramAccountData?.accounts, page],
  );

  useEffect(() => {
    if (
      isBottomScrollElementVisible &&
      visibleAccountsList.length < cityInstagramAccountData?.accounts?.length
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [isBottomScrollElementVisible]);

  return (
    <div
      className="current-city-overlay"
      style={{
        paddingTop: 'calc(env(safe-area-inset-top) + 5rem)',
        paddingBottom: 'calc(env(safe-area-inset-bottom) + 1rem)',
      }}
    >
      <div className="current-city-overlay-header">
        <h2>{initialCurrentCityData.city.name}</h2>
        <button onClick={onClose}>Close</button>
      </div>
      <ManualInstagramAccountRow cityId={initialCurrentCityData.city._id} />
      <div className="current-city-overlay-tabs">
        <button onClick={() => setTab('overview')}>Overview</button>
        <button onClick={() => setTab('venues')}>Venues</button>
      </div>
      {tab === 'venues' && (
        <div>
          {sortBy(initialCurrentCityData.venues, 'title')
            .filter(({ skipEventProcessing }) => !skipEventProcessing)
            .map((venue) => (
              <VenueRow key={venue._id} venue={venue} />
            ))}
        </div>
      )}
      {tab === 'overview' && (
        <>
          {!!visibleAccountsList.length && (
            <div>
              {visibleAccountsList.map((account, i) => (
                <InstagramAccountRow
                  key={account._id ?? (account as any)?.id ?? i}
                  cityId={initialCurrentCityData.city._id}
                  cities={cities}
                  account={account}
                  setCurrentAccount={setCurrentAccount}
                />
              ))}
            </div>
          )}
          <div ref={bottomScrollElementRef} />
          {currentAccount && (
            <div className="instagram-overlay">
              <button onClick={() => setCurrentAccount(undefined)}>Close</button>
              <iframe src={`https://instagram.com/${currentAccount.username}/embed/`} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const headCells: HeadCell<TableRow<InstagramAccountsPageCityData>>[] = [
  {
    id: 'cityName',
    label: 'City',
    TableBodyCell: ({ row }) => <td>{row.city.name}</td>,
  },
  {
    id: 'events',
    label: 'Events',
    TableBodyCell: ({ row }) => <td>{row.events.length}</td>,
  },
  {
    id: 'venues',
    label: 'Venues',
    TableBodyCell: ({ row }) => <td>{row.venues.length}</td>,
  },
  // {
  //   id: 'relatedProfiles',
  //   label: 'Related Profiles',
  //   TableBodyCell: ({ row }) => <td>{row.accounts.length}</td>,
  // },
];

export const InstagramAccountsPage: FC<{}> = () => {
  const [isViewingDragList, setIsViewingDragList] = useState(false);
  const { instagramAccountsPageData } = useInstagramAccountsPageQuery({ isEnabled: true });
  const [currentCity, setCurrentCity] = useState<InstagramAccountsPageCityData>();

  const rows = useMemo(() => {
    return (
      instagramAccountsPageData?.cities
        ?.filter(({ city }) => city.eventsAreEnabled)
        ?.map((cityData) => ({
          ...cityData,
          key: cityData.city._id,
        })) ?? []
    );
  }, [instagramAccountsPageData]);

  return (
    <div
      className="instagram-accounts-page"
      style={{
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
      }}
    >
      <div className="p-3">
        <Button color="primary" onClick={() => (document.location = '/home')}>
          Back
        </Button>
        <Button
          className="my-3"
          color="primary"
          onClick={() => setIsViewingDragList((prevValue) => !prevValue)}
        >
          {isViewingDragList ? 'Cities' : 'Drag Performers'}
        </Button>
        {isViewingDragList ? (
          <></>
        ) : (
          <>
            <div>
              <RenderTable
                headCells={headCells}
                rows={rows}
                onRowClick={(row) => setCurrentCity(row)}
              />
            </div>
            {currentCity && (
              <CurrentCityOverlay
                currentCity={currentCity}
                cities={sortBy(instagramAccountsPageData?.cities ?? [], ({ city }) =>
                  city.name.toLowerCase(),
                )}
                onClose={() => setCurrentCity(undefined)}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
