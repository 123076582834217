import { Button, Segmented } from 'framework7-react';
import { FC } from 'react';

interface ButtonFilterProps {
  className?: string;
  options: { value: string; label: string }[];
  selected: string;
  onChange: (updatedSelected: string) => void;
}

export const ButtonFilter: FC<ButtonFilterProps> = ({ className, options, selected, onChange }) => {
  return (
    <Segmented strong tag="p" className={className}>
      {options.map((option) => (
        <Button
          key={option.value}
          active={selected === option.value}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </Segmented>
  );
};
