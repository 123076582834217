//router
// import IndexRouters from "./router/index"
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { App as F7App, Link, Page, Toolbar, View, Views } from 'framework7-react';
import { ComponentProps, memo, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { initializeQueryClient, queryClient } from '@contexts/queryClient';

import { fetchCurrentEntertainer } from '@queries/EntertainerQueries';
import { fetchCurrentPromoter } from '@queries/PromoterQueries';
import { fetchCurrentUser, fetchTokenIsValid } from '@queries/UserQueries';

import { AuthGateProvider } from '@screens/LogInScreen/AuthGateContext';

import './App.scss';
import { AdminApp } from './admin/AdminApp';
import { AppProvider } from './contexts/AppContext';
import { CoreLayout } from './layouts/CoreLayout';
import { UserScoreNotification } from './layouts/UserScoreNotification/UserScoreNotification';
// import state selectors
import { LocationContext } from './router';
import { f7routes } from './router/router';

if (process.env.REACT_APP_ENV !== 'dev') {
  Sentry.init({
    dsn: 'https://cd5a749d99f1bd0804ace582bc57f640@o4506910036393984.ingest.us.sentry.io/4506910049763328',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/gaggl\.app\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const f7params: ComponentProps<typeof F7App> = {
  name: 'Gaggl',
  routes: f7routes,
  theme: 'ios',
  darkMode: true,
};

function App() {
  const [previousPathName, setPreviousPathName] = useState<string>();
  const [pathname, setPathname] = useState(document.location.pathname);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    initializeQueryClient()
      .then(() => {
        setIsReady(true);
      })
      .catch(() => {
        setIsReady(true);
      });
  }, []);

  return (
    <F7App
      className="gaggl color-deeppurple"
      {...f7params}
      on={{
        routeChange: (newRoute, prevRoute, router) => {
          setPathname((prev) => {
            requestAnimationFrame(() => {
              setPreviousPathName(prev);
            });
            return newRoute.path;
          });
        },
      }}
    >
      {isReady && (
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <AuthGateProvider>
              <UserScoreNotification />
              <LocationContext.Provider
                value={{
                  pathname,
                  previousPathName,
                  state: {},
                }}
              >
                <CoreLayout />
              </LocationContext.Provider>
            </AuthGateProvider>
          </AppProvider>
        </QueryClientProvider>
      )}
    </F7App>
  );
}

export default memo(() => {
  if (document.location.pathname.startsWith('/admin')) {
    return <AdminApp />;
  }

  return <App />;
});
