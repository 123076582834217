import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';

import * as dayjs from 'dayjs';
import { capitalize } from 'lodash';

import API from '@api/API';

import { PublicBookingOpportunity } from '@queries/PromoterQueries';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import { Chip } from '@components/chips/Chip';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

const useSuperAdminBookingOpportunityQuery = (bookingOpportunityId: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useSuperAdminBookingOpportunityQuery.queryKey(bookingOpportunityId),
    queryFn: () => useSuperAdminBookingOpportunityQuery.queryFn(bookingOpportunityId),
    enabled: !!bookingOpportunityId,
  });

  return {
    bookingOpportunityIsReady: isFetched,
    bookingOpportunity: data?.bookingOpportunity,
    refetchBookingOpportunity: refetch,
  };
};
useSuperAdminBookingOpportunityQuery.queryKey = (bookingOpportunityId: string) => [
  'super-admin',
  'booking-opportunities',
  bookingOpportunityId,
];
useSuperAdminBookingOpportunityQuery.queryFn = (bookingOpportunityId: string) =>
  API.get<{ bookingOpportunity: PublicBookingOpportunity }>(
    `/v1/super-admin/booking-opportunities/${bookingOpportunityId}`,
  );

const useSuperAdminUpdateBookingOpportunityMutation = (bookingOpportunityId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (payload: { status: 'open' | 'rejected' }) =>
      API.post(`/v1/super-admin/booking-opportunities/${bookingOpportunityId}`, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: useSuperAdminBookingOpportunityQuery.queryKey(bookingOpportunityId),
      });
    },
  });

  return {
    updateBookingOpportunityAsync: mutation.mutateAsync,
    updateBookingOpportunityIsSubmitting: mutation.isPending,
  };
};

export const SuperAdminManageBookingOpportunityScreen: FC<{ bookingOpportunityId: string }> = ({
  bookingOpportunityId,
}) => {
  const { bookingOpportunityIsReady, bookingOpportunity } =
    useSuperAdminBookingOpportunityQuery(bookingOpportunityId);

  const { updateBookingOpportunityIsSubmitting, updateBookingOpportunityAsync } =
    useSuperAdminUpdateBookingOpportunityMutation(bookingOpportunityId);

  return (
    <Screen
      name="SuperAdmin Manage Booking Opportunity Screen"
      headerProps={{ back: { to: '/' }, title: 'Manage Opportunity' }}
      bottomToolbar={
        <Screen.BottomToolbar maxWidth={425}>
          <div className="d-grid grid-cols-2 gap-2">
            <Button
              roundness="rounded"
              disabled={
                updateBookingOpportunityIsSubmitting || bookingOpportunity?.status !== 'pending'
              }
              onClick={() => {
                updateBookingOpportunityAsync({ status: 'open' });
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              roundness="rounded"
              disabled={
                updateBookingOpportunityIsSubmitting || bookingOpportunity?.status !== 'pending'
              }
              onClick={() => {
                updateBookingOpportunityAsync({ status: 'rejected' });
              }}
            >
              Ignore
            </Button>
          </div>
        </Screen.BottomToolbar>
      }
    >
      <Screen.Content maxWidth={425}>
        {bookingOpportunityIsReady ? (
          <div className="grid">
            <div>
              <TitleToolbar
                className="title-toolbar-welcome"
                text={bookingOpportunity.title}
                action={<Chip label={capitalize(bookingOpportunity.status)} />}
              />
              <div className="d-flex gap-2">
                <div>
                  <span className="me-1">
                    <LineAwesomeIcon name="clock" />
                  </span>
                  {dayjs(bookingOpportunity.startDate).format('ddd, MMM D, YYYY')}
                </div>
                <div>
                  <span className="me-1">
                    <LineAwesomeIcon name="map-marker" />
                  </span>
                  {`${bookingOpportunity.venue.title}, ${bookingOpportunity.city.name}`}
                </div>
              </div>

              {mapStringToParagraphs(bookingOpportunity.details)}
            </div>
          </div>
        ) : (
          <Skeleton height={200} />
        )}
      </Screen.Content>
    </Screen>
  );
};
