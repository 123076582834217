import { FC, useEffect, useState } from 'react';

import { useVenueAndPromoterRankings } from '@queries/CritiqueQueries';
import { Venue } from '@queries/VenueQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useShareLink } from '@hooks/useShareLink';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';
import { RankingList } from '@screens/entertainer/EntertainerRankingsScreen/EntertainerRankingsScreen';
import { VenueCritiquePopup } from '@screens/venue/VenueCritiquePopup/VenueCritiquePopup';

export const VenueRankingsContent: FC<{ city: { _id: string; name: string }; back: string }> = ({
  city,
  back,
}) => {
  const {
    venueRankings = [],
    venueAndPromoterRankingsAreReady,
    refetchVenueAndPromoterRankings,
  } = useVenueAndPromoterRankings(city._id);
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { createAuthGateHandler } = useAuthGate();
  const [selectedVenue, setSelectedVenue] = useState<{ _id: string; title: string }>();

  useEffect(() => {
    refetchVenueAndPromoterRankings();
  }, []);

  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();
  const handleClickRating =
    (venueId: string) => async (payload: CritiqueDocumentMutationPayload) => {
      track('Click rate venue', {
        ...payload,
        venueId,
      });
      createAuthGateHandler(async () => {
        await critiqueDocumentAsync({
          ...payload,
          documentType: 'venues',
          documentId: venueId,
        });
      });
    };

  const introMessage = `Discover ${city.name}'s top venues with our current leaderboard. Browse venue profiles and use the rating buttons to share your feedback and show support for your favorite places!`;

  const { didCopy, shareLink } = useShareLink();
  const handleShareClick = async () => {
    track('Click share');
    await shareLink({
      url: `https://my.gaggl.app/noauth/cities/${city._id}/venues/rankings`,
    });
  };

  const handleClickVenue = (venue: { _id: string; title: string }) => {
    // setSelectedVenue(venue)
    navigate(`/venues/${venue._id}`, {
      state: {
        back,
      },
    });
  };

  return (
    <>
      <TitleToolbar
        className="mb-0"
        text={city ? `${city.name} LGBTQ Nightlife` : <Skeleton height={25} />}
        size="md"
        action={
          <Button
            variant="smooth"
            roundness="rounded"
            size="sm"
            color="neutral"
            startIcon={
              didCopy ? <FontAwesomeIcon name="check" /> : <FontAwesomeIcon name="share-square" />
            }
            className="opacity-90"
            onClick={handleShareClick}
          >
            {didCopy ? 'Copied!' : 'Share'}
          </Button>
        }
      />
      <p className="mt-1">{introMessage}</p>
      <TitleToolbar
        text="Leaderboard"
        size="sm"
        action={
          venueAndPromoterRankingsAreReady ? (
            <span>{`${venueRankings.length} Total`}</span>
          ) : (
            <Skeleton height={16} />
          )
        }
      />
      <RankingList
        isLoading={!venueAndPromoterRankingsAreReady}
        list={venueRankings}
        onClick={handleClickVenue}
        onClickRating={handleClickRating}
      />
      <VenueCritiquePopup
        selectedVenue={selectedVenue}
        back={back}
        onClose={() => setSelectedVenue(undefined)}
      />
    </>
  );
};
