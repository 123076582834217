import EmojiPicker from 'emoji-picker-react';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { sum } from 'lodash';
import pluralize from 'pluralize';

import { FeedPost, usePostReactionQuery } from '@queries/PostQueries';

import { useReactToPostMutation, useRespondToPostMutation } from '@mutations/PostMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useIsVisible } from '@hooks/useIsVisible';

import { useNavigate } from '@router/index';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { LazyLoad } from '@components/layout/LazyLoad';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { ReportOverlay } from '@components/overlays/ReportOverlay/ReportOverlay';

import { CritiquePopupButton } from '@screens/CommunityScreen/CritiquePopupButton';
import { CritiqueQuestionCard } from '@screens/CommunityScreen/CritiqueQuestionCard';
import { RatingActivityCard } from '@screens/CommunityScreen/RatingActivityCard';
import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import EventCard from '../EventCard';
import { EntertainerMediaPostCard } from './EntertainerMediaPostCard';
import { EventInterestPostCard } from './EventInterestPostCard';
import './PostCard.scss';

dayjs.extend(relativeTime);

const usePostReactions = (postId: string, isEnabled: boolean) => {
  const { postReactionsAreReady, postReactions } = usePostReactionQuery(postId, isEnabled);
  const { isSubmitting, reactToPostAsync } = useReactToPostMutation();
  const [currentUserEmojiReaction, setCurrentUserEmojiReaction] = useState<string>();
  const { createAuthGateHandler } = useAuthGate();

  useEffect(() => {
    setCurrentUserEmojiReaction(postReactions?.currentUserEmojiReaction);
  }, [postReactions?.currentUserEmojiReaction]);

  const updateEmojiReaction = async (emoji: string) => {
    createAuthGateHandler(async () => {
      if (isSubmitting) {
        return;
      }

      if (emoji === currentUserEmojiReaction) {
        setCurrentUserEmojiReaction(undefined);
        await reactToPostAsync({ emoji: null, postId });
      } else {
        setCurrentUserEmojiReaction(emoji);
        await reactToPostAsync({ emoji, postId });
      }
    });
  };

  return {
    postReactionsAreReady,
    currentUserEmojiReaction,
    postReactions,
    updateEmojiReaction,
  };
};

const SHOW_AGREE_DISAGREE = true;
const AGREE = 'agree';
const DISAGREE = 'disagree';
const EMOJI_REACTIONS = ['👎', '🥱', '❤️', '🔥', '😍'];
interface PostReactionsProps {
  postId: string;
}
const PostReactions: FC<PostReactionsProps> = ({ postId }) => {
  const { currentUserEmojiReaction, updateEmojiReaction } = usePostReactions(postId, false);

  return (
    <div className="post-reactions d-flex gap-2">
      {EMOJI_REACTIONS.map((emoji) => (
        <IconButton
          key={emoji}
          className={emoji === currentUserEmojiReaction ? 'selected' : ''}
          color="neutral"
          variant="smooth"
          onClick={() => updateEmojiReaction(emoji)}
        >
          <span>{emoji}</span>
        </IconButton>
      ))}
      {
        // <IconButton className="add-custom-reaction" color="neutral" variant="smooth">
        //   <FontAwesomeIcon name="plus" />
        // </IconButton>
      }
    </div>
  );
};

interface ReactionTextProps {
  postId: string;
}
const ReactionText: FC<ReactionTextProps> = ({ postId }) => {
  const { postReactions } = usePostReactions(postId, false);

  if (!postReactions) {
    return <></>;
  }

  const emojiReactionCounts = Object.fromEntries(
    Object.entries(postReactions?.emojiReactionCounts).filter(
      ([reaction]) => reaction !== AGREE && reaction !== DISAGREE,
    ),
  );

  const total = sum(Object.values(emojiReactionCounts ?? {}));

  if (!total) {
    return <></>;
  }

  return (
    <div className="reaction-text d-flex flex-row align-items-center gap-2">
      <div className="reaction-text_emoji-wrapper">
        {Object.keys(emojiReactionCounts).map((emoji, i) => (
          <div key={`${emoji}-${i}`} className="reaction-text_emoji">
            <div className="reaction-text_emoji-bg">{emoji}</div>
            <span>{emoji}</span>
          </div>
        ))}
      </div>
      <span>{`${total} ${pluralize('reaction', total)}`}</span>
    </div>
  );
};

export const PostCardHeaderWrapper: FC<{
  wrapperTitle: string;
  title: string;
  createdAt: string;
  action?: ReactNode;
}> = ({ wrapperTitle, title, createdAt, action }) => {
  return (
    <div className="reviewed-header-wrapper">
      <div className="reviewed-header">
        <span>{wrapperTitle}</span>
        <Card.Header
          avatar={<LetterAvatar letter={findFirstAlphaCharacter(title)} />}
          title={title}
          subtitle={dayjs(createdAt).fromNow()}
          action={action}
        />
      </div>
    </div>
  );
};

const PostAgreeDisagreeButtons: FC<{ postId: string }> = ({ postId }) => {
  const { currentUserEmojiReaction, postReactionsAreReady, postReactions, updateEmojiReaction } =
    usePostReactions(postId, false);

  const agreeCount = postReactions?.emojiReactionCounts?.[AGREE] ?? 0;
  const disagreeCount = postReactions?.emojiReactionCounts?.[DISAGREE] ?? 0;
  const agreeDisagreeTotal = agreeCount + disagreeCount;
  const agreeText = agreeDisagreeTotal
    ? `Agree (${((agreeCount / agreeDisagreeTotal) * 100).toFixed(0)}%)`
    : 'Agree';
  const disagreeText = agreeDisagreeTotal
    ? `Disagree (${((disagreeCount / agreeDisagreeTotal) * 100).toFixed(0)}%)`
    : 'Disagree';

  return (
    <div className="d-flex gap-2">
      {postReactionsAreReady ? (
        <>
          <Button
            roundness="rounded"
            color="neutral"
            variant={currentUserEmojiReaction === AGREE ? 'default' : 'outlined'}
            onClick={() => updateEmojiReaction(AGREE)}
          >
            {agreeText}
          </Button>
          <Button
            roundness="rounded"
            color="neutral"
            variant={currentUserEmojiReaction === DISAGREE ? 'default' : 'outlined'}
            onClick={() => updateEmojiReaction(DISAGREE)}
          >
            {disagreeText}
          </Button>
        </>
      ) : (
        <>
          <div className="w-100">
            <Skeleton height={37} />
          </div>
          <div className="w-100">
            <Skeleton height={37} />
          </div>
        </>
      )}
    </div>
  );
};

const POPUP_TYPE_RECORD = {
  entertainerPopup: 'entertainer',
  venuePopup: 'venue',
  promoterPopup: 'promoter',
  eventPopup: 'event',
};

interface PostCardProps {
  post: FeedPost;
  hideHeaderAction?: boolean;
  showDetails?: boolean;
  showComments?: boolean;
  showCommentField?: boolean;
  onContentClick?: () => void;
  onCommentClick?: () => void;
}

const DeprecatedPostCard = ({
  post,
  hideHeaderAction,
  showDetails = true,
  showComments: showInitialComments = false,
  showCommentField: showInitialCommentField = false,
  onContentClick,
  onCommentClick,
}: PostCardProps) => {
  const { userIsLoggedIn } = useAppContext();
  const postRef = useRef<HTMLDivElement>(null);
  const { isVisible } = useIsVisible(postRef);
  const { postReactions } = usePostReactions(post._id, isVisible);
  const [commentText, setCommentText] = useState<string>();
  const [showComments, setShowComments] = useState(showInitialComments);
  const [showCommentField, setShowCommentField] = useState(showInitialCommentField);
  const { isSubmitting: isRespondingToPost, respondToPostAsync } = useRespondToPostMutation();
  const [reportOverlayIsVisible, setReportOverlayIsVisible] = useState(false);
  const { track } = useAnalytics();
  const navigate = useNavigate();

  const handleShowCommentFieldClick = () => {
    if (userIsLoggedIn) {
      onCommentClick ? onCommentClick() : setShowCommentField(true);
    }
  };

  const handleSubmitComment = async () => {
    if (userIsLoggedIn) {
      respondToPostAsync({ text: commentText, parentPostId: post._id });
    }
  };

  const showEngagement = !!post.comments.length || postReactions?.currentUserEmojiReaction;

  return (
    <div className="post-card-wrapper" ref={postRef}>
      <Card className="post-card">
        {post.postHeader ? (
          <div className="reviewed-header-wrapper">
            <div className="reviewed-header">
              <span>{post.postHeader.sectionTitle}</span>
              <Card.Header
                avatar={<LetterAvatar letter={findFirstAlphaCharacter(post.postHeader.title)} />}
                title={post.postHeader.title}
                subtitle={dayjs(post.createdAt).fromNow()}
                action={
                  post.postHeader.action &&
                  !hideHeaderAction && (
                    <CritiquePopupButton
                      document={post.postHeader.action.document}
                      documentType={POPUP_TYPE_RECORD[post.postHeader.action.type]}
                    />
                  )
                }
              />
            </div>
          </div>
        ) : (
          <>
            {post.isAnonymousReview && post.reviewedVenue ? (
              <div className="reviewed-header-wrapper">
                <div className="reviewed-header">
                  <span>New review</span>
                  <Card.Header
                    avatar={
                      <LetterAvatar letter={findFirstAlphaCharacter(post.reviewedVenue.title)} />
                    }
                    title={post.reviewedVenue.title}
                    subtitle={dayjs(post.createdAt).fromNow()}
                    action={
                      <CritiquePopupButton document={post.reviewedVenue} documentType={'venue'} />
                    }
                  />
                </div>
              </div>
            ) : (
              <Card.Header
                avatar={<LetterAvatar letter={findFirstAlphaCharacter(post?.user?.displayName)} />}
                title={post?.user?.displayName}
                subtitle={dayjs(post.createdAt).fromNow()}
                action={
                  userIsLoggedIn ? (
                    <IconButton
                      variant="flat"
                      color="neutral"
                      onClick={() => setReportOverlayIsVisible(true)}
                    >
                      <FontAwesomeIcon faStyle="far" name="flag" />
                    </IconButton>
                  ) : undefined
                }
              />
            )}
          </>
        )}
        <Card.Body onClick={post.disableClick ? undefined : onContentClick}>
          {mapStringToParagraphs(post.text)}
        </Card.Body>
        {showDetails && post.event && (
          <Card.Body>
            <EventCard
              event={post.event}
              onClick={() => {
                navigate(`/events/${post.event._id}`);
              }}
              hideActions
              onCommentClick={handleShowCommentFieldClick}
            />
          </Card.Body>
        )}
        {showDetails && !post.isAnonymousReview && post.reviewedVenue && (
          <div className="reviewed-header-wrapper">
            <div className="reviewed-header">
              <span>Venue</span>
              <Card.Header
                avatar={<LetterAvatar letter={findFirstAlphaCharacter(post.reviewedVenue.title)} />}
                title={post.reviewedVenue.title}
                onClick={() => navigate(`/venues/${post.reviewedVenue._id}`)}
              />
            </div>
          </div>
        )}
        {!post.hideActions && (
          <Card.Footer>
            <div className="d-flex flex-column gap-2 w-100">
              {showEngagement && (
                <div className="d-flex flex-row justify-content-between">
                  <div>
                    <ReactionText postId={post._id} />
                  </div>
                  {!!post.comments.length && (
                    <div onClick={() => setShowComments((prevValue) => !prevValue)}>
                      {`${showComments ? 'Hide' : 'View'} ${post.comments.length} ${pluralize(
                        'comment',
                        post.comments.length,
                      )}`}
                    </div>
                  )}
                </div>
              )}
              {SHOW_AGREE_DISAGREE ? (
                <PostAgreeDisagreeButtons postId={post._id} />
              ) : (
                <div className="d-flex flex-row justify-content-between">
                  <PostReactions postId={post._id} />
                  <div>
                    <Button
                      variant="flat"
                      startIcon={<FontAwesomeIcon name="plus" />}
                      onClick={
                        showCommentField
                          ? () => setShowCommentField(false)
                          : handleShowCommentFieldClick
                      }
                    >
                      Comment
                    </Button>
                  </div>
                </div>
              )}
              {showCommentField && !SHOW_AGREE_DISAGREE && (
                <div>
                  <Textarea
                    onChange={setCommentText}
                    submitButton={{
                      text: 'Send',
                      disabled: isRespondingToPost,
                      onClick: handleSubmitComment,
                    }}
                  />
                </div>
              )}
            </div>
          </Card.Footer>
        )}
        {post.hideActions && post?.meta?.to && post?.meta?.buttonText && (
          <Card.Footer>
            <Button
              roundness="rounded"
              color="neutral"
              variant="smooth"
              onClick={() => {
                track('System message button click');
                if (post.meta.to?.[0] === '/') {
                  navigate(post.meta.to);
                } else {
                  openExternalUrl(post.meta.to);
                }
              }}
            >
              {post.meta.buttonText}
            </Button>
          </Card.Footer>
        )}
      </Card>

      {showComments && post.comments?.length > 0 && (
        <div className="comments-wrapper">
          {post.comments.map((comment) => (
            <DeprecatedPostCard key={comment._id} post={comment} />
          ))}
        </div>
      )}

      <ReportOverlay
        isVisible={reportOverlayIsVisible}
        title="Report Post"
        content={<p>Are you sure you want to report this post?</p>}
        data={{
          collectionName: 'post',
          objectId: post._id,
        }}
        onClose={() => setReportOverlayIsVisible(false)}
      />
    </div>
  );
};

const NonLazyPostCard = (
  props: Omit<PostCardProps, 'post'> & {
    post: any;
  },
) => {
  const { post } = props;

  if (post._id) {
    return <DeprecatedPostCard {...props} />;
  }

  if (post.type === 'critiqueQuestion') {
    return <CritiqueQuestionCard post={post} />;
  }

  if (post.type === 'ratingActivity') {
    return <RatingActivityCard post={post} />;
  }

  if (post.type === 'eventInterest') {
    return <EventInterestPostCard post={post} />;
  }

  if (post.type === 'challengeAvailable') {
    return <></>;
  }

  if (post.type === 'entertainerMedia') return <EntertainerMediaPostCard post={post} />;
};

export const PostCard = ({
  disableLazyLoad,
  ...props
}: Omit<PostCardProps, 'post'> & {
  post: any;
  disableLazyLoad?: boolean;
}) => {
  if (disableLazyLoad) {
    return <NonLazyPostCard {...props} />;
  }

  return (
    <LazyLoad offset={300} className="mb-3">
      <NonLazyPostCard {...props} />
    </LazyLoad>
  );
};
