import CryptoJS from 'crypto-js';

const { REACT_APP_API_URL: apiUrl = '' } = process.env;

const getHeaders = async () => {
  const defaultHeaders: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'API-Version': '1.1',
  };

  const token = await localStorage.getItem('token');

  if (token) {
    defaultHeaders['Authorization'] = `Bearer ${token}`;
  }

  return defaultHeaders;
};

function decryptMessage(encryptedMessage: string, secretKey: string) {
  const [iv, encrypted] = encryptedMessage.split(':');
  const keyHex = CryptoJS.enc.Hex.parse(secretKey);
  const ivHex = CryptoJS.enc.Hex.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Hex.parse(encrypted),
    },
    keyHex,
    {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    },
  );

  const decryptedMessage = decrypted.toString(CryptoJS.enc.Utf8);
  return decryptedMessage;
}

function deriveKey(password: string, salt: string, keyLength = 32) {
  const iterations = 100000; // Number of iterations for PBKDF2
  const digest = CryptoJS.algo.SHA256; // Hash function to use

  const keyMaterial = CryptoJS.PBKDF2(password, salt, {
    keySize: keyLength / 4, // Dividing by 4 because CryptoJS keySize is in words (4 bytes per word)
    iterations: iterations,
    hasher: digest,
  });

  return keyMaterial.toString(CryptoJS.enc.Hex);
}

const handleEncryptedResponse = async <ResponseBody>(response: Response) => {
  const data = await response.text();
  const [one, two, three] = data.split(':');

  const decrypted = decryptMessage(
    [two, three].join(':'),
    deriveKey(document.location.origin, one),
  );
  const parsed = JSON.parse(decrypted);

  return parsed as ResponseBody;
};

const handleResponse = async <ResponseBody>(response: Response) => {
  const isEncrypted = response.headers.get('X-Gaggl') === 'true';
  if (isEncrypted) {
    return handleEncryptedResponse(response);
  }

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json();
  }

  return {} as ResponseBody;
};

const API = {
  get: async <ResponseBody>(url: string): Promise<ResponseBody> => {
    const headers = await getHeaders();

    const response = await fetch(`${apiUrl}${url}`, {
      headers,
    });

    return handleResponse(response);
  },
  post: async <ResponseBody extends {}, RequestBody extends {}>(
    url: string,
    payload: RequestBody,
  ): Promise<ResponseBody> => {
    const headers = await getHeaders();
    const body = JSON.stringify(payload);

    const response = await fetch(`${apiUrl}${url}`, {
      method: 'POST',
      headers,
      body,
    });

    return handleResponse(response);
  },
  put: async <ResponseBody extends {}, RequestBody extends {}>(
    url: string,
    payload: RequestBody,
  ): Promise<ResponseBody> => {
    const headers = await getHeaders();
    const body = JSON.stringify(payload);

    const response = await fetch(`${apiUrl}${url}`, {
      method: 'PUT',
      headers,
      body,
    });

    return handleResponse(response);
  },

  delete: async <ResponseBody extends {}, RequestBody extends {}>(
    url: string,
    payload?: RequestBody,
  ): Promise<ResponseBody> => {
    const headers = await getHeaders();
    const body = JSON.stringify(payload);

    const response = await fetch(`${apiUrl}${url}`, {
      method: 'DELETE',
      headers,
      body,
    });

    return handleResponse(response);
  },
};

export default API;
