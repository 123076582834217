import { FC, useMemo } from 'react';

import dayjs from 'dayjs';

import { FeedEvent } from '@queries/EventQueries';
import { useSearchQuery } from '@queries/UserQueries';

import { useAnalytics } from '@hooks/useAnalytics';

import { useRouter } from '@router/index';

import { SendEntertainerBadgeButton } from '@components/buttons/SendEntertainerBadgeButton';
import { EventHeader } from '@components/cards/EventCard';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { List } from '@components/layout/List/List';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

const EventEntertainerBadgeCard: FC<{ event: FeedEvent }> = ({ event }) => {
  return (
    <Card>
      <Card.Header
        className="pb-3"
        title={event.title}
        subtitle={
          (
            <h2 className="d-grid mt-1">
              <div>
                <span className="me-1">
                  <LineAwesomeIcon name="clock" />
                </span>
                {event.start}
              </div>
              <div>
                <span className="me-1">
                  <LineAwesomeIcon name="map-marker" />
                </span>
                {event.venue.title}
              </div>
            </h2>
          ) as any
        }
      />
      <div style={{ height: 1, width: '100%', background: 'rgba(255,255,255,.2)' }} />
      <List
        className="mt-0"
        listItems={event.entertainers.map((entertainer) => ({
          title: entertainer.title,
          endIcon: (
            <SendEntertainerBadgeButton
              entertainerId={entertainer._id}
              eventId={event._id}
              text="Send"
              buttonProps={{
                size: 'sm',
                className: 'px-3 py-2',
                roundness: 'rounded',
                variant: 'smooth',
                color: 'neutral',
              }}
            />
          ),
        }))}
      />
    </Card>
  );
};

export const useRecentEventsWithEntertainers = (cityId: string) => {
  const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
  const today = dayjs().format('YYYY-MM-DD');

  const { searchResultsAreReady, searchResults } = useSearchQuery({
    cityId,
    startDate: yesterday,
    endDate: today,
  });

  const pastEventsWithEntertainers = useMemo(() => {
    return (
      searchResults?.events?.filter((event) => event.isAfterStart && event.entertainers?.length) ??
      []
    );
  }, [searchResults?.events]);

  return { pastEventsWithEntertainersAreReady: searchResultsAreReady, pastEventsWithEntertainers };
};

export const PastEventsBadgesSection: FC<{ cityId: string }> = ({ cityId }) => {
  const { track } = useAnalytics();
  const { navigate } = useRouter();
  const { pastEventsWithEntertainersAreReady, pastEventsWithEntertainers } =
    useRecentEventsWithEntertainers(cityId);

  return (
    <>
      <TitleToolbar className="mb-1" text="Recent Spotlight Moments ⭐️" size="sm" />
      {/* <p className="mt-0">Send a badge to recognize someone's recent moment in the spotlight.</p> */}
      {pastEventsWithEntertainersAreReady ? (
        <>
          {pastEventsWithEntertainers.length ? (
            <HorizontalScroll
              items={pastEventsWithEntertainers.map((event) => (
                <EventEntertainerBadgeCard key={event._id} event={event} />
              ))}
            />
          ) : (
            <EmptyState
              title="No recent events"
              text="There are no recent events to award for, but you can still send badges to your favorite entertainers."
              button={{
                text: 'Send badges',
                onClick: () => {
                  track('Send badges (Recent events empty state)', { cityId });
                  navigate('/manage/entertainer/badges');
                },
              }}
            />
          )}
        </>
      ) : (
        <Skeleton height={148} />
      )}
    </>
  );
};
