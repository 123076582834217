import '../../assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import './FontAwesomeIcon.scss';

const Data_table = [
  {
    class: 'far fa-address-book',
    name: 'address-book',
  },
  {
    class: 'fa fa-envelope-open',
    name: 'envelope-open',
  },
  {
    class: 'fa fa-id-card',
    name: 'id-card',
  },
  {
    class: 'fab fa-telegram',
    name: 'telegram',
  },
  {
    class: 'fa fa-user-circle',
    name: 'user-circle',
  },
  {
    class: 'far fa-address-card',
    name: 'icard',
  },
  {
    class: 'fa fa-asterisk',
    name: 'aestrisk',
  },
  {
    class: 'fa fa-car',
    name: 'car',
  },
  {
    class: 'fa fa-bars',
    name: 'bars',
  },
  {
    class: 'fa fa-battery-full',
    name: 'battery-full',
  },
  {
    class: 'fab fa-bluetooth-b',
    name: 'bluetooth',
  },
  {
    class: 'fa fa-book',
    name: 'book',
  },
  {
    class: 'fa fa-bug',
    name: 'bug',
  },
  {
    class: 'fa fa-building',
    name: 'building',
  },
  {
    class: 'fa fa-calculator',
    name: 'calculator',
  },
  {
    class: 'fa fa-calendar',
    name: 'calendar',
  },
  {
    class: 'fa fa-camera',
    name: 'camera',
  },
  {
    class: 'far fa-comments',
    name: 'comment',
  },
  {
    class: 'fa fa-crop',
    name: 'crop',
  },
  {
    class: 'fa fa-download',
    name: 'download',
  },
  {
    class: 'fa fa-folder',
    name: 'folder',
  },
  {
    class: 'fa fa-gift',
    name: 'gift',
  },
  {
    class: 'fa fa-users',
    name: 'users',
  },
  {
    class: 'fa fa-hashtag',
    name: 'hashtag',
  },
  {
    class: 'fa fa-home',
    name: 'home',
  },
  {
    class: 'fa fa-lock',
    name: 'lock',
  },
  {
    class: 'fa fa-graduation-cap',
    name: 'graduation-cap',
  },
  {
    class: 'fa fa-paper-plane',
    name: 'paper-plane',
  },
  {
    class: 'fa fa-star',
    name: 'star',
  },
  {
    class: 'fa fa-tag',
    name: 'tag',
  },
  {
    class: 'fa fa-trash',
    name: 'trash',
  },
  {
    class: 'fa fa-upload',
    name: 'upload',
  },
  {
    class: 'fa fa-university',
    name: 'university',
  },
  {
    class: 'fa fa-wifi',
    name: 'wifi',
  },
  {
    class: 'fa fa-thumbs-up',
    name: 'thumbs-up',
  },
  {
    class: 'fa fa-train',
    name: 'train',
  },
  {
    class: 'fa fa-file',
    name: 'file',
  },
  {
    class: 'fab fa-snapchat',
    name: 'snapchat',
  },
  {
    class: 'fab fa-twitter',
    name: 'twitter',
  },
  {
    class: 'fab fa-wordpress',
    name: 'wordpress',
  },
  {
    class: 'fab fa-html5',
    name: 'html',
  },
  {
    class: 'fab fa-css3-alt',
    name: 'css',
  },
  {
    class: 'fa fa-beer',
    name: 'beer',
  },
  {
    class: 'far fa-comment',
    name: 'comment-outline',
  },
  {
    class: 'far fa-heart',
    name: 'heart-outline',
  },
];

const iconMap = Data_table.reduce((acc, cur) => {
  acc[cur.name] = cur.class;
  return acc;
}, {});

export const FontAwesomeIcon = ({
  faStyle = 'fa',
  name,
  className,
}: {
  faStyle?: string;
  name: string;
  className?: string;
}) => {
  const faClass = iconMap[name] ?? `${faStyle} fa-${name} ${className}`;
  return <i className={`fa-icon ${faClass}`} />;
};
