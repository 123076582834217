export const DRAG_NAMES = [
  'Sasha Fierce',
  'Carmen Mirage',
  'Venus Envy',
  'Roxie Hart',
  'Crystal Queer',
  'Scarlett Soiree',
  'Diva Divine',
  'Amber Alert',
  'Missy Majesty',
  'Cherry Bombshell',
  'Electra Glam',
  'Velvet Voltage',
  'Luna Loveglow',
  'Glitter Goddess',
  'Stella Stardust',
  'Phoenix Flame',
  'Nova Vortex',
  'Diamond Dazzle',
  'Gigi Glitz',
  'Trixie Tinsel',
  'Jade Jewel',
  'Misty Mystique',
  'Candy Couture',
  'Brooklyn Bling',
  'Holly Haze',
  'Jasmine Jamboree',
  'Bianca Blush',
  'Aurora Borealis',
  'Lola Luxe',
  'Sapphire Sparkle',
  'Valentina Velvet',
  'Ruby Rapture',
  'Madison Madness',
  'Glamour Galore',
  'Desiree Diamond',
  'Tiffany Twinkle',
  'Coco Crystal',
  'Zara Zest',
  'Ginger Glamazon',
  'Paris Pizzazz',
  'Kiki Khaos',
  'Marilyn Mirage',
  'Victoria Voltage',
  'Angel Aura',
  'Raven Royale',
  'Harmony Hues',
  'Mocha Madness',
  'Cleo Crystal',
  'Juno Jive',
  'Celeste Serenity',
  'Luscious Lulu',
  'Roxy Rocket',
  'Chanelle Charm',
  'Misty Mirage',
  'Dahlia Dazzle',
  'Magenta Moonbeam',
  'Lavender Lush',
  'Nikki Neon',
  'Starla Starlight',
  'Greta Glamour',
  'Crimson Crush',
  'Athena Allure',
  'Lola Lovegood',
  'Serena Spark',
  'Candy Kisses',
  'Remy Ritz',
  'Bella Bombshell',
  'Scarlet Sizzle',
  'Velma Voltage',
  'Luna Lux',
  'Sapphire Siren',
  'Roxanne Razzle',
  'Ginger Glitz',
  'Crystal Charm',
  'Jasmine Jazz',
  'Raven Rose',
  'Lola Luminance',
  'Misty Moonstone',
  'Ruby Rosebud',
  'Vivian Vogue',
  'Gigi Glitter',
  'Stella Starburst',
  'Harmony Heat',
  'Lavender Luster',
  'Carmen Crystal',
  'Jade Jester',
  'Roxy Roxanne',
  'Nova Noir',
  'Venus Velvet',
  'Holly Hologram',
  'Dahlia Dream',
  'Zara Zenith',
  'Luna Love',
  'Scarlett Stardust',
  'Sasha Shimmer',
  'Electra Ecstasy',
  'Valentina Vanity',
  'Xena Xtravaganza',
  'Zelda Zephyr',
  'Ursula Utopia',
  'Wanda Whimsy',
  'Yasmin Yass',
  'Zoe Zenith',
  'Abigail Absinthe',
  'Belladonna Blaze',
  'Cassandra Charisma',
  'Delilah Diva',
  'Esmeralda Enigma',
  'Fiona Fantasy',
  'Giselle Glitterati',
  'Hazel Haze',
  'Imogen Infinity',
  'Jasmine Jazz',
  'Kylie Khaos',
  'Lavender Love',
  'Monique Mirage',
  'Nadia Neon',
  'Ophelia Obscura',
  'Pandora Prism',
  'Quinn Quartz',
  'Raven Reign',
  'Samantha Starlight',
  'Tatiana Tempest',
  'Uma Unicorn',
  'Valerie Vixen',
  'Winona Wonder',
  'Xandra Xquisite',
  'Yvette Yum',
  'Zara Zephyr',
  'Adriana Amethyst',
  'Brianna Bliss',
  'Callista Carnival',
  'Diana Dazzle',
  'Eliza Elegance',
  'Felicity Fierce',
  'Greta Glitterbomb',
  'Heidi Hysteria',
  'Iris Infinity',
  'Jasmine Jester',
  'Kendra Kaleidoscope',
  'Layla Lush',
  'Morgan Mystery',
  'Nina Neon',
  'Olivia Opulence',
  'Penelope Paradise',
  'Quinn Queendom',
  'Roxanne Ruby',
  'Samantha Stardust',
  'Tiffany Topaz',
  'Ursula Universe',
  'Violet Velvet',
  'Wanda Wavelength',
  'Xena Xanadu',
  'Yasmin Yuletide',
  'Zelda Zara',
  'Amethyst Angel',
  'Bella Boudoir',
  'Candy Cyanide',
  'Dahlia Dior',
  'Elena Envy',
  'Fiona Fire',
  'Ginger Glimmer',
  'Harper Heart',
  'Ivy Illusion',
  'Jasmine Jewel',
  'Kylie Kiki',
  'Luna Luscious',
  'Mila Mirage',
  'Nina Nova',
  'Olive Ovation',
  'Penny Pixie',
  'Quinn Quickstep',
  'Roxie Radiance',
  'Savannah Stardust',
  'Trixie Twilight',
  'Ursula Uptown',
  'Vivian Vortex',
  'Willa Wild',
  'Xandra Xenon',
  'Yolanda Yum-Yum',
  'Zara Zephyr',
  'Amanda Ambrosia',
  'Bianca Belle',
  'Celeste Celestial',
  'Daisy Delight',
  'Ella Essence',
  'Frida Fantasy',
  'Giselle Glimpse',
  'Harmony Honey',
  'Isla Infinity',
  'Jade Jewel',
  'Karma Khaos',
  'Luna LaRue',
  'Misty Mirage',
  'Nadia Nectar',
  'Ophelia Opal',
  'Piper Prism',
  'Quinn Queenie',
  'Raven Ravenclaw',
  'Savannah Siren',
  'Trixie Temptation',
  'Ursula Umbrella',
  'Violet Vixen',
  'Willa Whimsy',
  'Xena Xylophone',
  'Yasmin Yellow',
  'Zara Zircon',
  'Adelaide Allure',
  'Bella Bizarre',
  'Carmen Casanova',
  'Dolly Diamond',
  'Eva Enchant',
  'Flora Fabulous',
  'Greta Glamazon',
  'Harper Hocus',
  'Ivy Icon',
  'Jasmine Jazz',
  'Kiki Karmic',
  'Lola Love',
  'Mabel Mystique',
  'Nina Noir',
  'Opal Obsession',
  'Poppy Pizzazz',
  'Quinn Quasar',
  'Roxie Rascal',
  'Savannah Stardust',
  'Trixie Tangerine',
  'Ursula Uber',
  'Valerie Viva',
  'Willow Wisp',
  'Xandra Xena',
  'Yolanda Yuletide',
  'Zelda Zest',
  'Amber Amore',
  'Bianca Belle',
  'Cherry Chantilly',
  'Dixie Dazzle',
  'Eva Eclipse',
  'Fleur Fatale',
  'Giselle Glow',
  'Heather Harmony',
  'Isabella Indigo',
  'Jade Jive',
  'Kitty Kiki',
  'Luna Lovegood',
  'Marigold Mystique',
  'Nina Nightshade',
  'Olive Ocean',
  'Penny Posh',
  'Quinn Qwerty',
  'Roxanne Rapture',
  'Savannah Starlight',
  'Trixie Taffeta',
  'Ursula Unique',
  'Valentina Vixen',
  'Winnie Wonderland',
  'Xena Xara',
  'Yara Yara',
  'Zara Zinnia',
  'Andromeda Aurora',
  'Bambi Belle',
  'Cleo Crimson',
  'Dakota Diamond',
  'Elena Elegance',
  'Fawn Foxy',
  'Giselle Gossip',
  'Heather Hurricane',
  'Ivy Ice',
  'Jade Jamboree',
  'Kitty Karma',
  'Lola Lux',
  'Mila Mystique',
  'Nadia Night',
  'Opal Orchid',
  'Piper Posh',
  'Quinn Quicksilver',
  'Roxie Rumor',
  'Savannah Sparkle',
  'Trixie Tequila',
  'Ursula Ultraviolet',
  'Valerie Verve',
  'Willow Whirl',
  'Xandra Xylia',
  'Yara Yacht',
  'Zara Zephyr',
  'Aria Allegro',
  'Belle Blossom',
  'Cleo Champagne',
  'Daisy Darling',
  'Ella Ember',
  'Frida Flame',
  'Giselle Galore',
  'Hazel Harmony',
  'Ivy Ivy',
  'Jade Joy',
  'Karma Kismet',
  'Lola LaLa',
  'Mila Moon',
  'Nadia Neon',
  'Opal Overture',
  'Piper Passion',
  'Quinn Quest',
  'Roxie Roxy',
  'Savannah Sky',
  'Trixie Tango',
  'Ursula Uprising',
  'Valentina Velvet',
  'Willow Whisper',
  'Xena Xylophone',
  'Yara Yuletide',
  'Zara Zest',
];
