import EmojiPicker from 'emoji-picker-react';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { useAppContext, useAppSettingsQuery } from '@contexts/AppContext';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { sum } from 'lodash';
import pluralize from 'pluralize';

import { useCurrentUserEventRsvp, useEventInterestDataQuery } from '@queries/EventQueries';
import { FeedPost, usePostReactionQuery } from '@queries/PostQueries';

import { useRsvpToEventMutation } from '@mutations/EventMutations';
import { useReactToPostMutation, useRespondToPostMutation } from '@mutations/PostMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { SendEntertainerBadgeButton } from '@components/buttons/SendEntertainerBadgeButton';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { ReportOverlay } from '@components/overlays/ReportOverlay/ReportOverlay';

import { CritiquePopupButton } from '@screens/CommunityScreen/CritiquePopupButton';
import { useInterestedButtonMutations } from '@screens/SuggestedEventsScreen/SuggestedEventsScreen';

interface EntertainerMediaPost {
  createdAt: string | Date;
  updatedAt: string | Date;
  postHeader: {
    sectionTitle: string;
    title: string;
  };
  entertainer: {
    _id: string;
    title: string;
    bookingHandle: string;
  };
  entertainerMedia: {
    _id: string;
    title: string;
    image?: string;
  };
  badgeId?: string;
  badgeCounts: {
    [key: string]: {
      _id: string;
      title: string;
      count: number;
    };
  };
  text?: string;
  type: 'entertainerMedia' | string;
}

dayjs.extend(relativeTime);

const usePostReactions = (postId: string) => {
  const { postReactions } = usePostReactionQuery(postId, true);
  const { isSubmitting, reactToPostAsync } = useReactToPostMutation();
  const [currentUserEmojiReaction, setCurrentUserEmojiReaction] = useState<string>();

  useEffect(() => {
    setCurrentUserEmojiReaction(postReactions?.currentUserEmojiReaction);
  }, [postReactions?.currentUserEmojiReaction]);

  const updateEmojiReaction = async (emoji: string) => {
    if (isSubmitting) {
      return;
    }

    if (emoji === currentUserEmojiReaction) {
      setCurrentUserEmojiReaction(undefined);
      await reactToPostAsync({ emoji: null, postId });
    } else {
      setCurrentUserEmojiReaction(emoji);
      await reactToPostAsync({ emoji, postId });
    }
  };

  return {
    currentUserEmojiReaction,
    postReactions,
    updateEmojiReaction,
  };
};

const SHOW_INTERESTED_NOT_INTERESTED = true;
const INTERESTED = 'interested';
const NOT_INTERESTED = 'notInterested';
const EMOJI_REACTIONS = ['👎', '🥱', '❤️', '🔥', '😍'];
interface PostReactionsProps {
  postId: string;
}
const PostReactions: FC<PostReactionsProps> = ({ postId }) => {
  const { currentUserEmojiReaction, updateEmojiReaction } = usePostReactions(postId);

  return (
    <div className="post-reactions d-flex gap-2">
      {EMOJI_REACTIONS.map((emoji) => (
        <IconButton
          key={emoji}
          className={emoji === currentUserEmojiReaction ? 'selected' : ''}
          color="neutral"
          variant="smooth"
          onClick={() => updateEmojiReaction(emoji)}
        >
          <span>{emoji}</span>
        </IconButton>
      ))}
      {
        // <IconButton className="add-custom-reaction" color="neutral" variant="smooth">
        //   <FontAwesomeIcon name="plus" />
        // </IconButton>
      }
    </div>
  );
};

export const PostCardHeaderWrapper: FC<{
  wrapperTitle: string;
  title: string;
  createdAt: string;
  action?: ReactNode;
}> = ({ wrapperTitle, title, createdAt, action }) => {
  return (
    <div className="reviewed-header-wrapper">
      <div className="reviewed-header">
        <span>{wrapperTitle}</span>
        <Card.Header
          avatar={<LetterAvatar letter={findFirstAlphaCharacter(title)} />}
          title={title}
          subtitle={dayjs(createdAt).fromNow()}
          action={action}
        />
      </div>
    </div>
  );
};

const PostInterestedNotInterestedButtons: FC<{ post: any }> = ({ post }) => {
  const eventId = post.event._id;
  const { rsvp } = useCurrentUserEventRsvp(eventId);
  const { eventInterestData, refetchEventInterestData } = useEventInterestDataQuery(eventId);
  const [updatedEventInterest, setUpdatedEventInterest] = useState<string>();
  const { handleInterested, handleNotInterested } = useInterestedButtonMutations();
  const { track } = useAnalytics();

  const eventInterest = useMemo(
    () => updatedEventInterest ?? rsvp?.eventInterest,
    [updatedEventInterest, rsvp?.eventInterest],
  );

  const interestedCount =
    eventInterestData?.eventInterest?.interested ?? post?.eventInterest?.[INTERESTED] ?? 0;
  const notInterestedCount =
    eventInterestData?.eventInterest?.notInterested ?? post?.eventInterest?.[NOT_INTERESTED] ?? 0;
  const interestedNotInterestedTotal = interestedCount + notInterestedCount;
  const interestedText = interestedNotInterestedTotal
    ? `Interested (${((interestedCount / interestedNotInterestedTotal) * 100).toFixed(0)}%)`
    : 'Interested';
  const notInterestedText = interestedNotInterestedTotal
    ? `Not Interested (${((notInterestedCount / interestedNotInterestedTotal) * 100).toFixed(0)}%)`
    : 'Not Interested';

  const handleInterestClick = (interest: string) => {
    track('Click Feed Event Interest', { interest });
    setUpdatedEventInterest(interest);
    if (interest === INTERESTED) {
      handleInterested(eventId);
    } else {
      handleNotInterested(eventId);
    }

    refetchEventInterestData();
  };

  return (
    <div className="d-flex gap-2">
      <Button
        className="px-0"
        roundness="rounded"
        color="neutral"
        variant={eventInterest === INTERESTED ? 'default' : 'outlined'}
        onClick={() => handleInterestClick(INTERESTED)}
      >
        {interestedText}
      </Button>
      <Button
        className="px-0"
        roundness="rounded"
        color="neutral"
        variant={eventInterest === NOT_INTERESTED ? 'default' : 'outlined'}
        onClick={() => handleInterestClick(NOT_INTERESTED)}
      >
        {notInterestedText}
      </Button>
    </div>
  );
};

const POPUP_TYPE_RECORD = {
  entertainerPopup: 'entertainer',
  venuePopup: 'venue',
  promoterPopup: 'promoter',
  eventPopup: 'event',
};

export const EntertainerMediaPostCard = ({ post }: { post: EntertainerMediaPost }) => {
  const { userIsLoggedIn } = useAppContext();
  const [reportOverlayIsVisible, setReportOverlayIsVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="post-card-wrapper">
      <Card className="post-card">
        {post.postHeader && (
          <div className="reviewed-header-wrapper">
            <div className="reviewed-header">
              <span>{post.postHeader.sectionTitle}</span>
              <Card.Header
                avatar={<LetterAvatar letter={findFirstAlphaCharacter(post.postHeader.title)} />}
                title={post.postHeader.title}
                subtitle={dayjs(post.createdAt).fromNow()}
                onClick={() => navigate(`/noauth/entertainer/${post.entertainer.bookingHandle}`)}
                action={
                  <Button
                    className="py-2 px-3"
                    size="sm"
                    roundness="rounded"
                    variant="smooth"
                    color="neutral"
                  >
                    Details
                  </Button>
                }
              />
            </div>
          </div>
        )}
        <Card.Body>
          {post.text && (
            <p className="mt-0" style={{ fontSize: 16 }}>
              <strong>{post.text}</strong>
            </p>
          )}
          <AsyncImage style={{ borderRadius: 8 }} src={post.entertainerMedia.image} />
        </Card.Body>
        {userIsLoggedIn && (
          <Card.Footer>
            <div className="w-100">
              <SendEntertainerBadgeButton
                entertainerId={post.entertainer._id}
                entertainerMediaId={post.entertainerMedia._id}
              />
            </div>
          </Card.Footer>
        )}
      </Card>

      <ReportOverlay
        isVisible={reportOverlayIsVisible}
        title="Report Post"
        content={<p>Are you sure you want to report this post?</p>}
        data={{
          collectionName: 'post',
          objectId: post.entertainerMedia._id,
        }}
        onClose={() => setReportOverlayIsVisible(false)}
      />
    </div>
  );
};
