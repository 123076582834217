import { List, ListItem } from 'framework7-react';
import { FC, useMemo, useRef, useState } from 'react';

import { uniqBy } from 'lodash';

import { CritiqueData, UserCritique } from '@queries/CritiqueQueries';
import { EntertainerRank } from '@queries/EntertainerQueries';
import {
  Venue,
  useCurrentUserVenueCritiqueQuery,
  useVenueCritiqueDataQuery,
} from '@queries/VenueQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Input } from '@components/form/Input';
import { CritiqueSection } from '@components/layout/DetailsChartSection/CritiqueSection';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { Popup } from '@components/layout/Popup/Popup';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';

const DEFAULT_STRENGTH_OPTIONS = [
  'Fearless Expression',
  'Gender Bending',
  'Political Provocateur',
  'Cultural Remix',
  'Erotic Artistry',
  'Vocal Virtuosity',
  'Costume Creativity',
  'Comedic Genius',
  'Dramatic Flair',
  'Dance Mastery',
];

const DEFAULT_IMPROVEMENT_OPTIONS = [
  'Limited Creativity',
  'Predictable Songs',
  'Static Performance',
  'Outdated References',
  'Monotone Delivery',
  'Subpar Lip Syncing',
  'Dull Costumes',
  'Poor Engagement',
  'Limited Awareness',
  'Uneven Makeup',
];

interface DocumentCritiqueContentProps {
  selectedDocument: { _id: string; title: string };
  critiqueData: CritiqueData;
  userDocumentCritique: UserCritique;
  onClickRating: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onUpdateStrengths?: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onUpdateImprovements?: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
  onViewDocumentDetails: (document: { _id: string; title: string }) => void;
  onClose: () => void;
}

let debouncedStrengthUpdate: any;
let debouncedImprovementUpdate: any;

const DocumentCritiqueContent: FC<DocumentCritiqueContentProps> = ({
  selectedDocument,
  critiqueData,
  userDocumentCritique,
  onClickRating,
  onUpdateStrengths,
  onUpdateImprovements,
  onViewDocumentDetails,
  onClose,
}) => {
  const initialUserStrentghs = critiqueData?.reasons?.like?.map(({ label }) => label) ?? [];
  const strengthOptions = useMemo(
    () =>
      uniqBy(
        [
          ...initialUserStrentghs,
          ...(userDocumentCritique?.like ?? []),
          ...DEFAULT_STRENGTH_OPTIONS,
        ],
        (label) => label.toLowerCase(),
      ).map((option) => ({
        label: option,
        value: option,
      })),
    [],
  );
  const [strengths, setStrengths] = useState<string[]>(initialUserStrentghs);

  const initialUserImprovements = critiqueData?.reasons?.neutral?.map(({ label }) => label) ?? [];
  const improvementOptions = useMemo(
    () =>
      uniqBy(
        [
          ...initialUserImprovements,
          ...(userDocumentCritique?.neutral ?? []),
          ...DEFAULT_IMPROVEMENT_OPTIONS,
        ],
        (label) => label.toLowerCase(),
      ).map((option) => ({
        label: option,
        value: option,
      })),
    [],
  );
  const [improvements, setImprovements] = useState<string[]>(initialUserImprovements);

  const handleUpdateStrengths = (updatedStrengths: string[]) => {
    setStrengths(updatedStrengths);
    clearTimeout(debouncedStrengthUpdate);
    debouncedStrengthUpdate = setTimeout(() => {
      onUpdateStrengths(selectedDocument._id)({
        like: updatedStrengths,
      });
    }, 1000);
  };

  const handleUpdateImprovements = (updatedImprovements: string[]) => {
    setImprovements(updatedImprovements);
    clearTimeout(debouncedImprovementUpdate);
    debouncedImprovementUpdate = setTimeout(() => {
      onUpdateImprovements(selectedDocument._id)({
        neutral: updatedImprovements,
      });
    }, 1000);
  };

  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();
  const handleCritique = async (payload: CritiqueDocumentMutationPayload) => {
    await critiqueDocumentAsync({
      ...payload,
      documentType: 'venues',
      documentId: selectedDocument._id,
    });
  };

  return (
    <>
      <TitleToolbar
        className="mt-2"
        text={selectedDocument.title}
        size="md"
        action={
          <PrimaryCritiqueCountChips
            hideCounts
            disableOverlay
            controlSelectedRating
            defaultValue={userDocumentCritique?.primaryCritique}
            counts={{
              like: 0,
              dislike: 0,
              neutral: 0,
            }}
            onSubmit={onClickRating(selectedDocument._id)}
          />
        }
      />

      <Button
        roundness="rounded"
        color="neutral"
        variant="smooth"
        onClick={() => {
          onClose();
          onViewDocumentDetails(selectedDocument);
        }}
      >
        View Profile
      </Button>

      <TitleToolbar text="Critiques 💅🏽" size="md" />
      <CritiqueSection
        showShareButton={false}
        critiqueData={critiqueData}
        userCritique={userDocumentCritique}
        onSubmit={handleCritique}
      />

      {/* <TitleToolbar text="Positives" size="sm" className="mt-4 mb-0" />
      <div className="mb-2">
        <span>
          Choose strengths to cheer on this entertainer or add your own! Shared publicly, posted
          anonymously.
        </span>
      </div>

      <ChipRadioOptions
        multiSelect
        addCustomOptions
        value={strengths}
        onChange={handleUpdateStrengths}
        options={strengthOptions}
      />
      <TitleToolbar text="Negatives" size="sm" className="mt-4 mb-0" />
      <div className="mb-2">
        <span>
          Pick areas to anonymously recommend improvements or add your own! Visible to all, but your
          name stays hidden.
        </span>
      </div>
      <ChipRadioOptions
        multiSelect
        addCustomOptions
        value={improvements}
        onChange={handleUpdateImprovements}
        options={improvementOptions}
      /> */}
    </>
  );
};

interface DocumentCritiquePopupProps
  extends Omit<DocumentCritiqueContentProps, 'selectedDocument'> {
  selectedDocument?: { _id: string; title: string };
  isLoading: boolean;
}
export const DocumentCritiquePopup: FC<DocumentCritiquePopupProps> = (props) => {
  const { selectedDocument, isLoading, onClose } = props;

  return (
    <Popup isVisible={!!selectedDocument} onClose={onClose}>
      <Popup.Content>
        {selectedDocument && (
          <>
            {isLoading ? (
              <>
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={120} />
                <Skeleton height={60} />
                <Skeleton height={120} />
              </>
            ) : (
              <DocumentCritiqueContent {...props} selectedDocument={selectedDocument} />
            )}
          </>
        )}
      </Popup.Content>
    </Popup>
  );
};
