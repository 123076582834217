export const EntertainerToolbarIcon = (props: { height: number; width: number }) => {
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 2048 2048"
      xmlns="http://www.w3.org/2000/svg"
      id="fi_14758801"
      {...props}
    >
      <g id="Layer_x0020_1">
        <g fill="currentColor" stroke="currentColor" fillRule="nonzero">
          <path
            strokeWidth="50"
            d="m1479 371c-156 40-250 265-340 484-117 282-230 554-482 464-78-28-139-34-187-24-33 7-59 22-79 42-21 21-37 49-48 80-29 83-28 193-9 297 79 0 127 1 177 2 57 1 116 3 244 3 225 0 315-230 407-466 96-245 194-496 447-541 46-8 76-21 94-38 16-15 23-35 24-58 5-67-15-120-36-175-13-33-26-67-35-106-31 6-49 9-65 12-25 4-46 7-111 24zm-399 459c97-233 196-473 384-521 66-17 90-20 117-25 21-3 44-7 93-18h2c17-3 34 9 36 27 7 46 23 87 38 126 24 62 46 122 40 202-3 39-15 72-44 100-27 25-67 44-127 55-218 38-310 273-399 501-100 256-198 506-467 506-129 0-188-1-245-3-49-1-97-2-202-2-15 0-28-10-31-25-26-121-30-255 6-357 14-41 35-76 64-104 29-29 66-50 111-59 59-13 132-6 222 26 195 69 296-175 402-428z"
          ></path>
          <path
            strokeWidth="30"
            d="m1490 823c-2-17 10-33 27-36 17-2 33 10 36 27l122 920c2 17-10 33-27 36-17 2-33-10-36-27z"
          ></path>
          <path
            strokeWidth="30"
            d="m1724 642c9-15 28-21 44-12 15 9 21 28 12 44-142 247-125 577-111 847 4 78 8 152 7 218 0 18-14 32-32 32s-32-14-32-32c0-63-3-137-7-215-14-278-32-619 119-882z"
          ></path>
        </g>
        <path d="m0 0h2048v2048h-2048z" fill="none"></path>
      </g>
    </svg>
  );
};
