import { FC } from 'react';

import './RenderTable.scss';

export type TableRow<T> = {
  key: string;
} & T;

export interface TableBodyCellProps<T> {
  row: TableRow<T>;
}

export interface HeadCell<T> {
  id: string;
  className?: string;
  label: string;
  TableBodyCell: FC<TableBodyCellProps<T>>;
}

interface RenderTableProps<T> {
  headCells: HeadCell<T>[];
  rows: TableRow<T>[];
  onRowClick?: (row: TableRow<T>) => void;
}

export const RenderTable = <T extends unknown>({
  headCells,
  rows,
  onRowClick,
}: RenderTableProps<T>) => {
  return (
    <div className="table-wrapper">
      <table className="table">
        <thead>
          <tr>
            {headCells.map((headCell) => (
              <th key={headCell.id} className={headCell.className}>
                {headCell.label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.map((row) => (
            <tr key={row.key} onClick={() => onRowClick?.(row)}>
              {headCells.map(({ id, TableBodyCell }, i) => (
                <TableBodyCell key={`${id}-${i}`} row={row} />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
