import { Toolbar } from 'framework7-react';
import { FC, useMemo, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import { keyBy } from 'lodash';

import { useInterestsQuery, usePopularInterestsQuery } from '@queries/UserQueries';

import { useUserOnboardingMutation } from '@mutations/UserMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { DEFAULT_AUTH_ROUTE } from '@router/constants';
import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { Chip } from '@components/chips/Chip';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Input } from '@components/form/Input';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { List } from '@components/layout/List/List';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { ONBOARDING_STEP_COUNT } from '../OnboardingConstants';
import './OnboardingInterestsScreen.scss';

type CheckedInterests = Record<
  string,
  {
    isChecked: boolean;
    label: string;
  }
>;

export const OnboardingInterestsScreen: FC<{}> = () => {
  const [checkedInterests, setCheckedInterests] = useState<CheckedInterests>({});
  const [searchValue, setSearchValue] = useState<string>();
  const { popularInterests = [] } = usePopularInterestsQuery();
  const { interests = [] } = useInterestsQuery({ searchValue });
  const { updateUserOnboardingAsync, isSubmitting } = useUserOnboardingMutation();
  const { track } = useAnalytics();
  const navigate = useNavigate();

  const handlePopularInterestChange = (checkedValues: string[]) => {
    const checkedValuesRecord = Object.fromEntries(checkedValues.map((v) => [v, true]));

    setCheckedInterests((prevValue) => {
      const updatedInterests = Object.fromEntries(
        popularInterests.map(({ _id, title }) => [
          _id,
          {
            isChecked: checkedValuesRecord[_id] ?? false,
            label: title,
          },
        ]),
      );
      return { ...prevValue, ...updatedInterests };
    });
  };

  const handleCheckboxChange = async (
    interest: { _id: string; title: string },
    updatedCheckboxValue: boolean,
  ) => {
    setCheckedInterests((prevState) => ({
      ...prevState,
      [interest._id]: { isChecked: updatedCheckboxValue, label: interest.title },
    }));
  };

  const handleRemoveInterest = (tagId: string) => {
    setCheckedInterests((prevState) => {
      const updatedCheckedInterests = { ...prevState };
      delete updatedCheckedInterests[tagId];
      return updatedCheckedInterests;
    });
  };

  const selectedInterests = useMemo(() => {
    return Object.entries(checkedInterests)
      .filter(([_, { isChecked }]) => isChecked)
      .map(([tagId, { label }]) => ({ _id: tagId, title: label }));
  }, [checkedInterests]);

  const personalInterests = useMemo(() => {
    const popularInterestsById = keyBy(popularInterests, '_id');

    return selectedInterests.filter(({ _id }) => !popularInterestsById[_id]);
  }, [popularInterests, selectedInterests]);

  const handleGoToNextStep = async () => {
    if (selectedInterests.length < 3) {
      return;
    }

    await updateUserOnboardingAsync({ interests: selectedInterests.map(({ _id }) => _id) });

    navigate(DEFAULT_AUTH_ROUTE);
    // navigate('/onboarding/critiques');
  };

  return (
    <Screen
      name="Onboarding Interests Screen"
      className="onboarding-interests-screen"
      bottomToolbar={
        <Screen.BottomToolbar>
          <Button
            disabled={isSubmitting || selectedInterests.length < 3}
            roundness="rounded"
            onClick={handleGoToNextStep}
          >
            Next
          </Button>
        </Screen.BottomToolbar>
      }
    >
      <Screen.Content>
        <TitleToolbar
          className="title-toolbar-upper"
          text={`Step 2 of ${ONBOARDING_STEP_COUNT}`}
          size="sm"
        />
        <TitleToolbar text="Your Interests" />
        <p>
          We use interests to help you find posts and events you'll love. You can always change
          these later, but for now, please <strong>select at least three interests</strong> to get
          started.
        </p>
        {!!popularInterests?.length && (
          <>
            <TitleToolbar text="Popular Interests" size="sm" />
            <ChipRadioOptions
              multiSelect
              value={selectedInterests.map(({ _id }) => _id)}
              options={popularInterests.map(({ _id, title }) => ({ value: _id, label: title }))}
              onChange={handlePopularInterestChange}
            />
          </>
        )}

        <TitleToolbar text="Personal Interests" size="sm" />
        {!!personalInterests?.length && (
          <div className="mb-2">
            {personalInterests.map((interest) => (
              <Chip
                key={interest._id}
                label={interest.title}
                color="neutral"
                variant="outlined"
                onRemove={() => {
                  handleRemoveInterest(interest._id);
                }}
              />
            ))}
          </div>
        )}
        <Input
          className="search-field"
          placeholder="Search Interests"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {!!interests?.length ? (
          <List
            ignoreScreenContentPadding
            className="interests-list"
            listItems={interests.map((interest) => ({
              hasCheckbox: true,
              defaultChecked: checkedInterests[interest._id]?.isChecked,
              title: interest.title,
              onCheckboxChange: (updatedCheckboxValue) =>
                handleCheckboxChange(interest, updatedCheckboxValue),
            }))}
          />
        ) : (
          <div className="mt-2">
            <EmptyState
              title="Search Interests"
              text="Use the search bar above to find and select more interests."
            />
          </div>
        )}
      </Screen.Content>
    </Screen>
  );
};
