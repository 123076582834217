import { FC, useMemo, useState } from 'react';

import { CritiqueData } from '@queries/CritiqueQueries';
import { FeedEvent } from '@queries/EventQueries';
import { useVenueEventsQuery, useVenuePostsQuery } from '@queries/PostQueries';
import { Promoter, usePromoterCritiqueDataQuery, usePromoterQuery } from '@queries/PromoterQueries';
import { useCurrentUser } from '@queries/UserQueries';
import { useFeedQuery, useNearbyVenuesQuery } from '@queries/useFeedQuery';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';
import { useCreateVenuePost } from '@mutations/PostMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useLocation, useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import EventCard from '@components/cards/EventCard';
import { PostCard } from '@components/cards/PostCard/PostCard';
import { PromoterFollowButton } from '@components/cards/PromoterCard';
import { VenueCard } from '@components/cards/VenueCard';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { CritiqueSection } from '@components/layout/DetailsChartSection/CritiqueSection';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { ReportOverlay } from '@components/overlays/ReportOverlay/ReportOverlay';

import { ClaimPopup } from './venue/ClaimPopup';
import './venue/VenueScreen/VenueScreen.scss';

export const overrideEventsForMarketing = (originalEvents: FeedEvent[]) => {
  const OverrideNames = [
    'Glitter Gala: A Night of Sparkles and Sass',
    'Rainbow Rendezvous: Disco Inferno Party',
    'Drag Delight: Lip Sync Battle Royale',
  ];

  const OverrideImages = [
    'https://images.pexels.com/photos/10330186/pexels-photo-10330186.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/2907679/pexels-photo-2907679.jpeg?auto=compress&cs=tinysrgb&w=800',
    'https://images.pexels.com/photos/2781924/pexels-photo-2781924.jpeg?auto=compress&cs=tinysrgb&w=800',
  ];

  const OverrideVenues = ['Rainbow Room', 'Glitter Palace', 'Drag Den'];

  return originalEvents.map((event, i) => ({
    ...event,
    title: OverrideNames[i],
    img: OverrideImages[i],
    venue: {
      ...event.venue,
      title: OverrideVenues[i],
    },
  }));
};

export const overridePromoterForMarketing = (
  originalPromoter: Promoter,
  originalEvents: {
    upcoming: FeedEvent[];
    previous: FeedEvent[];
  },
) => {
  const promoter = {
    ...originalPromoter,
    title: 'Glitter Palace',
  };

  const promoterEvents = {
    upcoming: overrideEventsForMarketing(originalEvents?.upcoming ?? []),
    previous: originalEvents?.previous,
  };

  const promoterCritiqueData: CritiqueData = {
    basicRatings: {
      like: 56,
      neutral: 10,
      dislike: 3,
    },
    reasons: {
      like: [
        { label: 'Incredible Music', count: 43 },
        { label: 'Inclusive Atmosphere', count: 32 },
        { label: 'Diverse Crowd', count: 21 },
      ],
      neutral: [],
      dislike: [],
    },
    reasonTotals: {
      like: 56,
      neutral: 10,
      dislike: 3,
    },
  };

  return {
    promoter,
    promoterEvents,
    promoterCritiqueData,
  };
};

const useVenueQuery = (venueId: string) => {
  const { venuesAreReady, venues = [] } = useNearbyVenuesQuery();

  const venue = useMemo(() => {
    return venues?.find((venue) => venue._id === venueId);
  }, [venues, venueId]);

  return { venue, venueIsReady: venuesAreReady };
};

interface VenueScreenProps {
  back?: string;
}

export const PromoterScreen: FC<VenueScreenProps> = ({ back = '/venues' }) => {
  const { promoterId } = useParams<{ promoterId: string }>();
  const { promoterIsReady, promoter, promoterEvents } = usePromoterQuery(promoterId);
  const { promoterCritiqueData, refetchPromoterCritiqueData } =
    usePromoterCritiqueDataQuery(promoterId);
  const { currentUser } = useCurrentUser();
  const [commentFieldIsVisible, setCommentFieldIsVisible] = useState<boolean>(false);
  const [reportOverlayIsVisible, setReportOverlayIsVisible] = useState(false);
  const navigate = useNavigate();
  const { openAlertDialog } = useDialog();
  const { track } = useAnalytics();
  const [isClaimPopupVisible, setIsClaimPopupVisible] = useState(false);

  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();

  const handleCritique = async (payload: CritiqueDocumentMutationPayload) => {
    await critiqueDocumentAsync({
      ...payload,
      documentType: 'promoters',
      documentId: promoterId,
    });
    refetchPromoterCritiqueData();
  };

  const description = promoter?.description;

  return (
    <Screen
      name="Promoter Screen"
      headerProps={{
        back: { to: back },
        title: 'Producer',
        right: (
          <IconButton variant="flat" color="danger" onClick={() => setReportOverlayIsVisible(true)}>
            <FontAwesomeIcon faStyle="far" name="flag" />
          </IconButton>
        ),
      }}
      hideToolbar
    >
      <Screen.Content>
        {promoterIsReady && promoter && (
          <>
            <div>
              <TitleToolbar
                className="mb-0"
                text={promoter.title}
                action={
                  !promoter.isClaimed && (
                    <Button
                      roundness="rounded"
                      onClick={() => {
                        setIsClaimPopupVisible(true);
                      }}
                    >
                      Claim
                    </Button>
                  )
                }
              />
              {description &&
                mapStringToParagraphs(description, {
                  collapseTopMargin: true,
                })}

              <TitleToolbar
                className={description ? '' : 'mt-0'}
                text="Upcoming Events 🎉"
                size="md"
                action={<PromoterFollowButton promoter={promoter} />}
              />
            </div>
            {promoterEvents?.upcoming?.length ? (
              <HorizontalScroll
                items={promoterEvents?.upcoming?.map((event) => (
                  <EventCard
                    showPromoter={false}
                    key={event._id}
                    event={event}
                    onClick={() => navigate(`/events/${event._id}`)}
                  />
                ))}
              />
            ) : (
              <EmptyState
                title="No Upcoming Events"
                text={`${promoter.title} doesn't have any upcoming events. Follow them to get notified when new events are added.`}
                button={{
                  text: 'Follow Venue',
                  onClick: () => {},
                }}
              />
            )}

            {promoterCritiqueData && (
              <>
                <TitleToolbar text="Critiques 💅🏽" size="md" />
                <CritiqueSection critiqueData={promoterCritiqueData} onSubmit={handleCritique} />
              </>
            )}

            {!!promoterEvents?.previous?.length && (
              <>
                <TitleToolbar text="Past Highlights 🪭" size="md" />
                <HorizontalScroll
                  items={promoterEvents?.previous?.map((event) => (
                    <EventCard
                      showPromoter={false}
                      key={event._id}
                      event={event}
                      onClick={() => navigate(`/events/${event._id}`)}
                    />
                  ))}
                />
              </>
            )}
          </>
        )}
      </Screen.Content>
      <ClaimPopup
        isVisible={isClaimPopupVisible}
        onClose={() => setIsClaimPopupVisible(false)}
        trackProperties={{
          promoterId,
          title: promoter?.title,
          userId: currentUser?._id,
        }}
      />
      <ReportOverlay
        isVisible={reportOverlayIsVisible}
        title="Report Promoter"
        content={<p>Are you sure you want to report this promoter?</p>}
        data={{
          collectionName: 'promoter',
          objectId: promoterId,
        }}
        onClose={() => setReportOverlayIsVisible(false)}
      />
    </Screen>
  );
};
