import * as Sentry from '@sentry/react';

import { CapacitorUpdater } from '@capgo/capacitor-updater';

import { useAnalytics } from './useAnalytics';

let availableUpdateBundle: any;

CapacitorUpdater.addListener('updateAvailable', async (res) => {
  try {
    availableUpdateBundle = res.bundle;
  } catch (error) {
    Sentry.captureException(error);
  }
});

export const useCapacitorUpdateAvailable = () => {
  const { track } = useAnalytics();

  const updateApp = async () => {
    track('App Update Requested', {
      availableUpdateBundle,
    });

    if (availableUpdateBundle) {
      try {
        await CapacitorUpdater.set(availableUpdateBundle);
        availableUpdateBundle = undefined;
      } catch (error) {
        Sentry.captureException(error);
        await CapacitorUpdater.reload();
      }
    }
  };

  return {
    isCapacitorUpdateAvailable: !!availableUpdateBundle,
    updateApp,
  };
};
