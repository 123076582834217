import { FC, ReactNode, useMemo, useState } from 'react';

import pluralize from 'pluralize';

import {
  EntertainerBadge,
  useCurrentEntertainerBadgesQuery,
  useCurrentEntertainerQuery,
} from '@queries/EntertainerQueries';
import { Notification, NotificationType, useNotificationsQuery } from '@queries/UserQueries';

import {
  useMarkNotificationAsReadMutation,
  useMarkNotificationsAsSeenMutation,
} from '@mutations/UserMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCanInstallIOSApp } from '@hooks/useCanInstallIOSApp';
import { useDevice } from '@hooks/useDevice';
import { usePWA } from '@hooks/usePWA';

import { NavigateFunction, useNavigate } from '@router/index';

import dayjs from '@utilities/dayjs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { SendEntertainerBadgeButton } from '@components/buttons/SendEntertainerBadgeButton';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { ButtonFilter } from '@components/layout/ButtonFilter/ButtonFilter';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { List, ListItemProps } from '@components/layout/List/List';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { SendBadgePopup, UnlockBadgePopup } from './UnlockBadgePopup';

const NOTIFICATION_ACTION_RECORD: Record<
  NotificationType,
  {
    label: string;
    to: (notification: Notification) => string;
  }
> = {
  suggestedEvents: { label: 'View events', to: () => '/events/suggested' },
};

const NotificationIcon: FC<{
  isUnread: boolean;
  avatarLetter: string;
}> = ({ isUnread, avatarLetter }) => {
  return (
    <div className="notification-media">
      {isUnread && (
        <div className="notification-unread-icon">
          <FontAwesomeIcon name="circle" />
        </div>
      )}
      <LetterAvatar letter={avatarLetter} />
    </div>
  );
};

const TAB_OPTION_RECORD = {
  received: 'Earned',
  sent: 'Given',
};
type TabOption = keyof typeof TAB_OPTION_RECORD;

const TAB_OPTIONS = Object.entries(TAB_OPTION_RECORD).map(([value, label]) => ({ value, label }));

export const EntertainerBadgesScreen: FC<{}> = () => {
  const { track } = useAnalytics();
  const { entertainerIsReady, entertainer } = useCurrentEntertainerQuery();
  const { entertainerBadgesAreReady, receivedBadges, sentBadges } =
    useCurrentEntertainerBadgesQuery(entertainer?._id);
  const [currentTab, setCurrentTab] = useState<TabOption>('received');
  const [lockedBadgeId, setLockedBadgeId] = useState<string>();
  const [sendBadgePopupIsVisible, setSendBadgePopupIsVisible] = useState(false);

  const handleUnlockBadge = (badge: EntertainerBadge) => {
    if (badge.isLocked) {
      track('Click unlock badge');
      setLockedBadgeId(badge._id);
    }
  };

  return (
    <Screen
      name="Entertainer Badges Screen"
      headerProps={{
        title: 'Your Badges',
        back: {
          to: '/manage/entertainer',
        },
      }}
      hideToolbar
    >
      <Screen.Content>
        <ButtonFilter
          options={TAB_OPTIONS}
          onChange={(value) => {
            track(`Switch to ${value} badges`);
            setCurrentTab(value as TabOption);
          }}
          selected={currentTab}
        />
        {entertainerIsReady && entertainerBadgesAreReady ? (
          <>
            {currentTab === 'received' && (
              <>
                <TitleToolbar
                  className="mb-2"
                  text={`${receivedBadges?.length} ${pluralize('Badge', receivedBadges?.length)} Earned`}
                  size="sm"
                  action={
                    <Button
                      size="sm"
                      className="px-3 py-2"
                      roundness="rounded"
                      variant="flat"
                      color="primary"
                      onClick={() => {
                        track('Open share badge popup', {
                          position: 'title toolbar',
                        });
                        setSendBadgePopupIsVisible(true);
                      }}
                    >
                      Share Badges
                    </Button>
                  }
                />
                {receivedBadges?.length ? (
                  <List
                    className="notification-list mt-0"
                    listItems={receivedBadges.map((badge) => ({
                      startIcon: (
                        <img
                          src={badge.imageUrl}
                          style={{ height: 42, width: 42, marginTop: -2, marginBottom: -8 }}
                        />
                      ),
                      title: badge.isLocked
                        ? 'Locked'
                        : ((
                            <span className="text-truncate">{badge?.from?.displayName}</span>
                          ) as any),
                      subTitle: badge.isLocked
                        ? undefined
                        : ((
                            <>
                              <div>{`${badge.title} for ${badge.reason}`}</div>
                              {badge.eventTitle && (
                                <div>
                                  <strong>{badge.eventTitle}</strong>
                                </div>
                              )}
                            </>
                          ) as any),
                      endIcon: (
                        <div>
                          {badge.isLocked ? (
                            <Button
                              size="sm"
                              className="px-3 py-2"
                              roundness="rounded"
                              variant="smooth"
                              color="neutral"
                              onClick={() => handleUnlockBadge(badge)}
                            >
                              Unlock
                            </Button>
                          ) : (
                            <>
                              {badge?.from?.entertainerId && (
                                <SendEntertainerBadgeButton
                                  entertainerId={badge?.from?.entertainerId}
                                  text="Share back"
                                  buttonProps={{
                                    size: 'sm',
                                    startIcon: undefined,
                                    className: 'px-3 py-2',
                                    roundness: 'rounded',
                                    variant: 'smooth',
                                    color: 'neutral',
                                  }}
                                />
                              )}
                            </>
                          )}
                        </div>
                      ),
                    }))}
                  />
                ) : (
                  <EmptyState
                    title="No badges yet"
                    text="You haven't received any badges yet, but you can still send some to your fellow entertainers."
                    button={{
                      text: 'Share a badge',
                      onClick: () => {
                        track('Open share badge popup', {
                          position: 'empty state',
                        });
                        setSendBadgePopupIsVisible(true);
                      },
                    }}
                  />
                )}
              </>
            )}
            {currentTab === 'sent' && (
              <>
                <TitleToolbar
                  className="mb-2"
                  text={`${sentBadges?.length} ${pluralize('Badge', sentBadges?.length)} Given`}
                  size="sm"
                />
                {sentBadges?.length ? (
                  <List
                    className="notification-list mt-0"
                    listItems={sentBadges.map((badge) => ({
                      startIcon: (
                        <img
                          src={badge.imageUrl}
                          style={{ height: 42, width: 42, marginTop: -2, marginBottom: -8 }}
                        />
                      ),
                      title: (<span className="text-truncate">{badge?.to?.title}</span>) as any,
                      subTitle: (
                        <>
                          <div>{`${badge.title} for ${badge.reason}`}</div>
                          {badge.eventTitle && (
                            <div>
                              <strong>{badge.eventTitle}</strong>
                            </div>
                          )}
                        </>
                      ) as any,
                      endIcon: (
                        <SendEntertainerBadgeButton
                          entertainerId={badge?.to?.entertainerId}
                          lockedBadgeId={lockedBadgeId}
                          text="Send Another"
                          buttonProps={{
                            size: 'sm',
                            startIcon: undefined,
                            className: 'px-3 py-2',
                            roundness: 'rounded',
                            variant: 'smooth',
                            color: 'neutral',
                          }}
                        />
                      ),
                    }))}
                  />
                ) : (
                  <EmptyState
                    title="No badges yet"
                    text="You haven't sent any badges yet. Share a badge and spread the love!"
                    button={{
                      text: 'Share a badge',
                      onClick: () => {
                        track('Open share badge popup', {
                          position: 'empty state',
                        });
                        setSendBadgePopupIsVisible(true);
                      },
                    }}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <div className="grid gap-2">
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
          </div>
        )}
      </Screen.Content>
      <SendBadgePopup
        isVisible={sendBadgePopupIsVisible}
        onClose={() => {
          setSendBadgePopupIsVisible(false);
        }}
      />
      <UnlockBadgePopup
        isVisible={!!lockedBadgeId}
        lockedBadgeId={lockedBadgeId}
        onClose={() => {
          setLockedBadgeId(undefined);
        }}
      />
    </Screen>
  );
};
