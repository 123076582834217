import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';

import { SourceLog, useSourceLogsQuery } from '@admin/queries/SourceLogsQueries';

import API from '@api/API';

interface CreateInstagramAccountPayload {
  username: string;
  type: 'venue' | 'promoter' | 'entertainer';
  cityId: string;
  venueId?: string;
}

export const useCreateInstagramAccountMutation = () => {
  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: async (payload: CreateInstagramAccountPayload) => {
      const response = await API.post<any, CreateInstagramAccountPayload>(
        `/v1/super-admin/instagram-accounts`,
        payload,
      );

      return response;
    },
  });

  return {
    isSubmitting,
    createInstagramAccountAsync: mutateAsync,
  };
};

interface UpdateInstagramAccountPayload {
  type: 'venue' | 'promoter' | 'entertainer';
  cityId: string;
  venueId?: string;
}

export const useUpdateInstagramAccountMutation = () => {
  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: async ({
      _id: instagramAccountInternalId,
      ...payload
    }: {
      _id: string;
    } & UpdateInstagramAccountPayload) => {
      const response = await API.put<any, UpdateInstagramAccountPayload>(
        `/v1/super-admin/instagram-accounts/${instagramAccountInternalId}`,
        payload,
      );

      return response;
    },
  });

  return {
    isSubmitting,
    updateInstagramAccountAsync: mutateAsync,
  };
};
