import { FC, ReactElement, useLayoutEffect } from 'react';

import './HorizontalScroll.scss';

interface HorizontalScrollProps {
  items: ReactElement[];
}
export const HorizontalScroll: FC<HorizontalScrollProps> = ({ items }) => {
  useLayoutEffect(() => {});

  return (
    <div className={`horizontal-scroll item-count-${items.length}`}>
      {items.map((item, i) => (
        <div key={i} className="horizontal-scroll-item">
          {item}
        </div>
      ))}
    </div>
  );
};
