import { f7ready } from 'framework7-react';

export const useDialog = () => {
  const openAlertDialog = ({ title, text }: { title: string; text: string }) => {
    f7ready((f7) => {
      f7.dialog.alert(text, title);
    });
  };

  const openConfirmDialog = ({
    title,
    text,
    onConfirm,
  }: {
    title: string;
    text: string;
    onConfirm?: () => void;
  }) => {
    f7ready((f7) => {
      f7.dialog.confirm(text, title, onConfirm);
    });
  };

  return { openAlertDialog, openConfirmDialog };
};
