import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './AdminApp.scss';
import { InstagramAccountsPage } from './pages/InstagramAccountsPage/InstagramAccountsPage';
import { SourceLogsPage } from './pages/SourceLogsPage/SourceLogsPage';

const routes = [
  {
    path: '/',
    element: <>Admin</>,
  },
  {
    path: '/source-logs',
    element: <SourceLogsPage />,
  },
  {
    path: '/instagram',
    element: <InstagramAccountsPage />,
  },
];

const router = createBrowserRouter(routes, { basename: '/admin' });

const defaultStaleMinutes = 5;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * defaultStaleMinutes,
    },
  },
});

export const AdminApp = () => {
  return (
    <div className="gaggl-admin-app">
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </div>
  );
};
