import { QueryClient, QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import * as dayjs from 'dayjs';

import API from '@api/API';

import { useAnalytics } from '@hooks/useAnalytics';

import { useAppContext } from '../contexts/AppContext';
import { FeedEvent } from './EventQueries';
import { Promoter } from './PromoterQueries';

interface OnboardingProperties {
  isComplete: boolean;
  basicInfoIsComplete?: boolean;
  interestsAreComplete?: boolean;
  discoverIsComplete?: boolean;
  communityIsComplete?: boolean;
}

export interface CurrentUser {
  _id: string;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  isSuperAdmin?: boolean;
  notificationsEnabled?: boolean;
  smsNotificationsEnabled?: boolean;
  onboarding?: OnboardingProperties;
}

export const useCurrentUser = () => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useCurrentUser.queryKey(),
    queryFn: useCurrentUser.queryFn,
    enabled: userIsLoggedIn,
  });

  return {
    currentUserIsReady: isFetched,
    currentUser: data?.user,
    refetchUser: refetch,
  };
};
useCurrentUser.queryKey = (): QueryKey => ['user'];
useCurrentUser.queryFn = () => API.get<{ user: CurrentUser }>(`/v1/user/me`);

export const fetchCurrentUser = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({ queryKey: useCurrentUser.queryKey() });

  return queryClient.fetchQuery({
    queryKey: useCurrentUser.queryKey(),
    queryFn: useCurrentUser.queryFn,
  });
};

export const useFetchCurrentUser = () => {
  const queryClient = useQueryClient();

  return () => fetchCurrentUser(queryClient);
};

export const useIsValidTokenQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useIsValidTokenQuery.queryKey(),
    queryFn: useIsValidTokenQuery.queryFn,
    enabled: userIsLoggedIn,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  if (!userIsLoggedIn) {
    return {
      tokenIsValidIsReady: true,
      tokenIsValid: false,
      refetchTokenIsValid: () => {},
    };
  }

  return {
    tokenIsValidIsReady: isFetched,
    tokenIsValid: data?.tokenIsValid,
    refetchTokenIsValid: refetch,
  };
};
useIsValidTokenQuery.queryKey = () => ['tokenIsValid'];
useIsValidTokenQuery.queryFn = () => API.get<{ tokenIsValid: boolean }>(`/v1/token/validate`);
export const fetchTokenIsValid = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({ queryKey: useIsValidTokenQuery.queryKey() });

  return queryClient.fetchQuery({
    queryKey: useIsValidTokenQuery.queryKey(),
    queryFn: useIsValidTokenQuery.queryFn,
  });
};

export const useFetchTokenIsValid = () => {
  const queryClient = useQueryClient();

  return () => fetchTokenIsValid(queryClient);
};

const NOTIFICATION_TYPES = ['suggestedEvents'] as const;
export type NotificationType = (typeof NOTIFICATION_TYPES)[number];

export interface Notification {
  _id: string;
  text: string;
  sentAt: string;
  seenAt?: string;
  readAt?: string;
  type: NotificationType;
  to: string;
}

export const useNotificationsQuery = () => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useNotificationsQuery.queryKey(),
    queryFn: () => useNotificationsQuery.queryFn(),
  });

  return {
    notificationsAreReady: isFetched,
    notifications: data?.notifications,
    refetchNotifications: refetch,
  };
};
useNotificationsQuery.queryKey = () => ['notifications'];
useNotificationsQuery.queryFn = () =>
  API.get<{ notifications: Notification[] }>(`/v1/user/notifications`);

export const useFollowsQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useFollowsQuery.queryKey(),
    queryFn: useFollowsQuery.queryFn,
    enabled: userIsLoggedIn,
    refetchOnMount: 'always',
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: 'always',
  });

  return {
    followsAreReady: isFetched,
    follows: data?.follows,
    refetchFollows: refetch,
  };
};
useFollowsQuery.queryKey = () => ['user-follows'];
useFollowsQuery.queryFn = () =>
  API.get<{ follows: { [key: string]: { [documentId: string]: true } } }>(`/v1/user/follows`);

export const useUserInterestsQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useUserInterestsQuery.queryKey(),
    queryFn: () => useUserInterestsQuery.queryFn(),
    enabled: userIsLoggedIn,
  });

  return {
    userInterestsAreReady: isFetched,
    userInterests: data?.tags,
    refetchUserInterests: refetch,
  };
};
useUserInterestsQuery.queryKey = () => ['user', 'interests'];
useUserInterestsQuery.queryFn = () =>
  API.get<{ tags: { _id: string; title: string }[] }>(`/v1/user/interests`);

export const useInterestsQuery = ({ searchValue }: { searchValue: string }) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useInterestsQuery.queryKey(searchValue),
    queryFn: () => useInterestsQuery.queryFn(searchValue),
    enabled: !!searchValue,
  });

  const { track } = useAnalytics();
  useEffect(() => {
    if (searchValue) {
      track('Interests Search', { Search: searchValue });
    }
  }, [searchValue]);

  return {
    interestsAreReady: isFetched,
    interests: data?.tags,
    refetchInterests: refetch,
  };
};
useInterestsQuery.queryKey = (searchValue: string) => ['interests', searchValue];
useInterestsQuery.queryFn = (searchValue: string) =>
  API.get<{ tags: { _id: string; title: string }[] }>(`/v1/interests?s=${searchValue}`);

export const useInterestQuery = (tagId: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useInterestQuery.queryKey(tagId),
    queryFn: () => useInterestQuery.queryFn(tagId),
    enabled: !!tagId,
  });

  return {
    interestIsReady: isFetched,
    interest: data,
    refetchInterest: refetch,
  };
};
useInterestQuery.queryKey = (tagId: string) => ['interests', 'results', tagId];
useInterestQuery.queryFn = (tagId: string) =>
  API.get<{ tag: { _id: string; title: string }; events: any[]; venues: any[] }>(
    `/v1/user/interests/${tagId}`,
  );

export const usePopularInterestsQuery = () => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: usePopularInterestsQuery.queryKey(),
    queryFn: () => usePopularInterestsQuery.queryFn(),
  });

  return {
    popularInterestsAreReady: isFetched,
    popularInterests: data?.tags,
    refetchInterests: refetch,
  };
};
usePopularInterestsQuery.queryKey = () => ['popular-interests', dayjs().format('YYYY-MM-DD')];
usePopularInterestsQuery.queryFn = () =>
  API.get<{ tags: { _id: string; title: string }[] }>(`/v1/interests/popular`);

interface SearchParams {
  cityId?: string;
  searchValue?: string;
  startDate?: string;
  endDate?: string;
}
export const useSearchQuery = (searchParams: SearchParams) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useSearchQuery.queryKey(searchParams),
    queryFn: () => useSearchQuery.queryFn(searchParams),
    enabled: Boolean(searchParams.searchValue || searchParams.startDate || searchParams.endDate),
  });

  return {
    searchResultsAreReady: isFetched,
    searchResults: data,
    refetchSearchResults: refetch,
  };
};
useSearchQuery.queryKey = ({ searchValue, startDate, endDate, cityId }: SearchParams) => [
  'search',
  'results',
  searchValue,
  startDate,
  endDate,
  cityId,
];
useSearchQuery.queryFn = ({ searchValue, startDate, endDate, cityId }: SearchParams) =>
  API.post<{ events: FeedEvent[]; venues: any[]; promoters: Promoter[] }, SearchParams>(
    `/v1/events/search`,
    {
      searchValue,
      startDate,
      endDate,
      cityId,
    },
  );

interface UserConversation {
  entertainerId?: string;
  userId?: string;
  promoterId?: string;
  isUnread: boolean;
  lastMessageAt: string;
  text?: string;
  title: string;
  _id: string;
}

export const useUserConversationsQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useUserConversationsQuery.queryKey(),
    queryFn: () => useUserConversationsQuery.queryFn(),
    enabled: userIsLoggedIn,
  });

  return {
    userConversationsAreReady: isFetched,
    userConversations: data?.conversations ?? { user: [], promoter: [], entertainer: [] },
    refetchUserConversations: refetch,
  };
};

useUserConversationsQuery.queryKey = (userId?: string) => ['conversations', userId];
useUserConversationsQuery.queryFn = () =>
  API.get<{
    conversations: {
      user: UserConversation[];
      promoter: UserConversation[];
      entertainer: UserConversation[];
    };
  }>('/v1/conversations/me');

export const useManageCompetitionsQuery = () => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useManageCompetitionsQuery.queryKey(),
    queryFn: useManageCompetitionsQuery.queryFn,
  });

  return {
    competitionsAreReady: isFetched,
    competitions: data?.campaigns,
    refetchCompetitions: refetch,
  };
};

useManageCompetitionsQuery.queryKey = () => ['competitions'];
useManageCompetitionsQuery.queryFn = () =>
  API.get<{ campaigns: Record<string, any[]> }>(`/v1/user/promoters/campaigns`);
export const fetchManageCompetitions = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({ queryKey: useManageCompetitionsQuery.queryKey() });

  return queryClient.fetchQuery({
    queryKey: useManageCompetitionsQuery.queryKey(),
    queryFn: useManageCompetitionsQuery.queryFn,
  });
};

export const useUserChallengesQuery = () => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useUserChallengesQuery.queryKey(),
    queryFn: useUserChallengesQuery.queryFn,
  });

  return {
    challengesAreReady: isFetched,
    challenges: data?.userChallenges,
    refetchChallenges: refetch,
  };
};

useUserChallengesQuery.queryKey = () => ['challenges'];
useUserChallengesQuery.queryFn = () => API.get<{ userChallenges: any[] }>(`/v1/user/challenges`);
