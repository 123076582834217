import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';

import API from '@api/API';

import { useCurrentUserGagglRankQuery } from '@queries/CritiqueQueries';
import {
  useEventCommentsQuery,
  usePostReactionQuery,
  useVenuePostsQuery,
} from '@queries/PostQueries';

interface CreateNewPostPayload {
  text: string;
}
export const useCreateNewPostMutation = () => {
  const queryClient = useQueryClient();
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (payload: CreateNewPostPayload) => {
      return API.post(`/v1/user/posts/`, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentUserGagglRankQuery.queryKey() });
    },
  });

  return {
    isSubmitting: isPending,
    createNewPostAsync: mutateAsync,
  };
};

interface RespondToPostPayload {
  text: string;
  parentPostId: string;
}
export const useRespondToPostMutation = () => {
  const queryClient = useQueryClient();
  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ parentPostId, ...payload }: RespondToPostPayload) => {
      return API.post(`/v1/posts/${parentPostId}/respond`, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentUserGagglRankQuery.queryKey() });
    },
  });

  return {
    isSubmitting: isPending,
    respondToPostAsync: mutateAsync,
  };
};

interface ReactToPostPayload {
  emoji: string;
  postId: string;
}
export const useReactToPostMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: async ({ postId, ...payload }: ReactToPostPayload) => {
      const response = await API.post(`/v1/posts/${postId}/react`, payload);
      await queryClient.invalidateQueries({ queryKey: usePostReactionQuery.queryKey(postId) });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentUserGagglRankQuery.queryKey() });
    },
  });

  return {
    isSubmitting: isPending,
    reactToPostAsync: mutateAsync,
  };
};

interface AskInAppMessageQueryPayload {
  message: string;
}
export const useAskInAppMessageQueryMutation = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (payload: AskInAppMessageQueryPayload) => {
      return API.post<{ query: { _id: string } }, AskInAppMessageQueryPayload>(
        `/v1/user/feed/inquire`,
        payload,
      );
    },
  });

  return {
    isSubmitting: isPending,
    askInAppMessageQueryAsync: mutateAsync,
  };
};

interface CreateEventCommentPayload {
  text: string;
}
export const useCreateEventComment = (eventId: string) => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (payload: CreateEventCommentPayload) => {
      return API.post(`/v1/events/${eventId}/comments/`, payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: useEventCommentsQuery.queryKey(eventId) });
      queryClient.invalidateQueries({ queryKey: useCurrentUserGagglRankQuery.queryKey() });
    },
  });

  return {
    isSubmitting: isPending,
    createEventCommentAsync: mutateAsync,
  };
};

interface CreateVenuePostPayload {
  text: string;
}
export const useCreateVenuePost = (eventId: string) => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (payload: CreateVenuePostPayload) => {
      return API.post(`/v1/venues/${eventId}/posts`, payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: useVenuePostsQuery.queryKey(eventId) });
      queryClient.invalidateQueries({ queryKey: useCurrentUserGagglRankQuery.queryKey() });
    },
  });

  return {
    isSubmitting: isPending,
    createVenuePostAsync: mutateAsync,
  };
};
