import { FC, ReactNode } from 'react';

import pluralize from 'pluralize';

import { useVenueCritiqueDataQuery } from '@queries/VenueQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';

import { useUserIsFollowing } from '@hooks/useUserIsFollowing';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';

import Button from '@components/buttons/Button';
import Card from '@components/layout/Card/Card';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';

interface VenueProps {
  showUserIsFollowing?: boolean;
  venue: {
    _id: string;
    title: string;
    distance?: number;
    description?: string;
    tagline?: string;
    upcomingEventsCount?: number;
  };
  footerOverride?: ReactNode;
  onClick?: () => void;
}

export const VenueFollowButton: FC<{ venue: { _id: string; title: string } }> = ({ venue }) => {
  const { userIsFollowing, handleFollow, handleUnfollow } = useUserIsFollowing('venue', venue._id);
  return (
    <>
      {userIsFollowing ? (
        <Button color="neutral" variant="outlined" size="sm" onClick={handleUnfollow}>
          Unfollow
        </Button>
      ) : (
        <Button color="neutral" variant="outlined" size="sm" onClick={handleFollow}>
          Follow
        </Button>
      )}
    </>
  );
};

export const VenueCard: FC<VenueProps> = ({
  showUserIsFollowing = true,
  venue,
  footerOverride,
  onClick,
}) => {
  const { venueCritiqueData, refetchVenueCritiqueData } = useVenueCritiqueDataQuery(
    venue._id,
    !footerOverride,
  );
  const { isSubmitting, critiqueDocumentAsync } = useCritiqueDocumentMutation();

  const handleSubmitCritique = async (properties: CritiqueDocumentMutationPayload) => {
    await critiqueDocumentAsync({
      documentType: 'venues',
      documentId: venue._id,
      ...properties,
    });
    refetchVenueCritiqueData();
  };

  const DISTANCE_ENABLED = false;

  const subtitleDistance =
    DISTANCE_ENABLED && venue.hasOwnProperty('distance')
      ? `${venue.distance.toFixed(2)}mi`
      : undefined;
  const subtitleUpcomingCount = venue.hasOwnProperty('upcomingEventsCount')
    ? `${venue.upcomingEventsCount} upcoming ${!DISTANCE_ENABLED ? pluralize('event', venue.upcomingEventsCount) : ''}`
    : undefined;
  const subtitle = [subtitleDistance, subtitleUpcomingCount].filter(Boolean).join(' • ');

  return (
    <Card key={venue.title} onClick={onClick}>
      <Card.Header
        avatar={<LetterAvatar letter={findFirstAlphaCharacter(venue.title)} />}
        title={venue.title}
        subtitle={subtitle}
        action={showUserIsFollowing ? <VenueFollowButton venue={venue} /> : undefined}
      />
      <Card.Body>
        <p>{venue.description ?? venue.tagline}</p>
      </Card.Body>
      {footerOverride ?? (
        <Card.Footer>
          <PrimaryCritiqueCountChips
            counts={venueCritiqueData?.basicRatings}
            onSubmit={handleSubmitCritique}
          />
        </Card.Footer>
      )}
    </Card>
  );
};
