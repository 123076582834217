import { FC, useState } from 'react';

import { InterestData, UserEventRsvp } from '@queries/EventQueries';

import { RsvpToEventPayload } from '@mutations/EventMutations';

import { useAnalytics } from '@hooks/useAnalytics';

// import { InterestDocumentMutationPayload } from '@mutations/InterestMutations';
import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { EventInterestOverlay } from '@components/overlays/CritiqueOverlay/EventInterestOverlay';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { EmptyState } from '../EmptyState/EmptyState';
// import { InterestOverlay } from '@components/overlays/InterestOverlay/InterestOverlay';
// import { BASIC_RATING_COLOR_RECORD } from '@components/overlays/InterestOverlay/InterestOverlayConstants';
import { DetailsChartSection } from './DetailsChartSection';

const COLOR_RECORD = {
  interested: 'rgb(103, 58, 183)',
  notInterested: '#e1e1e1',
};

interface InterestSectionProps {
  interestData: InterestData;
  currentUserRsvp?: UserEventRsvp;
  showShareButton?: boolean;
  onSubmit: (payload: RsvpToEventPayload) => Promise<void>;
}

export const EventInterestSection: FC<InterestSectionProps> = ({
  interestData,
  currentUserRsvp,
  onSubmit,
  showShareButton = true,
}) => {
  const { track } = useAnalytics();
  const [interestOverlayIsVisible, setInterestOverlayIsVisible] = useState(false);
  const { createAuthGateHandler } = useAuthGate();

  const handleOpenOverlay = () => {
    createAuthGateHandler(() => setInterestOverlayIsVisible(true));
  };

  const handleSubmit = async (payload: RsvpToEventPayload) => {
    setInterestOverlayIsVisible(false);
    onSubmit(payload);
  };

  return (
    <div className="d-flex gap-2 flex-column">
      <DetailsChartSection
        emptyState={
          showShareButton ? (
            <EmptyState
              text="No one has shared reasons for their hot takes yet"
              button={{
                text: 'Be the first',
                onClick: () => {
                  track('Open Event Interest Overlay', {
                    component: 'Empty State Button',
                  });
                  handleOpenOverlay();
                },
              }}
            />
          ) : (
            <EmptyState text="This event has passed and doesn't have any hot takes" />
          )
        }
        detailsChartData={{
          choices: interestData.eventInterest,
          reasons: interestData.reasons,
          reasonTotals: interestData.reasonTotals,
          colors: COLOR_RECORD,
        }}
      />
      {showShareButton && (
        <>
          <Button
            color="default"
            endIcon={<FontAwesomeIcon name="chevron-right" />}
            onClick={() => {
              track('Open Event Interest Overlay', {
                component: 'Primary Share Button',
              });
              handleOpenOverlay();
            }}
          >
            Share Hot Take
          </Button>
          <EventInterestOverlay
            isVisible={interestOverlayIsVisible}
            showCommentSection={
              !Boolean(
                currentUserRsvp?.interested?.length || currentUserRsvp?.notInterested?.length,
              )
            }
            primaryRating={currentUserRsvp?.eventInterest}
            reasons={{
              interested: currentUserRsvp?.interested ?? [],
              notInterested: currentUserRsvp?.notInterested ?? [],
            }}
            onSubmit={handleSubmit}
            onClose={() => setInterestOverlayIsVisible(false)}
          />
        </>
      )}
    </div>
  );
};
