import { FC } from 'react';

import { useInAppMessageQuery } from '@queries/PostQueries';
import { useCurrentUser } from '@queries/UserQueries';

import { useLocation, useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import EventCard from '@components/cards/EventCard';
import Card from '@components/layout/Card/Card';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { Screen } from '@components/layout/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import './QueryScreen.scss';

interface QueryScreenProps {
  back?: string;
}

export const QueryScreen: FC<QueryScreenProps> = ({ back = '/discover' }) => {
  const { currentUser } = useCurrentUser();
  const { queryId } = useParams<{ queryId: string }>();
  const { query, queryIsReady } = useInAppMessageQuery(queryId);
  const navigate = useNavigate();

  const handleViewEvent = (eventId: string) => {
    navigate(`/events/${eventId}`);
  };

  return (
    <Screen name="Query Screen" headerProps={{ back: { to: back } }}>
      <Screen.Content>
        {queryIsReady && query?._id && (
          <>
            <div className="sender-card">
              <Card>
                <Card.Body>{mapStringToParagraphs(query.message)}</Card.Body>
              </Card>
              <div>
                <LetterAvatar letter={currentUser?.firstName?.[0]} />
              </div>
            </div>
            <div className="system-card">
              <div>
                <LetterAvatar letter={'G'} />
              </div>
              <Card>
                <Card.Body>{mapStringToParagraphs(query.response.message)}</Card.Body>
              </Card>
            </div>

            {query.response.associatedData.events.length > 0 && (
              <>
                <TitleToolbar text="Recommended Events" size="md" />
                <HorizontalScroll
                  items={query.response.associatedData.events.map((event) => (
                    <EventCard
                      key={event._id}
                      event={event}
                      onClick={() => handleViewEvent(event._id)}
                    />
                  ))}
                />
              </>
            )}

            {query.response.associatedData.venues.length > 0 && (
              <>
                <TitleToolbar text="Recommended Bars 🍻" size="md" />
                <HorizontalScroll
                  items={query.response.associatedData.venues.map((venue) => (
                    <Card key={venue.title}>
                      <Card.Header title={venue.title} />
                      <Card.Body>
                        <p>{venue.description ?? venue.tagline}</p>
                      </Card.Body>
                    </Card>
                  ))}
                />
              </>
            )}
          </>
        )}
      </Screen.Content>
    </Screen>
  );
};
