//swiper
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay, Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

type SwiperType = typeof SwiperReact & {
  Slide?: typeof SwiperSlide;
};
export const Swiper: SwiperType = SwiperReact;
Swiper.Slide = SwiperSlide;
