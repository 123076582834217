import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import * as dayjs from 'dayjs';

import {
  AnswerTriviaQuestionResponse,
  TriviaQuestion as TriviaQuestionType,
  useFetchUserCurrentTriviaQuestion,
} from '@queries/TriviaQueries';

import {
  useAnswerTriviaQuestionMutation,
  usePlayAppTriviaChallengeMutation,
} from '@mutations/TriviaMutations';

import Card from '@components/Card';
import Button from '@components/buttons/Button';
import { Screen } from '@components/layout/Screen/Screen';

import './TriviaScreen.scss';

const useShortAnswerState = () => {
  const textFieldRef = useRef<HTMLInputElement>();

  useLayoutEffect(() => {
    textFieldRef.current?.focus();
  }, []);

  return { textFieldRef };
};

const TIME_LIMIT = 10;
const WARNING_TIME = 3;

interface TriviaQuestionProps {
  currentQuestion: TriviaQuestionType;
  currentResponse?: AnswerTriviaQuestionResponse;
  timeRemaining: number;
  handleNextQuestion: () => void;
  handleAnswerTriviaQuestion: (answer: string) => Promise<void>;
}
const TriviaQuestion: FC<TriviaQuestionProps> = ({
  currentQuestion,
  currentResponse,
  timeRemaining,
  handleNextQuestion,
  handleAnswerTriviaQuestion,
}) => {
  const { textFieldRef } = useShortAnswerState();
  const [updatedAnswer, setUpdatedAnswer] = useState<string>();

  const answer = useMemo(() => {
    return updatedAnswer ?? currentResponse?.answer;
  }, [updatedAnswer, currentResponse?.answer]);

  const isExpired = useMemo(() => {
    if (currentResponse?.expiresAt) {
      return dayjs().isAfter(dayjs(currentResponse.expiresAt), 'seconds');
    }

    return false;
  }, [currentResponse?.expiresAt]);

  const timeText = useMemo(() => {
    if (isExpired) {
      return "Time's up!";
    }

    return timeRemaining ? timeRemaining : "Time's up!";
  }, [isExpired, timeRemaining]);

  const nextButtonIsVisible = useMemo(
    () => !timeRemaining || currentResponse?.correctAnswer,
    [currentResponse?.correctAnswer, timeRemaining],
  );

  const handleSubmit = () => {
    console.log('this needs to be reimplemented');
  };

  if (!currentQuestion?.question) {
    return <></>;
  }

  return (
    <div className="trivia-question">
      <Form>
        <div className="d-grid gap-4">
          <div className="text-center">
            <div
              className={`trivia-question_time-remaining ${
                timeRemaining <= WARNING_TIME && timeRemaining > 0 ? 'text-danger' : ''
              }`}
            >
              <span>{timeText}</span>
            </div>
          </div>
          <div className="d-grid gap-5 trivia-question_question-card">
            <div className="d-grid gap-1">
              <div className="text-start">
                <span className="text-black-50">{`Question ${currentQuestion.progress?.current} `}</span>
                <span className="fs-4">{`/ ${currentQuestion.progress?.total}`}</span>
              </div>
              <div className="trivia-question_question">
                <span className={!timeRemaining ? 'blur-text' : ''}>
                  {currentQuestion.question}
                </span>
              </div>
            </div>
            <div className="d-grid gap-3">
              {currentQuestion.type === 'shortAnswer' && (
                <>
                  <Form.Group>
                    <Form.Control
                      ref={textFieldRef}
                      type="text"
                      placeholder="Answer"
                      size="lg"
                      onChange={(event) => setUpdatedAnswer(event.target.value)}
                    />
                  </Form.Group>
                  <Button size="lg" disabled={!timeRemaining || !answer} onClick={handleSubmit}>
                    Submit Answer
                  </Button>
                </>
              )}
              {currentQuestion.type === 'multipleChoice' && (
                <>
                  {currentQuestion?.options?.map((option) => (
                    <Button
                      key={option}
                      className={`trivia-question_question-option ${
                        option === currentResponse?.correctAnswer ? 'is-correct' : ''
                      } ${
                        currentResponse && !currentResponse.isCorrect && option === answer
                          ? 'is-incorrect'
                          : ''
                      }`}
                      size="lg"
                      onClick={() => handleAnswerTriviaQuestion(option)}
                    >
                      {option}
                    </Button>
                  ))}
                </>
              )}
            </div>
          </div>
          {nextButtonIsVisible && (
            <div className="d-flex justify-content-end">
              <Button className="trivia-question_next-btn" size="lg" onClick={handleNextQuestion}>
                Next
              </Button>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

const useTriviaGame = () => {
  const { currentTriviaQuestion, currentTriviaQuestionIsReady } =
    useFetchUserCurrentTriviaQuestion();
  const [fetchedTriviaQuestion, setFetchedTriviaQuestion] = useState<TriviaQuestionType>();
  const [updatedCanRequestNewQuestion, setUpdatedCanRequestNewQuestion] = useState<boolean>();
  const { playAppTriviaChallengeAsync } = usePlayAppTriviaChallengeMutation();
  const { answerTriviaQuestionAsync } = useAnswerTriviaQuestionMutation();

  const [hasFetchedNewQuestion, setHasFetchedNewQuestion] = useState(false);
  const [updatedResponse, setUpdatedResponse] = useState<Partial<AnswerTriviaQuestionResponse>>();
  const [timeRemaining, setTimeRemaining] = useState<number>(TIME_LIMIT);
  const timerRef = useRef<NodeJS.Timeout>();

  const currentQuestion = hasFetchedNewQuestion
    ? fetchedTriviaQuestion
    : currentTriviaQuestion?.currentQuestion;
  const currentResponse = hasFetchedNewQuestion
    ? updatedResponse
    : currentTriviaQuestion?.currentResponse;

  const startTimer = (seconds: number) => {
    setTimeRemaining(seconds);

    timerRef.current = setInterval(() => {
      setTimeRemaining((prevTimeRemaining) => {
        const updatedTimeRemaining = prevTimeRemaining - 1;
        if (updatedTimeRemaining) {
          return updatedTimeRemaining;
        } else {
          handleAnswerTriviaQuestion('EMPTY_ANSWER');
          clearInterval(timerRef.current);
          return 0;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    if (currentTriviaQuestion?.currentResponse?.expiresAt) {
      const timer = dayjs(currentTriviaQuestion.currentResponse.expiresAt).diff(dayjs(), 'seconds');
      if (timer > 0) {
        startTimer(timer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTriviaQuestion?.currentResponse?.expiresAt]);

  const handlePlayTrivia = async () => {
    const { canRequestNewQuestion, currentQuestion } = await playAppTriviaChallengeAsync({});
    setFetchedTriviaQuestion(currentQuestion);
    setUpdatedCanRequestNewQuestion(canRequestNewQuestion);
    setUpdatedResponse({});
    setHasFetchedNewQuestion(true);
    startTimer(TIME_LIMIT);
  };

  const handleAnswerTriviaQuestion = async (answer: string) => {
    const submitResponse = await answerTriviaQuestionAsync({
      triviaQuestionId: currentQuestion._id,
      answer,
    });
    setUpdatedResponse(submitResponse);
    clearInterval(timerRef.current);
  };

  return {
    currentTriviaQuestionIsReady,
    currentTriviaQuestion: currentQuestion,
    currentResponse,
    canRequestNewQuestion:
      updatedCanRequestNewQuestion ?? currentTriviaQuestion?.canRequestNewQuestion,
    hasTriviaQuestion: !!currentQuestion?.question,
    handlePlayTrivia,
    handleAnswerTriviaQuestion,
    timeRemaining,
  };
};

interface TriviaGameProps {}
const TriviaGame: FC<TriviaGameProps> = () => {
  const {
    currentTriviaQuestionIsReady,
    currentTriviaQuestion,
    currentResponse,
    canRequestNewQuestion,
    handlePlayTrivia,
    handleAnswerTriviaQuestion,
    hasTriviaQuestion,
    timeRemaining,
  } = useTriviaGame();

  // const currentQuestion = {
  //   canRequestNewQuestion: false,
  //   question: "Which queen was criticized for not being able to dance in a 'funky' way?",
  //   type: 'multipleChoice',
  //   options: ["Mhi'ya", 'Q', 'Plane', 'Dawn'],
  // } as any;

  if (!currentTriviaQuestionIsReady) {
    return <></>;
  }

  return (
    <div>
      {hasTriviaQuestion ? (
        <TriviaQuestion
          currentQuestion={currentTriviaQuestion}
          currentResponse={currentResponse as any}
          timeRemaining={timeRemaining}
          handleNextQuestion={handlePlayTrivia}
          handleAnswerTriviaQuestion={handleAnswerTriviaQuestion}
        />
      ) : (
        <div className="d-grid gap-2">
          {canRequestNewQuestion ? (
            <>
              <Card>
                <Card.Body>
                  <p className="fs-4">
                    Ready for some trivia fun? Tap the button below to get today's queer trivia
                    question and compete against others to win!
                  </p>
                  <p className="fs-4">
                    Quick heads up: you've got just 10 seconds for each question.
                  </p>
                  <p className="fs-4">
                    Remember, you can come back every week for a new challenge.
                  </p>
                </Card.Body>
              </Card>
              <Button onClick={handlePlayTrivia} size="lg">
                Play Trivia
              </Button>
            </>
          ) : (
            <Card>
              <Card.Body>
                <p className="fs-4">
                  You already answered all the trivia questions for this week's challenge. Turn on
                  SMS notifications to get updated when the next challenge is ready!
                </p>
              </Card.Body>
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

export const TriviaScreen: FC<{}> = () => {
  return (
    <Screen name="Trivia Screen" headerProps={{ title: 'Daily Trivia' }}>
      <Screen.Content>
        <TriviaGame />
      </Screen.Content>
    </Screen>
  );
};
