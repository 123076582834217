import { CSSProperties, FC } from 'react';

const DEFAULT_SIZE = 18;
export const VerifiedIcon: FC<{ style?: CSSProperties }> = ({ style = {} }) => {
  const { width = DEFAULT_SIZE, height = DEFAULT_SIZE } = style;

  return (
    <div style={{ display: 'inline-flex', ...style, width, height }}>
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <linearGradient
          id="New_Gradient_Swatch"
          gradientUnits="userSpaceOnUse"
          x1="2.698"
          x2="13.303"
          y1="2.698"
          y2="13.303"
        >
          <stop offset="0" stop-color="#1283ff"></stop>
          <stop offset="1" stop-color="#d21aff"></stop>
        </linearGradient>
        <path
          d="m15.04 6.89-1.11-1.11a.5608.5608 0 0 1 -.17-.4v-1.58a1.5619 1.5619 0 0 0 -1.56-1.56h-1.58a.5613.5613 0 0 1 -.4-.17l-1.11-1.11a1.5755 1.5755 0 0 0 -2.22 0l-1.11 1.11a.5613.5613 0 0 1 -.4.17h-1.58a1.5619 1.5619 0 0 0 -1.56 1.56v1.58a.5608.5608 0 0 1 -.17.4l-1.11 1.11a1.5751 1.5751 0 0 0 0 2.22l1.11 1.11a.5608.5608 0 0 1 .17.4v1.58a1.5619 1.5619 0 0 0 1.56 1.56h1.58a.5613.5613 0 0 1 .4.17l1.11 1.11a1.5692 1.5692 0 0 0 2.22 0l1.11-1.11a.5613.5613 0 0 1 .4-.17h1.58a1.5619 1.5619 0 0 0 1.56-1.56v-1.58a.5608.5608 0 0 1 .17-.4l1.11-1.11a1.5751 1.5751 0 0 0 0-2.22zm-4.69.13-2.66 2.67a.5195.5195 0 0 1 -.71 0l-1.33-1.34a.495.495 0 0 1 .7-.7l.98.98 2.32-2.32a.5011.5011 0 0 1 .7 0 .4949.4949 0 0 1 0 .71z"
          fill="url(#New_Gradient_Swatch)"
        ></path>
      </svg>
    </div>
  );
};
