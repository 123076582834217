export const SendBadgeIcon = ({ height, width }: { height?: number; width?: number }) => (
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" height={height} width={width}>
    <g id="_19-award" data-name="19-award">
      <path d="m10 36h28v2h-28z" fill="#f86b00" />
      <path d="m10 36v4h-9l2-3-2-3h6v2z" fill="#ffa233" />
      <path d="m47 34-2 3 2 3h-9v-4h3v-2z" fill="#ffa233" />
      <path d="m34 2.25v8.75h-20v-8.75l6 2.75 4-4 4 4z" fill="#ffdc56" />
      <path
        d="m9 17 1.85 10.66-3.03-1.9a6 6 0 0 1 -2.82-5.09v-2.09a5.966 5.966 0 0 1 -2-4.46v-3.47a5.966 5.966 0 0 1 -2-4.46v-5.19l5.32 1.99a6.034 6.034 0 0 1 3.59 3.73l2 6.02z"
        fill="#d6e0fb"
      />
      <path
        d="m45 10.65v3.47a5.966 5.966 0 0 1 -2 4.46v2.09a6 6 0 0 1 -2.82 5.09l-3.03 1.9 1.85-10.66-2.91-4.26 2-6.02a6.034 6.034 0 0 1 3.59-3.73l5.32-1.99v5.19a5.966 5.966 0 0 1 -2 4.46z"
        fill="#d6e0fb"
      />
      <path d="m11.91 12.74 1.14-1.65 1.95 3.91-2 3-4-1z" fill="#4f4eff" />
      <path d="m10.85 27.66-1.85-10.66 4 1 3 20-3 2z" fill="#805bff" />
      <path d="m36.09 12.74 2.91 4.26-4 1-2-3 1.95-3.91z" fill="#5c52ff" />
      <path d="m37.15 27.66-2.15 12.34-3-2 3-20 4-1z" fill="#805bff" />
      <path d="m34.95 11.09-1.95 3.91h-18l-1.95-3.91.06-.09h21.78z" fill="#805bff" />
      <path d="m33 15 2 3-3 20-8 5-8-5-3-20 2-3z" fill="#af77ff" />
      <path d="m33 15h-17.95a18.957 18.957 0 0 0 17.709 17.937l2.241-14.937z" fill="#b988ff" />
      <path d="m41 34v2h-3-2.3-3.4-16.6-3.4-2.3-3v-2-4h4.26 3.54 18.4 3.54 4.26z" fill="#ffdc56" />
      <path d="m16 38-3 2 11 7v-4z" fill="#514ef0" />
      <path d="m24 43v4l11-7-3-2z" fill="#5c52ff" />
      <path d="m41 34v2h-3v1h7l2-3z" fill="#ffb337" />
      <path d="m7 34v2h3v1h-7l-2-3z" fill="#ffb337" />
      <path d="m8 31h32v4h-32z" fill="#fff86a" />
      <path
        d="m28 5-4-4-4 4-6-2.75v3l4.736 2.171a2 2 0 0 0 2.247-.4l3.017-3.021 3.017 3.017a2 2 0 0 0 2.247.4l4.736-2.167v-3z"
        fill="#fff86a"
      />
      <path d="m14 10h20v1h-20z" fill="#ffca46" />
      <path
        d="m8 20.67v-2.09a5.966 5.966 0 0 1 -2-4.46v-3.47a5.966 5.966 0 0 1 -2-4.46v-4.068l-3-1.122v5.19a5.966 5.966 0 0 0 2 4.46v3.47a5.966 5.966 0 0 0 2 4.46v2.09a6 6 0 0 0 2.82 5.09l3.03 1.9-.372-2.145a6 6 0 0 1 -2.478-4.845z"
        fill="#e9e9ff"
      />
      <path
        d="m44 2.122v5.068a5.966 5.966 0 0 1 -2 4.46v3.47a5.966 5.966 0 0 1 -2 4.46v2.09a6 6 0 0 1 -2.676 4.987l-.174 1 3.03-1.9a6 6 0 0 0 2.82-5.087v-2.09a5.966 5.966 0 0 0 2-4.46v-3.47a5.966 5.966 0 0 0 2-4.46v-5.19z"
        fill="#e9e9ff"
      />
      <path d="m16 38-.3-2h-3.397l.349 2h3.048z" fill="#514ef0" />
      <path d="m32.3 36-.3 2h.3 3.048l.349-2z" fill="#514ef0" />
      <path d="m15.7 36 .3 2h16l.3-2z" fill="#805bff" />
      <path d="m43 34v3h-3v3h-2v-4h3v-2z" fill="#ff8d1f" />
      <path d="m5 34v3h3v3h2v-4h-3v-2z" fill="#ff8d1f" />
      <g fill="#ffca46">
        <path d="m14.5 30.5a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 0-1h-15a.5.5 0 0 0 -.5.5z" />
        <path d="m31.5 30.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 0-1h-1a.5.5 0 0 0 -.5.5z" />
        <rect height="1" rx=".5" width="6" x="10" y="35" />
        <rect height="1" rx=".5" width="2" x="17" y="35" />
      </g>
      <ellipse
        cx="30.684"
        cy="18.695"
        fill="#eaf6ff"
        rx="1.642"
        ry="2.286"
        transform="matrix(.707 -.707 .707 .707 -4.229 27.184)"
      />
      <ellipse
        cx="31.682"
        cy="22.708"
        fill="#eaf6ff"
        rx=".825"
        ry="1.148"
        transform="matrix(.707 -.707 .707 .707 -6.775 29.067)"
      />
      <ellipse
        cx="20.427"
        cy="25.208"
        fill="#eaf6ff"
        rx=".413"
        ry=".574"
        transform="matrix(.707 -.707 .707 .707 -11.843 21.839)"
      />
    </g>
  </svg>
);
