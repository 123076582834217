import { FC } from 'react';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';

import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useNavigate, useRouter } from '@router/index';

import Card from '@components/layout/Card/Card';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';

import { CreateEditEntertainerProfileForm } from './CreateEntertainerProfileScreen';

export const EditEntertainerProfileScreen: FC<{}> = () => {
  const { entertainer } = useCurrentEntertainerQuery();
  const { currentCity, cities } = useCurrentUserCity();
  const { back } = useRouter();

  const handleSave = () => {
    back();
  };

  return (
    <Screen
      name="Edit Entertainer Profile Screen"
      hideToolbar
      headerProps={{
        back: { to: '/manage/entertainer' },
        title: 'Edit Profile',
      }}
    >
      <Screen.Content maxWidth={425}>
        {entertainer && currentCity ? (
          <CreateEditEntertainerProfileForm
            currentCity={currentCity}
            cities={cities}
            entertainer={entertainer}
            onSave={handleSave}
          />
        ) : (
          <>
            <Skeleton height={240} />
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
            <Skeleton height={42} />
          </>
        )}
      </Screen.Content>
    </Screen>
  );
};
