import {
  MutableRefObject,
  SelectHTMLAttributes,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { uniqBy } from 'lodash';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: { label: string; value: string }[];
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ options, ...props }, forwardedRef) => {
    const internalRef = useRef<HTMLSelectElement>();
    const ref = forwardedRef ?? internalRef;

    const handleClick = () => {
      if (internalRef.current) {
        internalRef.current.click();
      } else {
        // Check if forwardedRef is a function or an object
        if (typeof forwardedRef === 'function') {
          // If it's a function, call it with the current value
          forwardedRef(internalRef.current);
        } else if (forwardedRef && 'current' in forwardedRef) {
          // If it's an object with a 'current' property, access it directly
          forwardedRef.current?.click();
        }
      }
    };

    const sanitizedOptions = useMemo(() => uniqBy(options, 'value'), [options]);

    return (
      <div className="select" onClick={handleClick}>
        <select ref={ref} {...props}>
          {sanitizedOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div>
          <FontAwesomeIcon name="chevron-down" />
        </div>
      </div>
    );
  },
);
