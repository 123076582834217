import { useMutation } from '@tanstack/react-query';
import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import * as dayjs from 'dayjs';

import API from '@api/API';

import { useCurrentPromoterQuery, usePromoterManageQuery } from '@queries/PromoterQueries';
import { useCurrentUser, useUserConversationsQuery } from '@queries/UserQueries';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserCity, useCurrentUserLocation } from '@hooks/useCurrentUserLocation';

import { useNavigate, useRouter } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import EventCard, { PromoterHeader } from '@components/cards/EventCard';
import { Form } from '@components/form/Form';
import { Input } from '@components/form/Input';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { EventbriteCard } from './EventbriteCard';
import './ManagePromoterProfileScreen.scss';
import { OpenBookingCard } from './OpenBookingCard';
import { PromoterForm } from './PromoterForm';

export const EventbriteSection = () => {
  const { promoter } = useCurrentPromoterQuery();

  return (
    <>
      {promoter ? (
        <>
          <TitleToolbar text="Connected!" />
          <Card>
            <Card.Header title={promoter?.title} />
            <Card.Body>
              <p>
                Your Eventbrite organizer profile is connected. There's nothing left for you to do.
              </p>
            </Card.Body>
          </Card>
        </>
      ) : (
        <EventbriteCard />
      )}
    </>
  );
};

export const InstagramPost: FC<{
  sourceLogWithImage: any;
  onResizeComplete: () => void;
  onImageLoadError: () => void;
}> = ({ sourceLogWithImage, onResizeComplete, onImageLoadError }) => {
  const [imageDidLoad, setImageDidLoad] = useState(false);
  const [didCaptureTargetHeight, setDidCaptureTargetHeight] = useState(false);
  const [targetHeight, setTargetHeight] = useState<number>();
  const [maxCharacterCount, setMaxCharacterCount] = useState(100);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (imageDidLoad && containerRef.current?.clientHeight) {
      setTargetHeight(containerRef.current.clientHeight);
      setDidCaptureTargetHeight(true);
    }
  }, [imageDidLoad]);

  useLayoutEffect(() => {
    if (didCaptureTargetHeight && targetHeight) {
      if (containerRef.current?.clientHeight > targetHeight) {
        setMaxCharacterCount((prev) => prev - 5);
      } else {
        onResizeComplete();
      }
    }
  }, [didCaptureTargetHeight, targetHeight, maxCharacterCount]);

  return (
    <div key={sourceLogWithImage._id} ref={containerRef} className="ig-post d-flex gap-2">
      <div className="ig-post_img-wrapper">
        <AsyncImage
          src={sourceLogWithImage.imageUrl}
          alt="instagram"
          onLoad={() => setImageDidLoad(true)}
          onError={onImageLoadError}
        />
      </div>
      <div>
        {didCaptureTargetHeight && (
          <div className="ig-post_description-wrapper">
            {/* <span>
              <strong>
                {`Posted ${dayjs(sourceLogWithImage?.postForProcessing?.postedAt).format('M/D/YY')}`}
              </strong>
            </span> */}
            {mapStringToParagraphs(
              `${sourceLogWithImage?.postForProcessing?.text?.substring(0, maxCharacterCount)}...`,
            )}
          </div>
        )}
        <div className="d-flex gap-2">
          <Button variant={sourceLogWithImage.hasEvent ? 'outlined' : 'default'}>
            {sourceLogWithImage.hasEvent ? 'Manage Event' : 'Create Event'}
          </Button>
          <Button variant="outlined" color="neutral">
            Ignore
          </Button>
        </div>
      </div>
    </div>
  );
};

const INSTAGRAM_ENABLED = false;

const EmptyPromoterProfile = () => {
  return (
    <div className="grid gap-2">
      <div>
        <TitleToolbar text="We're still in beta!" size="md" />
        <p>
          But don't worry! Working with us is totally free at the moment and you can still get a
          promoter account. Tell us a little about yourself below and we'll get back to you ASAP!
        </p>
      </div>
      <Input placeholder="Email Address" />
      <Textarea placeholder="Tell us why you want to promote with Gaggl..." onChange={() => {}} />
      <Button color="neutral">Submit</Button>
    </div>
  );
};

const EmptyPromoterProfileToCome = () => {
  return (
    <div className="grid gap-2">
      <div>
        <TitleToolbar text="We're still in beta!" size="md" />
        <p>
          But don't worry! You'll be able to do free promotions with us soon. Stay tuned for
          updates!
        </p>
      </div>
    </div>
  );
};

export const ManagePromoterProfileScreen: FC<{ back?: string }> = ({ back: backlinkUrl }) => {
  const [welcomeMessageIsVisible, setWelcomeMessageIsVisible] = useState(true);
  const [hiddenInstagramPosts, setHiddenInstagramPosts] = useState<Record<string, boolean>>({});
  const { currentCity, cities } = useCurrentUserCity();
  const { promoterIsReady, promoter } = useCurrentPromoterQuery();
  const { promoterDetails, promoterDetailsAreReady } = usePromoterManageQuery(promoter?._id);
  const [currentResizeIndex, setCurrentResizeIndex] = useState(0);
  const { navigate, back } = useRouter();

  const hasUserHasPromoterProfile = promoterIsReady && promoter;

  const { upcomingEvents, instagramPosts, openBookings } = useMemo(() => {
    const today = dayjs();

    return {
      upcomingEvents:
        promoterDetails?.events?.filter(
          (event) => dayjs(event.startDate).isAfter(today) || dayjs(event.startDate).isSame(today),
        ) ?? [],
      instagramPosts:
        promoterDetails?.instagramPosts?.filter(
          (sourceLogWithImage) => !hiddenInstagramPosts[sourceLogWithImage._id],
        ) ?? [],
      openBookings: promoterDetails?.bookingOpportunities ?? [],
    };
  }, [promoterDetails, hiddenInstagramPosts]);

  const handleCreateEvent = () => {
    navigate('/manage/promoter/events/create-edit');
  };

  const handleCreateBookingOpportunity = () => {
    navigate('/open-bookings/create-edit');
  };

  const handleResizeComplete = (index: number) => {
    setCurrentResizeIndex(index + 1);
  };

  const { userConversations } = useUserConversationsQuery();

  return (
    <Screen
      name="Manage Promoter Profile Screen"
      headerProps={{
        left: (
          <Button
            startIcon={<FontAwesomeIcon name="chevron-left" />}
            variant="smooth"
            color="neutral"
            roundness="rounded"
            className="py-2"
            onClick={() => back('/profile')}
          >
            Events
          </Button>
        ),
        title: <Screen.GagglTitle />,
        right:
          hasUserHasPromoterProfile && !!userConversations.promoter.length ? (
            <IconButton
              color="neutral"
              variant="smooth"
              badge={userConversations.promoter.some(({ isUnread }) => isUnread)}
              onClick={() => {
                navigate('/conversations/promoter', {
                  state: { back: '/manage/promoter' },
                });
              }}
            >
              <FontAwesomeIcon name="paper-plane" />
            </IconButton>
          ) : undefined,
      }}
      hideToolbar
    >
      <Screen.Content maxWidth={hasUserHasPromoterProfile ? undefined : 425}>
        {hasUserHasPromoterProfile ? (
          <>
            {promoter.permissions.status === 'approved' ? (
              <div className="grid">
                <div>
                  <TitleToolbar
                    className="title-toolbar-welcome"
                    text={promoter.title}
                    action={
                      <Button
                        className="py-2 px-3"
                        roundness="rounded"
                        variant="smooth"
                        color="neutral"
                        onClick={() => navigate('/manage/promoter/edit')}
                      >
                        Edit Profile
                      </Button>
                    }
                  />
                  <TitleToolbar
                    text="Manage Upcoming Events 🎉"
                    size="md"
                    action={
                      <Button
                        variant="flat"
                        size="sm"
                        startIcon={<FontAwesomeIcon name="plus" />}
                        onClick={handleCreateEvent}
                      >
                        Event
                      </Button>
                    }
                  />
                </div>

                {promoterDetailsAreReady ? (
                  <>
                    {!!upcomingEvents.length ? (
                      <HorizontalScroll
                        items={
                          upcomingEvents.map((event) => (
                            <EventCard
                              key={event._id}
                              showPromoter={false}
                              event={event}
                              onClick={() => {}}
                            />
                          )) ?? []
                        }
                      />
                    ) : (
                      <EmptyState
                        title="No upcoming events"
                        text="You don't have any upcoming events. Create one here to share with the public."
                        button={{
                          text: 'Create Event',
                          onClick: handleCreateEvent,
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Skeleton height={200} />
                )}

                <TitleToolbar
                  text="Open Bookings 📖"
                  size="md"
                  action={
                    <Button
                      variant="flat"
                      size="sm"
                      startIcon={<FontAwesomeIcon name="plus" />}
                      onClick={handleCreateBookingOpportunity}
                    >
                      Create
                    </Button>
                  }
                />
                {promoterDetailsAreReady ? (
                  <>
                    {!!openBookings.length ? (
                      <HorizontalScroll
                        items={
                          openBookings.map((openBooking) => (
                            <OpenBookingCard
                              key={openBooking._id}
                              bookingOpportunity={openBooking}
                            />
                          )) ?? []
                        }
                      />
                    ) : (
                      <EmptyState
                        title="No open bookings"
                        text="You don't have any open bookings. Create one here if you want to share your booking opportunity with local entertainers."
                        button={{
                          text: 'Create Open Booking',
                          onClick: handleCreateBookingOpportunity,
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Skeleton height={140} />
                )}

                {INSTAGRAM_ENABLED && (
                  <>
                    <TitleToolbar
                      text={
                        <div className="d-flex align-items-center gap-2">
                          <span>Instagram Posts</span>
                          <FontAwesomeIcon faStyle="fab" name="instagram" />
                        </div>
                      }
                      size="md"
                    />
                    <div className="grid gap-4">
                      {instagramPosts
                        .slice(0, currentResizeIndex + 1)
                        .map((sourceLogWithImage, i) => (
                          <InstagramPost
                            key={sourceLogWithImage._id}
                            sourceLogWithImage={sourceLogWithImage}
                            onResizeComplete={() => handleResizeComplete(i)}
                            onImageLoadError={() =>
                              setHiddenInstagramPosts({
                                ...hiddenInstagramPosts,
                                [sourceLogWithImage._id]: true,
                              })
                            }
                          />
                        ))}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <EmptyState
                title="Promoter Request Pending"
                text="We are still reviewing your promoter profile. Thank you for your patience!"
              />
            )}
          </>
        ) : (
          <>
            {welcomeMessageIsVisible ? (
              <>
                <TitleToolbar text="Enhance Your Event Planning Experience" size="md" />
                <p>
                  As a Gaggl member, you already know the value of discovering amazing events. If
                  you&apos;re an event organizer, maximize our platform&apos;s potential! Share gig
                  opportunities with talented entertainers, promote your events, and manage booking
                  requests seamlessly.
                </p>
                <p>
                  Gaggl helps you connect with performers, streamline your event planning, and
                  create unforgettable experiences. Enhance your profile today and elevate your
                  event planning to the next level!
                </p>
                <Button onClick={() => setWelcomeMessageIsVisible(false)}>
                  Create Producer Profile
                </Button>
              </>
            ) : (
              <PromoterForm currentCity={currentCity} cities={cities} />
            )}
          </>
        )}
      </Screen.Content>
    </Screen>
  );
};
