import { FC, ImgHTMLAttributes, ReactElement, useEffect, useState } from 'react';

interface AsyncImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  placeholder?: ReactElement;
  onLoad?: () => void;
  onError?: () => void;
}

export const AsyncImage: FC<AsyncImageProps> = ({
  alt,
  placeholder = <></>,
  onLoad,
  onError,
  ...props
}) => {
  const [loadedSrc, setLoadedSrc] = useState<string>();

  useEffect(() => {
    setLoadedSrc(undefined);

    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
        onLoad?.();
      };
      const image = new Image();
      image.addEventListener('load', handleLoad);
      image.addEventListener('error', onError);
      image.src = props.src;
      return () => {
        image.removeEventListener('load', handleLoad);
        image.removeEventListener('error', onError);
      };
    }
  }, [props.src]);

  if (loadedSrc === props.src) {
    return <img {...props} alt={alt} />;
  }

  return placeholder;
};
