import { useMemo } from 'react';

import { capitalize, groupBy } from 'lodash';

import {
  BOOKING_OPPORTUNITY_ENTERTAINER_STATUSES,
  PublicBookingOpportunity,
} from '@queries/PromoterQueries';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import Card from '@components/layout/Card/Card';

export const OpenBookingCard = ({
  bookingOpportunity,
}: {
  bookingOpportunity: PublicBookingOpportunity;
}) => {
  const navigate = useNavigate();
  const subtitle = useMemo(() => {
    if (!bookingOpportunity.entertainers.length) {
      return 'No Interested Entertainers';
    }
    const statusGroups = groupBy(bookingOpportunity.entertainers, 'status');

    return BOOKING_OPPORTUNITY_ENTERTAINER_STATUSES.filter(
      (status) => statusGroups?.[status]?.length,
    )
      .map((status) => {
        const entertainers = statusGroups[status] ?? [];
        return `${entertainers.length} ${capitalize(status)}`;
      })
      .join(' | ');
  }, [bookingOpportunity.entertainers]);

  return (
    <Card>
      <Card.Header
        tagline={bookingOpportunity.city?.name}
        title={bookingOpportunity.title}
        subtitle={subtitle}
      />
      <Card.Footer>
        <Button
          onClick={() => {
            navigate(`/manage/promoter/open-bookings/${bookingOpportunity._id}`);
          }}
        >
          Manage
        </Button>
      </Card.Footer>
    </Card>
  );
};
