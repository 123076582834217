import { Badge } from 'framework7-react';
import { ComponentProps, FC, PropsWithChildren } from 'react';

import Button from './Button';
import './IconButton.scss';

interface IconButtonProps extends PropsWithChildren<ComponentProps<typeof Button>> {
  // startIcon?: ReactElement;
  size?: 'sm' | 'md' | 'lg';
  badge?: boolean;
}
const IconButton: FC<IconButtonProps> = ({
  className = '',
  badge,
  size = 'md',
  children,
  ...rest
}) => (
  <Button className={`icon-btn icon-btn-size-${size} ${className} `} {...rest}>
    {badge && <Badge color="red" />}
    {children}
  </Button>
);

export default IconButton;
