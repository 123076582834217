import { Popup } from 'framework7-react';
import { FC, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { motion } from 'framer-motion';

import { BasicRating } from '@queries/CritiqueQueries';
import { FeedEvent } from '@queries/EventQueries';

import { CritiqueDocumentMutationPayload } from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { List } from '@components/layout/List/List';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import './CritiqueOverlay.scss';
import { BASIC_RATING_RECORD } from './CritiqueOverlayConstants';

interface PrimaryCritiqueButtonsProps {
  isPastTense?: boolean;
  value?: BasicRating;
  onChange: (value: BasicRating) => void;
}
export const PrimaryCritiqueButtons: FC<PrimaryCritiqueButtonsProps> = ({
  isPastTense = false,
  value: primaryRating,
  onChange,
}) => {
  return (
    <>
      <div className="d-flex justify-content-evenly primaryRating-options">
        {Object.entries(BASIC_RATING_RECORD).map(([rating, { Icon }]) => (
          <IconButton
            key={rating}
            className={`primaryRating-option primaryRating-option-${rating} ${primaryRating === rating ? 'selected' : ''}`}
            onClick={() => onChange(rating)}
          >
            <Icon isSelected={primaryRating === rating} />
          </IconButton>
        ))}
      </div>
    </>
  );
};

const BASIC_RATING_FOLLOW_UP_OPTIONS: Record<BasicRating, string[]> = {
  like: [
    'Great drinks',
    'Great food',
    'Great music',
    'Cozy atmosphere',
    'Unique cocktails',
    'Creative decor',
    'Welcoming staff',
    'Fun activities',
    'Comfortable seating',
    'Affordable prices',
    'Convenient location',
    'Clean environment',
    'Fun dance floor',
    'Genuine connections',
    'Diverse',
    'Inclusive',
    'Social',
    'Friendly',
  ],
  neutral: [
    'Unmemorable',
    'Stereotypical',
    'Mildly diverse',
    'Somewhat inclusive',
    'Average ambiance',
    'Standard menu',
    'Typical crowd',
    'Decent service',
    'Moderate pricing',
    'Average music selection',
    'Average food quality',
    'Ordinary atmosphere',
    'Common drinks',
  ],
  dislike: [
    'Rude People',
    'Bad Music',
    'Bad Drinks',
    'Bad Food',
    'Bad Service',
    'Overpriced',
    'Boring',
    'Crowded space',
    'Limited seating',
    'Slow service',
    'Loud atmosphere',
    'Poor hygiene',
    'Uncomfortable temperature',
    'Unorganized events',
    'Unfriendly staff',
    'Overcrowded dance floor',
  ],
};

interface CritiqueWrapperProps extends Partial<PropsWithChildren> {
  isPastTense?: boolean;
  walkthroughEnabled?: boolean;
  primaryRating: BasicRating;
  reasons?: Record<BasicRating, string[]>;
  onSubmit: (properties: CritiqueDocumentMutationPayload) => Promise<void>;
  onClose?: () => void;
}

type AdditionalReason = 'like' | 'dislike';

const sharedCardAnimationProps = {
  initial: { opacity: 0, scale: 0.5 },
  animate: { opacity: 1, scale: 1 },
};

export const CritiqueWrapper: FC<CritiqueWrapperProps> = ({
  isPastTense,
  walkthroughEnabled,
  primaryRating,
  reasons: initialReasons,
  children,
  onSubmit,
  onClose,
}) => {
  const [walkthroughStep, setWalkthroughStep] = useState<'reasons' | 'addAdditional'>();
  useEffect(() => {
    if (walkthroughEnabled) {
      setTimeout(() => {
        setWalkthroughStep('reasons');
      }, 2000);
    }
  }, []);

  const [followUpReasons, setFollowUpReasons] = useState<Record<BasicRating, string[]>>({
    like: [],
    neutral: [],
    dislike: [],
    ...initialReasons,
  });
  const [addCommentIsVisible, setAddCommentIsVisible] = useState(false);
  const [comment, setComment] = useState('');
  const { track } = useAnalytics();

  const [additionalReasonIsVisible, setAdditionalReasonIsVisible] = useState<AdditionalReason>();
  const [additionalReasons, setAdditionalReasons] = useState<Record<AdditionalReason, string[]>>({
    like: [],
    dislike: [],
  });

  const handleChangeFollowUpReasons = (updatedValues: string[]) => {
    setFollowUpReasons((prevValues) => ({
      ...prevValues,
      [primaryRating]: updatedValues,
    }));
  };

  const handleChangeAdditionalReasons = (updatedValues: string[]) => {
    setAdditionalReasons((prevValues) => ({
      ...prevValues,
      [additionalReasonIsVisible]: updatedValues,
    }));
  };

  const handleSubmit = () => {
    const payload: CritiqueDocumentMutationPayload = {
      primaryCritique: primaryRating,
      comment,
      [primaryRating]: followUpReasons[primaryRating],
    };

    if (primaryRating === 'neutral') {
      payload.like = additionalReasons.like;
      payload.dislike = additionalReasons.dislike;
    }

    if (primaryRating === 'like') {
      payload.dislike = additionalReasons.dislike;
    }

    if (primaryRating === 'dislike') {
      payload.like = additionalReasons.like;
    }

    onSubmit(payload);
  };

  const handleClose = () => {
    track('Close critique overlay');
    onClose?.();
  };

  return (
    <div>
      {children}

      <div className={`flex flex-column ${children ? 'mt-3' : ''}`}>
        {primaryRating ? (
          <>
            <Card {...sharedCardAnimationProps}>
              {additionalReasonIsVisible ? (
                <Card.Body>
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <h3 className="m-0">
                        {additionalReasonIsVisible === 'like' ? 'Positives' : 'Negatives'}
                      </h3>
                      <IconButton
                        variant="smooth"
                        color="neutral"
                        onClick={() => setAdditionalReasonIsVisible(undefined)}
                      >
                        <FontAwesomeIcon name="times" />
                      </IconButton>
                    </div>
                    <ChipRadioOptions
                      multiSelect
                      addCustomOptions
                      value={additionalReasons[additionalReasonIsVisible]}
                      options={BASIC_RATING_FOLLOW_UP_OPTIONS[additionalReasonIsVisible].map(
                        (option) => ({
                          value: option,
                          label: option,
                        }),
                      )}
                      onChange={handleChangeAdditionalReasons}
                    />
                  </div>
                </Card.Body>
              ) : (
                <Card.Body>
                  <div>
                    <div
                      className="d-flex justify-content-between align-items-center mb-2"
                      style={{ height: 32 }}
                    >
                      <h3 className="m-0">
                        <PopoverWrapper
                          isVisible={walkthroughStep === 'reasons'}
                          onClose={() => {
                            setTimeout(() => {
                              setWalkthroughStep('addAdditional');
                            }, 750);
                          }}
                          PopoverContent={({ onClose }) => (
                            <>
                              <div className="px-2">
                                <p>
                                  Select some reasons for your choice. These help other users (and
                                  yourself) find more venues that they might like.
                                </p>
                                <p>
                                  If you want to add your own options, click the "Add Custom"
                                  button.
                                </p>
                                <div className="pb-2">
                                  <Button color="neutral" variant="outlined" onClick={onClose}>
                                    Got it!
                                  </Button>
                                </div>
                              </div>
                            </>
                          )}
                        >
                          Reasons
                        </PopoverWrapper>
                      </h3>
                    </div>

                    <ChipRadioOptions
                      multiSelect
                      addCustomOptions
                      value={followUpReasons[primaryRating]}
                      options={BASIC_RATING_FOLLOW_UP_OPTIONS[primaryRating].map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      onChange={handleChangeFollowUpReasons}
                    />
                  </div>
                </Card.Body>
              )}
            </Card>

            <Card {...sharedCardAnimationProps}>
              {addCommentIsVisible ? (
                <>
                  <Card.Body className="px-0">
                    <Textarea
                      onChange={setComment}
                      placeholder="Add comment"
                      defaultValue={comment}
                      focusOnMount={!comment}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <div className="d-flex w-100 gap-2">
                      <div className="w-50">
                        <Button
                          variant="smooth"
                          color="neutral"
                          onClick={() => {
                            setComment('');
                            setAddCommentIsVisible(false);
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                      <div className="w-50">
                        <Button color="neutral" onClick={() => setAddCommentIsVisible(false)}>
                          Update
                        </Button>
                      </div>
                    </div>
                  </Card.Footer>
                </>
              ) : (
                <>
                  <PopoverWrapper
                    isVisible={walkthroughStep === 'addAdditional'}
                    onClose={() => setWalkthroughStep(undefined)}
                    PopoverContent={({ onClose }) => (
                      <>
                        <div className="px-2">
                          <p>
                            If you think of anything else, that might not match your original
                            rating, you can add those chips here.
                          </p>
                          <p>
                            If you want to add a detailed response, click the "Add Comment" button.
                          </p>
                          <div className="pb-2">
                            <Button color="neutral" variant="outlined" onClick={onClose}>
                              Got it!
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  >
                    <div />
                  </PopoverWrapper>
                  <List
                    listItems={[
                      {
                        isVisible: primaryRating !== 'like' && additionalReasonIsVisible !== 'like',
                        title: additionalReasons.like.length ? 'Update Positives' : 'Add Positives',
                        onClick: () => setAdditionalReasonIsVisible('like'),
                      },
                      {
                        isVisible:
                          primaryRating !== 'dislike' && additionalReasonIsVisible !== 'dislike',
                        title: additionalReasons.dislike.length
                          ? 'Update Negatives'
                          : 'Add Negatives',
                        onClick: () => setAdditionalReasonIsVisible('dislike'),
                      },
                      {
                        title: comment ? 'Edit Comment' : 'Add Comment',
                        onClick: () => setAddCommentIsVisible(true),
                      },
                    ]}
                  />
                </>
              )}
            </Card>

            <Card {...sharedCardAnimationProps}>
              <Button onClick={handleSubmit}>Done</Button>
            </Card>
          </>
        ) : (
          <>
            {onClose && (
              <Card {...sharedCardAnimationProps}>
                <Button color="default" onClick={handleClose}>
                  Nevermind
                </Button>
              </Card>
            )}
          </>
        )}
      </div>
    </div>
  );
};

interface CritiqueOverlayProps {
  isVisible: boolean;
  walkthroughEnabled?: boolean;
  primaryRating?: BasicRating;
  reasons?: Record<BasicRating, string[]>;
  upperContent?: ReactElement;
  onSubmit: (properties: CritiqueDocumentMutationPayload) => Promise<void>;
  onClose?: () => void;
}

export const CritiqueOverlay: FC<CritiqueOverlayProps> = ({
  isVisible: controlledIsVisible,
  walkthroughEnabled,
  primaryRating: initialPrimaryRating,
  reasons: initialReasons,
  upperContent,
  onSubmit,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [primaryRating, setPrimaryRating] = useState<BasicRating>(initialPrimaryRating);

  useEffect(() => {
    setPrimaryRating(initialPrimaryRating);
  }, [initialPrimaryRating]);

  useEffect(() => {
    setIsVisible(controlledIsVisible);
  }, [controlledIsVisible]);

  const handleClose = onClose
    ? () => {
        setIsVisible(false);
        onClose();
      }
    : undefined;

  if (!isVisible) {
    return <></>;
  }

  return createPortal(
    <motion.div
      className="generic-rating-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="generic-rating-overlay-content">
        <CritiqueWrapper
          primaryRating={primaryRating}
          reasons={initialReasons}
          walkthroughEnabled={walkthroughEnabled}
          onSubmit={async (properties) => {
            await onSubmit(properties);
            setIsVisible(false);
          }}
          onClose={handleClose}
        >
          {upperContent}
          <Card>
            <Card.Body>
              <PrimaryCritiqueButtons value={primaryRating} onChange={setPrimaryRating} />
            </Card.Body>
          </Card>
        </CritiqueWrapper>
      </div>
    </motion.div>,
    document.getElementById('framework7-root'),
  );
};
