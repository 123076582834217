import { f7 } from 'framework7-react';
import { useState } from 'react';

export const useAttachments = () => {
  const images: string[] = [
    // Image urls for attachments
  ];

  const [attachments, setAttachments] = useState([]);
  const [sheetVisible, setSheetVisible] = useState(false);

  const deleteAttachment = (image) => {
    const index = attachments.indexOf(image);
    attachments.splice(index, 1);
    setAttachments([...attachments]);
  };
  const handleAttachment = (e) => {
    const index = f7.$(e.target).parents('label.checkbox').index();
    const image = images[index];
    if (e.target.checked) {
      // Add to attachments
      attachments.unshift(image);
    } else {
      // Remove from attachments
      attachments.splice(attachments.indexOf(image), 1);
    }
    setAttachments([...attachments]);
  };

  return {
    images,
    attachments,
    setAttachments,
    deleteAttachment,
    handleAttachment,
    sheetVisible,
    setSheetVisible,
  };
};
