import { Popover, f7ready } from 'framework7-react';
import {
  ComponentType,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { Popover as PopoverType } from 'framework7/types';

import './PopoverWrapper.scss';

export const usePopoverRef = () => {
  const popoverRef = useRef<PopoverType.Popover>();

  return popoverRef;
};

export type PopoverContentComponent = ComponentType<{ onClose: () => void }>;

interface PopoverWrapperProps extends PropsWithChildren {
  className?: string;
  PopoverContent: PopoverContentComponent;
  isVisible?: boolean;
  onClose?: () => void;
}

export const PopoverWrapper: FC<PopoverWrapperProps> = ({
  className = '',
  PopoverContent,
  children,
  isVisible: controlledIsVisible,
  onClose,
}) => {
  const [isMounted, setIsMounted] = useState(controlledIsVisible);
  const popoverRef = usePopoverRef();
  const divRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useLayoutEffect(() => {
    if (isMounted) {
      f7ready((f7) => {
        popoverRef.current = f7.popover.create({
          el: divRef.current,
          on: {
            closed: () => {
              onClose?.();
            },
          },
        });
      });
    }
  }, [isMounted]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (controlledIsVisible && wrapperRef.current) {
      popoverRef.current?.open(wrapperRef.current);
    } else {
      popoverRef.current?.close();
    }
  }, [controlledIsVisible]);

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    popoverRef.current?.open(e.target as HTMLElement);
  };

  const handleClose = () => {
    popoverRef.current?.close();
    onClose?.();
  };

  const popover = useMemo(
    () =>
      document.getElementById('framework7-root') &&
      createPortal(
        <div ref={divRef} className="popover">
          <div className="popover-arrow"></div>
          <div className="popover-inner">
            <PopoverContent onClose={handleClose} />
          </div>
        </div>,
        document.getElementById('framework7-root'),
      ),
    [],
  );

  return (
    <>
      <div ref={wrapperRef} className={`popover-wrapper ${className}`} onClick={handleClick}>
        <div className="popover-wrapper-inner" />
        {children}
      </div>
      {isMounted && popover}
    </>
  );
};
