import { MutableRefObject, useEffect, useState } from 'react';

export function useIsVisible(ref: MutableRefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    if (ref.current) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    } else {
      console.error('useIsVisible::ref.current is not defined');
    }
  }, [ref]);

  return { isVisible: isIntersecting };
}
