import { List as F7List, ListItem as F7ListItem, Fab, f7ready } from 'framework7-react';
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { motion } from 'framer-motion';
import { orderBy } from 'lodash';

import { CritiqueQuestion, useCritiqueQuestionsQuery } from '@queries/CritiqueQueries';
import { useManageCompetitionsQuery } from '@queries/UserQueries';
import { useNearbyVenuesQuery } from '@queries/useFeedQuery';

import { useAnswerCritiqueQuestionMutation } from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import Button from '@components/buttons/Button';
import { Chip } from '@components/chips/Chip';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { HeadCell, RenderTable } from '@components/table/RenderTable';

import { useCompetitionInfo } from '@screens/entertainer/ManageEntertainerProfileScreen';

const headCells: HeadCell<{
  rank: number;
  entertainerId: string;
  entertainerName: string;
  totalVotes: number;
}>[] = [
  {
    id: 'rank',
    label: 'Rank',
    className: 'text-start',
    TableBodyCell: ({ row }) => <td className="text-start">{row.rank}</td>,
  },
  {
    id: 'label',
    label: 'Name',
    className: 'text-start',
    TableBodyCell: ({ row }) => <td className="text-start">{row.entertainerName}</td>,
  },
  {
    id: 'votes',
    label: 'Votes',
    className: 'text-start',
    TableBodyCell: ({ row }) => <td className="text-start">{row.totalVotes}</td>,
  },
];

const CompetitionRankingsCard: FC<{ competition: any }> = ({ competition }) => {
  const { status } = useCompetitionInfo({ competition });

  return (
    <Card>
      <Card.Header
        title={competition.title}
        subtitle={competition.subtitle}
        action={
          <Chip
            className="py-1 px-2"
            variant="outlined"
            color="neutral"
            label={<span style={{ fontSize: '.7rem' }}>{status}</span>}
          />
        }
      />
      <Card.Body>
        <RenderTable headCells={headCells} rows={competition.rankings}></RenderTable>
      </Card.Body>
    </Card>
  );
};

export const ManageCompetitionsScreen = () => {
  const { competitionsAreReady, competitions, refetchCompetitions } = useManageCompetitionsQuery();

  const currentCompetitions = useMemo(() => {
    if (!competitions) {
      return [];
    }

    return Object.values(competitions ?? {})
      .flat()
      .map((competition) => ({
        ...competition,
        rankings:
          competition.rankings?.map((ranking, index) => ({ ...ranking, rank: index + 1 })) ?? [],
      }));
  }, [competitions]);

  return (
    <Screen
      name="Manage Competitions Screen"
      headerProps={{
        back: { to: '/profile' },
        title: 'Competitions',
      }}
      onEnter={refetchCompetitions}
    >
      <Screen.Content>
        {competitionsAreReady ? (
          <>
            {currentCompetitions?.map((competition) => (
              <div key={competition._id} className="mb-4">
                <CompetitionRankingsCard competition={competition} />
              </div>
            ))}
          </>
        ) : (
          <Skeleton height={120} />
        )}
      </Screen.Content>
    </Screen>
  );
};
