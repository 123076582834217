import { useMemo } from 'react';

import { Capacitor } from '@capacitor/core';

const isIOSUserAgent = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

const isAndroidUserAgent = () => {
  return /Android/.test(navigator.userAgent);
};

export const useDevice = () => {
  const isNativePlatform = Capacitor.isNativePlatform;

  const { isAndriod, isIOS, isWeb, isIOSBrowser, isAndroidBrowser } = useMemo(() => {
    try {
      const platform = Capacitor.getPlatform();

      return {
        isAndriod: platform === 'android',
        isIOS: platform === 'ios',
        isWeb: platform === 'web',
        isAndroidBrowser: platform === 'web' && isAndroidUserAgent(),
        isIOSBrowser: platform === 'web' && isIOSUserAgent(),
      };
    } catch (error) {
      return {
        isAndriod: false,
        isIOS: false,
        isWeb: true,
        isAndroidBrowser: isAndroidUserAgent(),
        isIOSBrowser: isIOSUserAgent(),
      };
    }
  }, []);

  return {
    isNativePlatform,
    isAndriod,
    isIOS,
    isWeb,
    isIOSBrowser,
    isAndroidBrowser,
  };
};
