import { useMemo } from 'react';

import { keyBy } from 'lodash';

import { useCurrentUserCity } from './useCurrentUserLocation';

export const useCityOptionsByIds = (cityIds: string[]) => {
  const { currentCityIsReady, cities = [] } = useCurrentUserCity();

  const cityOptions = useMemo(() => {
    if (!cityIds.length) {
      return [];
    }

    const citiesById = keyBy(cities, '_id');

    return cityIds
      .filter((cityId) => citiesById[cityId])
      .map((cityId) => {
        const city = citiesById[cityId];

        return {
          label: city.name,
          value: city._id,
        };
      });
  }, [cities, cityIds]);

  return { cityOptionsAreReady: currentCityIsReady, cityOptions };
};
