import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';

import { SourceLog, useSourceLogsQuery } from '@admin/queries/SourceLogsQueries';

import API from '@api/API';

const replaceWithUpdatedSourceLog = (queryClient: QueryClient, updatedSourceLog: SourceLog) => {
  const { sourceLogs } = queryClient.getQueryData<{ sourceLogs: SourceLog[] }>(
    useSourceLogsQuery.queryKey(),
  );

  const updatedSourceLogs = sourceLogs.map((sourceLog) => {
    if (sourceLog._id === updatedSourceLog._id) {
      return updatedSourceLog;
    }
    return sourceLog;
  });

  queryClient.setQueryData(useSourceLogsQuery.queryKey(), { sourceLogs: updatedSourceLogs });
};

interface UpdateSourceLogPayload {
  events: SourceLog['fineTuningValue']['events'];
}

interface UpdateSourceLogResponseBody {
  sourceLog: SourceLog;
}

export const useUpdateSourceLogMutation = (sourceLogId: string) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationFn: async (payload: UpdateSourceLogPayload) => {
      const response = await API.put<UpdateSourceLogResponseBody, UpdateSourceLogPayload>(
        `/v1/super-admin/source-logs/${sourceLogId}`,
        payload,
      );

      return response;
    },
    onSuccess: (response) => {
      if (response.sourceLog) {
        replaceWithUpdatedSourceLog(queryClient, response.sourceLog);
      }
    },
  });

  return {
    isSubmitting,
    updateSourceLogAsync: mutateAsync,
  };
};
